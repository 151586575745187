import { get } from 'lodash';
import { FC } from 'react';
import { Datagrid, DateField, DateInput, Filter, FilterProps, List, ListProps, ReferenceField, SelectField, SelectInput, TextField } from 'react-admin';
import { choices } from './DataProvider';
import { TimestampsField } from './TimestampsField';
import { Childless } from './types';

export { default as FeedbackMessageIcon } from '@mui/icons-material/Feedback';

const FeedbackMessageFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <DateInput label="Submitted" source="createdAt" />
    <SelectInput label='Recommend' source='severity' choices={ choices.feedbackMessageSeverity } />
  </Filter>
);


export const FeedbackMessageList: FC<Childless<ListProps>> = props => (
  <List { ...props }
    filters={ <FeedbackMessageFilter /> }
    perPage={ 25 }
    exporter={ false }
    sort={ { field: 'createdAt', order: 'DESC' } }
  >
    <Datagrid
      bulkActionButtons={ false }
      rowStyle={ ( record ) => {
        const colors = { notAtAll: 'red', slightly: 'orange' };
        return {
          borderLeft: `solid 10px ${ get( colors, record.severity ) }`,
        }
      } }

    >

      <SelectField label='Recommend?' source='severity' choices={ choices.feedbackMessageSeverity } />
      <TimestampsField label="Submitted" source='createdAt' />
      <TextField source='text' label='Message' />

      <ReferenceField label="Submitter" source="feedback" reference="feedbacks" link={ false }>
        <ReferenceField label="Recipient" source="recipient" reference="recipients" link={ false }>
          <TextField source="fullName" />
        </ReferenceField>
      </ReferenceField>

      <ReferenceField label="Location" source="feedback" reference="feedbacks" link={ false }>
        <ReferenceField label="Location" source="location" reference="locations" link={ false }>
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>

      <ReferenceField label="Practitioner" source="feedback" reference="feedbacks" link={ false }>
        <ReferenceField label="Practitioner" source="practitioner" reference="practitioners" link={ false }>
          <TextField source="fullName" />
        </ReferenceField>
      </ReferenceField>

      <ReferenceField label="Appointment" source="feedback" reference="feedbacks" link={ false }>
        <ReferenceField source="appointment" reference="appointments" link={ false }>
          <DateField source="startTime" showTime />
        </ReferenceField>
      </ReferenceField>



    </Datagrid>
  </List>
);
