import { Box, Card, CardContent, Tab, Tabs } from '@mui/material';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Title } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertTemplateList } from './AlertTemplates';
import { OutboundAlertList } from './OutboundAlerts';
import { ResourceContextProvider } from './ResourceContextProvider';

export const Alerts: FC = () => {
  const basePath = 'broadcasts';
  const tabs = [ 'outboundalerts', 'alerttemplates' ];
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [ _location, setLocation ] = useAppLocationState();
  const [ tab, setTab ] = useState( tabs[ 0 ] );

  const handleChange = ( _event: SyntheticEvent, newValue: string ) => {
    navigate( `/${ basePath }/${ newValue }` );
  };

  useEffect( () => {
    setLocation( basePath );
    return () => setLocation( null );
  }, [] );

  useEffect( () => {
    const [ , , tab ] = pathname.split( '/' )
    if( !tabs.includes( tab ) ) return;
    setTab( tab );
  }, [ pathname ] );

  useEffect( () => {
    setLocation( `${ basePath }.${ tab }` );
  }, [ tab ] );

  return (
    <Box>
      <Title title='Broadcasts ' />
      <Tabs value={ tab } onChange={ handleChange }>
        <Tab disableRipple value='outboundalerts' label='Patient' />
        <Tab disableRipple value='alerttemplates' label='Templates' />
      </Tabs>

      <Card
        sx={ {
          minHeight: '25em',
        } }
      >
        <CardContent>
          <ResourceContextProvider value={ tab }>
            { tab == 'outboundalerts'
              ? <OutboundAlertList title={ <></> } />
              : <AlertTemplateList title={ <></> } />
            }
          </ResourceContextProvider>
        </CardContent>
      </Card>


    </Box >
  );
}
