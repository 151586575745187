import { Box, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { ChartProps, elevation } from './DashboardWidget';
import { useUserPreference } from './UserPreferences';
import useSummary from './use-summary';
import { ReputationActivityDatum } from './DashboardReputationActivity';
import { PatientActivityDatum } from './DashboardPatientActivity';
import useColorPalettes from './use-color-palettes';

interface ValueDeliveredCardProps {
  value: number;
  label: string;
}
const ValueDeliveredCard: FC<ValueDeliveredCardProps> = props => {
  const { value, label } = props;

  const colorPalettes = useColorPalettes();
  const [ green ] = useMemo( () => colorPalettes.spectrumGreenRed, [ colorPalettes ] );

  return (
    <Box
      marginBottom={ 2 }
      marginLeft={ 1 }
      sx={ {
        textAlign: 'right'
      } }
    >
      <Typography
        fontSize={ 112 }
        fontWeight='bold'
        color={ value > 0 ? green : undefined }
      >
        { Math.round( value ).toLocaleString( undefined, {
          style: 'currency',
          currency: 'USD',
          trailingZeroDisplay: 'stripIfInteger',
          notation: value >= 100_000 ? 'compact' : 'standard',
        } ) }
      </Typography>
      <Typography
        fontSize={ 18 }
        fontWeight='medium'
      >
        { label } Value
      </Typography>
    </Box>
  );
};

export const DashboardAdvertisingValueDelivered: FC<ChartProps> = ( { date } ) => {
  const defaultDurationDays = 30;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();

  const days = useMemo( () => {
    if( isLoadingPreferences || !preferences ) return defaultDurationDays;
    return preferences.dashboardActivityDays as number || defaultDurationDays;
  }, [ preferences, isLoadingPreferences ] );

  const summary = useSummary<PatientActivityDatum[]>( 'patientActivity', { date, days } );
  const { messageCount, amenityCount } = useMemo( () => {
    const data = [ 'Messages', 'Amenities' ].map( id => ( {
      id, data: summary.data?.map( d => {
        const { ymd, md, date } = d;
        const y = d[ id ] >= 1 ? d[ id ] : null; // no zeros for log scale
        return { x: ymd, y, ymd, md, date };
      } )
        .filter( d => !!d.y )
        .sort( ( a, b ) => a.ymd < b.ymd ? -1 : 1 ) || []
    } ) );
    const counts = Object.fromEntries( ( data || [] ).map( ( { id, data = [] } ) => [ id, data.reduce( ( tot, d ) => ( d.y || 0 ) + tot, 0 ) ] ) );
    return {
      amenityCount: counts.Amenities || 0,
      messageCount: counts.Messages || 0,
    };
  }, [ summary ] );

  return (
    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        height: '100%',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title="Advertising Value Delivered"
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `${ days } Days Ending ${ date.format( 'MMM D' ) }` : `Previous ${ days } Days` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        <Box>
          <ValueDeliveredCard value={ messageCount * 50 / 1000 } label='Messaging' />
          <Divider/>
          <ValueDeliveredCard value={ amenityCount * 50 / 1000 } label='Amenity Site' />
        </Box>
      </CardContent>
    </Card>
  );
}

export const DashboardLaborValueDelivered: FC<ChartProps> = ( { date } ) => {
  const defaultDurationDays = 30;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();

  const days = useMemo( () => {
    if( isLoadingPreferences || !preferences ) return defaultDurationDays;
    return preferences.dashboardActivityDays as number || defaultDurationDays;
  }, [ preferences, isLoadingPreferences ] );

  const summary = useSummary<PatientActivityDatum[]>( 'patientActivity', { date, days } );
  const { messageCount, profileCount } = useMemo( () => {
    const data = [ 'Messages', 'Profiles' ].map( id => ( {
      id, data: summary.data?.map( d => {
        const { ymd, md, date } = d;
        const y = d[ id ] >= 1 ? d[ id ] : null; // no zeros for log scale
        return { x: ymd, y, ymd, md, date };
      } )
        .filter( d => !!d.y )
        .sort( ( a, b ) => a.ymd < b.ymd ? -1 : 1 ) || []
    } ) );
    const counts = Object.fromEntries( ( data || [] ).map( ( { id, data = [] } ) => [ id, data.reduce( ( tot, d ) => ( d.y || 0 ) + tot, 0 ) ] ) );
    return {
      messageCount: counts.Messages || 0,
      profileCount: counts.Profiles || 0,
    };
  }, [ summary ] );

  return (
    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        height: '100%',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title="Labor Saving Value Delivered"
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `${ days } Days Ending ${ date.format( 'MMM D' ) }` : `Previous ${ days } Days` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        <Box>
          { /*  # of messages * 3 min per message / 60 min/hr * $20 wage   */ }
          <ValueDeliveredCard value={ messageCount * 3 / 60 * 20 } label='Messaging Labor Saved' />
          <Divider/>
          { /*  # of profiles * 10 min per profile / 60 min/hr * $20 wage   */ }
          <ValueDeliveredCard value={ profileCount * 10 / 60 * 20 } label='Profile Setup Labor' />
        </Box>
      </CardContent>
    </Card>
  );
}

export const DashboardReputationValueDelivered: FC<ChartProps> = ( { date } ) => {
  const defaultDurationDays = 30;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();

  const days = useMemo( () => {
    if( isLoadingPreferences || !preferences ) return defaultDurationDays;
    return preferences.dashboardActivityDays as number || defaultDurationDays;
  }, [ preferences, isLoadingPreferences ] );

  const summary = useSummary<ReputationActivityDatum[]>( 'reputationActivity', { date, days } );
  const reviewCount = useMemo( () => {
    const data = [ 'Reviews' ].map( id => ( {
      id, data: summary.data?.map( d => {
        const { ymd, md, date } = d;
        const y = d[ id ] >= 1 ? d[ id ] : null; // no zeros for log scale
        return { x: ymd, y, ymd, md, date };
      } )
        .filter( d => !!d.y )
        .sort( ( a, b ) => a.ymd < b.ymd ? -1 : 1 ) || []
    } ) );
    const counts = Object.fromEntries( ( data || [] ).map( ( { id, data = [] } ) => [ id, data.reduce( ( tot, d ) => ( d.y || 0 ) + tot, 0 ) ] ) );
    return counts.Reviews || 0;
  }, [ summary ] );

  return (
    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        height: '100%',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title="Reputation Value Delivered"
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `${ days } Days Ending ${ date.format( 'MMM D' ) }` : `Previous ${ days } Days` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        <ValueDeliveredCard value={ reviewCount * 200 } label='Reviews' />
      </CardContent>
    </Card>
  );
}
