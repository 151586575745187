import { getExample, parsePhoneNumber } from 'awesome-phonenumber';
import get from 'lodash/get';
import { FC, useCallback } from 'react';
import { FieldProps, FunctionField, TextInput, TextInputProps, useTranslate, Validator } from 'react-admin';

export const regionCode = 'US';  // TODO country should be put in Preferences
export const renderPhoneNumber = ( phone?: string ): string => {
  return phone && parsePhoneNumber( phone, { regionCode } ).number?.national || '';
}

export interface AwesomePhoneInputProps extends TextInputProps {
  strict?: boolean;
}

export const AwesomePhoneInput: FC<AwesomePhoneInputProps> = props => {
  const { source, validate, strict, ...rest } = props;
  const regionCode = 'US';  // TODO country should be put in Preferences
  const translate = useTranslate()

  // const normalizePhone = useCallback<NonNullable<TextInputProps[ 'parse' ]>>( ( value?: string ) => {
  //   if( !value || value.match( /[^-\d()\s]/ ) ) return value;
  //   const example = getExample( regionCode, 'fixed-line-or-mobile' ).number!.significant!;
  //   const onlyNums = value.replace( /[^\d]/g, '' ).slice( 0, example.length );
  //   const ayt = getAsYouType( regionCode );
  //   return ayt.reset( onlyNums );
  // }, [ regionCode ] );

  const phoneValidation = useCallback<Validator>( ( value?: string ): string | undefined => {
    if( !value || strict && value.length === 0 ) return;
    const number = parsePhoneNumber( value, { regionCode } );
    if( number?.number?.e164 && number.number.e164.length >= 12 && number.number.e164.slice( 2, 5 ) == '555' ) return; // allow area code 555
    if( !number.valid || !number.number.e164 ) return translate( 'app.validation.phone_invalid' );
  }, [ strict, regionCode, translate ] )

  const validatePhone = useCallback( (): Validator[] => {
    return [ phoneValidation ].concat( validate || [] );
  }, [ validate, phoneValidation ] );

  const formatNumber: TextInputProps[ 'format' ] = ( value?: string ) => {
    return !value ? undefined : parsePhoneNumber( value, { regionCode } ).number?.national || value;
  }
  const parseNumber: TextInputProps[ 'parse' ] = ( value?: string ) => {
    return !value ? undefined : parsePhoneNumber( value, { regionCode } ).number?.e164 || value;
  }
  const placeholder = (): TextInputProps[ 'placeholder' ] => {
    return getExample( regionCode, 'fixed-line-or-mobile' ).number?.national;
  }

  return (
    <TextInput
      type="text"
      source={ source }
      format={ formatNumber }
      parse={ parseNumber /* normalizePhone */ }
      validate={ validatePhone() }
      placeholder={ placeholder() }
      { ...rest } />
  );

}

export const AwesomePhoneField: FC<FieldProps> = props => {

  return (
    <FunctionField
      render={ ( record, source ) => {
        const phone = source && get( record, source );
        return renderPhoneNumber( phone );
      } }
      { ...props } />
  );

}
