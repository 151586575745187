
export interface WeekdayOpenClose {
  weekday: number;
  openHour?: number;
  openMinute?: number;
  closeHour?: number;
  closeMinute?: number;
}
export type WeekdaysHours = WeekdayOpenClose[]

export const defaultWeekdaysHours: WeekdaysHours = [
  { weekday: 1, openHour: 8, openMinute: 0, closeHour: 17, closeMinute: 0 },
  { weekday: 2, openHour: 8, openMinute: 0, closeHour: 17, closeMinute: 0 },
  { weekday: 3, openHour: 8, openMinute: 0, closeHour: 17, closeMinute: 0 },
  { weekday: 4, openHour: 8, openMinute: 0, closeHour: 17, closeMinute: 0 },
  { weekday: 5, openHour: 8, openMinute: 0, closeHour: 17, closeMinute: 0 },
  { weekday: 6 },
  { weekday: 0 },
]


export const lpad = ( n = 0, len = 2, pad = '0' ): string => ( pad.repeat( len ) + n ).slice( 0 - len )

type WeekdayHoursPair = [ string, string | undefined ];
type WeekdaysHoursPair = [ string[], string | undefined ];
type WeekdaysHoursPairs = WeekdaysHoursPair[];

const nbsp = '\u00a0';

export const formatWeekdaysHours = ( weekdaysHours: WeekdaysHours = [], useNbsp = false ): string => {
  if( !weekdaysHours ) return '';
  const days = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
  const formatTime = ( hour?: number, minute?: number ): string => (
    `${ ( hour && hour > 12 ? hour - 12 : hour )?.toString() }${ minute ? `:${ lpad( minute ) }` : '' } ${ !hour || hour <= 12 ? 'AM' : 'PM' }`
  );
  const formatDayHours = ( wd: WeekdayOpenClose ): WeekdayHoursPair => {
    const { weekday, openHour, openMinute, closeHour, closeMinute } = wd;
    const day = days[ weekday ] || '';
    if( openHour == undefined || closeHour == undefined ) {
      return [ day, undefined ];
    }
    const open = formatTime( openHour, openMinute );
    const close = formatTime( closeHour, closeMinute );
    return [ day, `${ open } - ${ close }` ];
  }
  const weekdaysHoursReducer = ( pairs: WeekdaysHoursPairs, [ day, hours ]: WeekdayHoursPair ): WeekdaysHoursPairs => {
    let [ prevDays, prevHours ]: WeekdaysHoursPair = [ [], undefined ];
    if( pairs.length > 0 ) {
      [ prevDays, prevHours ] = pairs[ pairs.length - 1 ];
    }
    if( prevHours && prevHours == hours ) {
      return pairs.slice( 0, -1 ).concat( [ [ [ ...prevDays, day ], prevHours ] ] )
    }
    return pairs.concat( [ [ [ day ], hours ] ] );
  }

  return weekdaysHours
    .map( formatDayHours )
    .reduce( weekdaysHoursReducer, [] as WeekdaysHoursPairs )
    .filter( pair => pair[ 1 ] )
    .map( ( [ days, hours ]: WeekdaysHoursPair ): string => {
      return `${ days.length > 2 ? `${ days[ 0 ] }-${ days.slice( -1 )[ 0 ] }` : days.join( ', ' ) } ${ hours }`
    } )
    .map( s => useNbsp ? s.replace( / /g, nbsp ) : s )
    .join( ', ' );
}

