/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import Icon from '@mui/icons-material/CloudUploadOutlined';
import { Box, Dialog } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { useTheme } from '@mui/material/styles';
import color from 'color-string';
import { range } from 'lodash';
import { Attributes, Children, cloneElement, FC, isValidElement, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { ImageInputProps, InputHelperText, InputProps, Labeled, RaRecord, sanitizeInputRestProps, useInput, useTranslate } from 'react-admin';
import { useDropzone } from 'react-dropzone';
import { Area, ImageCropper, OnDiscard, OnSave } from './ImageCropper';
import ImageInputEditPreview from './ImageInputEditPreview';

const setAlpha = ( c: string, a: number ): string => {
  return color.to.rgb( color.get.rgb( c ).slice( 0, 3 ), a );
}

export interface ImageInputEditProps extends ImageInputProps {
  aspect?: number;
  maxFiles?: number;
}

export type OnDrop = NonNullable<NonNullable<ImageInputEditProps[ 'options' ]>[ 'onDrop' ]>;

export interface RecordWithFile extends Omit<RaRecord, 'id'> {
  rawFile: File | Blob;
  encodedFileField?: string;
  crop?: Area;
  rotation?: number;
}

export const ImageInputEdit: FC<ImageInputEditProps> = ( props ) => {
  const {
    accept,
    aspect,
    children,
    // className,
    // classes: classesOverride,
    format,
    helperText,
    label,
    labelMultiple = 'ra.input.image.upload_several',
    labelSingle = 'ra.input.image.upload_single',
    maxFiles = 5,
    maxSize = 4000000, // 4mb
    minSize,
    multiple = false,
    options = {}, //options: {
    //   inputProps: inputPropsOptions,
    //   ...options
    // } = {} as ImageInputEditProps,
    onRemove: onRemoveCallback,
    parse,
    placeholder,
    resource,
    source,
    validate,
    ...rest
  } = props;
  const translate = useTranslate();
  const theme = useTheme();

  // turn a browser dropped file structure into expected structure
  const transformFile = useCallback( ( file: Blob | File | RecordWithFile | undefined /* , name: string */ ): RecordWithFile | null => {
    if( !( file instanceof File || file instanceof Blob ) ) {
      return file ?? null;
    }
    const { source, title } = ( Children.only( children ) as ReactElement ).props;
    const preview = URL.createObjectURL( file );
    const transformedFile: RecordWithFile = {
      rawFile: file,
      // originalName: name,
      [ source.split( '.' ).slice( -1 )[ 0 ] ]: preview,
    };
    if( title && file instanceof File ) {
      transformedFile[ title ] = file.name;
    }
    return transformedFile;
  }, [ children ] );

  const transformFiles = useCallback<NonNullable<InputProps[ 'format' ]>>( ( files: File[] /*, name: string */ ): RecordWithFile | RecordWithFile[] | null => {
    if( !files ) {
      return multiple ? [] : null;
    }
    if( Array.isArray( files ) ) {
      return files
        .map( file => transformFile( file ) )
        .filter( ( x ): x is RecordWithFile => x !== null );
    }
    return transformFile( files );
  }, [ multiple ] );

  const {
    id,
    field: { onChange, value, ...inputProps },
    fieldState: { error },
    // formState: { isSubmitted, errors: submitErrors },
    isRequired,
  } = useInput( {
    format: format || transformFiles,
    parse: parse || transformFiles,
    source,
    type: 'file',
    validate,
    ...rest,
  } );

  const [ editingIndex, setEditingIndex ] = useState<number | undefined>();
  const [ crop, setCrop ] = useState<Area | undefined>();
  const [ rotation, setRotation ] = useState<number | undefined>();
  const [ pendingCrop, setPendingCrop ] = useState<number[]>( [] );


  // const { touched = false, error, submitError } = formState;
  const files = useMemo( () => {
    return value ? ( Array.isArray( value ) ? value : [ value ] ) : [];
  }, [ value ] );


  const onDrop = useCallback<OnDrop>( async ( newFiles, rejectedFiles, event ) => {
    const updatedFiles = multiple ? [ ...files, ...newFiles ] : [ ...newFiles ];

    if( multiple ) {
      onChange( updatedFiles );
    } else {
      onChange( updatedFiles[ 0 ] );
    }

    if( options.onDrop ) {
      options.onDrop( newFiles, rejectedFiles, event );
    }

    // trigger edit so that the aspect is enforced
    setPendingCrop( range( files.length, updatedFiles.length ) );

  }, [ files, multiple, options.onDrop, setEditingIndex, setPendingCrop ] );

  useEffect( () => {
    if( editingIndex === undefined && pendingCrop.length > 0 ) {
      setPendingCrop( pendingCrop.slice( 1 ) );
      setEditingIndex( pendingCrop[ 0 ] );
    }
  }, [ editingIndex, pendingCrop, setPendingCrop ] )

  const onRemove = useCallback( ( index: number, file: File ) => () => {
    console.log( 'clicked remove', index, file.size )
    if( multiple ) {
      const filteredFiles = [ ...files ];
      filteredFiles.splice( index, 1 );
      onChange( filteredFiles );
    } else {
      onChange( null );
    }

    if( onRemoveCallback ) {
      onRemoveCallback( file );
    }
  }, [ multiple, files, onRemoveCallback ] );

  const onEdit = useCallback( ( index: number, file: RecordWithFile ) => () => {
    console.log( 'clicked edit', index, file.rawFile.size );
    setCrop( files[ index ].crop )
    setRotation( files[ index ].rotation )
    setEditingIndex( index );
  }, [ files, setCrop, setRotation, setEditingIndex ] );

  const onDiscard: OnDiscard = useCallback( async () => {
    console.log( 'onDiscard' );
    const isExisting = !!( editingIndex !== undefined && files[ editingIndex ]?.id )
    setEditingIndex( undefined );
    setCrop( undefined );
    setRotation( undefined );
    // keep if preexisting, discard is new
    if( isExisting ) {
      return;
    }
    if( multiple && editingIndex != undefined ) {
      const filteredFiles = [ ...files ];
      filteredFiles.splice( editingIndex, 1 );
      onChange( filteredFiles );
    } else {
      onChange( undefined );
    }
  }, [ editingIndex, files, setCrop, setRotation, setEditingIndex, onChange ] );

  const onSave: OnSave = useCallback( async ( image?: string, crop?: Area, rotation?: number ) => {
    if( !image || editingIndex == undefined ) {
      setEditingIndex( undefined );
      setCrop( undefined );
      setRotation( undefined );
      console.log( 'onSave crop empty' );
      return;
    }
    files[ editingIndex ][ source.split( '.' ).slice( -1 )[ 0 ] ] = image; // preview
    files[ editingIndex ].crop = crop;
    files[ editingIndex ].rotation = rotation;
    setCrop( crop );
    setRotation( rotation );
    console.log( 'onSave crop', editingIndex, crop );
    onChange( multiple ? files : files[ 0 ] );
    setEditingIndex( undefined );
  }, [ editingIndex, files, setCrop, setRotation, setEditingIndex, onChange ] );


  // const theme = useTheme();
  // const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );
  const fullScreen = true;

  const childrenElement: ReactElement<unknown> | undefined =
    children && isValidElement( Children.only( children ) )
      ? ( Children.only( children ) as ReactElement )
      : undefined;

  const { getRootProps, getInputProps } = useDropzone( {
    ...options,
    accept,
    maxFiles,
    maxSize,
    minSize,
    multiple,
    onDrop,
  } );

  return (
    <Labeled
      id={ id }
      label={ label }
      sx={ {
        width: '100%'
      } }
      source={ source }
      resource={ resource }
      isRequired={ isRequired }
      // meta={ meta }
      { ...sanitizeInputRestProps( rest ) }
    >
      <>
        <FormHelperText>
          <InputHelperText
            // touched={ isTouched }
            error={ error?.message }
            helperText={ helperText }
          />
        </FormHelperText>
        { children && childrenElement && (
          <Box
            sx={ {
              display: 'inline-block',
            } }
          >
            { files.map( ( file, index ) => (
              <ImageInputEditPreview
                key={ index }
                file={ file }
                onRemove={ onRemove( index, file ) }
                onEdit={ onEdit( index, file ) }
                sx={ {
                  display: 'inline-block',
                  position: 'relative',
                  paddingRight: theme.spacing( 9 ),
                  float: 'left',
                  '& button': {
                    position: 'absolute',
                    minWidth: theme.spacing( 2 ),
                    opacity: 0.4,
                  },
                  '&:hover button': {
                    opacity: 1,
                  },
                } }
              >
                <>
                  { cloneElement( childrenElement, {
                    record: file,
                    sx: {
                      display: 'inline-block',
                      background: theme.palette.background.default,
                    },
                  } as ( Partial<unknown> & Attributes ) ) }
                  <Dialog fullScreen={ fullScreen } open={ editingIndex === index } >
                    <ImageCropper
                      image={ file.rawFile }
                      initialCroppedArea={ crop }
                      rotation={ rotation }
                      aspect={ aspect }
                      onSave={ onSave }
                      onDiscard={ onDiscard }
                    />
                  </Dialog>
                </>
              </ImageInputEditPreview>
            ) ) }
          </Box>
        ) }
        { ( multiple || files.length < 1 ) &&
          <Box
            data-testid="dropzone"
            sx={ {
              maxWidth: '300px',
              background: setAlpha( theme.palette.secondary.main, .1 ),
              borderRadius: theme.spacing( 1 ),
              cursor: 'pointer',
              padding: theme.spacing( 3 ),
              textAlign: 'center',
              color: theme.palette.getContrastText(
                theme.palette.background.default
              ),
              '&.dropZoneDisabled': {
                display: 'none',
              },
            } }
            { ...getRootProps() }
          >
            <input
              id={ id }
              { ...getInputProps( {
                ...inputProps,
                //                ...inputPropsOptions,
              } ) }
            />
            { placeholder
              ? (
                placeholder
              )
              : (
                <p>
                  <Icon
                    sx={ {
                      verticalAlign: 'middle',
                      marginRight: theme.spacing( 2 ),

                    } }
                  />
                  { translate( multiple ? labelMultiple : labelSingle ) }
                </p>
              ) }
          </Box>
        }
      </>
    </Labeled >
  );
};
