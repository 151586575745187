/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { FC } from 'react';
import { Datagrid, Edit, EditProps, Filter, FilterProps, List, ListProps, SearchInput, Show, ShowProps, SimpleFormProps, TextField, TextInput } from 'react-admin';
import { AdminShowLayout, AdminSimpleForm, useAdminListAppLocation } from './Admin';
import { CustomToolbar } from './ReminderTemplateLists';
import { ShowActionsWithReset } from './ShowActionsWithReset';
import { TimestampsField } from './TimestampsField';
import { Childless } from './types';

export { default as HtmlEmailTitleIcon } from '@mui/icons-material/Email';

const HtmlEmailTitleFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export const HtmlEmailTitleList: FC<ListProps> = props => {
  useAdminListAppLocation();
  return (
    <List
      { ...props }
      exporter={ false }
      perPage={ 25 }
      filters={ <HtmlEmailTitleFilter /> }
    >
      <Datagrid rowClick="edit" expand={ <HtmlEmailTitleExpandEdit /> } bulkActionButtons={ false } >

        <TextField label="Name" source="id" />
        <TextField label="Comment" source="comment" />
        {/* <TextField source="value"  /> */ }
        <TimestampsField label="Updated" source="updatedAt" />
      </Datagrid>
    </List>
  );
}

export const HtmlEmailTitleShow: FC<ShowProps> = () => {

  return (
    <Show actions={ <ShowActionsWithReset /> }>
      <AdminShowLayout>
        <TextField label='Name' source='id' />
        <TextField source='value' />
        <TextField source='comment' />

        <TimestampsField label="Updated" source="updatedAt" />
        <TimestampsField label="Updated" source="createdAt" />
      </AdminShowLayout>
    </Show>
  );
}


// TODO set comment to edited in UI
const HtmlEmailTitleForm: FC<Omit<SimpleFormProps, 'children'>> = props => (
  <AdminSimpleForm sanitizeEmptyValues  { ...props } toolbar={ <CustomToolbar /> } >


    <TextField label="Name" source="id" />
    <TextInput source="comment" />

    <TextInput source="value" label="Value" />

  </AdminSimpleForm>
);

export const HtmlEmailTitleExpandEdit: FC<Childless<EditProps>> = props => (
  <Edit
    actions={ false }
    title={ '&nbsp;' }
    redirect='list'
    { ...props } >
    <HtmlEmailTitleForm />
  </Edit>
);

export const HtmlEmailTitleEdit: FC<EditProps> = props => (
  <Edit
    // actions={ <EditActions breadcrumb={ <MyBreadcrumb variant="actions" /> } /> }
    redirect='list'
    { ...props }
  >
    <HtmlEmailTitleForm />
  </Edit>
);

