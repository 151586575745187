/* eslint-disable */
// @ts-nocheck
import * as React from 'react';
import { useCallback, useState } from 'react';
import {
  ChoicesProps,
  FieldTitle,
  InputHelperText,
  InputProps,
  sanitizeInputRestProps,
  useChoices,
  useChoicesContext,
  useInput,
  useTranslate,
} from 'react-admin';
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  List,
  ListSubheader,
  styled,
} from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowRightIcon from '@mui/icons-material/ChevronRight';
import DoubleChevronLeft from './icons/DoubleChevronLeft';
import DoubleChevronRight from './icons/DoubleChevronRight';
import clsx from 'clsx';
import { DualListInputItem } from '@react-admin/ra-relationships/esm/src/DualListInput/DualListInputItem';
import { DualListInputSkeleton } from '@react-admin/ra-relationships/esm/src/DualListInput/DualListInputSkeleton';

/**
 * An Input component displaying two list of selected or available items.
 * It allows multiple selections and uses an array of objects for the options.
 *
 * Pass possible options as an array of objects in the 'choices' attribute.
 *
 * By default, the options are built from:
 *  - the 'id' property as the option value,
 *  - the 'name' property an the option text
 * @example
 * const choices = [
 *    { id: 'programming', name: 'Programming' },
 *    { id: 'lifestyle', name: 'Lifestyle' },
 *    { id: 'photography', name: 'Photography' },
 * ];
 * <DualListInput source="tags" choices={choices} />
 *
 * You can also customize the properties to use for the option name and value,
 * thanks to the 'optionText' and 'optionValue' attributes.
 * @example
 * const choices = [
 *    { _id: 123, full_name: 'Leo Tolstoi', sex: 'M' },
 *    { _id: 456, full_name: 'Jane Austen', sex: 'F' },
 * ];
 * <DualListInput source="authors" choices={choices} optionText="full_name" optionValue="_id" />
 *
 * `optionText` also accepts a function, so you can shape the option text at will:
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;
 * <DualListInput source="authors" choices={choices} optionText={optionRenderer} />
 *
 * `optionText` also accepts a React Element, that will be cloned and receive
 * the related choice as the `record` prop. You can use Field components there.
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
 * <DualListInput source="authors" choices={choices} optionText={<FullNameField />}/>
 *
 * The choices are translated by default, so you can use translation identifiers as choices:
 * @example
 * const choices = [
 *    { id: 'programming', name: 'myroot.tags.programming' },
 *    { id: 'lifestyle', name: 'myroot.tags.lifestyle' },
 *    { id: 'photography', name: 'myroot.tags.photography' },
 * ];
 */
export const DualListInput = ( props: DualListInputProps ) => {
  const {
    addButtonLabel = 'ra-relationships.duallistinput.select',
    addAllButtonLabel, //  = 'ra-relationships.duallistinput.select-all',
    availableItemsLabel = 'ra-relationships.duallistinput.availableItems',
    choices: choicesProp,
    className,
    dense = true,
    disableValue = 'disabled',
    label,
    helperText,
    isFetching: isFetchingProp,
    isLoading: isLoadingProp,
    optionText = 'name',
    optionValue = 'id',
    removeButtonLabel = 'ra-relationships.duallistinput.unselect',
    removeAllButtonLabel, // = 'ra-relationships.duallistinput.unselect-all',
    resource: resourceProp,
    selectedItemsLabel = 'ra-relationships.duallistinput.selectedItems',
    source: sourceProp,
    translateChoice,
    validate,
    format,
    onBlur,
    onChange,
    parse,
    ...rest
  } = props;

  const translate = useTranslate();

  const { allChoices, isLoading, source, resource } = useChoicesContext( {
    choices: choicesProp,
    isLoading: isLoadingProp,
    isFetching: isFetchingProp,
    resource: resourceProp,
    source: sourceProp,
  } );

  const { getChoiceValue } = useChoices( {
    optionText,
    optionValue,
    translateChoice,
  } );

  const {
    field,
    isRequired,
    fieldState: { error, invalid, isTouched, isDirty },
    formState: { isSubmitted },
  } = useInput( {
    format,
    onBlur,
    onChange,
    parse,
    resource,
    source,
    validate,
    ...rest,
  } );

  // This handle the internal selection of items which can then be moved
  // from one list to the other
  const [ selectedItems, setSelectedItems ] = useState( [] );

  // Toggle the selection of a single item
  const handleToggleItemSelection = useCallback(
    ( event, item ): void => {
      setSelectedItems( currentSelectedItems => {
        const isItemSelected = currentSelectedItems.some(
          selectedItem =>
            getChoiceValue( selectedItem ) === getChoiceValue( item )
        );

        if( isItemSelected ) {
          return currentSelectedItems.filter(
            selectedItem =>
              getChoiceValue( selectedItem ) !==
              getChoiceValue( item )
          );
        } else {
          return [ ...currentSelectedItems, item ];
        }
      } );
    },
    [ getChoiceValue ]
  );

  const setInputValue = useCallback(
    ( value: any[] ): void => {
      field.onChange( value );
      setSelectedItems( [] );
    },
    [ field ]
  );

  // Handler called when an item should be moved to the other list
  const handleMoveItem = useCallback(
    ( event, choice ) => {
      if(
        ( field.value || [] ).some(
          value => value === getChoiceValue( choice )
        )
      ) {
        setInputValue(
          ( field.value || [] ).filter(
            item => item !== getChoiceValue( choice )
          )
        );
        return;
      }

      setInputValue( [ ...field.value, getChoiceValue( choice ) ] );
    },
    [ getChoiceValue, field, setInputValue ]
  );

  // Handler called when the selected items should be added to the input value
  const handleAddItems = useCallback( () => {
    const currentSet = new Set( field.value || [] );
    selectedItems.forEach( item => currentSet.add( getChoiceValue( item ) ) );
    setInputValue( Array.from( currentSet ) );
  }, [ getChoiceValue, field, selectedItems, setInputValue ] );

  const handleAddAllItems = useCallback( () => {
    const currentSet = new Set( field.value || [] );
    allChoices.filter( choice =>
      !( field.value || [] )
        .some( val => getChoiceValue( choice ) === val ) )
      .forEach( item => currentSet.add( getChoiceValue( item ) ) );
    setInputValue( Array.from( currentSet ) );
  }, [ getChoiceValue, field, selectedItems, setInputValue ] );

  const disabledAddItems = useCallback( () => {
    return !selectedItems
      .map( item => getChoiceValue( item ) )
      .some( selected => !field.value?.includes( selected ) );
  }, [ getChoiceValue, field, selectedItems ] );

  const disabledAddAllItems = useCallback( () => {
    return ( field.value || [] ).length >= allChoices.length;
  }, [ field.value, allChoices ] );

  // Handler called when the selected items should be removed from the input value
  const handleRemoveItems = useCallback( () => {
    const newValue = ( field.value || [] ).filter(
      value =>
        !selectedItems.some( choice => getChoiceValue( choice ) === value )
    );
    setInputValue( newValue );
  }, [ getChoiceValue, field, selectedItems, setInputValue ] );

  const handleRemoveAllItems = useCallback( () => {
    setInputValue( [] );
  }, [ getChoiceValue, field, selectedItems, setInputValue ] );

  const disabledRemoveItems = useCallback( () => {
    return !selectedItems
      .map( item => getChoiceValue( item ) )
      .some( selected => field.value?.includes( selected ) );
  }, [ getChoiceValue, field, selectedItems ] );

  const disabledRemoveAllItems = useCallback( () => {
    return ( field.value || [] ).length === 0
  }, [ field.value ] );

  return (
    <Root
      fullWidth
      margin="normal"
      className={ clsx( 'ra-input', `ra-input-${ source }`, className ) }
      error={ ( isDirty || isSubmitted ) && invalid }
      { ...sanitizeInputRestProps( rest ) }
    >
      <InputLabel
        htmlFor={ source }
        shrink
        error={ ( isDirty || isSubmitted ) && invalid }
      >
        <FieldTitle
          label={ label }
          source={ source }
          resource={ resource }
          isRequired={ isRequired }
        />
      </InputLabel>
      <div className={ DualListInputClasses.main }>
        <div>
          <ListSubheader
            component="div"
            id="available-items-title"
            className={ DualListInputClasses.listHeader }
          >
            { translate( availableItemsLabel, {
              _:
                availableItemsLabel ===
                  'ra-relationships.duallistinput.availableItems'
                  ? 'Available items'
                  : availableItemsLabel,
            } ) }
          </ListSubheader>
          { isLoading ? (
            <DualListInputSkeleton
              className={ DualListInputClasses.list }
            />
          ) : (
            <List
              className={ clsx(
                DualListInputClasses.list,
                DualListInputClasses.availableList
              ) }
              dense={ dense }
              disablePadding
              aria-labelledby="available-items-title"
              aria-multiselectable="true"
              role="listbox"
            >
              { allChoices.map( choice =>
                !field.value.includes(
                  getChoiceValue( choice )
                ) ? (
                  <DualListInputItem
                    key={ getChoiceValue( choice ) }
                    choice={ choice }
                    disableValue={ disableValue }
                    onMove={ handleMoveItem }
                    onToggleSelection={
                      handleToggleItemSelection
                    }
                    optionText={ optionText }
                    optionValue={ optionValue }
                    selected={ selectedItems.some(
                      selectedItem =>
                        getChoiceValue( selectedItem ) ===
                        getChoiceValue( choice )
                    ) }
                    translateChoice={ translateChoice }
                  />
                ) : null
              ) }
            </List>
          ) }
        </div>
        <div className={ DualListInputClasses.actions }>
          { addAllButtonLabel &&
            <Button
              className={ clsx(
                DualListInputClasses.button,
                DualListInputClasses.addButton,
                DualListInputClasses.addAllButton
              ) }
              onClick={ handleAddAllItems }
              endIcon={ <DoubleChevronRight /> }
              variant="contained"
              color='inherit'
              disabled={ disabledAddAllItems() }
            >
              { translate( addAllButtonLabel, {
                _: addAllButtonLabel,
              } ) }
            </Button>
          }
          <Button
            className={ clsx(
              DualListInputClasses.button,
              DualListInputClasses.addButton
            ) }
            onClick={ handleAddItems }
            endIcon={ <ArrowRightIcon /> }
            color='inherit'
            variant="contained"
            disabled={ disabledAddItems() }
          >
            { translate( addButtonLabel, {
              _:
                addButtonLabel ===
                  'ra-relationships.duallistinput.select'
                  ? 'Select'
                  : addButtonLabel,
            } ) }
          </Button>
          <Button
            className={ clsx(
              DualListInputClasses.button,
              DualListInputClasses.removeButton
            ) }
            onClick={ handleRemoveItems }
            startIcon={ <ArrowLeftIcon /> }
            color='inherit'
            variant="contained"
            disabled={ disabledRemoveItems() }
          >
            { translate( removeButtonLabel, {
              _:
                removeButtonLabel ===
                  'ra-relationships.duallistinput.unselect'
                  ? 'Unselect'
                  : removeButtonLabel,
            } ) }
          </Button>
          { removeAllButtonLabel &&
            <Button
              className={ clsx(
                DualListInputClasses.button,
                DualListInputClasses.removeButton,
                DualListInputClasses.removeAllButton
              ) }
              onClick={ handleRemoveAllItems }
              startIcon={ <DoubleChevronLeft /> }
              color='inherit'
              variant="contained"
              disabled={ disabledRemoveAllItems() }
            >
              { translate( removeAllButtonLabel, {
                _: removeAllButtonLabel,
              } ) }
            </Button>
          }
        </div>
        <div>
          <ListSubheader
            component="div"
            id="selected-items-title"
            className={ clsx( DualListInputClasses.listHeader, DualListInputClasses.selectedListHeader ) }
          >
            { translate( selectedItemsLabel, {
              _:
                selectedItemsLabel ===
                  'ra-relationships.duallistinput.selectedItems'
                  ? 'Selected items'
                  : selectedItemsLabel,
            } ) }
          </ListSubheader>
          { isLoading ? (
            <DualListInputSkeleton
              className={ DualListInputClasses.list }
            />
          ) : (
            <List
              className={ clsx(
                DualListInputClasses.list,
                DualListInputClasses.selectedList
              ) }
              dense={ dense }
              disablePadding
              aria-labelledby="selected-items-title"
              aria-multiselectable="true"
              role="listbox"
            >
              { allChoices.map( choice =>
                field.value.includes( getChoiceValue( choice ) ) ? (
                  <DualListInputItem
                    key={ getChoiceValue( choice ) }
                    choice={ choice }
                    disableValue={ disableValue }
                    onMove={ handleMoveItem }
                    onToggleSelection={
                      handleToggleItemSelection
                    }
                    optionText={ optionText }
                    optionValue={ optionValue }
                    selected={ selectedItems.some(
                      selectedItem =>
                        getChoiceValue( selectedItem ) ===
                        getChoiceValue( choice )
                    ) }
                    translateChoice={ translateChoice }
                  />
                ) : null
              ) }
            </List>
          ) }
        </div>
      </div>
      <FormHelperText error={ ( isTouched || isSubmitted ) && invalid }>
        <InputHelperText
          touched={ isTouched || isSubmitted }
          error={ error?.message }
          helperText={ helperText }
        />
      </FormHelperText>
    </Root>
  );
};

DualListInput.defaultProps = {
  defaultValue: [],
  source: '',
};

interface Props {
  availableItemsLabel?: string;
  selectedItemsLabel?: string;
  choices?: any;
  className?: string;
  disableValue?: string;
  source?: string;
  dense?: boolean;
  addButtonLabel?: string;
  removeButtonLabel?: string;
  addAllButtonLabel?: string;
  removeAllButtonLabel?: string;
}

export type DualListInputProps = Props &
  Omit<ChoicesProps, 'choices'> &
  Omit<InputProps, 'source'>;

const PREFIX = 'RaDualListInput';

export const DualListInputClasses = {
  main: `${ PREFIX }-main`,
  actions: `${ PREFIX }-actions`,
  button: `${ PREFIX }-button`,
  addButton: `${ PREFIX }-addButton`,
  addAllButton: `${ PREFIX }-addAllButton`,
  removeButton: `${ PREFIX }-removeButton`,
  removeAllButton: `${ PREFIX }-removeAllButton`,
  list: `${ PREFIX }-list`,
  listHeader: `${ PREFIX }-listHeader`,
  selectedListHeader: `${ PREFIX }-selectedListHeader`,
  selectedList: `${ PREFIX }-selectedList`,
  availableList: `${ PREFIX }-availableList`,
};

const Root = styled( FormControl, {
  name: PREFIX,
  overridesResolver: ( props, styles ) => styles.root,
} )( ( { theme } ) => ( {
  [ `& .${ DualListInputClasses.main }` ]: {
    display: 'flex',
    marginTop: theme.spacing( 3 ),
  },
  [ `& .${ DualListInputClasses.actions }` ]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing( 1 ),
    justifyContent: 'center',
    marginLeft: theme.spacing( 1 ),
    marginRight: theme.spacing( 1 ),
  },
  [ `& .${ DualListInputClasses.button }` ]: {
    justifyContent: 'space-between',
  },
  [ `& .${ DualListInputClasses.addAllButton }` ]: {
    // marginBottom: theme.spacing( 1 ),
  },
  [ `& .${ DualListInputClasses.removeAllButton }` ]: {
    // marginTop: theme.spacing( 1 ),
  },
  [ `& .${ DualListInputClasses.list }` ]: {
    minWidth: 256,
    height: 256,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.divider,
    overflow: 'auto',
  },
  [ `& .${ DualListInputClasses.listHeader }` ]: {
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottom: 'none',
  },
  [ `& .${ DualListInputClasses.selectedListHeader }` ]: {
    fontWeight: 'bold',
  },
  [ `& .${ DualListInputClasses.selectedList }` ]: {
    backgroundColor: theme.palette.background.paper,
  },
  [ `& .${ DualListInputClasses.availableList }` ]: {
    backgroundColor: theme.palette.background.paper,
  },
} ) );
