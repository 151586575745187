/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { BooleanField, Datagrid, DateField, Filter, FilterProps, FunctionField, List, ListProps, Pagination, ReferenceField, ReferenceManyField, SearchInput, SelectField, Show, ShowProps, Tab, TextField, TitleProps } from 'react-admin';
import { AwesomePhoneField, renderPhoneNumber } from './AwesomePhoneInput';
import { choices } from './DataProvider';
import { TabbedShowLayoutWithLocation, useListAppLocation } from './MyBreadcrumb';
import { useEmr } from './Organizations';
import { Childless } from './types';

export { Face as PatientIcon } from '@mui/icons-material';

export const RenderedText: FC<{ value?: string }> = ( { value } ) => <Typography>{ value || null } &nbsp;</Typography>;

const PatientTitle: FC<TitleProps> = props => {
  return <span>Patient { props.record?.fullName ? `"${ props.record.fullName }"` : '' }</span>;
};


const PatientFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export const PatientList: FC<Childless<ListProps>> = props => {
  useListAppLocation( 'patient' );
  const emr = useEmr();

  const filterDefaultValues = {
    // status: 'Current',
  };

  return (

    <List { ...props }
      resource='recipients'
      filter={ {
        recipientType: 'Patient',
        active: true,
      } }
      filterDefaultValues={ filterDefaultValues }
      filters={ <PatientFilter /> }
      perPage={ 25 }
      sort={ { field: 'fullName', order: 'ASC' } }
      exporter={ false }
    >
      <Datagrid
        rowClick="show"                         // TODO HERE redirects to Recipients 
        bulkActionButtons={ false }

      >
        <TextField source="fullName" />
        <DateField source="birthDate" options={ { timeZone: 'UTC' } } />
        <SelectField source="status" choices={ choices.patientStatus } />
        <ReferenceField source="location" reference="locations" link='show' >
          <TextField source='name' />
        </ReferenceField>


        <TextField source="fhirId" label={ `${ emr } Id` } />

      </Datagrid>
    </List >
  );
}

export const PatientShow: FC<ShowProps> = props => {

  return (
    <Show
      resource='recipients'
      title={ <PatientTitle /> }
      { ...props }
    >
      <TabbedShowLayoutWithLocation basePath='patient'      >
        <Tab label="Patient">
          <TextField source="fullName" />
          <DateField source="birthDate" options={ { timeZone: 'UTC' } } />
          <SelectField source="status" choices={ choices.patientStatus } />


          <TextField source="medicalRecordNumber" />

          <ReferenceField source="location" reference="locations" link='show' >
            <TextField source='name' />
          </ReferenceField>

          {/* 
        <ArrayField source="telecomOptions">
        <EditableDatagrid
        undoable
        createForm={ <TelecomOptionForm /> }
        editForm={ <TelecomOptionForm /> }
        children={ TelecomOptionRow() }
        />
        </ArrayField>
      */}

          <FunctionField
            label="Mobile"
            source="telecomOptions"
            render={ ( record ): ReactNode => {
              const mobile = record?.telecomOptions?.find( ( o: Record<string, string> ) => o.system == 'phone' && o.use == 'mobile' )?.value;
              return <RenderedText value={ renderPhoneNumber( mobile ) } />
            } }
            sortable={ false }
          />
          <FunctionField
            label="Email"
            source="telecomOptions"
            render={ ( record ): ReactNode => {
              const email = record?.telecomOptions?.find( ( o: Record<string, string> ) => o.system == 'email' )?.value;
              // if( !email ) return <Typography>&nbsp;</Typography>;
              // return email;
              return <RenderedText value={ email } />
            } }
            sortable={ false }
          />

          <ReferenceManyField
            label="Contacts"
            target="parent"
            reference="recipients"

          >
            <Datagrid
              bulkActionButtons={ false }
              empty={ <Typography variant='body2'>No contacts</Typography> }
            >
              <TextField source="fullName" />
              <AwesomePhoneField label="Mobile" source="telecomOptions.0.value" />
              <TextField label="Email" source="telecomOptions.1.value" />
              <SelectField source="recipientType" choices={ choices.recipientType } />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Sent Messages">
          <ReferenceManyField label="" reference="outboundmessages" target="recipient"
            pagination={ <Pagination /> }
            sort={ { field: 'postedDate', order: 'DESC' } }
          >
            <Datagrid rowStyle={ () => ( { verticalAlign: 'top' } ) } bulkActionButtons={ false }>
              <DateField label="Posted" source="postedDate" showTime />
              <TextField label="Template" source="templateName" />
              <SelectField label="Channel" source="transmitMethod" choices={ choices.transmitMethod } sortable={ false } />
              <BooleanField label="Delivered" source="transmitCompleted" sortable={ false } />
              <DateField label="Delivered" source="smsDeliveredDate" showTime />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Calendar Events">
          <ReferenceManyField label="" reference="calendarevents" target="participant"
            pagination={ <Pagination /> }
            sort={ { field: 'start', order: 'ASC' } }
          >
            <Datagrid rowStyle={ () => ( { verticalAlign: 'top' } ) } bulkActionButtons={ false }>
              <TextField label="Description" source="description" />
              <FunctionField label="Start" render={ ( record ): ReactNode => {
                return <DateField label="Start" source="start" showTime options={ { timeZone: record?.timeZoneName } } />
              } } />
              <FunctionField label="End" render={ ( record ): ReactNode => {
                return <DateField label="End" source="end" showTime options={ { timeZone: record?.timeZoneName } } />
              } } />
              <TextField label="Notes" source="notes" />
              <FunctionField label="Participants" render={ ( record ): ReactNode => {
                return <RenderedText value={ ( record?.emrParticipantIds || [] ).length || 1 } />
              } } />
              <TextField label="Repeats" source="repeating" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Calendar Occurrences">
          <ReferenceManyField label="" reference="calendaroccurrences" target="participant"
            pagination={ <Pagination /> }
            sort={ { field: 'start', order: 'ASC' } }
          >
            <Datagrid rowStyle={ () => ( { verticalAlign: 'top' } ) } bulkActionButtons={ false }>
              <TextField label="Description" source="description" />
              <FunctionField label="Start" render={ ( record ): ReactNode => {
                return <DateField label="Start" source="start" showTime options={ { timeZone: record?.timeZoneName } } />
              } } />
              <FunctionField label="End" render={ ( record ): ReactNode => {
                return <DateField label="End" source="end" showTime options={ { timeZone: record?.timeZoneName } } />
              } } />
              <TextField label="Notes" source="notes" />
              <FunctionField label="Participants" render={ ( record ): ReactNode => {
                return <RenderedText value={ ( record?.emrParticipantIds || [] ).length || 1 } />
              } } />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayoutWithLocation>
    </Show >
  );
}

