import { SsidChart as LineChartIcon, TableChartOutlined as TableIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, IconButton as MuiIconButton, Typography, useTheme } from '@mui/material';
import { Datum, Line, Serie } from '@nivo/line';
import { addDays } from 'date-fns';
import { chunk } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { Loading } from 'react-admin';
import { DailyLineChartSliceTooltipHeader, makeLineChartSliceTooltip } from './DashboardTooltip';
import { ChartProps, elevation, LineLegendProps, OnlyNumbers } from './DashboardWidget';
import { useUserPreference } from './UserPreferences';
import useColorPalettes from './use-color-palettes';
import { useOrgDateMarkers } from './use-markers';
import useSummary from './use-summary';
import { DashboardDatagrid } from './DashboardDatagrid';


export interface PatientActivityDatum extends Datum {
  date: string;
  md: string;
  ymd: string;
  Amenities: number;
  Appointments: number;
  Messages: number;
  Profiles: number;
  Reviews: number;
  Undeliverables: number;
  Unsubscribes: number;
}

export interface PatientActivityLineDatum extends Datum {
  y: number | null;
  ymd: string;
  md: string;
}

export interface PatientActivityLinePoint extends Serie {
  data: PatientActivityLineDatum[];
}

export const DashboardPatientActivity: FC<ChartProps> = ( { date } ) => {
  const theme = useTheme();
  const isSmall = false;
  const defaultDurationDays = 30;
  const legendCols = 2;
  const cacheKey = 'dashboard-patient-activity';
  const [ showDatagrid, setShowDatagrid ] = useState( false );
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const keys = [ 'Appointments', 'Messages', 'Profiles', 'Amenities', 'Undeliverables', 'Unsubscribes', 'Reviews' ] as ( keyof OnlyNumbers<PatientActivityDatum> )[];

  const durationDays = useMemo( () => {
    if( isLoadingPreferences || !preferences?.dashboardActivityDays ) return defaultDurationDays;
    return preferences.dashboardActivityDays as number;
  }, [ preferences, isLoadingPreferences ] );

  const summary = useSummary<PatientActivityDatum[]>( 'patientActivity', { date, days: durationDays } );

  // @ts-ignore: 2322
  const data = useMemo( (): PatientActivityLinePoint[] => keys.map( id => ( {
    id, data: summary.data?.map( d => {
      const { ymd, md, date } = d;
      const y = d[ id ] >= 1 ? d[ id ] : null; // no zeros for log scale
      return { x: ymd, y, ymd, md, date };
    } )
      .filter( d => !!d.y )
      .sort( ( a, b ) => a.ymd < b.ymd ? -1 : 1 ) || []
  } ) ), [ date, keys, summary.data ] );

  const isEmpty = useMemo( () => !data || data[ 0 ]?.date === 'No data', [ data ] );
  const counts = useMemo( () => (
    Object.fromEntries( ( data || [] ).map( ( { id, data = [] } ) => [ id, data.reduce( ( tot, d ) => ( d.y || 0 ) + tot, 0 ) ] ) )
  ), [ data ] );
  const yMax = useMemo( () => Math.max( ...[ 10, ...( data || [] ).flatMap( row => ( row.data || [] ).map( d => d.y || 0 ) ) ] ), [ data ] );

  const [ hiddenSeries, setHiddenSeries ] = useState<string[]>( [] );
  const handleLegendClick = useCallback( ( datum: Datum ) => {
    const id = datum.id;
    const idx = hiddenSeries.indexOf( id );
    if( idx < 0 ) {
      setHiddenSeries( [ ...hiddenSeries, id ] );
      return;
    }
    const newValue = [ ...hiddenSeries ];
    newValue.splice( idx, 1 );
    setHiddenSeries( newValue );
  }, [ hiddenSeries, setHiddenSeries ] );

  //   discretePrimary: [ '#FF8A00', '#00BEE6', '#F6484D', '#00BBC1', '#2973EF', '#9600E5', '#18B74E' ], // orange, cyan, red, turquois, blue, purple, green
  const colorPalettes = useColorPalettes();
  const colors = useMemo( () => colorPalettes.discretePrimary //.slice( -keys.length )
    , [ colorPalettes, keys ] );

  const markers = useOrgDateMarkers( { date, duration: { type: 'days', count: durationDays } } );

  return (
    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={
          <Box sx={ { display: 'flex' } } >
            <Typography>Patient Activity</Typography>
            <Box sx={ { flexGrow: 1 } } />
            <MuiIconButton
              sx={ {
                // paddingTop: 0, paddingBottom: 0,
                marginTop: '-8px',
                marginBottom: '-8px',
              } }
              onClick={ () => setShowDatagrid( !showDatagrid ) }
            >
              { showDatagrid ? <LineChartIcon /> : <TableIcon /> }
            </MuiIconButton>
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `${ durationDays } Days Ending ${ date.format( 'MMM D' ) }` : `Previous ${ durationDays } Days` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
            // fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
      // avatar={ <DashboardAlertIcon /> }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        { summary.isLoading
          ? <Box>
            <Loading
              loadingPrimary=''
              loadingSecondary=''
              sx={ {
                '@media (min-width: 0)': {
                  marginTop: 0,
                  marginBottom: 7,
                  height: '100%',
                  width: 500,
                  minHeight: 400,
                }
              } }
            />
          </Box>

          :
          <Box
            sx={ {
              position: 'relative',
              marginTop: '-30px', // see height 420
            } }
          >
            { showDatagrid
              ? (
                <DashboardDatagrid
                  rows={ ( summary.data || [] ).map( ( d, id ) => ( { id, ...d } ) ) }
                  columns={ keys.map( k => `${k}` ) }
                  exportFileName={ cacheKey }
                />
              )
              : <>
                <Line
                  width={ 500 }
                  height={ 430 }
                  curve='monotoneX'

                  data={ !data || isEmpty ? [] : data }
                  // margin={ { top: 40, right: 40, bottom: 55, left: 75 } }
                  margin={ { top: 110, right: 20, bottom: 55, left: 75 } }
                  gridYValues={ 4 }

                  isInteractive={ !isEmpty }
                  useMesh={ !isEmpty }
                  enableCrosshair
                  // enableArea
                  areaOpacity={ 0.1 }
                  lineWidth={ 3 }
                  pointSize={ 6 }

                  xFormat="time:%Y-%m-%d"
                  xScale={ {
                    type: 'time',
                    format: '%Y-%m-%d',
                    precision: 'day',
                    useUTC: false,
                    max: date ? date.toDate() : new Date(),
                    min: date ? addDays( date.toDate(), 0 - durationDays ) : addDays( new Date(), 0 - durationDays ),
                  } }

                  yScale={ {
                    type: 'log',
                    base: 2,
                    max: yMax * 1.5,
                    min: 1,
                  } }



                  // isInteractive={ !isEmpty }

                  // onClick={ ( datum ) => {
                  //   const { id: activity, data: { ymd } } = datum;  // activity: 'views', date: '5/31'
                  //   if( !ymd ) return;
                  //   const resource = typeof activity == 'string' && activity.toLowerCase() == 'views'
                  //     ? 'reputationviews'
                  //     : typeof activity == 'string' && activity.toLowerCase() == 'blocks'
                  //       ? 'reputationfeedbacks'
                  //       : 'reputationclicks';
                  //   const filter = JSON.stringify( {
                  //     delimiter: ';',
                  //     // createdAt: [ `gte:${ ymd }`, `lte:${ ymd }` ].join( ';' ),
                  //     createdAt: ymd,
                  //   } );
                  //   redirect( `/reviews/${ resource }?${ queryString.stringify( { filter } ) }` );
                  // } }
                  enableSlices='x'
                  sliceTooltip={ makeLineChartSliceTooltip( {
                    theme,
                    renderHeader: DailyLineChartSliceTooltipHeader,
                  } ) }

                  markers={ markers }

                  // onClick={ ( point ) => {
                  //   // @ts-ignore:2339,2352
                  //   const { serieId: id, data: { ymd } } = point as ActionTrendLinePoint;  // action: 'confirm', date: '5/31'
                  //   if( !ymd ) return;
                  //   const filter = JSON.stringify( {
                  //     action: typeof id == 'string' ? id.toLowerCase() : undefined,
                  //     // createdAt: [ `gte:${ ymd }`, `lte:${ ymd }` ].join( ';' ),
                  //     createdAt: ymd,
                  //     delimiter: ';',
                  //   } );
                  //   // TODO HERE
                  //   // redirect( `/actionlogs?${ queryString.stringify( { filter } ) }` );
                  // } }

                  theme={ {
                    background: theme.palette?.background?.default,
                    axis: {
                      legend: { text: { fontSize: isSmall ? 16 : 18 } },
                      ticks: { text: { fontSize: isSmall ? 0 : 14 } },
                    },
                    labels: { text: { fill: '#333' } },
                    legends: { text: { fontSize: 14 } },
                    text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
                    tooltip: {
                      container: {
                        borderRadius: '8px',
                        border: `0.5px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
                        boxShadow: `${ theme.palette?.grey?.A400 ?? 'grey' } 0px 2px 2px`,
                        backgroundColor: theme.palette?.background?.default,
                        color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
                        padding: '5px 9px',
                      },
                    },
                  } }

                  // theme={ {
                  //   background: theme.palette?.background?.default,
                  //   axis: {
                  //     legend: { text: { fontSize: isSmall ? 16 : 18 } },
                  //     ticks: { text: { fontSize: isSmall ? 0 : 14 } },
                  //   },
                  //   labels: { text: { fill: '#333' } },
                  //   legends: { text: { fontSize: 14 } },
                  //   text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
                  //   tooltip: {
                  //     container: {
                  //       borderRadius: '8px',
                  //       border: `0.5px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
                  //       boxShadow: `${ theme.palette?.grey?.A400 ?? 'grey' } 0px 2px 2px`,
                  //       backgroundColor: theme.palette?.background?.default,
                  //       color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
                  //       padding: '5px 9px',
                  //     },
                  //   },
                  // } }

                  // colors={ { scheme: 'nivo' } }
                  colors={ isEmpty ? [ theme.palette?.mode === 'dark' ? '#555' : '#ccc' ] : colors } // { scheme: 'accent', size: keys.length } }
                  // enableLabel={ false }
                  enableGridX={ false }
                  enableGridY={ true }
                  axisTop={ null }
                  axisRight={ null }
                  axisLeft={ {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: 4, // [ 0, 1000, 2000, 3000 ],
                    legend: 'Daily count (log2)',
                    legendPosition: 'middle',
                    legendOffset: -67

                  } }

                  axisBottom={ {
                    tickSize: 5,
                    // ticksPosition: 'before',
                    tickPadding: 5,
                    format: '%-m/%d',
                    tickValues: `every ${ durationDays < 30 ? 2 : 7 } days`,
                    legend: 'Activity date',
                    legendPosition: 'middle',
                    legendOffset: 42,
                  } }

                  // multirow legend (by 2)
                  legends={ isEmpty
                    ? undefined
                    : chunk( keys, legendCols ).map<LineLegendProps>( ( ch, chIdx, chArr ) => (
                      {
                        data: [
                          ...ch.map( ( id, idx, _arr ) => ( {
                            id,
                            label: `${ id } (${ counts[ id ] || 0 })`,
                            fill: colors[ idx + legendCols * chIdx ],
                            hidden: !!hiddenSeries.includes( `${ id }` ),
                          } ) ),
                          // this works for legendCols 2 but not 3
                          ...( keys.length % legendCols !== 0 && chIdx == chArr.length - 1
                            ? [ { id: 'x', opacity: 0.2, label: '', fill: 'transparent' } ]
                            : []
                          ),
                        ],
                        anchor: 'top-left',
                        direction: 'row',
                        justify: false,
                        translateX: -40,
                        translateY: -100 + chIdx * 22,
                        itemsSpacing: 80,
                        itemWidth: 120, // * ( keys.length % legendCols !== 0 && chIdx == chArr.length - 1 ? legendCols : 1 ),
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 1, // 0.95,
                        onClick: handleLegendClick,
                        symbolSize: 18, // 20,
                        symbolShape: 'circle', // 'square',
                        // effects: [
                        //   {
                        //     on: 'hover',
                        //     style: {
                        //       itemOpacity: 1,
                        //       itemBackground: '#eee',
                        //
                        //     }
                        //   }
                        // ],
                        toggleSerie: true,
                      }
                    ) ) }
                />
              </>
            }
          </Box>
        }
      </CardContent >
    </Card >
  );

}

