/*eslint @typescript-eslint/no-unused-vars: "off"*/
import { Box, InputAdornment, Typography, useTheme } from '@mui/material';
import { blue, green, purple, red, yellow } from '@mui/material/colors';
import { get } from 'lodash';
import { FC, useMemo } from 'react';
import { AutocompleteInput, BooleanField, Datagrid, DateField, DateInput, Filter, FilterProps, Identifier, Labeled, List, ListProps, RaRecord, ReferenceField, SelectField, SelectInput, Show, ShowProps, TextField, TextInput, TitleProps, useGetList, WithRecord } from 'react-admin';
import { apiUrl, choices } from './DataProvider';
import { SimpleShowLayoutWithLocation, useListAppLocation } from './MyBreadcrumb';
import { PolymorphicReferenceField } from './PolymorphicReferenceField';
import { TimestampsField } from './TimestampsField';
import { Childless } from './types';



export { default as OutboundMessageIcon } from '@mui/icons-material/Sms';


export const OutboundMessageTitle: FC<TitleProps> = props => {
  return <span>OutboundMessage { props.record?.name ? `"${ props.record.name }"` : '' }</span>;
};


import SearchIcon from '@mui/icons-material/Search';
import { useEmr } from './Organizations';

const OutboundMessageFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => {
  const { data: templates } = useGetList( 'messagetemplates', { pagination: { perPage: 200, page: 1 }, sort: { field: 'name', order: 'ASC' } } );
  const templateChoices = useMemo( () => templates?.map( t => ( { id: t.name, name: t.name } ) ) || [], [ templates ] );

  //  <SearchInput  source="q" alwaysOn />

  return (
    <Filter { ...props }>
      <DateInput label="Date" source="postedDate" alwaysOn />
      <TextInput
        label='Recipient name'
        source="q"
        resettable
        InputProps={ {
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon color="disabled" />
            </InputAdornment>
          ),
        } }
        size="small"

      />
      <SelectInput label="Channel" source="transmitMethod" choices={ choices.transmitMethod.slice( 2, -1 ) } />
      <AutocompleteInput sx={ { width: '40rem' } } label="Template" source="templateName" choices={ templateChoices } multiple />
    </Filter>
  );
}

const OutboundMessageListPanel: FC<Partial<{ basePath: string; id: Identifier; record: RaRecord; resource: string; }>> = ( { record } ) => (
  <div dangerouslySetInnerHTML={ { __html: `<pre>${ record?.message }</pre>` } } />
);

export const OutboundMessageList: FC<Childless<ListProps>> = props => {
  useListAppLocation( 'messaging' );
  const emr = useEmr();

  const patientLink = [ 'PointClickCare' ].includes( emr ) ? 'show' : false;

  const rowStyle = ( { recipientRole, templateName = '' }: RaRecord ): Record<string, unknown> => {
    const sat = '200';
    const roleMap = {
      Practitioner: blue[ sat ],
      'Non-Patient': green[ sat ],
      'Family Member': green[ sat ],
      'Extended Family Member': green[ sat ],
      Neighbor: green[ sat ],
      Friend: green[ sat ],
      Guardian: green[ sat ],
      Caregiver: green[ sat ],
      Transportation: green[ sat ],
      'Skilled Nursing': green[ sat ],
      Physician: green[ sat ],
      Attorney: green[ sat ],
      Other: red[ sat ],
    };
    const isUpdate = () => templateName.match( /^(Appointment Updated|Patient Update) - Patient/ )
      ? yellow[ sat ]
      : templateName.match( /^Pathology/ )
        ? purple[ sat ]
        : undefined;
    return {
      // backgroundColor: get( roleMap, recipientRole, isUpdate() ),
      verticalAlign: 'top',
      // '& .column-templateName .MuiTypography-root': {
      borderLeft: `solid 10px ${ get( roleMap, recipientRole, isUpdate() ) ?? 'transparent' }`,
      // }
    }

  }

  const filterDefaultValues = {
    //transmitMethod: 'sms'
  };

  return (
    <List { ...props }
      filterDefaultValues={ filterDefaultValues }
      filters={ <OutboundMessageFilter /> }
      sort={ { field: 'postedDate', order: 'DESC' } }
      perPage={ 25 }
      exporter={ false }
    >
      <Datagrid
        rowClick='show'
        rowStyle={ rowStyle }
        // expand={ <OutboundMessageListPanel /> }
        bulkActionButtons={ false }
      >
        <DateField label="Posted" source="postedDate" showTime />
        <TextField label="Template" source="templateName" />
        <TextField label="Subject" source="subject" />
        { /*
         Cannot link to template because record store name, not id!
         <ReferenceField label="Purpose" source="templateName" reference="MessageTemplates" link={ false }>
         <TextField source="messagePurpose" />
         </ReferenceField>
       */ }
        { /*
         <ReferenceField source="appointment" reference="appointments" link='show'>
         <DateField source="startTime" showTime />
         </ReferenceField>
       */ }
        <ReferenceField source="patient" reference="recipients" link={ patientLink } sortable={ false }>
          <TextField source="fullName" />
        </ReferenceField>
        <PolymorphicReferenceField
          source="recipient"
          reference={ ( record ) => record?.recipientRole == 'Practitioner' ? 'practitioners' : 'recipients' }
          link={ false }
          sortable={ false }
        >
          <TextField source="fullName" />
        </PolymorphicReferenceField>
        { /*
         <TextField label="Role" source="recipientRole" />
         <TextField label="Message" source="message" component="pre" style={ { maxHeight: '4em', overflow: 'hidden', maxWidth: '20em' } } />
       */ }
        <SelectField label="Channel" source="transmitMethod" choices={ choices.transmitMethod } sortable={ false } />
        <BooleanField label="Delivered" source="transmitCompleted" sortable={ false } FalseIcon={ null } textAlign='center' />
        <TextField label="Log" source="transmitCompletedComment" sortable={ false } />
      </Datagrid>
    </List>
  );
}

export const parseJson = ( json?: string ): Record<string, unknown> => {
  if( !json ) return {};
  try {
    return JSON.parse( json );
  } catch( _e ) {
    return {};
  }
}

export const convertTwimlToText = ( twiml?: string | unknown ): string => {
  if( !twiml || typeof twiml != 'string' ) return '';
  return twiml.replaceAll( /<break[^>]*>/g, '\n\n' )
    .replaceAll( /<.*?>/g, '' )
    .replaceAll( /<Gather[^>]*>/g, '' )
    .replaceAll( /<[?]xml[^>]*>/g, '' )
    .replaceAll( /<[/]Gather[^>]*>/g, '' )
    .replaceAll( /\n\n\n\n/g, '\n' )
    .replaceAll( /\n\n\n/g, '\n' )
    .replaceAll( /\n\n/g, '\n' );
}

export const OutboundMessageShow: FC<ShowProps> = props => {
  const theme = useTheme();
  // const appointmentRowStyle = ( { status }: RaRecord ): Record<string, unknown> => {
  //   const map = {
  //     fulfilled: grey[ '400' ],
  //     'checked-in': green[ '200' ],
  //     cancelled: yellow[ '200' ],
  //     // pending: 'yellow',
  //   };
  //   return { backgroundColor: get( map, status ) };
  // }

  return (
    ( <Show
      // actions={ <ShowActionsOnShowTabOnly /> }

      // title={ <PractitionerTitle /> }
      { ...props }
    >
      <SimpleShowLayoutWithLocation basePath='messaging'>
        <DateField label="Posted" source="postedDate" showTime />
        <TextField label="Template" source="templateName" />
        { /*
             Cannot link to template because record store name, not id!
             <ReferenceField label="Purpose" source="templateName" reference="MessageTemplates" link={ false }>
             <TextField source="messagePurpose" />
             </ReferenceField>
           */ }
        { /*
             <ReferenceField source="appointment" reference="appointments" link='show'>
             <DateField source="startTime" showTime />
             </ReferenceField>
           */ }
        <ReferenceField source="patient" reference="recipients" link={ false } sortable={ false }>
          <TextField source="fullName" />
        </ReferenceField>
        <PolymorphicReferenceField
          source="recipient"
          reference={ ( record ) => record?.recipientRole == 'Practitioner' ? 'practitioners' : 'recipients' }
          link={ false }
          sortable={ false }
        >
          <TextField source="fullName" />
        </PolymorphicReferenceField>
        <TextField label="Role" source="recipientRole" />
        { /*
             <TextField label="Message" source="message" component="pre" style={ { maxHeight: '4em', overflow: 'hidden', maxWidth: '20em' } } />
           */ }
        <SelectField label="Channel" source="transmitMethod" choices={ choices.transmitMethod } sortable={ false } />
        <BooleanField label="Delivered" source="transmitCompleted" sortable={ false } />

        <TimestampsField source='createdAt' />
        <TimestampsField source='updatedAt' />

        <Labeled label='Preview'>
          <WithRecord render={ ( record: RaRecord ) => {
            const { transmitMethod, message } = record as unknown as { transmitMethod: string, message: string; };
            const { twimlStart = '' } = transmitMethod == 'voice' ? parseJson( message ) : {};
            const twiml = convertTwimlToText( twimlStart );

            return transmitMethod == 'sms'
              ? <Box
                sx={ {
                  minHeight: '10rem',
                  marginTop: 0,
                  padding: '1rem',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: theme.palette.divider,
                  overflowWrap: 'break-word',
                  '& .MuiTypography-root': {
                    fontFamily: 'monospace',
                    fontSize: '90%',
                  },
                } }
              >
                { message.split( /\n/ ).map( ( s, key ) => <Typography key={ key }> { s || ( <span>&nbsp;</span> ) } </Typography> ) }
              </Box>
              : transmitMethod == 'email'
                ? <Box sx={ { margin: '0 10rem 2rem' } }>

                  <iframe
                    width={ 700 }
                    height={ 1200 }
                    seamless
                    src={ `${ apiUrl }/preview/outboundmessages/${ record.id }` }
                  />

                </Box>
                : transmitMethod == 'voice'
                  ? <Box // sx={ { margin: '0 10rem 2rem' } }>
                    sx={ {
                      minHeight: '10rem',
                      marginTop: 0,
                      padding: '1rem',
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderColor: theme.palette.divider,
                      overflowWrap: 'break-word',
                      '& .MuiTypography-root': {
                        fontSize: '90%',
                      },
                    } }
                  >
                    { twiml.split( /\n/ ).map( ( s, key ) => <Typography key={ key }> { s || ( <span>&nbsp;</span> ) } </Typography> ) }
                  </Box>
                  : <Typography>Voice preview not implement yet.</Typography>;
          } } />
        </Labeled>

      </SimpleShowLayoutWithLocation>
    </Show > )
  );
}

