import { FC } from 'react';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';


export const SurveyCreatorExample: FC = () => {

  const creator = new SurveyCreator( {
    showThemeTab: true,
    showLogicTab: true,
    isAutoSave: true,
    showSimulatorInPreviewTab: true,
  } );

  return (
    <SurveyCreatorComponent creator={ creator } />
  );
}
