/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { AddCircleOutline as AddIcon, ArrowCircleDown as ArrowDownwardIcon, ArrowCircleUp as ArrowUpwardIcon, RemoveCircleOutline as CloseIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { FC, useMemo } from 'react';
import { ArrayField, ArrayInput, AutocompleteInput, ButtonProps, Datagrid, Edit, EditProps, IconButtonWithTooltip, Labeled, List, ListProps, Show, ShowProps, SimpleFormIterator, SimpleFormProps, TextField, TextInput, useGetList, useSimpleFormIterator, useSimpleFormIteratorItem, WithRecord } from 'react-admin';
import { AdminShowLayout, AdminSimpleForm, useAdminListAppLocation } from './Admin';
import { apiUrl } from './DataProvider';
import { CustomToolbar } from './ReminderTemplateLists';
import { ShowActionsWithReset } from './ShowActionsWithReset';
import { TimestampsField } from './TimestampsField';

export { default as HtmlAssemblyIcon } from '@mui/icons-material/Email';

export const HtmlAssemblyList: FC<ListProps> = props => {
  useAdminListAppLocation();

  return (
    <List
      { ...props }
      exporter={ false }
      perPage={ 25 }
    >
      <Datagrid rowClick="show"
        bulkActionButtons={ false }
      >

        <TextField label="Name" source="id" />
        <TextField label="Comment" source="comment" />
        {/* <TextField source="value"  /> */ }
        <TimestampsField label="Updated" source="updatedAt" />
      </Datagrid>
    </List>
  );
}

export const HtmlAssemblyShow: FC<ShowProps> = () => {

  return (
    <Show
      actions={ <ShowActionsWithReset /> }
    >
      <AdminShowLayout >

        <TextField label="Name" source="id" />

        <TextField source="comment" />

        <ArrayField source="assemblies">
          <Datagrid
            bulkActionButtons={ false }
            sx={ {
              '& .RaDatagrid-rowCell.column-id': {
                verticalAlign: 'top',
              },
            } }
          >
            <TextField label='Assembly' source="id" />

            <ArrayField source="components">
              <Datagrid
                bulkActionButtons={ false }
                sx={ {
                  '& .RaDatagrid-thead': { display: 'none' },
                  // '& .RaDatagrid-headerCell.column-id': { borderBottom: 0 },
                  '& .RaDatagrid-rowCell.column-id': { borderBottom: 0 },
                } }
                empty={ <></> }
              >
                <TextField label={ false } source="id" />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ArrayField>

        <TimestampsField source='createdAt' />
        <TimestampsField source='updatedAt' />

        <Labeled label='Preview'>
          <WithRecord render={ record => {

            return (
              <Box sx={ { margin: '0 10rem 2rem' } }>

                <iframe
                  width={ 700 }
                  height={ 1200 }
                  seamless
                  src={ `${ apiUrl }/preview/htmlassemblies/${ record.id }` }
                />

              </Box>
            );
          } } />
        </Labeled>

      </AdminShowLayout >
    </Show>
  );
}

export const AddItemButton = ( props: ButtonProps & { label?: string } ) => {
  const { label, ...rest } = props;
  const { add } = useSimpleFormIterator();
  return (
    <IconButtonWithTooltip
      label={ label ? `Add ${ label }` : 'ra.action.add' }
      size="small"
      onClick={ () => add() }
      color="primary"
      { ...rest }
    >
      <AddIcon fontSize="medium" />
    </IconButtonWithTooltip>
  );
};

export const RemoveItemButton = ( props: Omit<ButtonProps, 'onClick'> & { label?: string } ) => {
  const { label, ...rest } = props;
  const { remove } = useSimpleFormIteratorItem();

  return (
    <IconButtonWithTooltip
      label={ label ? `Remove ${ label }` : 'ra.action.remove' }
      size="small"
      onClick={ () => remove() }
      color="warning"
      { ...rest }
    >
      <CloseIcon fontSize="medium" />
    </IconButtonWithTooltip>
  );
};

export const ReOrderButtons = ( { className, label }: { className?: string, label?: string } ) => {
  const { index, total, reOrder } = useSimpleFormIteratorItem();

  return (
    <span className={ className }>
      <IconButtonWithTooltip
        label={ label ? `Move ${ label } up` : 'ra.action.move_up' }
        size="small"
        onClick={ () => reOrder( index - 1 ) }
        disabled={ index <= 0 }
        color="primary"
      >
        <ArrowUpwardIcon fontSize="medium" />
      </IconButtonWithTooltip>
      <IconButtonWithTooltip
        label={ label ? `Move ${ label } down` : 'ra.action.move_down' }
        size="small"
        onClick={ () => reOrder( index + 1 ) }
        disabled={ total == null || index >= total - 1 }
        color="primary"
      >
        <ArrowDownwardIcon fontSize="medium" />
      </IconButtonWithTooltip>
    </span>
  );
};


const HtmlAssemblyForm: FC<Omit<SimpleFormProps, 'children'>> = props => {
  const { data: components, isLoading } = useGetList( 'htmlcomponents', { pagination: { page: 1, perPage: 1000 } } );
  // const choices = useMemo( () => components?.map( c => ( { id: c.id, name: c.id.replace( /^html(Presentation)?/, '' ) } ) ) || [], [ components ] );
  const choices = useMemo( () => components?.map( c => ( { id: c.id, name: c.id } ) ) || [], [ components ] );
  const sectionChoices = useMemo( () => choices.filter( c => !c.id.match( /^htmlPresentation/ ) ), [ choices ] );
  const componentChoices = useMemo( () => choices.filter( c => c.id.match( /^htmlPresentation/ ) ), [ choices ] );

  if( isLoading ) return null;

  return (
    <AdminSimpleForm sanitizeEmptyValues  { ...props } toolbar={ <CustomToolbar /> } >

      <Labeled label='Name'>
        <TextField label="Name" source="id" />
      </Labeled>

      <ArrayInput source='assemblies' label='Sections'>
        <SimpleFormIterator
          inline

          getItemLabel={ index => `Section ${ index + 1 }` }
          addButton={ <AddItemButton label='section' /> }
          removeButton={ <RemoveItemButton label='section' /> }
          reOrderButtons={ <ReOrderButtons label='section' /> }
          disableClear
        >
          <AutocompleteInput label="Name" source="id" choices={ sectionChoices }
            sx={ {
              minWidth: '20rem',
              // border: 'solid red 1px',
            } }
            isRequired
          />

          <ArrayInput source='components'
            sx={ {
              // border: 'solid blue 1px',
              width: 'inherit',
              paddingTop: '4rem',
            } }
          >
            <SimpleFormIterator
              inline
              getItemLabel={ index => `Component ${ index + 1 }` }
              addButton={ <AddItemButton label='component' /> }
              removeButton={ <RemoveItemButton label='component' /> }
              reOrderButtons={ <ReOrderButtons label='component' /> }
              disableClear
            >
              <AutocompleteInput label="Name" source="id" choices={ componentChoices }
                sx={ {
                  minWidth: '25rem',
                  // border: 'solid red 1px',
                } }
                isRequired
              />
            </SimpleFormIterator>
          </ArrayInput>

        </SimpleFormIterator>
      </ArrayInput>


      <TextInput source="comment" />

    </AdminSimpleForm >
  );
}


export const HtmlAssemblyEdit: FC<EditProps> = props => (
  <Edit
    // actions={ <EditActions breadcrumb={ <MyBreadcrumb variant="actions" /> } /> }
    redirect='show'
    { ...props }
    mutationMode='pessimistic'
  >
    <HtmlAssemblyForm />
  </Edit>
);

// export const HtmlAssemblyEdit: FC<EditProps> = props => ( <Edit { ...props } ><HtmlAssemblyForm /></Edit> );

