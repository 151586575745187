import { CancelOutlined as CancelIcon, RestorePageOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { FC, MouseEventHandler, ReactElement, useState } from 'react';
import { Button, ButtonProps, Confirm, EditButton, RaRecord, TopToolbar, useNotify, useRecordContext, useResourceContext, useResourceDefinition } from 'react-admin';
import { apiUrl, httpClient, queryClient } from './DataProvider';

/**
 * Action Toolbar for the Show view
 *
 * Internal component. If you want to add or remove actions for a Show view,
 * write your own ShowActions Component. Then, in the <Show> component,
 * use it in the `actions` prop to pass a custom component.
 *
 * @example
 *     import Button from '@mui/material/Button';
 *     import { TopToolbar, ShowButton, Show } from 'react-admin';
 *
 *     const PostShowActions = () => (
 *         <TopToolbar>
 *             <ShowButton />
 *             // Add your custom actions here //
 *             <Button color="primary" onClick={customAction}>Custom Action</Button>
 *         </TopToolbar>
 *     );
 *
 *     export const PostShow = (props) => (
 *         <Show actions={<PostShowActions />} {...props}>
 *             ...
 *         </Show>
 *     );
 */
export const ShowActionsWithReset = ( props: ShowActionsWithResetProps ) => {
  const { buttons, disableRecordEdit } = props;
  const record = useRecordContext( props );
  const { hasEdit } = useResourceDefinition();
  const disabled = record && disableRecordEdit ? disableRecordEdit( record ) : false;

  if( !hasEdit ) {
    return null;
  }
  return (
    <TopToolbar className={ props.className }>
      { buttons }
      <ResetButton disabled={ disabled } />
      <EditButton record={ record } disabled={ disabled } />
    </TopToolbar>
  );
};

export interface ShowActionsWithResetProps {
  className?: string;
  record?: RaRecord;
  buttons?: ReactElement;
  disableRecordEdit?: ( record: RaRecord ) => boolean;
}

ShowActionsWithReset.propTypes = {
  className: PropTypes.string,
  record: PropTypes.any,
};


export type ResetButtonProps = Omit<ButtonProps, 'onClick'>;

export const ResetButton: FC<ResetButtonProps> = props => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const notify = useNotify();

  const handleReset = async () => {
    if( !record ) return;
    console.log( 'reset', resource, record.id );
    const { body: _body } = await httpClient( `${ apiUrl }/${ resource }/${ record.id }/reset`, {
      method: 'POST',
    } );
    await queryClient.invalidateQueries( { queryKey: [ resource ] } );
    notify( 'app.confirmation.value.reset', {
      type: 'success',
      messageArgs: { formatted: record.id }
    },
    );
  }

  return (
    <ActionButtonWithConfirm
      confirmTitle='Reset to default'
      confirmContent='Are you sure you want to restore the factory default value?'
      // startIcon={ <RestorePageOutlined /> }
      label='Reset to default'
      { ...props }
      onClick={ handleReset }
    >
      <RestorePageOutlined />
    </ActionButtonWithConfirm >

  );
}

export interface ActionButtonWithConfirmProps extends Omit<ButtonProps, 'onClick'> {
  onClick?: () => void;
  confirmContent?: string;
  confirmTitle?: string;
}


export const ActionButtonWithConfirm: FC<ActionButtonWithConfirmProps> = ( props ) => {
  const [ open, setOpen ] = useState( false );
  const {
    onClick,
    confirmContent: content = 'Are you sure you want to delete this item?',
    confirmTitle: title = 'Delete Item',
    className = '',
    ...rest
  } = props;

  const handleClick: MouseEventHandler = () => {
    setOpen( true );
  }

  const handleConfirm: MouseEventHandler = () => {
    setOpen( false );
    onClick && onClick();
  }

  const handleClose: MouseEventHandler = () => {
    setOpen( false );
  }

  return (
    <>
      <Button
        { ...rest }
        className={ className }
        onClick={ handleClick }
      />
      <Confirm
        isOpen={ open }
        title={ title }
        content={ content }
        confirm="Yes"
        confirmColor="primary"
        cancel="Cancel"
        CancelIcon={ CancelIcon }
        onConfirm={ handleConfirm }
        onClose={ handleClose }

      />

    </>
  );
}
