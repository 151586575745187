/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { Chip } from '@mui/material';
import { FC } from 'react';
import { BooleanField, Create, CreateProps, Datagrid, Edit, EditProps, Filter, FilterProps, InputProps, List, ListProps, SearchInput, SelectField, SelectInput, Show, ShowProps, TextField, TextInput } from 'react-admin';
import { AdminShowLayout, AdminSimpleForm, useAdminListAppLocation } from './Admin';
import { choices } from './DataProvider';
import { CustomToolbar } from './ReminderTemplateLists';
import { ShowActionsWithReset } from './ShowActionsWithReset';
import { TimestampsField } from './TimestampsField';

export { default as HtmlComponentIcon } from '@mui/icons-material/Email';


export const QuickFilter: FC<InputProps> = ( { label } ) => {
  return <Chip sx={ { marginBottom: 1 } } label={ label } />;
};


const HtmlComponentFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <SearchInput source="q" alwaysOn />
    <SelectInput label="Type" source="type" choices={ choices.htmlComponentType } />
    <QuickFilter label="Parent" source="isParent" defaultValue={ true } />

  </Filter>
);


export const HtmlComponentList: FC<ListProps> = props => {
  useAdminListAppLocation();
  return (
    <List
      { ...props }
      exporter={ false }
      filters={ <HtmlComponentFilter /> }
      perPage={ 25 }

    >
      <Datagrid
        rowClick="edit"
        // expand={ <HtmlComponentExpandEdit /> }
        bulkActionButtons={ false }
      >

        <TextField label="Name" source="id" />
        <SelectField label="Type" source="type" choices={ choices.htmlComponentType } />
        <BooleanField label="Parent" source="isParent" FalseIcon={ null } />
        <TextField label="Comment" source="comment" />
        {/* <TextField source="value"  /> */ }
        <TimestampsField label="Updated" source="updatedAt" />
        { /* <HtmlComponentCloneButton /> */ }
      </Datagrid>
    </List>
  );
}

export const HtmlComponentShow: FC<ShowProps> = () => {

  return (
    <Show actions={ <ShowActionsWithReset /> }>
      <AdminShowLayout>
        <TextField label='Name' source='id' />
        <TextField label='Message' source='value' />
        <TextField source='comment' />

        <TimestampsField label="Updated" source="updatedAt" />
        <TimestampsField label="Updated" source="createdAt" />
      </AdminShowLayout>
    </Show>
  );
}


// export const HtmlComponentCloneButton = ( props: CloneButtonProps ) => {
//   const {
//     label = 'ra.action.clone',
//     scrollToTop = true,
//     icon = <Queue />,
//     ...rest
//   } = props;
//   const resource = useResourceContext( props );
//   const record = useRecordContext( props );
//   const createPath = useCreatePath();
//   const pathname = createPath( { resource, type: 'create' } );
//  
//   const prepClone = ( { id, comment, ...rest }: any ) => {
//     return {
//       ...rest,
//       comment: `Cloned from ${ id }`,
//       clonedId: `${ id }`
//     };
//   }
//  
//   const sanitizeRestProps = ( {
//     resource,
//     record,
//     ...rest
//   }: Omit<CloneButtonProps, 'label' | 'scrollToTop' | 'icon'> ) => rest;
//  
//   const clickHandler = useCallback( ( id: string ): MouseEventHandler<HTMLButtonElement> => ( e ) => {
//     e.stopPropagation();
//     
//  
//   }, [ record, resource ] );
//  
//   return (
//     <Button
//       component={ Link }
//       // to={
//       //   record
//       //     ? {
//       //       pathname,
//       //       search: queryString.stringify( {
//       //         source: JSON.stringify( prepClone( record ) ),
//       //       } ),
//       //       state: { _scrollToTop: scrollToTop },
//       //     }
//       //     : pathname
//       // }
//       label={ label }
//       onClick={ ( e ) => e.stopPropagation }
//       { ...sanitizeRestProps( rest ) }
//     >
//       { icon }
//     </Button>
//   );
// };


export const HtmlComponentEdit: FC<EditProps> = props => (
  <Edit
    redirect='list'
    mutationMode='pessimistic'
    { ...props }
  >
    <AdminSimpleForm sanitizeEmptyValues toolbar={ <CustomToolbar /> } >

      <TextField label="Name" source="id" />
      <TextInput source="comment" />

      <TextInput source="value" label="Message" multiline />

    </AdminSimpleForm>
  </Edit>
);


export const HtmlComponentCreate: FC<CreateProps> = props => (
  <Create
    redirect='list'
    { ...props }
  >
    <AdminSimpleForm sanitizeEmptyValues toolbar={ <CustomToolbar /> } >

      <TextInput label="Name" source="id" required />

      <TextInput source="comment" />

      <TextInput source="value" label="Message" multiline />

    </AdminSimpleForm>
  </Create>
);


