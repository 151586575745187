import { Box, Card, CardContent, Tab, Tabs } from '@mui/material';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Title } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import { ResourceContextProvider } from './ResourceContextProvider';
import { CustomTextList, TextList } from './Texts';
import { IfCanAccess } from '@react-admin/ra-rbac';

export const Text: FC = () => {
  const basePath = 'text';
  const baseLocation = `admin.${ basePath }`;
  const tabs = [ 'texts', 'customtexts' ];
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [ location, setLocation ] = useAppLocationState();
  const [ tab, setTab ] = useState( tabs[ 0 ] );

  const handleChange = ( _event: SyntheticEvent, newValue: string ) => {
    navigate( `/${ basePath }/${ newValue }` );
    setTab( newValue );
    setLocation( `${ baseLocation }.${ newValue }` );
  };

  useEffect( () => {
    const [ , , tab ] = pathname.split( '/' )
    if( !tabs.includes( tab ) ) return;
    setTab( tab );
    setLocation( `${ baseLocation }.${ tab }` );
    return () => setLocation( null );
  }, [] );

  return (
    <IfCanAccess resource='system' action='manage'>
      <Box>
        <Title title='Text ' />
        <Tabs value={ tab } onChange={ handleChange } >
          <Tab disableRipple value='texts' label='Standard Texts' />
          <Tab disableRipple value='customtexts' label='Custom Texts' />
        </Tabs>

        <Card
          sx={ {
            minHeight: '25em',
          } }
        >
          <CardContent>
            <ResourceContextProvider value='texts' >
              { ( location.path || '' ).includes( 'customtexts' )
                ? <CustomTextList title={ <></> } />
                : <TextList title={ <></> } />
              }
            </ResourceContextProvider>

            {/* <Typography color='gray'>{ location.path }</Typography> */ }

          </CardContent>
        </Card>


      </Box >
    </IfCanAccess>
  );
}
