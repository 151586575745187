import { Box, Link as MuiLink } from '@mui/material';
import { FC } from 'react';
import { ChipField, Datagrid, DateInput, Filter, FilterProps, FunctionField, List, ListProps, RaRecord, ReferenceArrayField, ReferenceField, SelectField, SelectInput, SimpleShowLayout, SingleFieldList, TextField, useRedirect, WithRecord } from 'react-admin';
import { useAdminListAppLocation } from './Admin';
import { choices } from './DataProvider';
import { TimestampsField } from './TimestampsField';
import { Childless } from './types';

export { default as InboundEmailIcon } from '@mui/icons-material/MoveToInbox';

const InboundEmailFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <DateInput label="Received" source="createdAt" />
    <SelectInput label='Type' source="processType" choices={ choices.processType } />
  </Filter>
);

export const InboundEmailList: FC<Childless<ListProps>> = props => {
  useAdminListAppLocation();
  const redirect = useRedirect();

  const onClickRecipient = ( recipient: RaRecord ) => () => {
    redirect( '/contacteditor', undefined, undefined, undefined, { recipient } )
  };

  const filterDefaultValues = {
    //transmitMethod: 'sms'
  };

  const fromTypes = [ 'messageReply', 'newEmail' ];


  return (
    <List { ...props }
      filterDefaultValues={ filterDefaultValues }
      filters={ <InboundEmailFilter /> }
      exporter={ false }
      sort={ { field: 'createdAt', order: 'DESC' } }
    >
      <Datagrid
        bulkActionButtons={ false }
        rowStyle={ ( record ) => {
          const colors = [ 'green', 'blue', 'yellow', 'orange', 'red' ];
          return {
            borderLeft: `solid 10px ${ colors[ Math.min( 4, Math.max( 0, record.spamScore - 1 ) ) ] }`,
          }
        } }
        expand={ <InboundEmailExpandShow /> }
        isRowExpandable={ ( record ) => !!record.text }
        sx={ {
          '& .column-spamScore': {
            maxWidth: '2rem',
          },
          '& .column-createdAt': {
            maxWidth: '6rem',
          }
        } }
      >

        <SelectField label='Type' source="processType" choices={ choices.processType } />
        <WithRecord render={ ( record: RaRecord ) => (
          <TextField label="Address" source={ fromTypes.includes( record.processType ) ? 'from' : 'to.0' } />
        ) } />

        <ReferenceField source="recipient" reference="recipients" link={ false } sortable={ false } >
          <WithRecord render={ ( record ) =>
            <MuiLink onClick={ onClickRecipient( record ) } underline='none' >
              <TextField source="fullName" />
            </MuiLink>
          } />
        </ReferenceField>
        <ReferenceArrayField source="recipients" reference="recipients" sortable={ false } >
          <SingleFieldList linkType={ false } >
            <WithRecord render={ ( record ) =>
              <ChipField source="fullName" onClick={ onClickRecipient( record ) } clickable />
            } />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="subject" />
        <TimestampsField label="Received" source='createdAt' />

      </Datagrid>
    </List>
  );
}

const InboundEmailExpandShow: FC = () => (
  <SimpleShowLayout>
    <FunctionField label=""
      render={ ( record: { text?: string, processLog?: string } ) => (
        <Box>
          { record.processLog &&
            <Box component='pre'
              sx={ {
                maxWidth: '60rem',
                overflowX: 'scroll',
                borderBottom: '1px solid gray',
              } }
            >
              Log: { record.processLog }
            </Box>
          }
          <Box component='pre'
            sx={ {
              maxWidth: '60rem',
              overflowX: 'scroll',
            } }
          >
            {
              record.text
            }
          </Box>
        </Box>
      )
      } />
  </SimpleShowLayout>
);
