import { Box, Button, Grid, Slider, Stack, Typography } from '@mui/material';
import Compressor from 'compressorjs';
import { FC, useCallback, useEffect, useState } from 'react';
import Cropper, { CropperProps } from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import { aspectRatioString, getCroppedImage, getPixelCrop } from './canvasUtils';
export type { Area, MediaSize, Point, Size, VideoSrc } from 'react-easy-crop/types';
export { aspectRatioString };

export const defaultCrop = { x: 0, y: 0, width: 100, height: 100 };
export const defaultRotation = 0;
export const defaultAspect = 4 / 3;

export type OnSave = ( image?: string, crop?: Area, rotation?: number ) => Promise<void>;
export type OnDiscard = () => Promise<void>;

export interface ImageCropperOptions {
  image: File;
  initialCroppedArea?: Area;
  rotation?: number;
  aspect?: number;
  onSave: OnSave;
  onDiscard?: OnDiscard;
  [ index: string ]: string | Record<string, unknown> | OnSave | File | Area | number | undefined;
}

const compressImage = async ( file: File ): Promise<Blob> => {
  const { size } = file;
  return new Promise( ( resolve, reject ) => {
    new Compressor( file, {
      strict: true,
      checkOrientation: false,
      maxWidth: 2048,
      maxHeight: 2048,
      quality: 0.8,
      mimeType: 'auto',
      convertSize: 1000000,
      success: ( image ) => {
        console.log( `compressed ${ size } to ${ image.size } for ${ ( 100 * ( size - image.size ) / size ).toFixed() }% compression` );
        resolve( image );
      },
      error: reject,
    } );
  } );
};

export const ImageCropper: FC<ImageCropperOptions> = ( props ) => {
  const {
    image: fullImage,
    initialCroppedArea,
    rotation: initialRotation,
    aspect = defaultAspect,
    onSave,
    onDiscard,
    // ...rest
  } = props;
  const [ image, setImage ] = useState<string | undefined>();
  const [ initialCroppedAreaPixels, setInitialCroppedAreaPixels ] = useState<Area | undefined>();
  const [ crop, setCrop ] = useState( { x: 0, y: 0 } )
  const [ rotation, setRotation ] = useState<number>( initialRotation || 0 )
  const [ zoom, setZoom ] = useState( 1 )
  const [ croppedArea, setCroppedArea ] = useState<Area | undefined>()
  // const [ croppedAreaPixels, setCroppedAreaPixels ] = useState<Area | undefined>()
  const [ croppedImage, setCroppedImage ] = useState<string | undefined>()
  // const classes = cropperStyles( { ...rest } );
  // const theme = useTheme();

  useEffect( () => {
    ( async () => {
      const compressed = await compressImage( fullImage )
      const image = URL.createObjectURL( compressed );
      setImage( image );
      const initialCroppedAreaPixels = !initialCroppedArea ? undefined : await getPixelCrop( image, initialCroppedArea );
      setInitialCroppedAreaPixels( initialCroppedAreaPixels );
      console.log( 'initial crop', initialCroppedArea, initialCroppedAreaPixels );
    } )()
  }, [ fullImage, initialCroppedArea ] );

  const onCropComplete = useCallback<NonNullable<CropperProps[ 'onCropComplete' ]>>( ( croppedArea, croppedAreaPixels ) => {
    setCroppedArea( croppedArea )
    console.log( 'onCropComplete', croppedArea, croppedAreaPixels );
    // // setCroppedAreaPixels( croppedAreaPixels )
    // // console.log( croppedArea, croppedAreaPixels )
  }, [] )

  const showCroppedImage = useCallback( async () => {
    try {
      if( !image || !croppedArea ) return;
      const croppedImage = await getCroppedImage( image, croppedArea, rotation );
      console.log( 'preview cropped image', croppedArea, rotation )
      setCroppedImage( croppedImage )
    } catch( e ) {
      console.error( e )
    }
  }, [ image, croppedArea, rotation ] )

  const onKeep = useCallback( async () => {
    try {
      if( !image || !croppedArea ) {
        setCroppedImage( undefined )
        setInitialCroppedAreaPixels( undefined );
        onSave();
        return;
      }
      const croppedImage = await getCroppedImage( image, croppedArea, rotation );
      console.log( 'keeping' );
      await onSave( croppedImage, croppedArea, rotation );
      console.log( 'kept' );
      setCroppedImage( undefined )
    } catch( e ) {
      console.error( e )
      onSave();
    }
  }, [ onSave, croppedArea, image, rotation, setCroppedImage ] )

  const onCancel = useCallback( () => {
    setCroppedImage( undefined )
    setInitialCroppedAreaPixels( undefined );
    onDiscard ? onDiscard() : onSave();
  }, [ onSave, onDiscard, setCroppedImage, setInitialCroppedAreaPixels ] )

  const rotateDisabled = false; // disabling until api processIncomingDataUrl is fixed

  return image
    ? (
      <Stack spacing={ 2 }>
        <Box
          sx={ {
            position: 'relative', // required for Cropper 
            background: '#eee', // '#dde',
            height: { xs: 300, sm: 400 },
          } }
        >
          <Cropper
            image={ image }
            aspect={ aspect }
            objectFit='cover'
            crop={ crop }
            rotation={ rotation }
            zoom={ zoom }
            onCropChange={ setCrop }
            onRotationChange={ setRotation }
            onCropComplete={ onCropComplete }
            onZoomChange={ setZoom }
            initialCroppedAreaPixels={ initialCroppedAreaPixels }
          />
        </Box>

        <Box
          sx={ {
            textAlign: 'center',
            // border: '1px solid blue',
          } }
        >
          <Typography variant="overline" >
            { aspectRatioString( aspect ) } aspect ratio
          </Typography>
        </Box>

        <Grid container spacing={ 1 } justifyContent='space-evenly' alignItems='flex-start'
        // sx={ { border: '1px solid blue' } }
        >

          <Grid item xs={ 11 } sm={ 5 } >

            <Typography variant="overline" >
              Zoom: { zoom.toString() }x
            </Typography>

            <Slider
              value={ zoom }
              min={ 0.1 }
              max={ 3 }
              step={ 0.01 }
              aria-labelledby="Zoom"
              onChange={ ( _e, zoom ) => { if( typeof zoom == 'number' ) setZoom( zoom ); } }
            />
          </Grid>

          <Grid item xs={ 11 } sm={ 5 } >

            <Typography variant="overline" >
              Rotation: { rotation.toString() } degrees
            </Typography>

            <Slider
              value={ rotation }
              min={ -180 }
              max={ 180 }
              step={ 1 }
              aria-labelledby="Rotation"
              onChange={ ( _e, rotation ) => { if( typeof rotation == 'number' ) setRotation( rotation ); } }
              disabled={ rotateDisabled }
            />
          </Grid>

        </Grid>

        <Grid container spacing={ 2 } justifyContent='space-around' alignItems='flex-start'
        // sx={ { border: '1px solid blue' } }
        >

          <Grid item xs={ 2 } >

            <Button
              onClick={ onCancel }
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={ 2 } >

            <Button
              onClick={ showCroppedImage }
              variant="outlined"
              color="primary"
            >
              Preview
            </Button>
          </Grid>
          <Grid item xs={ 2 } >

            <Button
              onClick={ onKeep }
              variant="contained"
              color="primary"
            >
              Keep
            </Button>
          </Grid>

        </Grid>

        { croppedImage &&
          <Grid container spacing={ 2 } justifyContent='space-around' alignItems='flex-start'
          // sx={ { border: '1px solid blue' } }
          >

            <Grid item xs={ 'auto' } >

              <Box
                sx={ {
                  '& img': {
                    height: { xs: '300px', sm: '400px' },
                    border: '2px solid #eee',
                  },
                } }
              >
                <img src={ croppedImage } alt="preview" />
              </Box>
            </Grid>

          </Grid>
        }
      </Stack >
    )
    : <></>;
}

export default ImageCropper;
