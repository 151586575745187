import { Send as SendIcon } from '@mui/icons-material';
import { alpha, Box, Button as MuiButton, CircularProgress, Grid, lighten, Link as MuiLink, TextField as MuiTextField, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { formatDistanceToNow } from 'date-fns';
import { reverse, sortBy } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { ChipField, Labeled, RaRecord, ReferenceArrayField, ReferenceField, Show, ShowProps, SingleFieldList, TextField, TitleProps, useGetList, useRedirect, useUpdate, WithRecord } from 'react-admin';
import { AwesomePhoneField } from './AwesomePhoneInput';
import { apiUrl, httpClient } from './DataProvider';
import { SimpleShowLayoutWithLocation } from './MyBreadcrumb';

export const cleanFormatDistanceToNow = ( date?: Date | string ): string => {
  const d = typeof date === 'string' ? new Date( Date.parse( date ) ) : date;
  if( !d ) return '';
  return formatDistanceToNow( d, { addSuffix: true } ).replace( /about /, '' );
}

const IncomingTextChatTitle: FC<TitleProps> = props => {
  return <span>Incoming SMS { props.record?.senderName ? `"${ props.record.senderName }"` : '' }</span>;
};

export interface InboundText extends RaRecord {
  // message?: { From?: string };
  preview?: string;
  createdAt: string;
  senderName?: string;
  recipient: string;
  phone: string;
  isResolved?: boolean;
}

export const IncomingTextChat: FC<ShowProps> = props => {
  const redirect = useRedirect();
  const inputRef = useRef<HTMLInputElement>();
  const theme = useTheme();
  const isXSmall = useMediaQuery( ( theme: Theme ) => theme.breakpoints.down( 'sm' ) );
  const queryClient = useQueryClient();
  const transmitMethod = 'sms';

  const onClickRecipient = ( recipient: RaRecord ) => () => {
    redirect( '/contacteditor', undefined, undefined, undefined, { recipient } )
  };

  const onClickPhone = ( inboundtext: InboundText ) => () => {
    redirect( '/contacteditor', undefined, undefined, undefined, { search: inboundtext.phone.slice( 2 ) } )
  };

  const postMessage = useCallback( async ( recipientId: string, phone: string, message: string ): Promise<string> => {
    const { body: result } = await httpClient( `${ apiUrl }/recipients/${ recipientId }/messages`, {
      method: 'post',
      body: JSON.stringify( { message, transmitMethod, telecomValue: phone } ),
    } );
    return result;
  }, [ httpClient ] );

  const handleSendClick = useCallback( ( recipientId: string, phone: string, callback?: () => void ) => async () => {
    if( !phone || !inputRef?.current?.value ) return;
    await postMessage( recipientId, phone, inputRef.current.value );
    callback && callback();
  }, [ postMessage, inputRef.current ] );

  const gray = theme.palette.mode == 'dark' ? theme.palette.grey[ 800 ] : theme.palette.grey[ 100 ];

  return (
    ( <Show
      { ...props }
      title={ <IncomingTextChatTitle /> }
      resource='inboundtexts'
    >
      <SimpleShowLayoutWithLocation basePath='messaging'>
        <Grid container columnSpacing={ 8 }>
          <Grid item>
            <Labeled label='Patient sender'>
              <ReferenceField label='Sender' source="recipient" reference="recipients" link={ false } sortable={ false } >
                <WithRecord render={ ( record ) => {
                  const { fullName, birthDate } = record;
                  return <MuiLink onClick={ onClickRecipient( record ) } underline='none' >
                    <TextField source="fullName" record={ { fullName: `${ birthDate.slice( 0, 10 ) } ${ fullName }` } } />
                  </MuiLink>
                } } />
              </ReferenceField>
            </Labeled>
          </Grid>

          <Grid item>
            <Labeled label='Phone'>
              <WithRecord render={ ( record ) =>
                <MuiLink onClick={ onClickPhone( record ) } underline='none' >
                  <AwesomePhoneField label='Phone' source="phone" />
                </MuiLink>
              } />
            </Labeled>
          </Grid>

          { !isXSmall &&
            <Grid item>
              <Labeled label='Related recipients'>
                <ReferenceArrayField label='Related recipients' source="recipients" reference="recipients" sortable={ false } >
                  <SingleFieldList linkType={ false } >
                    <WithRecord render={ ( record ) =>
                      <ChipField source="fullName" onClick={ onClickRecipient( record ) } clickable />
                    } />
                  </SingleFieldList>
                </ReferenceArrayField>
              </Labeled>
            </Grid>
          }
        </Grid>


        <WithRecord render={ ( record: InboundText ) => {
          const { id, senderName, recipient: recipientId, phone, isResolved } = record;
          const phoneNumber = phone.slice( 2 ); // groan
          const [ update ] = useUpdate<InboundText>();
          const { data: outboundMessages, isLoading: isLoading1, refetch: refetch1 } = useGetList( 'outboundmessages', { filter: { phoneNumber, transmitMethod }, sort: { field: 'postedDate', order: 'DESC' } } );
          const { data: inboundTexts, isLoading: isLoading2, refetch: refetch2 } = useGetList( 'inboundtexts', { filter: { phone }, sort: { field: 'createdAt', order: 'DESC' } } );
          const data = useMemo( () => {
            return reverse( sortBy( [
              ...( outboundMessages || [] ).map( m => ( { ...m, date: m.postedDate, resource: 'outboundmessages' } ) ),
              ...( inboundTexts || [] ).map( m => ( { ...m, date: m.createdAt, resource: 'inboundtexts' } ) ),
            ], m => m.date ) );
          }, [ inboundTexts, outboundMessages ] );
          const refetch = useCallback( () => { refetch1(); refetch2() }, [ refetch1, refetch2 ] );
          const isLoading = useMemo( () => isLoading1 || isLoading2, [ isLoading1, isLoading2 ] );

          useEffect( () => {
            if( isResolved ) return;
            ( async () => {
              await update( 'inboundtexts', { id, data: { ...record, isResolved: true }, previousData: record }, { returnPromise: true, mutationMode: 'optimistic' } );
              await queryClient.invalidateQueries( {
                queryKey: [ 'inboundtexts', 'getList' ]
              } );
            } )();
          }, [ record ] );

          return (
            <Box>
              <Box
                sx={ {
                  width: { sm: '100%', md: '28rem' },
                  marginBottom: '5rem',
                  marginLeft: { xs: '0rem', md: '3rem' },
                } }
              >
                <MuiTextField
                  id='reply'
                  inputRef={ inputRef }
                  label='Reply'
                  variant='outlined'
                  multiline
                  fullWidth
                  disabled={ isLoading }
                  rows={ 4 }
                />
                <MuiButton
                  size='small'
                  endIcon={ <SendIcon /> }
                  variant='contained'
                  // disabled={ isLoading || ( inputRef?.current?.value?.length ?? 0 ) < 2 }
                  disabled={ isLoading }
                  disableFocusRipple
                  sx={ {
                    float: 'right',
                    marginTop: '0.5rem',
                  } }
                  onClick={ handleSendClick( recipientId, phone, () => {
                    if( inputRef?.current ) {
                      inputRef.current.value = '';
                    }
                    refetch();
                  }
                  ) }
                >
                  Send
                </MuiButton>
              </Box>

              { isLoading
                //////////////////////////
                //  LOADING INDICATOR
                //////////////////////////
                ? <Box sx={ { width: '32rem', textAlign: 'center', margin: '1rem 0 10rem' } }><CircularProgress /></Box>
                //////////////////////////
                //  INCOMING MESSAGE 
                //////////////////////////
                : <Box>
                  { ( data || [] ).map( rec => {
                    const { resource, message: raw1, preview: raw2 = '', date, templateName } = rec as unknown as { resource: string; id: string; message?: string; preview?: string; date: string; templateName?: string; };
                    const message = typeof raw1 == 'string' ? raw1 : raw2;
                    const messageLines = message.split( '\n' );
                    const dateAgo = cleanFormatDistanceToNow( date );

                    return (
                      resource == 'inboundtexts'
                        ? <Box
                          key={ rec.id }
                          sx={ {
                            minWidth: '10rem',
                            maxWidth: '28rem',
                            marginBottom: '1.5rem',
                            marginRight: { xs: '2rem', sm: '3rem' },
                            position: 'relative',
                          } }
                        >
                          <Typography
                            sx={ {
                              color: lighten( theme.palette.secondary.main, 0.3 ),
                              fontSize: '90%',
                              marginLeft: '1rem',
                            } }
                          >
                            { senderName }
                          </Typography>
                          <Box
                            sx={ {
                              backgroundColor: theme.palette.secondary.main,
                              color: theme.palette.getContrastText( theme.palette.secondary.main ),
                              padding: '1rem',
                              borderRadius: '1rem',
                            } }
                          >
                            { messageLines.map( ( m, idx ) => (
                              m ? <Typography key={ idx } >{ m } </Typography> : <Typography key={ idx } >&nbsp;</Typography>
                            ) ) }
                          </Box>
                          <Typography
                            align='right'
                            sx={ {
                              color: lighten( theme.palette.secondary.main, 0.3 ),
                              fontSize: '90%',
                              marginRight: '1rem',
                            } }
                          >
                            { dateAgo }
                          </Typography>
                        </Box>

                        //////////////////////////
                        //  OUTGOING MESSAGE 
                        //////////////////////////
                        : <Box
                          key={ rec.id }
                          sx={ {
                            minWidth: '10rem',
                            maxWidth: '28rem',
                            marginBottom: '1.5rem',
                            marginLeft: { xs: '2rem', sm: '3rem' },
                            position: 'relative',
                          } }
                        >
                          <Typography
                            sx={ {
                              color: theme.palette.grey[ 500 ],
                              fontSize: '80%',
                              marginLeft: '1rem',
                            } }
                          >
                            { templateName }
                          </Typography>
                          <Box
                            sx={ {
                              position: 'relative',
                            } }
                          >
                            <Box
                              sx={ {
                                backgroundColor: gray,
                                padding: '1rem 2rem 1.5rem',
                                borderRadius: '1rem',
                                maxHeight: '10.5rem',
                                overflowY: 'scroll',

                              } }
                            >
                              { messageLines.map( ( m, idx ) => (
                                m ? <Typography key={ idx } >{ m } </Typography> : <Typography key={ idx } >&nbsp;</Typography>
                              ) ) }
                            </Box>
                            <Box
                              sx={ {
                                position: 'absolute',
                                background: `linear-gradient( ${ alpha( gray, 0 ) }, ${ alpha( gray, 0.8 ) })`,
                                borderBottomLeftRadius: '1rem',
                                borderBottomRightRadius: '1rem',
                                // opacity: '0.8',
                                bottom: 0,
                                height: '2.5rem',
                                width: '100%',
                                zIndex: 1,
                              } }
                            />
                          </Box>
                          <Typography
                            align='right'
                            sx={ {
                              fontSize: '90%',
                              marginRight: '1rem',
                              color: theme.palette.grey[ 500 ],
                            } }
                          >
                            { dateAgo }
                          </Typography>
                        </Box>
                    );
                  } ) }
                </Box>
              }

            </Box>

          )
        } } />


      </SimpleShowLayoutWithLocation>
    </Show > )
  );
}
