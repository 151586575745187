import { SsidChart as LineChartIcon, TableChartOutlined as TableIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, IconButton as MuiIconButton, Typography, useTheme } from '@mui/material';
import { BarDatum } from '@nivo/bar';
import { Datum, Line, LineProps, Serie } from '@nivo/line';
import { useCanAccess } from '@react-admin/ra-rbac';
import { addDays } from 'date-fns';
import { chunk, reverse, sumBy } from 'lodash';
import { default as queryString } from 'query-string';
import { FC, useCallback, useMemo, useState } from 'react';
import { Loading, useRedirect } from 'react-admin';
import { DailyLineChartSliceTooltipHeader, makeLineChartSliceTooltip } from './DashboardTooltip';
import { ChartProps, elevation, OnlyNumbers } from './DashboardWidget';
import { useUserPreference } from './UserPreferences';
import useColorPalettes from './use-color-palettes';
import { useOrgDateMarkers } from './use-markers';
import useSummary from './use-summary';
import { DashboardDatagrid } from './DashboardDatagrid';

interface AppointmentActivityDatum extends BarDatum {
  date: string;
  md: string;
  ymd: string;
  Confirm: number;
  Reschedule: number;
  Cancel: number;
  Schedule: number;
  'No Show': number;
}
export interface AppointmentActivityLineDatum extends Datum {
  y: number | null;
  ymd: string;
  md: string;
}
export interface AppointmentActivityLinePoint extends Serie {
  data: AppointmentActivityLineDatum[];
}

export type LineLegendProps = NonNullable<LineProps[ 'legends' ]>[ 0 ];

export const DashboardAppointmentActivity: FC<ChartProps> = ( { date } ) => {
  const theme = useTheme();
  const redirect = useRedirect();
  const { canAccess: canAccessSystem } = useCanAccess( { action: 'manage', resource: 'system' } );
  const isSmall = false;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const defaultDurationDays = 30;
  const cacheKey = 'dashboard-appointment-activity';
  const [ showDatagrid, setShowDatagrid ] = useState( false );
  const keys: ( keyof OnlyNumbers<AppointmentActivityDatum> )[] = [ 'Confirm', 'Schedule', 'Reschedule', 'Cancel', 'No Show' ];

  const durationDays = useMemo( () => {
    if( isLoadingPreferences || !preferences?.dashboardActivityDays ) return defaultDurationDays;
    return preferences.dashboardActivityDays as number;
  }, [ preferences, isLoadingPreferences ] );

  const summary = useSummary<AppointmentActivityDatum[]>( 'appointmentActivity', { date, days: durationDays } );

  const data = useMemo( (): AppointmentActivityLinePoint[] => keys.map( id => ( {
    id, data: summary.data?.map( d => {
      const { ymd, md, date } = d;
      const y = d[ id ] >= 1 ? d[ id ] : null; // no zeros for log scale
      return { x: ymd, y, ymd, md, date };
    } )
      .filter( d => !!d.y )
      .sort( ( a, b ) => a.ymd < b.ymd ? -1 : 1 ) || []
  } ) ), [] );

  const isEmpty = useMemo( () => sumBy( data, d => d.data.length ) === 0, [ data ] );

  const counts = useMemo( () => (
    Object.fromEntries( data.map( ( { id, data } ) => [ id, data.reduce( ( tot, d ) => ( d.y || 0 ) + tot, 0 ) ] ) )
  ), [ data ] );
  const yMax = useMemo( () => Math.max( ...[ 10, ...data.flatMap( row => row.data.map( d => d.y || 0 ) ) ] ), [ data ] );

  const [ hiddenSeries, setHiddenSeries ] = useState<string[]>( [] );
  const handleLegendClick = useCallback( ( datum: Datum ) => {
    const id = datum.id;
    const idx = hiddenSeries.indexOf( id );
    if( idx < 0 ) {
      setHiddenSeries( [ ...hiddenSeries, id ] );
      return;
    }
    const newValue = [ ...hiddenSeries ];
    newValue.splice( idx, 1 );
    setHiddenSeries( newValue );
  }, [ hiddenSeries, setHiddenSeries ] );

  const colorPalettes = useColorPalettes();
  const colors = useMemo( () => reverse( [ ...colorPalettes.discretePrimary.slice( 0, keys.length ) ] ), [ keys, colorPalettes ] );

  const markers = useOrgDateMarkers( { date, duration: { type: 'days', count: durationDays } } );

  return (
    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={
          <Box sx={ { display: 'flex' } } >
            <Typography>Appointment Activity</Typography>
            <Box sx={ { flexGrow: 1 } } />
            <MuiIconButton
              sx={ {
                // paddingTop: 0, paddingBottom: 0,
                marginTop: '-8px',
                marginBottom: '-8px',
              } }
              onClick={ () => setShowDatagrid( !showDatagrid ) }
            >
              { showDatagrid ? <LineChartIcon /> : <TableIcon /> }
            </MuiIconButton>
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `${ durationDays } Days Ending ${ date.format( 'MMM D' ) }` : `Previous ${ durationDays } Days` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
            // fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        { summary.isLoading
          ? <Box>
            <Loading
              loadingPrimary=''
              loadingSecondary=''
              sx={ {
                '@media (min-width: 0)': {
                  marginTop: 0,
                  marginBottom: 7,
                  height: '100%',
                  width: 500,
                  minHeight: 400,
                }
              } }
            />
          </Box>

          :
          <Box
            sx={ {
              position: 'relative',
            } }
          >
            { showDatagrid
              ? (
                <DashboardDatagrid
                  rows={ ( summary.data || [] ).map( ( d, id ) => ( { id, ...d } ) ) }
                  columns={ keys }
                  exportFileName={ cacheKey }
                />
              )
              : <>

                <Line
                  width={ 500 }
                  height={ 400 }
                  curve='monotoneX'
                  data={ isEmpty ? [] : data }
                  margin={ { top: 55, right: 20, bottom: 55, left: 75 } }
                  gridYValues={ 4 }

                  isInteractive={ !isEmpty }
                  useMesh={ !isEmpty }
                  enableCrosshair
                  // enableArea
                  areaOpacity={ 0.1 }
                  lineWidth={ 3 }
                  pointSize={ 6 }

                  xFormat="time:%Y-%m-%d"
                  xScale={ {
                    type: 'time',
                    format: '%Y-%m-%d',
                    precision: 'day',
                    useUTC: false,
                    max: date ? date.toDate() : new Date(),
                    min: date ? addDays( date.toDate(), 0 - durationDays ) : addDays( new Date(), 0 - durationDays ),
                  } }

                  yScale={ {
                    type: 'log',
                    base: 2,
                    max: yMax * 1.5,
                    min: 1,
                  } }


                  enableSlices='x'
                  sliceTooltip={ makeLineChartSliceTooltip( {
                    theme,
                    renderHeader: DailyLineChartSliceTooltipHeader,
                  } ) }

                  onClick={ ( point ) => {
                    if( !canAccessSystem ) return;
                    // @ts-ignore:2339,2352
                    const { serieId: id, data: { ymd } } = point as AppointmentActivityLinePoint;  // action: 'confirm', date: '5/31'
                    if( !ymd ) return;
                    const filter = JSON.stringify( {
                      action: typeof id == 'string' ? id.toLowerCase() : undefined,
                      // createdAt: [ `gte:${ ymd }`, `lte:${ ymd }` ].join( ';' ),
                      createdAt: ymd,
                      delimiter: ';',
                    } );
                    redirect( `/actionlogs?${ queryString.stringify( { filter } ) }` );
                  } }

                  theme={ {
                    background: theme.palette?.background?.default,
                    axis: {
                      legend: { text: { fontSize: isSmall ? 16 : 18 } },
                      ticks: { text: { fontSize: isSmall ? 0 : 14 } },
                    },
                    labels: { text: { fill: '#333' } },
                    legends: { text: { fontSize: 14 } },
                    text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
                    tooltip: {
                      container: {
                        borderRadius: '8px',
                        border: `0.5px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
                        boxShadow: `${ theme.palette?.grey?.A400 ?? 'grey' } 0px 2px 2px`,
                        backgroundColor: theme.palette?.background?.default,
                        color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
                        padding: '5px 9px',
                      },
                    },
                  } }

                  colors={ isEmpty ? [ theme.palette?.mode === 'dark' ? '#555' : '#ccc' ] : colors } //{ scheme: colors, size: keys.length } }
                  // enableLabel={ false }
                  enableGridX={ false }
                  enableGridY={ true }
                  axisTop={ null }
                  axisRight={ null }
                  axisLeft={ {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: 4, // [ 0, 1000, 2000, 3000 ],
                    legend: 'Daily count (log2)',
                    legendPosition: 'middle',
                    legendOffset: -67

                  } }

                  axisBottom={ {
                    tickSize: 5,
                    // ticksPosition: 'before',
                    tickPadding: 5,
                    format: '%-m/%d',
                    tickValues: `every ${ durationDays < 30 ? 2 : 7 } days`,
                    legend: 'Activity date',
                    legendPosition: 'middle',
                    legendOffset: 42,
                  } }

                  markers={ markers }

                  // multirow legend (by 2)
                  legends={ chunk( keys, 3 ).map<LineLegendProps>( ( ch, chIdx ) => (
                    {
                      data: ch.map( ( id, idx ) => ( {
                        id,
                        label: `${ id } (${ counts[ id ] || 0 })`,
                        fill: colors[ idx + 3 * chIdx ],
                        hidden: !!hiddenSeries.includes( `${ id }` ),
                      } ) ),
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: -30,
                      translateY: -55 + chIdx * 22,
                      itemsSpacing: 65,
                      itemWidth: 80,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 1, // 0.95,
                      onClick: handleLegendClick,
                      symbolSize: 18, // 20,
                      symbolShape: 'circle', // 'square',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1
                          }
                        }
                      ],
                      toggleSerie: true,
                    }
                  ) ) }
                />
              </>
            }
          </Box>
        }
      </CardContent>
    </Card >

  );

}
