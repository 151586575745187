/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { Card, CardHeader, Grid } from '@mui/material';
import { ordinalize } from 'inflection';
import { FC } from 'react';
import { ArrayField, ArrayInput, BooleanField, BooleanInput, Datagrid, DateField, Edit, EditProps, FormDataConsumer, List, ListProps, NumberField, NumberInput, SelectField, SelectInput, Show, ShowProps, SimpleFormIterator, SimpleFormProps, TextField, TextInput } from 'react-admin';
import { AdminForm, AdminShowLayout, useAdminListAppLocation } from './Admin';
import { choices } from './DataProvider';
import { CustomToolbar } from './ReminderTemplateLists';
import TextArrayField, { AutocompleteTextArrayInput } from './TextArrayField';

export { default as CalendarPackageIcon } from '@mui/icons-material/DateRange';


export const CalendarPackageList: FC<ListProps> = props => {
  useAdminListAppLocation();

  // const filterDefaultValues = {};

  return (
    <List { ...props }
      exporter={ false }
      sort={ { field: 'name', order: 'ASC' } }
      perPage={ 25 }
    >
      <Datagrid rowClick="edit" bulkActionButtons={ false } >
        <TextField label="Code" source="id" />
        <TextField source="name" />

        <ArrayField source="event">
          <Datagrid>
            <BooleanField source="isEventSeries" looseValue />
          </Datagrid>
        </ArrayField>

        <DateField label="Updated" source="updatedAt" showTime />
      </Datagrid>
    </List >
  );
}

export const CalendarPackageShow: FC<ShowProps> = () => (
  <Show>
    <AdminShowLayout>
      <TextField source="comment" />

      <TextField source="name" />

      <TextField source="deliveryLocationTitle" />
      <TextField source="deliveryLocationAddress" />
      <NumberField source="priority" />
      <SelectField source="busyStatus" choices={ choices.busyStatus } />
      <TextArrayField source="categories" label='Categories' />
      <TextField source="summary" />
      <TextField source="description" />
      <BooleanField source="alarmDisplayEnable" looseValue />
      <NumberField source="alarmDisplayBeforeSecs" />
      <TextField source="alarmDisplayBeforeDescription" />
      <BooleanField source="alarmAudioEnable" looseValue />
      <NumberField source="alarmAudioBeforeSecs" />
      <TextField source="alarmAudioBeforeDescription" />
      { /*
    <ArrayInput source='events' >
      <SimpleFormIterator inline
        getItemLabel={ idx => ordinalize( ( idx + 1 ).toString() + ' reminder' ) }
      >
        <BooleanInput source="isEventSeries" />
        <NumberInput source="seriesDayOffsetToStart" helperText={ false } />
        <NumberInput source="seriesNumberOfDaysToRepeat" helperText={ false } />
        <TextInput source="deliveryLocationTitle" fullWidth />
        <TextInput source="deliveryLocationAddress" fullWidth />
        <NumberInput source="priority" />
        <SelectInput source="busyStatus" choices={ choices.busyStatus } />
        <TextArrayField source="categories" />
        <TextInput source="summary" fullWidth />
        <TextInput source="description" fullWidth />
        <BooleanInput source="alarmDisplayEnable" />
        <NumberInput source="alarmDisplayBeforeSecs" />
        <TextInput source="alarmDisplayBeforeDescription" fullWidth />
        <BooleanInput source="alarmAudioEnable" />
        <NumberInput source="alarmAudioBeforeSecs" />
        <TextInput source="alarmAudioBeforeDescription" fullWidth />
      </SimpleFormIterator>
    </ArrayInput>
*/}



    </AdminShowLayout>
  </Show >
);


const CalendarPackageForm: FC<Omit<SimpleFormProps, 'children'>> = props => {
  const border = '0px solid blue';


  return (
    <AdminForm
      sanitizeEmptyValues
      warnWhenUnsavedChanges
      { ...props }
    // toolbar={ <CustomToolbar /> }
    >
      <Grid container
        sx={ {
          padding: '1rem',
        } }
      >
        <TextInput source="name" fullWidth />
        <TextInput source="comment" fullWidth />

        <Card sx={ {
          width: '100%',
          marginLeft: '3rem',
          padding: '1rem',
        } } >
          <CardHeader
            title='Common event detail'
            subheader='Applies to all events unless overriden below'
            titleTypographyProps={ { fontSize: '110%' } }
            subheaderTypographyProps={ { fontSize: '90%' } }
          />
          <Grid container item xs={ 12 } direction='row' spacing={ '1rem' } alignItems='stretch' >
            <Grid item xs={ 6 } sx={ { border } }>
              <TextInput source="description" fullWidth multiline rows={ 8 } />
            </Grid>
            <Grid item xs={ 6 } sx={ { border } } >
              <TextInput source="summary" fullWidth />
              <TextInput source="deliveryLocationTitle" fullWidth />
              <TextInput source="deliveryLocationAddress" fullWidth />
            </Grid>
          </Grid>
          <Grid container item xs={ 12 } spacing={ 2 }>
            <Grid item xs={ 2 }>
              <NumberInput source="priority" inputProps={ { sx: { textAlign: 'end' } } } max={ 4 } min={ 1 } />
            </Grid>
            <Grid item xs={ 2 }>
              <SelectInput source="busyStatus" choices={ choices.busyStatus } fullWidth />
            </Grid>
            <Grid item xs={ 6 }>
              <AutocompleteTextArrayInput source="categories" label='Categories' choices={ choices.eventCategories.map( c => c.id ) } fullWidth />
            </Grid>

          </Grid>


          <Grid container item xs={ 12 } spacing={ 2 }>
            <Grid item xs={ 2 }>
              <BooleanInput source="alarmDisplayEnable" label="Display alarm" />
            </Grid>
            <FormDataConsumer>
              { ( { formData } ) => (
                <>
                  <Grid item xs={ 2 }>
                    <NumberInput source="alarmDisplayBeforeSecs" max={ 1000 } min={ 1 }
                      inputProps={ {
                        sx: { textAlign: 'end' },
                        // startAdornment: <InputAdornment position="end">secs</InputAdornment>,
                      } }
                      InputProps={ { disabled: !formData.alarmDisplayEnable } } />
                  </Grid>
                  <Grid item xs={ 6 }>
                    <TextInput source="alarmDisplayBeforeDescription" InputProps={ { disabled: !formData.alarmDisplayEnable } } fullWidth />
                  </Grid>
                </>
              ) }
            </FormDataConsumer>
          </Grid>
          <Grid container item xs={ 12 } spacing={ 2 }>
            <Grid item xs={ 2 }>
              <BooleanInput source="alarmAudioEnable" label="Audio alarm" />
            </Grid>
            <FormDataConsumer>
              { ( { formData } ) => (
                <>
                  <Grid item xs={ 2 }>
                    <NumberInput source="alarmAudioBeforeSecs" max={ 1000 } min={ 1 }
                      inputProps={ { sx: { textAlign: 'end' } } } InputProps={ { disabled: !formData.alarmAudioEnable } } />
                  </Grid>
                  <Grid item xs={ 6 }>
                    <TextInput source="alarmAudioBeforeDescription" InputProps={ { disabled: !formData.alarmAudioEnable } } fullWidth />
                  </Grid>
                </>
              ) }
            </FormDataConsumer>
          </Grid>
        </Card>


        <ArrayInput source='events'
          label='Detailed events'
          sx={ {
            marginTop: '1rem',
            '& > .MuiInputLabel-root': {
              fontSize: '140%',
            }
          } }
        >
          <SimpleFormIterator disableReordering disableClear
          // getItemLabel={ idx => ordinalize( ( idx + 1 ).toString() + ' reminder' ) }
          >
            <FormDataConsumer>
              { ( { scopedFormData, formData } ) => {
                // @ts-ignore: 2339
                const idx = ( formData.events || [] ).findIndex( e => e.Id == scopedFormData?.Id || e.id == scopedFormData?.id );
                const title = idx >= 0 ? ordinalize( ( idx + 1 ).toString() ) + ' reminder' : '';

                return scopedFormData && (
                  <Card sx={ {
                    // width: '100%',
                    marginLeft: '3rem',
                    padding: '1rem',
                    marginTop: '1rem',
                  } } >
                    <CardHeader
                      // title={ ordinalize( ( parseInt( getSource( 'id' ).split( '.' ).slice( -2 )[ 0 ] ) + 1 ).toString() ) + ' reminder' }
                      title={ title }
                      titleTypographyProps={ { fontSize: '110%' } }
                      subheaderTypographyProps={ { fontSize: '90%' } }
                    />

                    <Grid container item xs={ 12 } direction='row' spacing={ '1rem' } alignItems='stretch' >
                      <Grid item xs={ 2 } sx={ { border } }>
                        <BooleanInput source='isEventSeries' label='Multiple events' />
                      </Grid>
                      <Grid item xs={ 10 } sx={ { border } }>
                        <BooleanInput source='override' label='Customize details'
                          defaultValue={ scopedFormData.description || scopedFormData.summary || scopedFormData.priority || scopedFormData.busyStatus || scopedFormData.deliveryLocationAddress || scopedFormData.deliveryLocationTitle }
                        />
                      </Grid>


                      { scopedFormData.isEventSeries
                        ? (
                          <>
                            <Grid item xs={ 2 } sx={ { border } }>
                              <NumberInput label='Offset days to start' source='seriesDayOffsetToStart' helperText={ false } defaultValue={ 0 } inputProps={ { sx: { textAlign: 'end' } } } />
                            </Grid>
                            <Grid item xs={ 2 } sx={ { border } }>
                              <NumberInput label='Days to repeat' source='seriesNumberOfDaysToRepeat' helperText={ false } defaultValue={ 0 } inputProps={ { sx: { textAlign: 'end' } } } />
                            </Grid>
                            <Grid item xs={ 12 } sx={ { border } }>
                              <ArrayInput source='seriesDailyDetails' >
                                <SimpleFormIterator inline disableReordering disableClear>
                                  <FormDataConsumer>
                                    { ( { scopedFormData } ) => scopedFormData && (
                                      <>
                                        <BooleanInput source='seriesDeltaDeliveryAllDay' label="All day" />
                                        <NumberInput source='seriesDeltaDayNumber' helperText={ false } label="Offset days" defaultValue={ 0 } InputProps={ { disabled: scopedFormData.seriesDeltaDeliveryAllDay } } inputProps={ { sx: { textAlign: 'end' } } } />
                                        <NumberInput source='seriesDeltaDeliveryHour' helperText={ false } label="Offset hours" defaultValue={ 0 } InputProps={ { disabled: scopedFormData.seriesDeltaDeliveryAllDay } } inputProps={ { sx: { textAlign: 'end' } } } max={ 23 } min={ -23 } />
                                        <NumberInput source='seriesDeltaDeliveryMinute' helperText={ false } label="Offset minutes" defaultValue={ 0 } InputProps={ { disabled: scopedFormData.seriesDeltaDeliveryAllDay } } inputProps={ { sx: { textAlign: 'end' } } } max={ 59 } min={ -59 } />
                                      </>
                                    ) }
                                  </FormDataConsumer>
                                </SimpleFormIterator>
                              </ArrayInput>
                            </Grid>
                          </>
                        )
                        : (

                          <Grid item container xs={ 12 } spacing={ '1rem' }>
                            <Grid item xs={ 2 } sx={ { border } }>
                              <BooleanInput source='singleDeltaDeliveryAllDay' label="All day" />
                            </Grid>
                            <Grid item xs={ 2 } sx={ { border } }>
                              <NumberInput source='singleDeltaDayNumber' label="Offset days" helperText={ false } defaultValue={ 0 } inputProps={ { sx: { textAlign: 'end' } } } InputProps={ { disabled: scopedFormData.singleDeltaDeliveryAllDay } } />
                            </Grid>
                            <Grid item xs={ 2 } sx={ { border } }>
                              <NumberInput source='singleDeltaDayDeliverHour' label="Offset hours" helperText={ false } defaultValue={ 0 } inputProps={ { sx: { textAlign: 'end' } } } max={ 23 } min={ -23 } InputProps={ { disabled: scopedFormData.singleDeltaDeliveryAllDay } } />
                            </Grid>
                            <Grid item xs={ 2 } sx={ { border } }>
                              <NumberInput source='singleDeltaDayDeliverMinute' label="Offset minutes" helperText={ false } defaultValue={ 0 } inputProps={ { sx: { textAlign: 'end' } } } max={ 59 } min={ -59 } InputProps={ { disabled: scopedFormData.singleDeltaDeliveryAllDay } } />
                            </Grid>

                          </Grid>
                        ) }

                      { scopedFormData.override && (
                        <>
                          <Grid container item xs={ 12 } direction='row' spacing={ '1rem' } alignItems='stretch' >
                            <Grid item xs={ 6 } sx={ { border } }>
                              <TextInput source='description' fullWidth multiline rows={ 8 } />
                            </Grid>
                            <Grid item xs={ 6 } sx={ { border } } >
                              <TextInput source='summary' fullWidth />
                              <TextInput source='deliveryLocationTitle' fullWidth />
                              <TextInput source='deliveryLocationAddress' fullWidth />
                            </Grid>
                          </Grid>
                          <Grid container item xs={ 12 } spacing={ 2 }>
                            <Grid item xs={ 2 }>
                              <NumberInput source='priority' inputProps={ { sx: { textAlign: 'end' } } } max={ 4 } min={ 1 } />
                            </Grid>
                            <Grid item xs={ 2 }>
                              <SelectInput source='busyStatus' choices={ choices.busyStatus } fullWidth />
                            </Grid>
                            <Grid item xs={ 6 }>
                              <AutocompleteTextArrayInput source='categories' label='Categories' choices={ choices.eventCategories.map( c => c.id ) } fullWidth />
                            </Grid>

                          </Grid>


                          <Grid container item xs={ 12 } spacing={ 2 }>
                            <Grid item xs={ 2 }>
                              <BooleanInput source='alarmDisplayEnable' label="Display alarm" />
                            </Grid>
                            <Grid item xs={ 2 }>
                              <NumberInput source='alarmDisplayBeforeSecs' max={ 1000 } min={ 1 }
                                inputProps={ {
                                  sx: { textAlign: 'end' },
                                  // startAdornment: <InputAdornment position="end">secs</InputAdornment>,
                                } }
                                InputProps={ { disabled: !scopedFormData.alarmDisplayEnable } } />
                            </Grid>
                            <Grid item xs={ 6 }>
                              <TextInput source='alarmDisplayBeforeDescription' InputProps={ { disabled: !scopedFormData.alarmDisplayEnable } } fullWidth />
                            </Grid>
                          </Grid>
                          <Grid container item xs={ 12 } spacing={ 2 }>
                            <Grid item xs={ 2 }>
                              <BooleanInput source='alarmAudioEnable' label="Audio alarm" />
                            </Grid>
                            <Grid item xs={ 2 }>
                              <NumberInput source='alarmAudioBeforeSecs' max={ 1000 } min={ 1 }
                                inputProps={ { sx: { textAlign: 'end' } } } InputProps={ { disabled: !scopedFormData.alarmAudioEnable } } />
                            </Grid>
                            <Grid item xs={ 6 }>
                              <TextInput source='alarmAudioBeforeDescription' InputProps={ { disabled: !scopedFormData.alarmAudioEnable } } fullWidth />
                            </Grid>
                          </Grid>
                        </>
                      ) }
                    </Grid>
                  </Card>
                );
              }
              }
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>

      </Grid >

      <CustomToolbar />
    </AdminForm >

  );
}
export const CalendarPackageEdit: FC<EditProps> = props => (
  <Edit
    redirect='list'
    // actions={ <EditActions breadcrumb={ <MyBreadcrumb variant="actions" /> } /> }
    { ...props }
  >
    <CalendarPackageForm />
  </Edit>
);
