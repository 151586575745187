import { Box, Link as MuiLink } from '@mui/material';
import { FC } from 'react';
import { ChipField, Datagrid, DateInput, Filter, FilterProps, FunctionField, List, ListProps, RaRecord, ReferenceArrayField, ReferenceField, SimpleShowLayout, SingleFieldList, TextField, useRedirect, WithRecord } from 'react-admin';
import { TimestampsField } from './TimestampsField';
import { Childless } from './types';

export { default as UndeliverableEmailIcon } from '@mui/icons-material/MoveToInbox';
const UndeliverableEmailFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <DateInput label="Received" source="createdAt" />
  </Filter>
);

export const UndeliverableEmailList: FC<Childless<ListProps>> = props => {
  const redirect = useRedirect();

  const onClickRecipient = ( recipient: RaRecord ) => () => {
    redirect( '/contacteditor', undefined, undefined, undefined, { recipient } )
  };

  return (
    <List { ...props }
      filter={ { processType: 'undeliverable' } }
      filters={ <UndeliverableEmailFilter /> }
      exporter={ false }
      sort={ { field: 'createdAt', order: 'DESC' } }
      resource='inboundemails'
    >
      <Datagrid
        bulkActionButtons={ false }
        rowStyle={ ( record ) => {
          const colors = [ 'green', 'blue', 'yellow', 'orange', 'red' ];
          return {
            borderLeft: `solid 10px ${ colors[ Math.min( 4, Math.max( 0, record.spamScore - 1 ) ) ] }`,
          }
        } }
        expand={ <UndeliverableEmailExpandShow /> }
        expandSingle
        isRowExpandable={ ( record ) => !!record.text }
        sx={ {
          '& .column-spamScore': {
            maxWidth: '2rem',
          },
          '& .column-createdAt': {
            maxWidth: '6rem',
          }
        } }
      >

        <TimestampsField label="Received" source='createdAt' />

        <ReferenceArrayField label='Affected recipients' source="recipients" reference="recipients" sortable={ false } >
          <SingleFieldList linkType={ false } >
            <WithRecord render={ ( record ) =>
              <ChipField source="fullName" onClick={ onClickRecipient( record ) } clickable />
            } />
          </SingleFieldList>
        </ReferenceArrayField>

        <TextField label="Undeliverable address" source='to.0' />

        <ReferenceField label='Original recipient' source="recipient" reference="recipients" link={ false } sortable={ false } >

          <WithRecord render={ ( record ) =>
            <MuiLink onClick={ onClickRecipient( record ) } underline='none' >
              <TextField source="fullName" />
            </MuiLink>
          } />
        </ReferenceField>



      </Datagrid>
    </List>
  );
}

const UndeliverableEmailExpandShow: FC = () => (
  <SimpleShowLayout>
    <FunctionField label=""
      render={ ( record: { text?: string, processLog?: string } ) => (
        <Box>
          { record.processLog &&
            <Box component='pre'
              sx={ {
                maxWidth: '60rem',
                overflowX: 'scroll',
                borderBottom: '1px solid gray',
              } }
            >
              Log: { record.processLog }
            </Box>
          }
          <Box component='pre'
            sx={ {
              maxWidth: '60rem',
              overflowX: 'scroll',
            } }
          >
            {
              record.text
            }
          </Box>
        </Box>
      )
      } />
  </SimpleShowLayout>
);
