/*eslint @typescript-eslint/no-unused-vars: "off" */
import { CircularProgress } from '@mui/material';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { get } from 'lodash';
import { FC, useEffect, useMemo } from 'react';
import { Create, Datagrid, DateInput, EditContextProvider, EditProps, Filter, FilterProps, FunctionField, List, ListProps, RaRecord, SaveButton, Show, ShowProps, TextField, Toolbar, ToolbarProps, useEditController, useRecordContext, useRedirect, useUpdate, WithRecord } from 'react-admin';
import { useFormState } from 'react-hook-form';
import 'survey-core/defaultV2.min.css';
import { CreatorBase } from 'survey-creator-core';
import 'survey-creator-core/survey-creator-core.min.css';
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';
import { AdminShowLayout, AdminSimpleForm, useAdminListAppLocation } from './Admin';
import { TimestampsField } from './TimestampsField';
import { Childless } from './types';

export { default as FormDefinitionIcon } from '@mui/icons-material/Assignment';

const FormDefinitionFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <DateInput label="Date" source="createdAt" alwaysOn />
  </Filter>
);

export const FormDefinitionList: FC<Childless<ListProps>> = props => {
  useAdminListAppLocation();
  const redirect = useRedirect();

  const onClickRecipient = ( recipient: RaRecord ) => () => {
    redirect( '/contacteditor', undefined, undefined, undefined, { recipient } )
  };

  const filterDefaultValues = {
    //transmitMethod: 'sms'
  };

  return (
    <List { ...props }
      // filterDefaultValues={ filterDefaultValues }
      // filters={ <FormDefinitionFilter /> }
      exporter={ false }
      sort={ { field: 'createdAt', order: 'DESC' } }
    >
      <Datagrid
        rowClick='show'
        // expand={ <HtmlEmailTitleExpandEdit /> }
        bulkActionButtons={ false }
      >

        <FunctionField
          label='Title'
          source='json'
          render={ ( r: RaRecord ) => {
            try {
              const form = JSON.parse( r.json );
              return get( form, 'title', get( form, 'pages.0.name', 'Untitled' ) );
            } catch( e ) {
              return null;
            }
          } }
        />
        <TextField source='id' />

        <TimestampsField label="Updated" source='createdAt' />
        <TimestampsField label="Created" source='updatedAt' />


      </Datagrid>
    </List>
  );
}

export const FormDefinitionShow: FC<ShowProps> = props => {

  return (
    <Show >
      <AdminShowLayout>

        <WithRecord render={ record => {

          const creator = useMemo( () => {
            if( record?.json ) {
              try {
                const form = JSON.parse( record.json );
                const creator = new SurveyCreator( {
                  showPreview: true,
                  showDesignerTab: false,
                  showJSONEditorTab: false,
                  showThemeTab: false,
                  showLogicTab: false,
                  showSimulatorInPreviewTab: true,
                } );
                creator.JSON = form;
                creator.activeTab = 'test';
                return creator;
                // eslint-disable-next-line no-empty
              } catch( e ) { }
            }
          }, [ record ] );

          if( !creator ) return <CircularProgress />;

          return (
            <SurveyCreatorComponent creator={ creator } />
          );
        } }
        />


      </AdminShowLayout>
    </Show>
  );
}

export const FormDefinitionCustomToolbar: FC<ToolbarProps> = props => {
  const { isValid, isDirty } = useFormState();
  return (
    <Toolbar { ...props }
    >
      <SaveButton disabled={ !isDirty } invalid={ !isValid } />
      {/* <Button label="Cancel" redirect={ props.redirect || 'show' }><Cancel /></Button> */ }
    </Toolbar>
  );
}



export const FormDefinitionCreate: FC<EditProps> = props => {
  const record = useRecordContext();
  const form = useMemo( () => {
    if( !record ) return;
    try {
      return JSON.parse( record.json );
      // eslint-disable-next-line no-empty
    } catch( e ) { }
  }, [ record ] );

  const creator = useMemo( () => {
    const creator = new SurveyCreator( {
      showThemeTab: true,
      showLogicTab: true,
      showSimulatorInPreviewTab: true,
      showPreview: true,
    } );
    if( form ) {
      creator.JSON = form;
    }
    creator.saveSurveyFunc = ( saveNo: number, callback: ( ( saveNo: number, success: boolean ) => void ) ) => {
      // If you use localStorage:
      window.localStorage.setItem( 'survey-json', creator.text );
      callback( saveNo, true );

      // // If you use a web service:
      // saveSurveyJson(
      //   "https://your-web-service.com/",
      //   creator.JSON,
      //   saveNo,
      //   callback
      // );
    };
    return creator;

  }, [ record, form ] );

  return (
    <Create
      redirect='show'
      actions={ false }
      { ...props }
    >
      <AdminSimpleForm
        // sanitizeEmptyValues
        toolbar={ <FormDefinitionCustomToolbar /> }
      >
        <SurveyCreatorComponent creator={ creator } />

      </AdminSimpleForm>
    </Create>
  );
}

export const FormDefinitionEdit: FC<EditProps> = props => {
  const controllerProps = useEditController<RaRecord>( props );
  const { resource, record, ...rest } = controllerProps;
  const [ update ] = useUpdate();

  const [ _location, setLocation ] = useAppLocationState();
  useEffect( () => {
    setLocation( `admin.${ resource }.${ record?.id ? 'edit' : 'create' }`, { record } );
    return () => setLocation( null );
  }, [ record, resource ] );

  const form = useMemo( () => {
    if( !record?.json ) return;
    try {
      return JSON.parse( record.json );
      // eslint-disable-next-line no-empty
    } catch( e ) { }
  }, [ record ] );

  const creator = useMemo( () => {
    const creator = new SurveyCreator( {
      showThemeTab: true,
      showLogicTab: true,
      showSimulatorInPreviewTab: true,
      showPreview: true,
    } );
    if( form ) {
      creator.JSON = form;
    }
    creator.onModified.add( ( creator: CreatorBase, options ) => {
      console.log( 'modified', options );
      // console.dir( creator.text );
      // setValue( 'json', JSON.stringify( creator.text ) );
    } );
    // creator.onPropertyValueChanging.add( ( creator: CreatorBase, options: Record<string, unknown> ) => {
    //   console.log( 'pvc', options );
    //   setValue( 'json', JSON.stringify( creator.text ) );
    // } );
    creator.saveSurveyFunc = ( saveNo: number, callback: ( ( saveNo: number, success: boolean ) => void ) ) => {
      // window.localStorage.setItem( 'survey-json', creator.text );
      if( !record ) {
        callback( saveNo, false );
        return;
      }
      const json = JSON.stringify( creator.text );
      update( resource, { id: record.id, data: { ...record, json: creator.text }, previousData: record } )
      // !save
      //   ? callback( saveNo, false )
      //   : save( data, {
      //     onSuccess: () => callback( saveNo, true ),
      //     onError: () => callback( saveNo, false ),
      //   } );
    };
    return creator;

  }, [ record, form ] );

  return (
    <EditContextProvider value={ controllerProps }>

      <SurveyCreatorComponent creator={ creator } />
    </EditContextProvider>

  );

}
