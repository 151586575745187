import { useTheme } from '@mui/material';
import { BarCustomLayer, BarDatum, BarLayer } from '@nivo/bar';
import { CustomLayer, Datum, Layer, Serie } from '@nivo/line';
import { endOfMonth, format as formatDate } from 'date-fns';
import { Dayjs } from 'dayjs';
import _ from 'lodash';
import { SVGProps, useCallback, useMemo } from 'react';
import { useUserPreference } from './UserPreferences';

export interface PredictionDatum extends Datum {
  y: number | null;
  ymd: string;
  md: string;
  isPrediction?: boolean;
}
export interface PredictionSerie extends Serie {
  data: PredictionDatum[];
}

export interface TrendPrediction {
  predictedData: PredictionSerie[] | undefined;
  hasPrediction: boolean;
  predictionBackgroundLayer: CustomLayer;
  layers: Layer[];
}
export const useTrendPredictions = ( data: PredictionSerie[], date?: Dayjs | null ): TrendPrediction => {
  const theme = useTheme();
  const defaultDurationMonths = 6;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const durationMonths = useMemo( () => {
    if( isLoadingPreferences || !preferences?.dashboardTrendMonths ) return defaultDurationMonths;
    return preferences.dashboardTrendMonths as number;
  }, [ preferences, isLoadingPreferences ] );

  const progressRatio = useMemo( () => {
    const daysInMonth = endOfMonth( new Date() ).getDate();
    const dayOfMonth = new Date().getDate();
    return dayOfMonth / daysInMonth;
  }, [] );

  const predictedData = useMemo( () => {
    if( date ) return;
    const predicted: PredictionSerie[] = [];
    for( const linePoint of data ) {
      const current = linePoint.data.find( lp => lp.ymd.startsWith( formatDate( new Date(), 'yyyy-MM' ) ) );
      const projection = ( current?.y ?? 0 ) / progressRatio;

      const prediction: PredictionDatum = {
        isPrediction: true,
        x: formatDate( new Date(), 'yyyy-MM-01' ),
        y: projection ? Math.round( projection ) : null,
        ymd: formatDate( new Date(), 'yyyy-MM-01' ),
        md: formatDate( new Date(), 'MM-01' ),
      };

      predicted.push( {
        id: linePoint.id,
        data: [ ...linePoint.data.slice( 0, current ? -1 : undefined ), prediction ],
      } );
    }
    return predicted;
  }, [ data, date, progressRatio ] );

  const predictedLines = useMemo( () => {
    return predictedData?.filter( l => !!l.data.find( d => d.isPrediction ) ).map( l => l.id ) || [];
  }, [ predictedData ] );

  const hasPrediction = useMemo( () => !!predictedLines.length, [ predictedLines ] );

  const predictionBackgroundLayer = useCallback<CustomLayer>( props => {
    if( !hasPrediction ) return;
    const width = 0.6 * props.innerWidth / ( durationMonths );
    const sharedProps: SVGProps<SVGRectElement> = {
      x: props.innerWidth - width,
      width,
      fill: 'url(#diagonalHatch)',
    };
    return <>
      <pattern id="diagonalHatch" width="10" height="10" patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="10" style={ {
          stroke: theme.palette?.mode === 'dark' ? '#444' : '#ccc',
          strokeWidth: 10,
        } } />
      </pattern>
      <rect { ...sharedProps }
        y={ 0 }
        height={ props.innerHeight }
        opacity={ 0.25 }
      />
      <rect { ...sharedProps }
        y={ props.innerHeight * ( 1 - progressRatio ) }
        height={ props.innerHeight * progressRatio }
        opacity={ 0.3 }
      />
    </>;
  }, [ durationMonths, hasPrediction, predictedData, progressRatio, theme ] );

  return {
    predictedData: hasPrediction ? predictedData : undefined,
    hasPrediction,
    predictionBackgroundLayer,
    layers: [ 'grid', 'markers', 'axes', 'areas', predictionBackgroundLayer, 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends' ],
  };

}

export interface BarTrendPrediction<T extends BarDatum> {
  predictedData: T[] | undefined;
  hasPrediction: boolean;
  predictionBackgroundLayer: BarCustomLayer<T>;
  layers: BarLayer<T>[];
}
export const useBarTrendPredictions = <T extends BarDatum & { ymd: string }>( data: T[], date?: Dayjs | null, keys: Array<keyof T> = [] ): BarTrendPrediction<T> => {
  const theme = useTheme();
  const defaultDurationMonths = 6;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const durationMonths = useMemo( () => {
    if( isLoadingPreferences || !preferences?.dashboardTrendMonths ) return defaultDurationMonths;
    return preferences.dashboardTrendMonths as number;
  }, [ preferences, isLoadingPreferences ] );

  const progressRatio = useMemo( () => {
    const daysInMonth = endOfMonth( new Date() ).getDate();
    const dayOfMonth = new Date().getDate();
    return dayOfMonth / daysInMonth;
  }, [] );

  const predictedData = useMemo( () => {
    if( date ) return;
    const current = data.find( d => d.ymd.startsWith( formatDate( new Date(), 'yyyy-MM' ) ) );

    const projections = _( current )
      .pick( keys )
      .mapValues( v => typeof v == 'number' ? Math.round( v / progressRatio ) : v )
      .value();

    const prediction = {
      datum: {
        date: formatDate( new Date(), 'MM/01' ),
        id: formatDate( new Date, `MMM ''yy` ),
        ymd: formatDate( new Date(), 'yyyy-MM-01' ),
        md: formatDate( new Date(), 'MM-01' ),
        ...projections,
      } as unknown as T,
      isPrediction: true,
    };

    return [
      ...data.map( datum => ( { datum, isPrediction: false } ) ).slice( 0, current ? -1 : undefined ),
      prediction,
    ];
  }, [ data, date, progressRatio ] );

  const predictedBars = useMemo( () => {
    return predictedData?.filter( d => d.isPrediction ).map( d => d.datum.id ) || [];
  }, [ predictedData ] );

  const hasPrediction = useMemo( () => !!predictedBars.length, [ predictedBars ] );

  const predictionBackgroundLayer = useCallback<BarCustomLayer<T>>( props => {
    if( !hasPrediction ) return;
    const bar = props.bars[ props.bars.length - 1 ];
    return <>
      <pattern id="diagonalHatch" width="10" height="10" patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse">
        <line x1="0" y1="0" x2="0" y2="10" style={ {
          stroke: theme.palette?.mode === 'dark' ? '#444' : '#ccc',
          strokeWidth: 10,
        } } />
      </pattern>
      <rect
        x={ bar.x }
        y={ bar.y }
        width={ bar.width }
        height={ props.innerHeight - bar.y }
        opacity={ 0.6 }
        fill='url(#diagonalHatch)'
        pointerEvents='none'
      />
    </>;
  }, [ durationMonths, hasPrediction, predictedData, progressRatio, theme ] );

  return {
    predictedData: hasPrediction ? predictedData?.map( d => d.datum ) : undefined,
    hasPrediction,
    predictionBackgroundLayer,
    layers: [ 'grid', 'axes', 'bars', predictionBackgroundLayer, 'totals', 'markers', 'legends', 'annotations' ],
  };
}
