/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined, Send as SendIcon } from '@mui/icons-material';
import { ButtonProps, Divider, Grid, useTheme } from '@mui/material';
import { FC, MouseEventHandler, ReactElement, useEffect, useMemo, useState } from 'react';
import { AutocompleteArrayInput, AutocompleteInput, BooleanField, BooleanInput, ChipField, Create, CreateProps, Datagrid, DatagridProps, DateField, DateInput, DateTimeInput, DeleteButton, Edit, EditButton, EditProps, Filter, FilterProps, FormDataConsumer, IconButtonWithTooltip, Identifier, InputProps, Labeled, List, ListProps, RaRecord, ReferenceArrayField, ReferenceArrayInput, ReferenceField, ReferenceInput, ReferenceManyField, SaveButton, SaveButtonProps, SearchInput, SelectArrayInput, SelectField, SelectInput, Show, ShowProps, SimpleFormProps, SimpleShowLayout, SingleFieldList, TextField, TextInput, TitleProps, Toolbar, TopToolbar, useGetOne, useRecordContext, useRedirect, useSaveContext, useShowController, useUpdate, WithRecord } from 'react-admin';
import { apiUrl, choices, httpClient } from './DataProvider';
import { FormWithLocation, SimpleShowLayoutWithLocation, useListAppLocation } from './MyBreadcrumb';
import { Option } from './TelecomEditorForm';
import TextArrayField from './TextArrayField';

export { default as OutboundAlertIcon } from '@mui/icons-material/ScheduleSend';


export const OutboundAlertTitle: FC<TitleProps> = props => {
  return <span>OutboundAlert { props.record?.name ? `"${ props.record.name }"` : '' }</span>;
};

const OutboundAlertShowActions: FC<{ record: RaRecord }> = ( { record } ) => (
  <TopToolbar>
    { isAlertEditable( record ) && <DeleteButton /> }
    { isAlertEditable( record ) && <EditButton /> }
  </TopToolbar>
);

export const OutboundAlertShow: FC<ShowProps> = props => {
  const theme = useTheme();
  const { record, isLoading } = useShowController( props );

  const recipientRowStyle: DatagridProps[ 'rowStyle' ] = ( record ) => {
    return ( {
      backgroundColor: record.enable ? undefined : theme.palette?.grey?.[ 100 ],
    } );
  }

  if( isLoading ) return null;

  return (
    <Show
      actions={ <OutboundAlertShowActions record={ record } /> }
      title={ <OutboundAlertTitle /> }
      { ...props }>

      <Grid
        container
        m={ 1 }
        justifyContent='flex-start'
        columnSpacing={ 4 }
        rowSpacing={ 2 }
        columns={ 10 }
      >

        <Grid item xs={ 9 } >
          <SimpleShowLayoutWithLocation basePath='broadcasts'>
            <TextField source="name" fullWidth />
          </SimpleShowLayoutWithLocation>
        </Grid>

        <Grid item xs={ 1.5 } >
          <SimpleShowLayout>
            <SelectField
              source="alertDataSource"
              label='Source'
              choices={ choices.alertSource }
              sx={ {
                width: '100%',
              } }
            />
          </SimpleShowLayout>
        </Grid>

        <Grid item xs={ 1.5 } >
          <SimpleShowLayout>

            <SelectField
              source="alertScheduleType"
              label='Schedule type'
              choices={ choices.alertScheduleType }
              sx={ {
                width: '100%',
              } }
            />
          </SimpleShowLayout>
        </Grid>

        <Grid item xs={ 2 } >
          <SimpleShowLayout>
            <DateField source='alertRequestedFireDate' label='Scheduled' showTime />
          </SimpleShowLayout>
        </Grid>

        <Grid item xs={ 1.5 } >
          <SimpleShowLayout>
            <ReferenceField label='Base template' source='baseAlertTemplate' reference='alerttemplates'>
              <TextField source='name' />
            </ReferenceField>
          </SimpleShowLayout>
        </Grid>

        <Grid item xs={ 1 } >
          <SimpleShowLayout>
            <TextField label='State' source="alertState" />
          </SimpleShowLayout>
        </Grid>


        <Grid
          container
          item
          mt={ -4 }
          justifyContent='flex-start'
          columnSpacing={ 4 }
          rowSpacing={ 2 }
          columns={ 10 }
        >
          <Grid item xs={ 9 }>
            <Divider textAlign="left">
              Delivery and Content
            </Divider>
          </Grid>

          <Grid item xs={ 'auto' }>
            <SimpleShowLayout>
              <BooleanField
                source='alertTemplate.sendPatientAlert'
                label='Send to Patient'
              />
              {/* 
                  </SimpleShowLayout>
                  </Grid>

                  <Grid item xs={ 'auto' }>
                  <SimpleShowLayout>
                */}
              <BooleanField
                source='alertTemplate.sendPatientRecipientsAlert'
                label='Send to Patient Recipients'
              />
            </SimpleShowLayout>
          </Grid>

          <Grid item xs={ 5 }>
            <SimpleShowLayout>
              <TextField source="alertTemplate.alertSmsText" label="SMS Message" />
            </SimpleShowLayout>
          </Grid>


          { /* record.alertDataSource == 'Patient List' &&
                       <>
                       <Grid item xs={ 10 }>
                       <Divider textAlign="left">
                       Patient Select
                       </Divider>
                       </Grid>
                       <Grid item xs={ 10 } >
                       <SimpleShowLayout>
                       <ReferenceArrayField source='patients' reference='recipients' >
                       <SingleFieldList>
                       <ChipField source='fullName' />
                       </SingleFieldList>
                       </ReferenceArrayField>
                       </SimpleShowLayout>
                       </Grid>
                       </>
                     */ }

          { record.alertDataSource == 'Appointment List' &&
            <>

              <Grid item xs={ 10 }>
                <Divider textAlign="left">
                  Appointment Filters
                </Divider>
              </Grid>
              <Grid item xs={ 4 } >
                <SimpleShowLayout>
                  <ReferenceArrayField
                    source='locations'
                    reference='locations'
                  >
                    <SingleFieldList>
                      <ChipField source='name' />
                    </SingleFieldList>
                  </ReferenceArrayField>
                </SimpleShowLayout>
              </Grid>

              <Grid item xs={ 6 } >
                <SimpleShowLayout>
                  <ReferenceArrayField
                    source='practitioners'
                    reference='practitioners'
                  >
                    <SingleFieldList>
                      <ChipField source='fullName' />
                    </SingleFieldList>
                  </ReferenceArrayField>
                </SimpleShowLayout>
              </Grid>

              <Grid item xs={ 'auto' }>
                <SimpleShowLayout>
                  <DateField
                    source='startTime'
                    label='Appointment range start'
                    showTime
                    sx={ {
                      width: '100%',
                    } }
                  />
                </SimpleShowLayout>
              </Grid>

              <Grid item xs={ 'auto' } >
                <SimpleShowLayout>
                  <DateField
                    source='stopTime'
                    label='Appointment range end'
                    showTime
                    sx={ {
                      width: '100%',
                    } }
                  />
                </SimpleShowLayout>
              </Grid>

              <Grid item xs={ 3 }>

                <Labeled label='Enabled statuses'>
                  <TextArrayField
                    source="alertTemplate.enabledStatuses"
                    label='Enabled statuses'
                  />
                </Labeled>

              </Grid>

            </>
          }
        </Grid>

        <Grid item xs={ 10 }>
          <Divider textAlign="left">
            Patient Recipients
          </Divider>
        </Grid>

        <Grid item xs={ 10 } >
          <SimpleShowLayout>

            <ReferenceManyField label={ false } reference="alertrecipients" target="outboundAlert">
              <Datagrid
                bulkActionButtons={ false }
                rowStyle={ recipientRowStyle }
                sx={ {
                  '& tbody .MuiTableRow-root .MuiTableCell-root:last-child': {
                    width: record.alertDataSource == 'Patient List' ? '100%' : undefined, // force Patient to consume width when alone
                  },
                  '& .column-enabled': {
                    textAlign: 'center',

                  }
                } }
              >

                <ToggleEnableButton label='Enabled' source='enabled' />

                <ReferenceField source="patient" reference="recipients" fullWidth link={ false }>
                  <TextField source="fullName" />
                </ReferenceField>

                { record.alertDataSource == 'Appointment List' &&
                  <ReferenceField source="location" reference="locations" >
                    <TextField source="name" />
                  </ReferenceField>
                }
                { record.alertDataSource == 'Appointment List' &&
                  <ReferenceField source="practitioner" reference="practitioners" >
                    <TextField source="fullName" />
                  </ReferenceField>

                }
                { record.alertDataSource == 'Appointment List' &&
                  <ReferenceField source="appointment" reference="appointments" >
                    <DateField source="startTime" showTime />
                  </ReferenceField>
                }

                { record.alertDataSource == 'Appointment List' &&
                  <ReferenceField label='Status' source="appointment" reference="appointments" link={ false } >
                    <TextField source="status" />
                  </ReferenceField>
                }



              </Datagrid>
            </ReferenceManyField>
          </SimpleShowLayout>
        </Grid>

      </Grid>


    </Show>
  );
}


export const ToggleEnableButton: FC<ButtonProps | { label?: string; source?: string }> = ( props ) => {
  // settable but usused label/source props are for Datagrid header label & cell classname
  const resource = 'alertrecipients';
  const record = useRecordContext( props );
  const [ update ] = useUpdate();

  const { label, icon } = useMemo( () => {
    if( !record ) return { label: '', icon: null };
    if( record?.enable ) return { label: 'Disable', icon: <CheckBoxOutlined /> };
    return { label: 'Enable', icon: <CheckBoxOutlineBlankOutlined /> };
  }, [ record?.enable ] );

  const onClick: MouseEventHandler<HTMLButtonElement> = ( e ) => {
    if( !record ) return;
    e.stopPropagation();
    update( resource, { id: record.id, data: { enable: !record.enable }, previousData: record }, { mutationMode: 'optimistic' } );
  }

  if( !record ) return null;


  return (
    <IconButtonWithTooltip
      label={ label }
      onClick={ onClick }
      size='small'
      color='primary'
      // edge='start'
      sx={ {
        paddingTop: 0,
        paddingBottom: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        '& .MuiSvgIcon-root': {
          fontSize: '1.5rem',
        }
      } }
      disabled={ !isAlertEditable( record ) }
    >
      { icon }
    </IconButtonWithTooltip>
  );
};

export const OutboundAlertFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <SelectInput label="Type" source="recipientRole" choices={ choices.recipientType.slice( 2 ) } alwaysOn />
    <DateInput label="Date" source="postedDate" alwaysOn />
    <ReferenceInput label="Patient" source="patient" reference="recipients" filter={ { status: 'active', recipientType: 'Patient' } } sort={ { field: 'fullName', order: 'ASC' } } allowEmpty alwaysOn >
      <AutocompleteInput optionText={ choice => choice ? `${ choice.birthDate?.slice( 0, 10 ) ?? '' } ${ choice.fullName ?? '' }` : '' } />
    </ReferenceInput>
    <ReferenceInput label="Practitioner" source="practitioner" reference="practitioners" filter={ { active: true } } sort={ { field: 'fullName', order: 'ASC' } } allowEmpty alwaysOn >
      <AutocompleteInput optionText="fullName" />
    </ReferenceInput>
    <TextInput label="Status" source="smsStatus" />
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export const OutboundAlertListPanel: FC<Partial<{ basePath: string; id: Identifier; record: RaRecord; resource: string; }>> = ( { record } ) => (
  <div dangerouslySetInnerHTML={ { __html: `<pre>${ record?.message }</pre>` } } />
);

const isAlertEditable = ( record: RaRecord ) => record.alertScheduleType != 'Immediate' && [ 'Waiting', 'New' ].includes( record.alertState );

export const OutboundAlertList: FC<Omit<ListProps, 'children'>> = props => {
  useListAppLocation( 'broadcasts' );

  // const filterDefaultValues = { transmitMethod: 'sms' };


  // TODO filter on alertState

  return (
    <List { ...props }
      // bulkActionButtons={ false }
      exporter={ false }
      // filterDefaultValues={ filterDefaultValues }
      // filters={ <OutboundAlertFilter /> }
      // sort={ { field: 'postedDate', order: 'DESC' } }
      perPage={ 10 }
    >
      <Datagrid
        rowClick="show"

        bulkActionButtons={ false }
      >

        <TextField source="name" />
        <TextField source="alertDataSource" label='Source' />
        <ReferenceField source='baseAlertTemplate' reference='alerttemplates'>
          <TextField source='name' label='Base template' />
        </ReferenceField>
        <TextField source="alertTemplate.name" label='Template' />
        <SelectField source='alertScheduleType' choices={ choices.alertScheduleType } label='Type' />
        <DateField source='alertRequestedFireDate' label='Scheduled' showTime />
        <SelectField source='alertState' choices={ choices.alertState } label='Status' />
        <WithRecord render={ record => ( isAlertEditable( record ) // record.alertScheduleType != 'Immediate' && [ 'Waiting', 'New' ].includes( record.alertState )
          ? <EditButton />
          : <></>
        ) } />
      </Datagrid>
    </List>
  );
}


export const OutboundAlertForm: FC<Omit<SimpleFormProps, 'children'>> = () => {

  //////////////////////////////////////////////////////////////////
  // Manage embedded alertTemplate on baseAlertTemplate set/update
  //////////////////////////////////////////////////////////////////
  const record = useRecordContext(); // for initial load on Edit, not Create 
  const [ templateId, setTemplateId ] = useState<string | undefined>();
  const onBaseTemplateChange: InputProps[ 'onChange' ] = ( e ) => setTemplateId( e.target.value ); // for Create and update during Edit
  const { data: template, refetch } = useGetOne( 'alerttemplates', { id: templateId }, { enabled: !!templateId } );
  useEffect( () => {
    if( !templateId || !refetch ) return;
    refetch();
    // how to load template into alertTemplate ??
  }, [ templateId ] ); // reload when base changed
  useEffect( () => {
    if( !record?.baseAlertTemplate ) return;
    setTemplateId( record.baseAlertTemplate )
  }, [ record?.baseAlertTemplate ] );
  // const dataProvider = useDataProvider();
  const { save } = useSaveContext();
  const redirect = useRedirect();
  // @ts-ignore: 2339
  const onSubmit: SimpleFormProps[ 'onSubmit' ] = async ( data ) => {
    // const { baseAlertTemplate } = data;
    console.log( data );
    // if( data.baseAlertTemplate ) {
    //   const { data: template } = await dataProvider.getOne( 'alerttemplates', { id: baseAlertTemplate } );
    //   data.alertTemplate = { ...template };
    // }
    data.alertTemplate = { ...template, ...data.alertTemplate };
    data.entry = [];
    data.alertState = data.alertState || 'Waiting';
    if( data.alertScheduleType == 'Immediate' ) {
      data.alertRequestedFireDate = new Date().toISOString();
    }
    console.log( data );
    if( save ) {
      await save( data, {
        onSuccess: async ( data ) => {
          await httpClient( `${ apiUrl }/outboundalerts/${ data.id }/prepare`, { method: 'POST' } );
          console.log( 'redirect' );
          redirect( 'show', 'outboundalerts', data.id );
        },
      } );
    }
    // redirect( 'show' ); // TODO broken?
  }

  const getOptionLabel = ( option?: Option | string ): string | ReactElement => { // function(props: object, option: T, state: object) => ReactNode
    if( !option ) return '';
    if( typeof option === 'string' ) return option;
    const identifiers = ( option?.identifier || [] ).map( i => i.id ).join( ' / ' );
    return (
      <Grid container justifyContent='space-between'>
        <Grid item>
          { `${ ( option?.birthDate ?? '          ' ).slice( 0, 10 ) } ${ option?.fullName }` }
        </Grid>
        <Grid item>
          { identifiers }
        </Grid>
      </Grid>
    );
  };


  //////////////////////////////////////////////////////////////////

  return (
    <FormWithLocation
      basePath='broadcasts'
      shouldUnregister
      sanitizeEmptyValues
      warnWhenUnsavedChanges
      onSubmit={ onSubmit }
    >
      <Grid
        container
        m={ 1 }
        justifyContent='flex-start'
        columnSpacing={ 4 }
        rowSpacing={ 2 }
        columns={ 10 }
      >

        <Grid item xs={ 8 } >
          <TextInput
            source="name"
            helperText='Any description to help identify this alert later'
            required
            sx={ {
              width: '100%',
            } }
          />
        </Grid>
        <FormDataConsumer>
          { ( { formData } ) => (
            <>
              <Grid item xs={ 4 } >
                <SelectInput
                  source="alertDataSource"
                  label='Source'
                  choices={ choices.alertSource }
                  required
                  sx={ {
                    width: '100%',
                  } }
                  disabled={ !formData.name?.length }
                />
              </Grid>

              <Grid item xs={ 4 } >

                <ReferenceInput source="baseAlertTemplate" label='Template' reference='alerttemplates' >
                  <SelectInput
                    optionText="name"
                    required
                    sx={ {
                      width: '100%',
                    } }
                    onChange={ onBaseTemplateChange }
                    disabled={ !formData.name?.length }
                  />
                </ReferenceInput>

              </Grid>

              <Grid item xs={ 4 } >
                <SelectInput
                  source="alertScheduleType"
                  label='Schedule type'
                  choices={ choices.alertScheduleType }
                  required
                  sx={ {
                    width: '100%',
                  } }
                  disabled={ !formData.name?.length || formData.alertSource }
                />
              </Grid>
            </>
          ) }
        </FormDataConsumer>

        <Grid item xs={ 4 } >

          <FormDataConsumer>
            { ( { formData } ) => formData.alertScheduleType == 'Scheduled' && (
              <DateTimeInput
                source='alertRequestedFireDate'
                label='Requested send time'
                disabled={ !( formData.name && formData.alertDataSource && formData.baseAlertTemplate ) }
                sx={ {
                  width: '100%',
                } }
                required
              />
            ) }
          </FormDataConsumer>
        </Grid>

        <Grid
          container
          item
          mt={ -4 }
          justifyContent='flex-start'
          columnSpacing={ 4 }
          rowSpacing={ 2 }
          columns={ 12 }
        // sx={ { border: '1px solid blue' } }
        >

          <FormDataConsumer>
            { ( { formData } ) => formData.alertDataSource == 'Patient List' &&
              <>
                <Grid item xs={ 9 }>
                  <Divider textAlign="left">
                    Patient Select
                  </Divider>
                </Grid>
                <Grid item xs={ 10 } >

                  <ReferenceArrayInput
                    source='patients'
                    reference='recipients'
                    filter={ { recipientType: 'Patient' } }
                  >
                    <AutocompleteArrayInput
                      optionText={ getOptionLabel }
                      helperText='Search by name, date of birth, phone, email or identifier/MRN'
                      disabled={ !( formData.name && formData.alertDataSource && formData.baseAlertTemplate ) }
                      sx={ {
                        width: '100%',
                      } }
                    />
                  </ReferenceArrayInput>
                </Grid>
              </>
            }
          </FormDataConsumer>

          <FormDataConsumer>
            { ( { formData } ) => formData.alertDataSource == 'Appointment List' &&
              <>

                <Grid item xs={ 12 }>
                  <Divider textAlign="left">
                    Appointment Filters
                  </Divider>
                </Grid>
                <Grid item xs={ 5 } >
                  <ReferenceArrayInput
                    source='locations'
                    reference='locations'
                    filter={ { status: 'active' } }
                    sort={ { field: 'fullName', order: 'ASC' } }
                    page={ 1 }
                    perPage={ 200 }
                  >
                    <AutocompleteArrayInput
                      optionText="name"
                      disabled={ !( formData.name && formData.alertDataSource && formData.baseAlertTemplate ) }
                      sx={ {
                        width: '100%',
                      } }

                    />
                  </ReferenceArrayInput>
                </Grid>

                <Grid item xs={ 5 } >

                  <ReferenceArrayInput
                    source='practitioners'
                    reference='practitioners'
                    filter={ { active: true } }
                    sort={ { field: 'fullName', order: 'ASC' } }
                    page={ 1 }
                    perPage={ 200 }
                  >
                    <AutocompleteArrayInput
                      optionText="fullName"
                      disabled={ !( formData.name && formData.alertDataSource && formData.baseAlertTemplate ) }
                      sx={ {
                        width: '100%',
                      } }

                    />
                  </ReferenceArrayInput>
                </Grid>

                <Grid item xs={ 'auto' } sm={ 3 }>

                  <DateTimeInput
                    source='startTime'
                    label='Appointment range start'
                    disabled={ !( formData.name && formData.alertDataSource && formData.baseAlertTemplate ) }
                    sx={ {
                      width: '100%',
                    } }
                  />
                </Grid>

                <Grid item xs={ 3 } >

                  <DateTimeInput
                    source='stopTime'
                    label='Appointment range end'
                    disabled={ !( formData.name && formData.alertDataSource && formData.baseAlertTemplate ) }
                    sx={ {
                      width: '100%',
                    } }
                  />
                </Grid>

                <Grid item xs={ 4 }>

                  <SelectArrayInput
                    source="alertTemplate.enabledStatuses"
                    label='Enabled statuses'
                    choices={ choices.appointmentStatus }
                    defaultValue={ template?.enabledStatuses }
                    disabled={ !template }
                    sx={ {
                      width: '100%',
                    } }
                  />

                </Grid>
              </>
            }

          </FormDataConsumer>
        </Grid>

        { template &&
          <>
            <Grid item xs={ 10 }>
              <Divider textAlign="left">
                Delivery and Content
              </Divider>
            </Grid>

            <Grid item xs={ 'auto' }>
              <BooleanInput
                source='alertTemplate.sendPatientAlert'
                defaultValue={ template.sendPatientAlert }
                label='Send to Patient'
              />
            </Grid>

            <Grid item xs={ 9 }>
              <BooleanInput
                source='alertTemplate.sendPatientRecipientsAlert'
                defaultValue={ template.sendPatientRecipientsAlert }
                label='Send to Patient Recipients'
              />

            </Grid>

            <Grid item xs={ 3 }>
              {/* <ReplacementArgsInput source="alertTemplate.alertSmsText" label="SMS Message" defaultValue={ template.alertSmsText } minRows={ 8 } fullWidth /> */ }
              { /* <HtmlEditor source="alertTemplate.alertSmsText" defaultValue={ template.alertSmsText } label="SMS Message" { ...editorConfig.smsMessage } width={ 400 } height={ 250 } /> */ }
              <TextInput
                label='SMS Message'
                source='alertTemplate.alertSmsText'
                defaultValue={ template.alertSmsText }
                multiline
                minRows={ 8 }
                fullWidth
              />

            </Grid>

            <Grid item xs={ 3 }>
              { /* <HtmlEditor
                 source="alertTemplate.alertEmailText"
                 defaultValue={ template.alertEmailText }
                 label="Email Message"
                 { ...editorConfig.htmlMessage }
                 width={ 400 }
                 height={ 250 }
                 /> */ }
              <TextInput
                label='Email Message'
                source='alertTemplate.alertEmailText'
                defaultValue={ template.alertEmailText }
                multiline
                minRows={ 8 }
                fullWidth
              />

            </Grid>

            <Grid item xs={ 3 }>
              <TextInput
                label='Voice Message'
                source='alertTemplate.alertTwimlText'
                defaultValue={ template.alertTwimlText }
                multiline
                minRows={ 8 }
                fullWidth
              />
              {/* <HtmlEditor
                source="alertTemplate.alertTwimlText"
                defaultValue={ template.alertTwimlText }
                label="Spoken Message"
                { ...editorConfig.ssmlMessage }
                width={ 400 }
                height={ 250 }
                /> */}
            </Grid>
          </>
        }

      </Grid>
      <Toolbar>
        <FormDataConsumer>
          { ( { formData } ) => (
            <SaveOrSendNowButton scheduleType={ formData.alertScheduleType } />
          ) }
        </FormDataConsumer>
      </Toolbar>
    </FormWithLocation >
  );
}

export interface SaveOrSendNowButtonProps extends SaveButtonProps { scheduleType?: string; }
export const SaveOrSendNowButton: FC<SaveOrSendNowButtonProps> = props => {
  const { scheduleType, ...rest } = props;
  const label = useMemo( () => scheduleType == 'Immediate' ? 'Send now' : 'Save', [ scheduleType ] );
  const icon = useMemo( () => scheduleType == 'Immediate' ? <SendIcon /> : undefined, [ scheduleType ] );
  const color = useMemo( () => scheduleType == 'Immediate' ? 'error' : undefined, [ scheduleType ] );
  return <SaveButton { ...rest } { ...{ label, icon, color } } />;
}

export const OutboundAlertCreate: FC<CreateProps> = props => {

  return (
    <Create
      { ...props }
      redirect='list'
    >
      <OutboundAlertForm />
    </Create>
  )

}

export const OutboundAlertEdit: FC<EditProps> = props => {

  return (
    <Edit
      { ...props }
      redirect='list'
      mutationMode='pessimistic'
    >
      <OutboundAlertForm />
    </Edit>
  )

}

