/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { CancelOutlined as CancelIcon } from '@mui/icons-material';
import { MouseEventHandler, useState } from 'react';
import { Button, ButtonProps, Confirm } from 'react-admin';

export interface ButtonWithConfirmProps extends Omit<ButtonProps, 'onClick'> {
  onClick?: () => void;
  confirm?: boolean;
  confirmContent?: string;
  confirmTitle?: string;
}


export function ButtonWithConfirm( props: ButtonWithConfirmProps ) {
  const [ open, setOpen ] = useState( false );
  const {
    onClick,
    confirm = false,
    confirmContent: content = 'Are you sure you want to delete everything?',
    confirmTitle: title = 'Delete Everything',
    className = '',
    ...rest
  } = props;

  const handleClick: MouseEventHandler = () => {
    if( !confirm ) {
      onClick && onClick();
      return;
    }
    setOpen( true );
  }

  const handleConfirm: MouseEventHandler = () => {
    setOpen( false );
    onClick && onClick();
  }

  const handleClose: MouseEventHandler = () => {
    setOpen( false );
  }

  return (
    <>
      <Button
        { ...rest }
        className={ `buttonWithConfirm ${ className }` }
        onClick={ handleClick }
      />
      { confirm &&
        <Confirm
          isOpen={ open }
          title={ title }
          content={ content }
          confirm="Yes"
          confirmColor="primary"
          cancel="Cancel"
          CancelIcon={ CancelIcon }
          onConfirm={ handleConfirm }
          onClose={ handleClose }

        />
      }
    </>
  );
}

