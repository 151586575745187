import { useQuery } from '@tanstack/react-query';
import { apiUrl, httpClient } from './DataProvider';
import { Dayjs } from 'dayjs';
import _ from 'lodash';

export type SummaryOptions = {
  date?: Date | Dayjs | null,
  months?: number,
  days?: number,
  [ key: string ]: string | number | Date | Dayjs | null | undefined,
}
export function useSummary<T = unknown>( key: string, options: SummaryOptions = {} ) {
  const cacheKey = `summary-${ key }`;
  const {
    date,
    months,
    days,
    ...rest
  } = options;
  return useQuery<T>( {
    queryKey: [ cacheKey, options ],
    queryFn: async () => {
      const search = _.mapValues( rest, v => `${ v }` );
      if( date ) search.date = date.toISOString();
      if( days ) search.days = `${ days }`;
      if( months ) search.months = `${ months }`;
      const { body } = await httpClient( `${ apiUrl }/summaries/${ key }?${ new URLSearchParams( search ) }` );
      localStorage.setItem( cacheKey, body );
      return JSON.parse( body ) as T;
    },
    placeholderData: () => {
      const cache = localStorage.getItem( cacheKey );
      if( !cache ) return;
      return JSON.parse( cache );
    },
  } );
}

export default useSummary;
