import { alpha, Box, CircularProgress, MenuItem, Select as MuiSelect, SelectProps as MuiSelectProps, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { SelectField, SelectFieldProps, SelectInput, SelectInputProps, useGetList } from 'react-admin';
import { LangApi } from './langs';


export const LanguageBox: FC<{ lang: LangApi, i?: number }> = ( { lang, i } ) => {
  const theme = useTheme();
  return (
    <Box>
      {/* <Box pr={ 1 } component='span' >{ lang.flag }</Box> */ }
      <Box pr={ 1 } component='span' >
        { lang.svg
          ? <img src={ lang.svg } height='14' style={ { border: `1px solid ${ theme.palette.divider }` } } />
          : <Box sx={ { height: 14, width: 14 * 4 / 3, border: `1px solid ${ theme.palette.divider }`, display: 'inline-block' } } />
        }
      </Box>
      { lang.nativeName } { lang.name.toLowerCase() == lang.nativeName?.toLowerCase() ? '' : `/ ${ lang.name }` }
      <Box sx={ { flexGrow: 1 } } component='span' />
      <Typography pl={ 1 } component='span' sx={ { color: alpha( theme.palette.text.primary, 0.5 ), fontSize: '80%' } }>{ lang.id }</Typography>
      { i !== undefined &&
        <Typography pl={ 1 } component='span' sx={ { color: alpha( theme.palette.text.primary, 0.5 ), fontSize: '80%' } }>{ i }</Typography>
      }
    </Box>
  );
}

export const LanguageFlag: FC<{ lang: LangApi }> = ( { lang } ) => {
  const theme = useTheme();
  return (
    <Box pr={ 1 } component='span' >
      { lang.svg
        ? <img src={ lang.svg } height='14' style={ { border: `1px solid ${ theme.palette.divider }` } } />
        : <Box sx={ { height: 14, width: 14 * 4 / 3, border: `1px solid ${ theme.palette.divider }`, display: 'inline-block' } } />
      }
    </Box>
  );
}

export interface LanguageSelectProps {
  onChange?: MuiSelectProps<string>[ 'onChange' ];
  lang: string;
  // setLang: ( lang: string ) => void;
}

export const LanguageSelect: FC<LanguageSelectProps> = props => {
  const { onChange, lang } = props;
  const { data: apiLangs, isLoading: _isLangsLoading } = useGetList<LangApi>( 'languages', {
    pagination: { perPage: 200, page: 1 },
    sort: { field: 'name', order: 'ASC' },
    filter: { isSupported: true },
  } );

  const langs = useMemo( () => {
    if( !apiLangs ) return;
    const langs = [ ...apiLangs ];
    const i = langs.findIndex( l => l.id == 'en' );
    if( i < 0 ) return langs;
    const en = langs.splice( i, 1 );
    return [ ...en, ...langs ];
  }, [ apiLangs ] );

  const langOption = useCallback( ( l: LangApi, i?: number ) => (
    <MenuItem key={ l.id } value={ l.id } disabled={ l.disabled }>
      <LanguageBox lang={ l } i={ i } />
    </MenuItem >
  ), [] );

  if( !langs ) return null;

  return (
    <MuiSelect
      labelId="select-label"
      id="select"
      defaultValue={ lang || 'en' }
      value={ lang }
      // label="Lang"
      onChange={ ( e, c ) => onChange && onChange( e, c ) }
      size='small'
      sx={ {
        minWidth: '20rem',
      } }
      MenuProps={ {
        sx: {
          maxHeight: '80%',
        }
      } }
    >
      { ( langs || [] ).map( ( l, i ) => langOption( l, i + 1 ) ) }
    </MuiSelect>
  );
}

export interface LanguagesSelectProps {
  onChange?: MuiSelectProps<string[]>[ 'onChange' ];
  langs: string[];
  // setLang: ( lang: string ) => void;
}

export const LanguagesSelect: FC<LanguagesSelectProps> = props => {
  const { onChange, langs } = props;
  const { data: apiLangs, isLoading: _isLangsLoading } = useGetList<LangApi>( 'languages', {
    pagination: { perPage: 200, page: 1 },
    sort: { field: 'name', order: 'ASC' },
    filter: { isSupported: true },
  } );

  const languages = useMemo( () => {
    if( !apiLangs ) return;
    const langs = [ ...apiLangs ];
    const i = langs.findIndex( l => l.id == 'en' );
    if( i < 0 ) return langs;
    const en = langs.splice( i, 1 );
    return [ ...en, ...langs ];
  }, [ apiLangs ] );

  const langOption = useCallback( ( l: LangApi, i?: number ) => (
    <MenuItem key={ l.id } value={ l.id } disabled={ l.disabled }>
      <LanguageBox lang={ l } i={ i } />
    </MenuItem >
  ), [] );

  const renderValue = useCallback( ( value: string[] ) => {
    return (
      <Box sx={ { maxWidth: '15em', overflowX: 'scroll' } } >
        { ( value || [] ).map( l => {
          const lang = ( languages || [] ).find( lang => lang.id == l );
          if( !lang ) return null;
          return <LanguageFlag key={ lang.id } lang={ lang } />;
        } )
        }
      </Box>
    )

  }, [ languages ] );

  return (
    <MuiSelect
      labelId="select-label"
      id="select"
      multiple
      defaultValue={ langs || [ 'en' ] }
      value={ langs }
      // label="Lang"
      onChange={ ( e, c ) => onChange && onChange( e, c ) }
      size='small'
      sx={ {
        minWidth: '20rem',
      } }
      MenuProps={ {
        sx: {
          maxHeight: '80%',
        }
      } }
      renderValue={ renderValue }
    >
      { ( languages || [] ).map( ( l, i ) => langOption( l, i + 1 ) ) }
    </MuiSelect>
  );
}

export interface LanguageInputProps extends Omit<SelectInputProps, 'choices'> {
  choices?: SelectInputProps[ 'choices' ]; // ignored
  x?: unknown;
}

export const LanguageInput: FC<LanguageInputProps> = props => {
  const { choices: _ignored, ...rest } = props;
  const { data: apiLangs, isLoading: _isLangsLoading } = useGetList<LangApi>( 'languages', {
    pagination: { perPage: 200, page: 1 },
    sort: { field: 'name', order: 'ASC' },
    filter: { isSupported: true },
  } );

  const choices = useMemo( () => {
    if( !apiLangs ) return;
    const langs = [ ...apiLangs ];
    const i = langs.findIndex( l => l.id == 'en' );
    const en = langs.splice( i, 1 );
    if( i >= 0 ) {
      langs.unshift( ...en )
    }

    return langs.map( l => ( {
      id: l.id,
      // name: `${ l.flag } ${ l.name }`, // <LanguageBox lang={ l } />
      // name: <LanguageBox lang={ l } />
      name: l.name,
      lang: l,
    } ) );
  }, [ apiLangs ] );

  if( !choices ) return null;

  return (
    <SelectInput
      choices={ choices }
      optionText={ c => <LanguageBox lang={ c.lang } /> }
      { ...rest }
      sx={ {
        minWidth: '20rem',
      } }
    />
  );
}

export interface LanguageFieldProps extends Omit<SelectFieldProps, 'choices'> {
  x?: unknown;
}

export const LanguageField: FC<LanguageFieldProps> = props => {
  const { data: apiLangs, isLoading: isLangsLoading } = useGetList<LangApi>( 'languages', {
    pagination: { perPage: 200, page: 1 },
    sort: { field: 'name', order: 'ASC' },
    filter: { isSupported: true },
  } );

  const choices = useMemo( () => {
    if( !apiLangs ) return;
    const langs = [ ...apiLangs ];
    const i = langs.findIndex( l => l.id == 'en' );
    const en = langs.splice( i, 1 );
    if( i >= 0 ) {
      langs.unshift( ...en )
    }
    return langs.map( l => ( {
      id: l.id,
      name: <LanguageBox lang={ l } />
    } ) );
  }, [ apiLangs ] );

  if( isLangsLoading ) return <CircularProgress size='small' />

  return (
    <SelectField
      choices={ choices }
      { ...props }
      sx={ {
        minWidth: '20rem',
      } }
    />
  );
}


