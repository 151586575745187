/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { AddLink as AddLinkIcon, Check as CheckIcon, Delete as DeleteIcon, Link as LinkIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button as MuiButton, Dialog, DialogActions, DialogContent, DialogTitle, Link, Stack, Tooltip, Typography } from '@mui/material';
import { CreateInDialogButton, useFormDialogContext } from '@react-admin/ra-form-layout';
import { IfCanAccess, ListActions } from '@react-admin/ra-rbac';
import { useQueryClient } from '@tanstack/react-query';
import _, { debounce } from 'lodash';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AutocompleteInput, BooleanInput, Button, CheckboxGroupInput, ChipField, Create, CreateActions, CreateProps, Datagrid, DataProviderContext, Edit, EditActions, EditButton, EditProps, EmailField, Filter, FilterProps, Form, FormProps, Identifier, ImageField, List, ListProps, NumberField, RaRecord, ReferenceArrayField, ReferenceArrayInput, ReferenceField, ReferenceInput, ReferenceOneField, regex, SaveButton, SearchInput, SelectArrayInput, SelectField, SelectInput, Show, ShowActionsProps, ShowProps, SimpleFormProps, SingleFieldList, Tab, TextField, TextInput, TextInputProps, TitleProps, Toolbar, TopToolbar, UrlField, useCreate, useGetList, useGetOne, useNotify, useRecordContext, useResourceDefinition, useUpdate, Validator, WithRecord } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { LocationShowAssets, LocationShowProfileAssets } from './AssetShow';
import { AwesomePhoneField, AwesomePhoneInput } from './AwesomePhoneInput';
import { BooleanColorField } from './BooleanColorField';
import { apiUrl, choices, choicesYesNo, httpClient } from './DataProvider';
import { SimpleFormWithLocation, TabbedShowLayoutWithLocation, useListAppLocation } from './MyBreadcrumb';
import MyUrlField from './MyUrlField';
import { BrandingButton, useHasNoEmr, useShowScheduleFields } from './Organizations';
import { CustomToolbar } from './ReminderTemplateLists';
import { ShowActionsOnShowTabOnly } from './ShowActionsOnShowTabOnly';
import { Childless } from './types';
import { validateName } from './validate';
import { RetailHoursField, RetailHoursInput } from './RetailHours';
import { WeekdaysHoursInput } from './WeekdaysHours';

export { default as LocationIcon } from '@mui/icons-material/Business';

const LocationTitle: FC<TitleProps> = props => {
  return <span>Location { props.record?.name ? `"${ props.record.name }"` : '' }</span>;
};

const LocationFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <SearchInput source="q" alwaysOn />
    <SelectInput source="status" choices={ choices.locationStatus } />
    <TextInput label="ID (24 char hex)" source="id" />
    <TextInput label="FHIR ID" source="fhirId" />
  </Filter>
);

export const AmenityButton: FC = () => {
  const record = useRecordContext();
  // @ts-ignore: 2339
  const { amenityUrl } = record || {};
  if( !amenityUrl ) return null;
  return (
    <MuiButton target="_blank"
      rel="noopener"
      onClick={ e => { e.stopPropagation(); } }
      href={ amenityUrl }
      size='small'
    >
      Preview
    </MuiButton>
  );
}

export const ReviewSiteChipField: FC = () => {
  const record = useRecordContext<RaRecord & { platform: string, externalId?: string }>();
  const oauthSupportedPlatforms = [ 'google' ];

  const isOauthSupported = useMemo( () => record && oauthSupportedPlatforms.includes( record.platform ), [ record?.platform ] );
  const isOauthConnected = useMemo( () => record && isOauthSupported && !!record.externalId, [ isOauthSupported, record?.externalId ] )

  const [ googleAuthUrl, setGoogleAuthUrl ] = useState( '' );
  const dataProvider = useContext( DataProviderContext );
  useEffect( () => {
    ( async () => {
      if( !dataProvider ) return;
      const query = new URLSearchParams( {
        redirect_uri: window.location.href,
      } );
      const response = await dataProvider.fetchJson( `${ dataProvider.apiUrl }/reputationoauth/google/init?${ query }` );
      setGoogleAuthUrl( response.json.url );
    } )();
  }, [ dataProvider ] );

  if( isOauthSupported && !isOauthConnected ) {
    return (
      <Link href={ googleAuthUrl } onClick={ e => e.stopPropagation() }>
        <ReferenceField source='platform' reference='reputationplatforms' link={ false }>
          <ChipField
            source='name'
            icon={ <Tooltip title='Link Account'><LinkIcon /></Tooltip> }
            color={ 'warning' }
          />
        </ReferenceField>
      </Link>
    )
  }
  return (
    <ReferenceField source='platform' reference='reputationplatforms' link={ false }>
      <ChipField
        source='name'
        icon={ isOauthConnected ? <Tooltip title='Verified'><CheckIcon /></Tooltip> : undefined }
        color={ isOauthConnected ? 'secondary' : isOauthSupported ? 'warning' : 'default' }
      />
    </ReferenceField>
  );
}

export const LocationList: FC<Omit<ListProps, 'children'>> = props => {
  useListAppLocation( 'organization' );
  const hasNoEmr = useHasNoEmr();
  const filterDefaultValues = { status: 'active' };
  // const filter = { status: 'active' };

  const fetchNewEmr = useCallback( debounce( () => {
    httpClient( `${ apiUrl }/locations/actions/fetchNewEmr`, { method: 'POST' } );
  }, 10000, { leading: true, trailing: true } ), [] )

  useEffect( () => {
    fetchNewEmr();
  }, [ fetchNewEmr ] );

  const navigate = useNavigate();

  return (
    <List { ...props }
      exporter={ false }
      // filter={ filter }
      filters={ <LocationFilter /> }
      filterDefaultValues={ filterDefaultValues }
      perPage={ 25 }
      sort={ { field: 'name', order: 'ASC' } }
      actions={ <ListActions hasCreate={ hasNoEmr } /> }
    >
      <Datagrid rowClick="show" bulkActionButtons={ false } >
        <TextField source="name" />
        <TextField source="humanAddress" label="Address" />
        <ReferenceField label="Amenity organization" source="organization" reference="organizations" >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="nickname" />
        <TextField label="SEO tag" source="slug" />
        <BooleanColorField source='locationEnabledForMessageSend' label='Messaging Enabled' />
        <BooleanColorField source='locationEnabledForReviewGating' label='Review Gating' />
        <SelectField source="status" choices={ choices.locationStatus } />
        <ReferenceOneField label="Review Sites" source="id" target='reference' reference="reputationsubjects" filter={ { kind: 'location' } } link={ false }>
          <ReferenceArrayField source='profiles' reference='reputationprofiles'>
            <SingleFieldList linkType={ false }>
              <ReviewSiteChipField />
            </SingleFieldList>
          </ReferenceArrayField>
        </ReferenceOneField>
        <WithRecord render={ record => (
          <Button
            label='Review Sites'
            size='small'
            onClick={ e => { navigate( `/locations/${ record.id }/show/review-sites` ); e.stopPropagation() } }
          />
        ) } />
        <AmenityButton />
        <BrandingButton enableSource='amenityUrl' />
        <EditButton />
      </Datagrid>
    </List >
  );
}

export const ProfileConnectLink: FC = () => {
  const record = useRecordContext<RaRecord>();
  const [ googleAuthUrl, setGoogleAuthUrl ] = useState( '' );
  const dataProvider = useContext( DataProviderContext );

  useEffect( () => {
    ( async () => {
      if( !dataProvider ) return;
      const query = new URLSearchParams( {
        redirect_uri: window.location.href,
      } );
      const response = await dataProvider.fetchJson( `${ dataProvider.apiUrl }/reputationoauth/google/init?${ query }` );
      setGoogleAuthUrl( response.json.url );
    } )();
  }, [ dataProvider ] );

  if( !record || record.platform != 'google' ) return <></>;
  if( record.externalId ) {
    return (
      <MuiButton
        variant='text'
        size='small'
        sx={ {
          '&:hover': {
            backgroundColor: 'transparent',
            cursor: 'default',
          }
        } }
        startIcon={ <CheckIcon /> }
      >
        Verified
      </MuiButton>
    );
  }
  return (
    <Tooltip title='Login with your Google account to verify your ownership of this location.'>
      <MuiButton
        variant='text'
        size='small'
        startIcon={ <AddLinkIcon /> }
      >
        <Link underline='none' href={ googleAuthUrl } color="inherit" >
          Connect
        </Link>
      </MuiButton>
    </Tooltip>
  );
}

export const LocationShowActions = ( props: ShowActionsProps ) => {
  const record = useRecordContext( props );
  const { hasEdit } = useResourceDefinition();
  if( !hasEdit ) {
    return null;
  }
  return (
    <TopToolbar className={ props.className }
      sx={ {
        '& .MuiButton-root': {
          marginLeft: '1rem',
        }
      } }
    >
      <AmenityButton />
      <EditButton record={ record } />
    </TopToolbar>
  );
};

export const LocationShow: FC<ShowProps> = props => {
  const showScheduleFields = useShowScheduleFields();

  return (
    <Show
      title={ <LocationTitle /> }
      actions={ <ShowActionsOnShowTabOnly actions={ <LocationShowActions /> } /> }
      { ...props }
      sx={ {
        '& .ra-field': {
          minHeight: '2.5rem',
        }
      } }
    >
      <TabbedShowLayoutWithLocation basePath='organization' >
        <Tab label="Location">
          <TextField source="name" />
          <TextField source="comment" />
          <TextField source="nickname" />
          <TextField label="SEO tag" source="slug" />

          <ReferenceField label="Amenity organization" source="organization" reference="organizations" >
            <TextField label="Amenity organization" source="name" />
          </ReferenceField>

          <TextField source="timeZoneName" />
          <SelectField source="status" choices={ choices.locationStatus } />
          <SelectField source='locationEnabledForMessageSend' label='Messaging' choices={ choicesYesNo } />
          <SelectField source='locationEnabledForReviewGating' label='Review Gating' choices={ choicesYesNo } />

          <ReferenceArrayField label='Appointment types patient may cancel' source='appointmentTypeCodesEnabledForCancel' reference='appointmenttypes' sort={ { field: 'name', order: 'ASC' } } />
          <ReferenceArrayField label='Appointment types patient may reschedule' source='appointmentTypeCodesEnabledForReschedule' reference='appointmenttypes' sort={ { field: 'name', order: 'ASC' } } />
          <ReferenceArrayField label='Appointment types patient may schedule' source='appointmentTypeCodesEnabledForSchedule' reference='appointmenttypes' sort={ { field: 'name', order: 'ASC' } } />

          <TextField source="humanAddress" />
          <AwesomePhoneField source="telephone" />
          <AwesomePhoneField source="fax" />
          <EmailField source="email" />
          <UrlField source="webUrl" />
          <UrlField source="logoUrl" />
          <UrlField source="amenityUrl" />
          <UrlField label="Short link" source="locationAmenitiesUrlLink" />
          <TextField source="locationSignature" />

          <NumberField source="lat" />
          <NumberField source="lng" />
          {/*
              <ArrayField source="telecomOptions">
              <Datagrid bulkActionButtons={ false } >
              <TextField source="use" />
              <TextField source="system" />
              <TextField source="value" />
              <TextField source="transmitMethod" />
              </Datagrid>
              </ArrayField>

              <ArrayField source="fhirAddress">
              <Datagrid bulkActionButtons={ false }>
              <TextField source="use" />
              <TextField source="type" />
              <TextField label="Line 1" source="line.0" />
              <TextField label="Line 2" source="line.1" />
              <TextField source="district" />
              <TextField source="city" />
              <TextField source="state" />
              <TextField source="postalCode" />
              <TextField source="country" />
              <TextField source="text" />
              </Datagrid>
              </ArrayField>
              <TextField source="fhirAddressId" />

              <ArrayField source="fhirTelecom">
              <Datagrid bulkActionButtons={ false } >
              <TextField source="use" />
              <TextField source="system" />
              <TextField source="value" />
              </Datagrid>
              </ArrayField>


              <TextField source="id" />
              <TextField source="fhirId" />
            */}
          <SelectField source="lastUpdateMethod" choices={ choices.locationUpdateMethod } />

          { showScheduleFields &&
            <RetailHoursField source='weekdaysHours' />
          }

        </Tab>
        { /*
             <Tab path="branding" label="Branding Collateral" >
             <ResourceShowAssets { ...props } choices={ assetChoices } />
             </Tab>
           */}

        < Tab path="assets" label='Branding Collateral' >
          <LocationShowProfileAssets />
        </Tab >
        <Tab path="amenities" label="Amenities" >
          <LocationShowAssets />
        </Tab>

        <Tab path='review-sites' label='Review Sites'>
          <TextField source="name" />

          <ReferenceOneField label="Review Sites" source="id" target='reference' reference="reputationsubjects" filter={ { kind: 'location' } } >
            <WithRecord render={ ( { id: subjectId, name: subjectName } ) => (
              <ReferenceArrayField source="profiles" reference='reputationprofiles' >
                <Datagrid bulkActionButtons={ false }>
                  <ReferenceField source='platform' reference='reputationplatforms' >
                    <TextField source='name' />
                  </ReferenceField>
                  <ReferenceField label='Logo' source='platform' reference='reputationplatforms' >
                    <WithRecord render={ ( record ) => (
                      <ImageField source='src' record={ { src: `/${ record.id }.png` } } sx={ {
                        '& .RaImageField-image': {
                          width: '100px'
                        }
                      } } />
                    ) } />
                  </ReferenceField>
                  <UrlField source='url'
                    style={ {
                      textDecoration: 'none',
                    } }
                    rel="noopener"
                    target="_blank"
                    onClick={ e => { e.stopPropagation(); } }

                  />
                  <ProfileConnectLink />
                  <IfCanAccess action='manage' resource='system'>
                    <MyUrlField
                      source='url'
                      record={ {
                        url: `https://reputation.analoginformation.com/#/subjects?displayedFilters=%7B%22name%22%3Atrue%7D&filter=%7B%22name%22%3A%22${ encodeURIComponent( subjectName ) }%22%7D&order=ASC&page=1&perPage=10&sort=id`
                      } }
                      text='Rep Admin'
                    />

                  </IfCanAccess>
                  <RemoveReviewSitesButton subjectId={ subjectId } />
                </Datagrid>
              </ReferenceArrayField>
            ) } />
          </ReferenceOneField>

          <div>
            <WithRecord render={ ( record ) => (
              <CreateInDialogButton
                label="Add review site url"
                title={ `Add new review site url` }
                maxWidth='md'
                fullWidth
                record={ record }
              >
                <LocationReviewSiteUrlForm />
              </CreateInDialogButton>
            ) } />
          </div>
        </Tab>
      </TabbedShowLayoutWithLocation >
    </Show >
  );
}

export interface RemoveReviewSiteButtonProps {
  subjectId: Identifier;
}
export const RemoveReviewSitesButton: FC<RemoveReviewSiteButtonProps> = props => {
  const { subjectId } = props;
  // @ts-ignore: 2339
  const { id, url } = useRecordContext() || {};
  const { data: subject, isLoading: isLoadingSubject } = useGetOne<RaRecord & { profiles: Identifier[] }>( 'reputationsubjects', { id: subjectId } );
  const [ update, { isLoading: isUpdating } ] = useUpdate();
  const [ open, setOpen ] = useState( false );

  const handleClickOpen = () => setOpen( true );
  const handleClose = () => setOpen( false );
  const handleSubmit = useCallback( () => {
    if( !subject ) return;
    const diff = { profiles: _.without( subject.profiles, id ) };
    const previousData = { profiles: subject.profiles };
    const updateData = { id: subjectId, data: diff, previousData: previousData };
    update( 'reputationsubjects', updateData );
    handleClose();
  }, [ id, subject, update ] );
  if( isLoadingSubject || !subject ) return null;
  return (
    <>
      <MuiButton
        variant='text'
        size='small'
        onClick={ handleClickOpen }
        startIcon={ <DeleteIcon /> }
        color='error'
      > Remove </MuiButton>
      <Dialog open={ open || isUpdating } onClose={ handleClose }>
        <DialogTitle> Are you sure you want to remove this Review Site? </DialogTitle>
        <DialogContent><Typography>{ url }</Typography></DialogContent>
        <DialogActions>
          <MuiButton
            onClick={ handleClose }
            color='inherit'
            variant='contained'
            disableFocusRipple
          > Cancel </MuiButton>
          <LoadingButton
            loading={ isUpdating }
            onClick={ handleSubmit }
            color='error'
            variant='contained'
            disableFocusRipple
            disabled={ isUpdating }
          > Remove </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export const LocationReviewSiteUrlForm: FC<Childless<FormProps>> = () => {
  const { close } = useFormDialogContext() || {};
  const location = useRecordContext();
  const notify = useNotify();
  const reference = useMemo<string>( () => location?.id.toString() || '', [ location ] );
  const { data: subjects } = useGetList( 'reputationsubjects', { filter: { kind: 'location', reference } } );
  const { data: organizations } = useGetList<{ name: string } & RaRecord>( 'organizations' );
  const [ create ] = useCreate();
  const [ update ] = useUpdate();
  const queryClient = useQueryClient();

  const testProfile = useCallback( async ( url: string ): Promise<RaRecord | undefined> => {
    try {
      // TODO this api return 500 instead of 404 and needs fixing.
      const { body } = await httpClient( `${ apiUrl }/reputationprofiles/lookup?${ new URLSearchParams( { url: url.trim() } ) }` );
      return JSON.parse( body ) as RaRecord;
    } catch( e ) {
      return;
    }
  }, [ httpClient ] );

  return (
    ( <Form
      record={ { url: '' } }
      warnWhenUnsavedChanges
      validate={ async ( data ) => {
        if( !data.url.includes( 'https' ) ) return { url: 'Must be a valid url' };
        return {};
      } }
      // @ts-ignore: 2339
      onSubmit={ async ( data ) => {
        if( !location ) return;
        const profile = await testProfile( data.url )
        if( !profile ) return { url: 'Could not process' };
        const { id: profileId } = profile;
        const [ existingSubject ] = subjects || [];
        if( existingSubject ) {
          await update( 'reputationsubjects', {
            id: existingSubject.id,
            data: {
              ...existingSubject,
              profiles: _.uniq( ( existingSubject.profiles || [] ).concat( profileId ) ),
            },
            previousData: existingSubject
          }, { returnPromise: true } );
        } else {
          await create( 'reputationsubjects', {
            data: {
              kind: 'Location',
              name: location.name,
              reference: location.id,
              organization: organizations && organizations[ 0 ].name,
              profiles: [ profileId ],
            },
          }, { returnPromise: true } );
          await queryClient.invalidateQueries( {
            queryKey: [ 'reputationsubjects', 'getList' ]
          } );
          await queryClient.invalidateQueries( {
            queryKey: [ 'reputationsubjects', 'getManyReference' ]
          } );
        }

        notify( 'app.confirmation.add.reviewSiteUrl', { type: 'success' } ); // TODO i18n
        close && close();
      } }
    >
      <Stack
        sx={ {
          padding: '1rem',
        } }
      >
        <TextInput source="url" fullWidth />
      </Stack>
      <Toolbar>
        <SaveButton />
      </Toolbar>
    </Form> )
  );
}


export const useValidateSlugIsUnique = ( locations: RaRecord[] = [] ) => {
  return useCallback<Validator>( async ( value: string, values: RaRecord, props: TextInputProps ) => {
    return locations.find( o => o.id != values.id && o[ props.source ] == value ) ? 'Cannot be the same SEO Tag as another location' : undefined;
  }, [ locations ] );
}

export const locationTagChoices = [
  { id: '6102f0d53b3fd28628ca9362', name: 'Public Transit' },
  { id: '613914a32ab88d000b62d1cb', name: 'Ride Share' },
];

const LocationForm: FC<Omit<SimpleFormProps, 'children'>> = props => {
  const { data: locations } = useGetList( 'locations', { filter: { status: 'active' } } );
  const validateSlugIsUnique = useValidateSlugIsUnique( locations );
  const showScheduleFields = useShowScheduleFields();

  return (
    <SimpleFormWithLocation
      basePath='organization'
      sanitizeEmptyValues={ true }
      toolbar={ <CustomToolbar /> }
      { ...props }
      margin='none'
    >
      <TextInput source="name" fullWidth />
      <TextInput source="comment" fullWidth />
      <ReferenceInput source="organization" reference="organizations"
        filter={ { status: 'active' } }
      >
        <SelectInput label="Amenity organization" optionText="name" fullWidth />
      </ReferenceInput>
      <BooleanInput source='locationEnabledForMessageSend' label='Messaging' />
      <BooleanInput source='locationEnabledForReviewGating' label='Review Gating' />
      <ReferenceArrayInput source='appointmentTypeCodesEnabledForCancel' reference='appointmenttypes' sort={ { field: 'name', order: 'ASC' } } perPage={ 100 }>
        <SelectArrayInput label='Appointment types patient may cancel' source='name' fullWidth />
      </ReferenceArrayInput>
      <ReferenceArrayInput source='appointmentTypeCodesEnabledForReschedule' reference='appointmenttypes' sort={ { field: 'name', order: 'ASC' } } perPage={ 100 } >
        <SelectArrayInput label='Appointment types patient may reschedule' source='name' fullWidth />
      </ReferenceArrayInput>
      <ReferenceArrayInput source='appointmentTypeCodesEnabledForSchedule' reference='appointmenttypes' sort={ { field: 'name', order: 'ASC' } } perPage={ 100 } >
        <SelectArrayInput label='Appointment types patient may schedule' source='name' fullWidth />
      </ReferenceArrayInput>
      <AutocompleteInput source="timeZoneName" choices={ choices.timeZoneName } fullWidth />
      <TextInput source="nickname" />
      <TextInput
        label="SEO tag"
        source="slug"
        validate={ [ regex( /^[-a-z0-9]+$/ ), ...validateName, validateSlugIsUnique ] }
        fullWidth />
      <TextInput source="humanAddress" fullWidth />
      <AwesomePhoneInput source="telephone" />
      <AwesomePhoneInput source="fax" />
      <TextInput source="email" fullWidth />
      <TextInput source="webUrl" fullWidth />
      { /* <TextInput source="logoUrl" fullWidth /> */ }
      { /* <TextInput source="amenityUrl" fullWidth /> */ }
      <TextInput source="locationSignature" fullWidth />
      <CheckboxGroupInput source="tags" choices={ locationTagChoices } />
      { showScheduleFields && // <WeekdaysHoursInput source='weekdaysHours' />
        <RetailHoursInput />
      }

    </SimpleFormWithLocation>
  );
}

//breadcrumb={ <MyBreadcrumb variant="actions" /> }
export const LocationEdit: FC<EditProps> = props => (
  <Edit
    redirect='show'
    mutationMode='pessimistic'
    // actions={ <EditActions /> }
    { ...props }
  >
    <LocationForm />
  </Edit>
);
export const LocationCreate: FC<CreateProps> = props => (
  <Create
    redirect={ () => 'organization/locations' }
    actions={ <CreateActions /> }
    record={ {
      status: 'active',
    } }
    { ...props }
  >
    <LocationForm />
  </Create>
);

