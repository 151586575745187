import { Area } from 'react-easy-crop/types';

const createImage = ( url: string ): Promise<HTMLImageElement> =>
  new Promise( ( resolve, reject ) => {
    const image = new Image()
    image.addEventListener( 'load', () => resolve( image ) )
    image.addEventListener( 'error', ( error ) => reject( error ) )
    image.src = url
  } )

function getRadianAngle( degreeValue: number ) {
  return ( degreeValue * Math.PI ) / 180
}

const toAreaPixels = ( area: Area = { width: 1, height: 1, x: 0, y: 0 }, image: HTMLImageElement ): Area => ( {
  width: area.width * image.width / 100,
  height: area.height * image.height / 100,
  x: area.x * image.width / 100,
  y: area.y * image.height / 100,
} );


// normalizedArea is ratio of bounding box of rotated image
const toRotatedAreaPixels = ( image: HTMLImageElement, normalizedArea: Area = { width: 1, height: 1, x: 0, y: 0 }, centerRotationDegrees = 0 ): Area => {
  const rad = getRadianAngle( centerRotationDegrees );
  const sinAng = Math.sin( rad );
  const cosAng = Math.cos( rad );
  const bbox = {
    width: image.width * Math.abs( cosAng ) + image.height * Math.abs( sinAng ),
    height: image.width * Math.abs( sinAng ) + image.height * Math.abs( cosAng ),
  }
  console.log( 'bbox', bbox );

  return ( {
    width: normalizedArea.width * bbox.width / 100,
    height: normalizedArea.height * bbox.height / 100,
    x: normalizedArea.x * ( bbox.width ) / 100,
    y: normalizedArea.y * ( bbox.height ) / 100,
  } );
}

export async function getPixelCrop( imageSrc: string, cropArea?: Area ): Promise<Area> {
  const image = await createImage( imageSrc )
  return toAreaPixels( cropArea, image );
}

export async function getCroppedImage( imageSrc: string, cropArea?: Area, rotation = 0 ): Promise<string> {
  const image = await createImage( imageSrc )

  // 
  const rad = getRadianAngle( rotation );
  const sinAng = Math.sin( rad );
  const cosAng = Math.cos( rad );
  const bbox = {
    width: image.width * Math.abs( cosAng ) + image.height * Math.abs( sinAng ),
    height: image.width * Math.abs( sinAng ) + image.height * Math.abs( cosAng ),
  }
  //

  // const pixelCrop = toAreaPixels( cropArea, image );
  const pixelCrop = toRotatedAreaPixels( image, cropArea, rotation );
  console.log( 'pixelCrop', pixelCrop );
  const canvas = document.createElement( 'canvas' )
  const ctx = canvas.getContext( '2d' )
  if( !ctx ) throw ( new Error() ); // TODO

  const maxSize = Math.max( bbox.width, bbox.height )
  const safeArea = 2 * ( ( maxSize / 2 ) * Math.sqrt( 2 ) )

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea
  canvas.height = safeArea

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate( safeArea / 2, safeArea / 2 )
  ctx.rotate( getRadianAngle( rotation ) )
  ctx.translate( -safeArea / 2, -safeArea / 2 )

  ctx.fillStyle = 'white';
  ctx.fillRect( 0, 0, safeArea, safeArea );

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    ( safeArea - image.width ) / 2,
    ( safeArea - image.height ) / 2
  )
  const data = ctx.getImageData( 0, 0, safeArea, safeArea )

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round( 0 - safeArea / 2 + bbox.width / 2 - pixelCrop.x ),
    Math.round( 0 - safeArea / 2 + bbox.height / 2 - pixelCrop.y )
  )

  // // As Base64 string
  // return canvas.toDataURL( 'image/jpeg' );

  // As a blob
  return new Promise( ( resolve ) => {
    canvas.toBlob( ( file ) => {
      resolve( file ? URL.createObjectURL( file ) : '' )
    }, 'image/jpeg' )
  } )
}

// https://stackoverflow.com/questions/1186414/whats-the-algorithm-to-calculate-aspect-ratio
export type AspectRatioPair = [ number, number ];
export const aspectRatio = ( val: number, lim = 50 ): AspectRatioPair => {

  let lower: AspectRatioPair = [ 0, 1 ];
  let upper: AspectRatioPair = [ 1, 0 ];

  while( true ) {
    const mediant: AspectRatioPair = [ lower[ 0 ] + upper[ 0 ], lower[ 1 ] + upper[ 1 ] ];

    if( val * mediant[ 1 ] > mediant[ 0 ] ) {
      if( lim < mediant[ 1 ] ) {
        return upper;
      }
      lower = mediant;
    } else if( val * mediant[ 1 ] == mediant[ 0 ] ) {
      if( lim >= mediant[ 1 ] ) {
        return mediant;
      }
      if( lower[ 1 ] < upper[ 1 ] ) {
        return lower;
      }
      return upper;
    } else {
      if( lim < mediant[ 1 ] ) {
        return lower;
      }
      upper = mediant;
    }
  }
}
export const aspectRatioString = ( val: number ): string => aspectRatio( val ).join( ':' );

