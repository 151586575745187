import { MarkChatRead, MarkChatUnread } from '@mui/icons-material';
import { Link as MuiLink, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { AutocompleteInput, BooleanField, BulkUpdateButton, BulkUpdateButtonProps, Datagrid, Filter, FilterProps, List, ListProps, NullableBooleanInput, RaRecord, ReferenceField, ReferenceInput, TextField, useListContext, useRedirect, WithRecord } from 'react-admin';
import { useListAppLocation } from './MyBreadcrumb';
import { TimestampsField } from './TimestampsField';
import { Childless } from './types';

export { default as IncomingTextIcon } from '@mui/icons-material/MoveToInbox';
export { IncomingTextChat } from './IncomingTextChat';


export interface InboundText extends RaRecord {
  // message?: { From?: string };
  preview?: string;
  createdAt: string;
  senderName?: string;
  recipient: string;
  phone: string;
  isResolved?: boolean;
}

export interface OutboundMessage extends RaRecord {
  message: string;
  transmitMethod: string;
  templateName?: string;
  postedDate: string;
  replyingUser?: string;
  replyingUserName?: string;
}


const IncomingTextFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <NullableBooleanInput label='Read' source='isResolved' trueLabel='app.label.read' falseLabel='app.label.unread' nullLabel='app.label.all' />
    <ReferenceInput label="Practitioner" source="practitioner" reference="practitioners" filter={ { active: true } } sort={ { field: 'fullName', order: 'ASC' } } allowEmpty  >
      <AutocompleteInput optionText="fullName" sx={ { width: '16rem' } } />
    </ReferenceInput>
    <ReferenceInput label="Location" source="location" reference="locations" filter={ { status: 'active' } } sort={ { field: 'name', order: 'ASC' } } allowEmpty alwaysOn >
      <AutocompleteInput sx={ { width: '22rem' } } />
    </ReferenceInput>
    { /* <DateInput label="Received" source="createdAt" /> */ }
  </Filter>
);

export const IncomingTextList: FC<Childless<ListProps>> = props => {
  const redirect = useRedirect();
  useListAppLocation( 'messaging' );

  const onClickRecipient = ( recipient: RaRecord ) => () => {
    redirect( '/contacteditor', undefined, undefined, undefined, { recipient } )
  };

  return (
    <List { ...props }
      filter={ { processType: 'newText' } }
      filters={ <IncomingTextFilter /> }
      exporter={ false }
      sort={ { field: 'createdAt', order: 'DESC' } }
      resource='inboundtexts'
    >
      <Datagrid
        bulkActionButtons={ <IncomingTextBulkActionButtons /> }
        rowClick='show'
      >

        <BooleanField label='Read' source='isResolved' FalseIcon={ null } />

        <TimestampsField label="Received" source='createdAt' />

        <ReferenceField label='Recipient' source="recipient" reference="recipients" link={ false } sortable={ false } >
          <WithRecord render={ ( record ) =>
            <MuiLink onClick={ onClickRecipient( record ) } underline='none' >
              <TextField source="fullName" />
            </MuiLink>
          } />
        </ReferenceField>

        <WithRecord label='Preview' render={ ( record ) => (
          <Typography component='span'
            sx={ {
              fontSize: '1rem',
              fontWeight: !record.isResolved ? 'bold' : undefined,
            } }
          >
            { record.preview }
          </Typography>
        ) } />

        <ReferenceField label='Practitioner' source="practitioner" reference="practitioners" link={ false } sortable={ false } >
          <TextField source="fullName" />
        </ReferenceField>

        <ReferenceField label='Location' source="location" reference="locations" link={ false } sortable={ false } >
          <TextField source="name" />
        </ReferenceField>


      </Datagrid>
    </List>
  );
}

const MarkReadButton: FC<Pick<BulkUpdateButtonProps, 'disabled'>> = props => (
  <BulkUpdateButton label="Mark Read" data={ { isResolved: true } } icon={ <MarkChatRead /> } mutationMode='pessimistic' { ...props } />
);

const MarkUnreadButton: FC<Pick<BulkUpdateButtonProps, 'disabled'>> = props => (
  <BulkUpdateButton label="Mark Unread" data={ { isResolved: false } } icon={ <MarkChatUnread /> } mutationMode='pessimistic' { ...props } />
);

const IncomingTextBulkActionButtons: FC = () => {
  const { selectedIds, data } = useListContext<InboundText>();
  const selecteds = useMemo( () => ( data || [] ).filter( d => selectedIds.includes( d.id ) ), [ data, selectedIds ] );
  const isAnyRead = useMemo( () => !!selecteds.find( s => s.isResolved ), [ selecteds ] );
  const isAnyUnread = useMemo( () => !!selecteds.find( s => !s.isResolved ), [ selecteds ] );

  return (
    <>
      <MarkUnreadButton disabled={ !isAnyRead } />
      <MarkReadButton disabled={ !isAnyUnread } />
    </>
  );
}



// const IncomingTextChatTitle: FC<TitleProps> = props => {
//   return <span>Incoming SMS { props.record?.senderName ? `"${ props.record.senderName }"` : '' }</span>;
// };
//  
//  
// export const IncomingTextChat: FC<ShowProps> = props => {
//   const redirect = useRedirect();
//   const isXSmall = useMediaQuery( ( theme: Theme ) => theme.breakpoints.down( 'sm' ) );
//  
//   const onClickRecipient = ( recipient: RaRecord ) => () => {
//     redirect( '/contacteditor', undefined, undefined, undefined, { recipient } )
//   };
//  
//   const onClickPhone = ( inboundtext: InboundText ) => () => {
//     redirect( '/contacteditor', undefined, undefined, undefined, { search: inboundtext.phone.slice( 2 ) } )
//   };
//  
//   return (
//     <Show
//       { ...props }
//       title={ <IncomingTextChatTitle /> }
//       resource='inboundtexts'
//     >
//       <SimpleShowLayoutWithLocation basePath='messaging'>
//         <Grid container columnSpacing={ 8 }>
//           <Grid item>
//             <Labeled label='Patient sender'>
//               <ReferenceField label='Sender' source="recipient" reference="recipients" link={ false } sortable={ false } >
//                 <WithRecord render={ ( record ) => {
//                   const { fullName, birthDate } = record;
//                   return <MuiLink onClick={ onClickRecipient( record ) } underline='none' >
//                     <TextField source="fullName" record={ { fullName: `${ birthDate.slice( 0, 10 ) } ${ fullName }` } } />
//                   </MuiLink>
//                 } } />
//               </ReferenceField>
//             </Labeled>
//           </Grid>
//  
//           <Grid item>
//             <Labeled label='Phone'>
//               <WithRecord render={ ( record ) =>
//                 <MuiLink onClick={ onClickPhone( record ) } underline='none' >
//                   <AwesomePhoneField label='Phone' source="phone" />
//                 </MuiLink>
//               } />
//             </Labeled>
//           </Grid>
//  
//           { !isXSmall &&
//             <Grid item>
//               <Labeled label='Related recipients'>
//                 <ReferenceArrayField label='Related recipients' source="recipients" reference="recipients" sortable={ false } >
//                   <SingleFieldList linkType={ false } >
//                     <WithRecord render={ ( record ) =>
//                       <ChipField source="fullName" onClick={ onClickRecipient( record ) } clickable />
//                     } />
//                   </SingleFieldList>
//                 </ReferenceArrayField>
//               </Labeled>
//             </Grid>
//           }
//         </Grid>
//  
//         <IncomingTextChatThread />
//  
//       </SimpleShowLayoutWithLocation>
//  
//     </Show >
//   );
// }
//  
// export interface ChatReplyProps {
//   disabled?: boolean;
//   onReply?: () => void;
// }
//  
// export const ChatReply: FC<ChatReplyProps> = props => {
//   const { disabled, onReply } = props;
//   const record = useRecordContext<InboundText>();
//  
//   const inputRef = useRef<HTMLInputElement>();
//   const { recipient: recipientId, phone } = record;
//   const transmitMethod = 'sms';
//  
//   const postMessage = useCallback( async ( recipientId: string, phone: string, message: string ): Promise<string> => {
//     const { body: result } = await httpClient( `${ apiUrl }/recipients/${ recipientId }/messages`, {
//       method: 'post',
//       body: JSON.stringify( { message, transmitMethod, telecomValue: phone } ),
//     } );
//     return result;
//   }, [ httpClient ] );
//  
//   const handleSendClick = useCallback( ( recipientId: string, phone: string, callback?: () => void ) => async () => {
//     if( !phone || !inputRef?.current?.value ) return;
//     await postMessage( recipientId, phone, inputRef.current.value );
//     callback && callback();
//   }, [ postMessage, inputRef.current ] );
//  
//  
//   return (
//     <Box
//       sx={ {
//         // border: '1px solid blue',
//         // margin: '1rem',
//         // width: '100%', //  { sm: '100%', md: '28rem' },
//         width: { sm: '100%', md: '40rem' },
//         paddingBottom: '1rem',
//         marginLeft: { xs: '0rem', md: '3rem' },
//       } }
//     >
//       <MuiTextField
//         id='reply'
//         inputRef={ inputRef }
//         label='Reply'
//         variant='outlined'
//         multiline
//         
//         disabled={ disabled }
//         rows={ 4 }
//       />
//       <Grid container justifyContent='flex-end'
//         sx={ {
//           // border: '1px solid green',
//         } }
//  
//       >
//         <Grid item
//         >
//           <MuiButton
//             size='small'
//             endIcon={ <SendIcon /> }
//             variant='contained'
//             // disabled={ disabled || ( inputRef?.current?.value?.length ?? 0 ) < 2 }
//             disabled={ disabled }
//             disableFocusRipple
//             sx={ {
//               // alignSelf: 'right',
//               // float: 'right',
//               marginTop: '0.5rem',
//             } }
//             onClick={ handleSendClick( recipientId, phone, () => {
//               if( inputRef?.current ) {
//                 inputRef.current.value = '';
//               }
//               onReply && onReply();
//             }
//             ) }
//           >
//             Send
//           </MuiButton>
//         </Grid>
//       </Grid>
//     </Box>
//  
//   );
// }
//  
// /////////////////////////////
// // Inbound Text Bubble
// /////////////////////////////
// const InboundTextBubble: FC<{ record: InboundText, xRef?: RefObject<HTMLDivElement> }> = ( { record, xRef } ) => {
//   const { message: raw1, preview: raw2 = '', senderName, date } = record;
//   const theme = useTheme();
//   const message = typeof raw1 == 'string' ? raw1 : raw2;
//   const messageLines = message.split( '\n' );
//   const dateAgo = cleanFormatDistanceToNow( date );
//  
//   return (
//     <Box
//       key={ record.id }
//       ref={ xRef }
//       sx={ {
//         minWidth: '10rem',
//         maxWidth: '40rem',
//         marginBottom: '1.5rem',
//         marginRight: { xs: '2rem', sm: '3rem' },
//         position: 'relative',
//       } }
//     >
//       <Typography
//         sx={ {
//           color: lighten( theme.palette.secondary.main, 0.3 ),
//           fontSize: '90%',
//           marginLeft: '1rem',
//         } }
//       >
//         { senderName }
//       </Typography>
//       <Box
//         sx={ {
//           backgroundColor: theme.palette.secondary.main,
//           color: theme.palette.getContrastText( theme.palette.secondary.main ),
//           padding: '1rem',
//           borderRadius: '1rem',
//         } }
//       >
//         { messageLines.map( ( m, idx ) => (
//           m ? <Typography key={ idx } >{ m } </Typography> : <Typography key={ idx } >&nbsp;</Typography>
//         ) ) }
//       </Box>
//       <Typography
//         align='right'
//         sx={ {
//           color: lighten( theme.palette.secondary.main, 0.3 ),
//           fontSize: '90%',
//           marginRight: '1rem',
//         } }
//       >
//         { dateAgo }
//       </Typography>
//     </Box>
//   );
// }
//  
// /////////////////////////////
// // Outgoing Message Bubble
// /////////////////////////////
// export const OutgoingMessageBubble: FC<{ record: OutboundMessage, xRef?: RefObject<HTMLDivElement> }> = ( { record, xRef } ) => {
//   const { id, message, postedDate: date, templateName, replyingUserName } = record;
//   const theme = useTheme();
//   const messageLines = message.split( '\n' );
//   const dateAgo = cleanFormatDistanceToNow( date );
//   const gray = theme.palette.mode == 'dark' ? theme.palette.grey[ 800 ] : theme.palette.grey[ 100 ];
//  
//   return (
//     <Box
//       key={ id }
//       ref={ xRef }
//       sx={ {
//         minWidth: '10rem',
//         maxWidth: '40rem',
//         marginBottom: '1.5rem',
//         marginLeft: { xs: '2rem', sm: '3rem' },
//         position: 'relative',
//       } }
//     >
//       <Typography
//         key='top'
//         sx={ {
//           color: theme.palette.grey[ 500 ],
//           fontSize: '80%',
//           marginLeft: '1rem',
//         } }
//       >
//         { templateName || replyingUserName }
//       </Typography>
//  
//       <ScrollFader id='message' color={ gray } >
//         <Box
//           sx={ {
//             backgroundColor: gray,
//             padding: '1rem 2rem 1.5rem',
//             borderRadius: '1rem',
//             maxHeight: '10.5rem',
//             overflowY: 'scroll',
//  
//           } }
//         >
//           { messageLines.map( ( m, idx ) => (
//             m
//               ? <Typography key={ idx } >{ m }</Typography>
//               : <Typography key={ idx } >&nbsp;</Typography>
//           ) ) }
//         </Box>
//       </ScrollFader>
//  
//       <Typography
//         key='bottom'
//         align='right'
//         sx={ {
//           fontSize: '90%',
//           marginRight: '1rem',
//           color: theme.palette.grey[ 500 ],
//         } }
//       >
//         { dateAgo }
//       </Typography>
//  
//     </Box >
//   );
// }
//  
//  
// export const IncomingTextChatThread: FC<Childless<ShowProps>> = () => {
//   const record = useRecordContext<InboundText>();
//   const queryClient = useQueryClient();
//  
//   const { id, phone, isResolved, transmitMethod } = record;
//   const phoneNumber = phone.slice( 2 ); // groan
//  
//   const [ update ] = useUpdate<InboundText>();
//   const { data: outboundMessages, isLoading: isLoading1, refetch: refetch1 } = useGetList( 'outboundmessages', {
//     filter: { phoneNumber, transmitMethod },
//     sort: { field: 'postedDate', order: 'DESC' }
//   } );
//   const { data: inboundTexts, isLoading: isLoading2, refetch: refetch2 } = useGetList( 'inboundtexts', {
//     filter: { phone },
//     sort: { field: 'createdAt', order: 'DESC' }
//   } );
//   const data = useMemo( () => {
//     return reverse( sortBy( [
//       ...( outboundMessages || [] ).map( m => ( { ...m, date: m.postedDate, resource: 'outboundmessages' } ) ),
//       ...( inboundTexts || [] ).map( m => ( { ...m, date: m.createdAt, resource: 'inboundtexts' } ) ),
//     ], m => m.date ) );
//   }, [ inboundTexts, outboundMessages ] );
//   const refetch = useCallback( () => { refetch1(); refetch2() }, [ refetch1, refetch2 ] );
//   const isLoading = useMemo( () => isLoading1 || isLoading2, [ isLoading1, isLoading2 ] );
//  
//   useEffect( () => {
//     if( !record || isResolved || isLoading ) return;
//     ( async () => {
//       await update( 'inboundtexts', { id, data: { ...record, isResolved: true }, previousData: record }, {
//         // returnPromise: true,
//         mutationMode: 'optimistic',
//       } );
//       await queryClient.invalidateQueries( [ 'inboundtexts', 'getList' ] );
//     } )();
//   }, [ record, isResolved, isLoading ] );
//  
//  
//   const LoadingIndicator: FC = () => <Box sx={ { width: '32rem', textAlign: 'center', marginBottom: '10rem', padding: '2rem' } }><CircularProgress /></Box>;
//  
//  
//   return (
//     <Paper
//       elevation={ 1 } // { isXSmall ? 0 : 4 }
//       sx={ {
//         // maxWidth: '33rem',
//         // padding: '1rem 0',
//         margin: '2rem 0',
//       } }
//     >
//  
//       <ChatReply
//         disabled={ isLoading }
//         onReply={ refetch }
//       />
//  
//       <Divider
//         sx={ {
//           margin: '1rem 0',
//         } }
//       />
//  
//       { isLoading &&
//         <LoadingIndicator />
//       }
//  
//       {
//         !isLoading &&
//         <Box
//           key='bubbles'
//           sx={ {
//             padding: '1rem 1rem',
//           } }
//         >
//           { ( data || [] ).map( ( record ) => {
//             const { resource, id } = record;
//  
//             return (
//               <Box key={ id } >
//                 { resource == 'inboundtexts'
//                   ? <InboundTextBubble record={ record } />
//                   : <OutgoingMessageBubble record={ record } />
//                 }
//               </Box>
//             );
//           } ) }
//         </Box>
//       }
//  
//  
//     </Paper >
//  
//   );
// }

