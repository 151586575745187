import { CancelOutlined as CancelIcon } from '@mui/icons-material';
import { TableCellProps } from '@mui/material';
import { ComponentElement, MouseEventHandler, useState } from 'react';
import { Confirm, DatagridCellProps, IconButtonWithTooltip, IconButtonWithTooltipProps, RaRecord } from 'react-admin';

export type CopyWithPartial<T, K extends keyof T> = Omit<T, K> & Partial<T>;
export type ResourceActionHandler<T extends RaRecord = RaRecord> = ( resource?: string, record?: CopyWithPartial<T, 'id'> ) => void;

export interface DatagridRowButtonProps<T extends RaRecord = RaRecord> extends Omit<DatagridCellProps, 'field' | keyof TableCellProps>,
  Omit<IconButtonWithTooltipProps, 'onClick'> {
  resource: string;
  record: T;
  onClick?: ResourceActionHandler<T>;
  confirm?: boolean;
  confirmContent?: string | ( ( record?: T ) => string | ComponentElement<unknown, any> );  // eslint-disable-line @typescript-eslint/no-explicit-any
  confirmTitle?: string | ( ( record?: T ) => string );
  source?: string; // used to set class when inside Datagrid
  hide?: boolean | ( ( record?: T ) => boolean );
}


export function DatagridRowButton<T extends RaRecord = RaRecord>( props: DatagridRowButtonProps<T> ) {
  const [ open, setOpen ] = useState( false );
  const {
    resource,
    record,
    onClick,
    confirm = false,
    confirmContent: content = 'Are you sure you want to delete this item?',
    confirmTitle: title = 'Delete Item',
    className = '',
    hide,
    ...rest
  } = props;

  const handleClick: MouseEventHandler = () => {
    if( !confirm ) {
      onClick && onClick( resource, record );
      return;
    }
    setOpen( true );
  }

  const handleConfirm: MouseEventHandler = () => {
    setOpen( false );
    onClick && onClick( resource, record );
  }

  const handleClose: MouseEventHandler = () => {
    setOpen( false );
  }

  if( record && typeof hide == 'function' ? hide( record ) : hide ) return null;

  return (
    <>
      <IconButtonWithTooltip
        { ...rest }
        className={ `rowButton ${ className }` }
        onClick={ handleClick }
      />
      { confirm &&
        <Confirm
          isOpen={ open }
          title={ typeof title == 'function' ? title( record ) : title }
          content={ typeof content == 'function' ? content( record ) : content }
          confirm="Yes"
          confirmColor="primary"
          cancel="Cancel"
          CancelIcon={ CancelIcon }
          onConfirm={ handleConfirm }
          onClose={ handleClose }

        />
      }
    </>
  );
}
