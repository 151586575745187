
import { MenuBook as DocIcon } from '@mui/icons-material';
import { IconButton, Link, Tooltip } from '@mui/material';
import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { range } from 'lodash';

const docLinkMap: Record<string, string> = {
  '/': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/dashboard-data',

  '/anomalies': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/reports',
  '/anomalies/anomalyreports': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/reports',
  '/anomalies/anomalyreportdefinitions': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/definitions',
  '/anomalies/anomalyreportemails': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/emails',
  '/anomalies/dashboardalerts': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/emr-setup-issues',

  '/anomalyreports': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/reports',
  '/anomalyreportdefinitions': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/definitions',
  '/anomalyreportemails': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/emails',
  '/dashboardalerts': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/emr-setup-issues',

  '/appointment': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/appointments',
  '/appointment/appointments': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/appointments',
  '/appointment/appointmenttypes': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/types',

  '/appointments': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/appointments',
  '/appointmenttypes': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/types',

  '/broadcasts': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/patient-broadcasts',
  '/broadcasts/outboundalerts': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/patient-broadcasts',
  '/broadcasts/alerttemplates': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/broadcast-templates',

  '/outboundalerts': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/patient-broadcasts',
  '/alerttemplates': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/broadcast-templates',

  '/contacteditor': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/patient-practitioner-contact-editor',

  '/messaging': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/sent-messages',
  '/messaging/outboundmessages': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/sent-messages',
  '/messaging/reminders': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/scheduled-messages',

  '/outboundmessages': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/sent-messages',
  '/reminders': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/scheduled-messages',


  '/organization': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/practitioners',
  '/organization/practitioners': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/practitioners',
  '/organization/locations': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/locations',
  '/organization/organizations': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/organizations',

  '/organizations': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/organizations',
  '/locations': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/locations',
  '/practitioners': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/practitioners',

  '/reviews': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/online',
  '/reviews/reputationreviews': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/online',
  '/reviews/reputationfeedbacks': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/internal',
  '/reviews/reputationviews': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/views',
  '/reviews/reputationclicks': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/clicks',
  '/reviews/reputationresponsetemplates': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/templates',

  '/reputationreviews': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/reviews',
  '/reputationfeedbacks': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/feedback',
  '/reputationviews': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/views',
  '/reputationclicks': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/clicks',

  '/reputation': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/charts',
  '/reputation/reputationvisualizations': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/charts',
  '/reputation/reputationreportschedules': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/email',
  '/reputation/reputationexplorer': 'https://docs.analoginfo.com/books/analog-informatics-documentation/chapter/reputation',

  '/reputationvisualizations': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/charts',
  '/reputationreportschedules': 'https://docs.analoginfo.com/books/analog-informatics-documentation/page/email',

  '/admin': 'https://docs.analoginfo.com/books/aic-admin/chapter/admin-interface',

  '/admin/system': 'https://docs.analoginfo.com/books/aic-admin/page/system-tests',
  '/messagetemplates': 'https://docs.analoginfo.com/books/aic-admin/page/message-templates',
  '/users': 'https://docs.analoginfo.com/books/aic-admin/page/users',
  '/evaluators': 'https://docs.analoginfo.com/books/aic-admin/page/evaluators',
  '/recipients': 'https://docs.analoginfo.com/books/aic-admin/page/recipients',
  '/actionlogs': 'https://docs.analoginfo.com/books/aic-admin/page/action-logs',
  '/inboundemails': 'https://docs.analoginfo.com/books/aic-admin/chapter/admin-interface',

  '/htmlcomponents': 'https://docs.analoginfo.com/books/aic-admin/page/email-components',
  '/htmlemailtitles': 'https://docs.analoginfo.com/books/aic-admin/page/email-titles',
  '/htmlassemblies': 'https://docs.analoginfo.com/books/aic-admin/page/email-assemblies',
  '/configs': 'https://docs.analoginfo.com/books/aic-admin/page/configs',
  '/snippets': 'https://docs.analoginfo.com/books/aic-admin/page/snippets',
  '/twimls': 'https://docs.analoginfo.com/books/aic-admin/page/twimls',
  '/appointmenttypepackages': 'https://docs.analoginfo.com/books/aic-admin/page/appointment-types',
  '/calendarpackages': 'https://docs.analoginfo.com/books/aic-admin/page/calendars',
  '/appointmentupdatetexts': 'https://docs.analoginfo.com/books/aic-admin/page/update-text',
  '/remindertemplatelists': 'https://docs.analoginfo.com/books/aic-admin/page/reminder-templates',
}


export const DocButton: FC = () => {
  const location = useLocation();
  const { pathname } = location;
  const pathCount = pathname.slice( 1 ).split( '/' ).length;
  const pathnames = useMemo( () => {
    if( !pathname ) return [];
    return range( pathCount ).map( i => '/' + pathname.slice( 1 ).split( '/' ).slice( 0, pathCount - i ).join( '/' ) )
  }, [ pathname ] );

  const href = pathnames.reduce( ( href, p ) => href || docLinkMap[ p ], '' )
    || 'https://docs.analoginfo.com/books/analog-informatics-documentation';

  return (
    <Tooltip title='Documentation'>
      <IconButton aria-label='Documentation'
        color='inherit'
        sx={ { '& .MuiLink-root': { fontSize: '6px' } } }
      >
        <Link underline='none' href={ href } target="_blank" rel="noopener" color="inherit" >
          <DocIcon />
        </Link>
      </IconButton>
    </Tooltip>
  );
}
