import { Box, Link as MuiLink } from '@mui/material';
import { FC } from 'react';
import { Datagrid, DateInput, Filter, FilterProps, FunctionField, List, ListProps, RaRecord, ReferenceField, SimpleShowLayout, TextField, useRedirect, WithRecord } from 'react-admin';
import { TimestampsField } from './TimestampsField';
import { Childless } from './types';

export { default as IncomingReplyIcon } from '@mui/icons-material/MoveToInbox';
const IncomingReplyFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <DateInput label="Received" source="createdAt" />
  </Filter>
);

export const IncomingReplyList: FC<Childless<ListProps>> = props => {
  const redirect = useRedirect();

  const onClickRecipient = ( recipient: RaRecord ) => () => {
    redirect( '/contacteditor', undefined, undefined, undefined, { recipient } )
  };

  return (
    <List { ...props }
      filter={ { processType: 'messageReply' } }
      filters={ <IncomingReplyFilter /> }
      exporter={ false }
      sort={ { field: 'createdAt', order: 'DESC' } }
      resource='inboundemails'
    >
      <Datagrid
        bulkActionButtons={ false }
        rowStyle={ ( record ) => {
          const colors = [ 'green', 'blue', 'yellow', 'orange', 'red' ];
          return {
            borderLeft: `solid 10px ${ colors[ Math.min( 4, Math.max( 0, record.spamScore - 1 ) ) ] }`,
          }
        } }
        expand={ <IncomingReplyExpandShow /> }
        expandSingle
        isRowExpandable={ ( record ) => !!record.text }
        sx={ {
          '& .column-spamScore': {
            maxWidth: '2rem',
          },
          '& .column-createdAt': {
            maxWidth: '6rem',
          }
        } }
      >

        <TimestampsField label="Received" source='createdAt' />

        <ReferenceField label='Recipient' source="recipient" reference="recipients" link={ false } sortable={ false } >

          <WithRecord render={ ( record ) =>
            <MuiLink onClick={ onClickRecipient( record ) } underline='none' >
              <TextField source="fullName" />
            </MuiLink>
          } />
        </ReferenceField>

        <TextField source="subject" />


      </Datagrid>
    </List>
  );
}

const IncomingReplyExpandShow: FC = () => (
  <SimpleShowLayout>
    <FunctionField label=""
      render={ ( record: { text?: string, processLog?: string } ) => (
        <Box>
          <Box component='pre'
            sx={ {
              maxWidth: '60rem',
              overflowX: 'scroll',
            } }
          >
            {
              record.text
            }
          </Box>
        </Box>
      )
      } />
  </SimpleShowLayout>
);
