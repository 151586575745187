/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { FC } from 'react';
import { Create, CreateProps, Datagrid, Edit, EditProps, FileField, FileInput, List, ListProps, Show, ShowProps, SimpleFormProps, TextField, TextInput } from 'react-admin';
import { AdminShowLayout, AdminSimpleForm } from './Admin';
import { TimestampsField } from './TimestampsField';
import { Childless } from './types';

export { default as FileIcon } from '@mui/icons-material/Description';

export const FileList: FC<ListProps> = props => {
  // useAdminListAppLocation();
  return (
    <List
      exporter={ false }
      { ...props }
    >
      <Datagrid rowClick="edit" bulkActionButtons={ false } empty={ undefined } >

        <TextField source="name" />
        <TextField source="mimeType" />

        <TimestampsField source='createdAt' />

      </Datagrid>
    </List>
  );
}

export const FileShow: FC<ShowProps> = () => {

  return (
    <Show>
      <AdminShowLayout>
        <TextField source="name" />
        <TextField source="mimeType" />
        <FileField source="src" title="title" />

        <TimestampsField source='createdAt' />

      </AdminShowLayout>
    </Show>

  );

}


export const FileForm: FC<Childless<SimpleFormProps>> = props => (
  <AdminSimpleForm sanitizeEmptyValues { ...props } >

    <TextInput source="name" required />

    <FileInput label='Upload' source="url" accept={ {
      'text/csv': [ '.csv' ],
      'application/csv': [ '.csv' ],
      'application/pdf': [ '.pdf' ]
    } } >
      <FileField source="src" title="title" />
    </FileInput>

  </AdminSimpleForm >
);


export const FileCreate: FC<CreateProps> = props => ( <Create redirect='show' { ...props } ><FileForm /></Create> );
export const FileEdit: FC<EditProps> = props => ( <Edit redirect='list'  { ...props } mutationMode='pessimistic' ><FileForm /></Edit> );
