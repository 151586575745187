import { Settings, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, BoxProps, Button, Checkbox, ClickAwayListener, FormControlLabel, Grid, IconButton, Menu, MenuItem, MenuItemProps, Modal, Radio, RadioGroup, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { createContext, FC, MouseEventHandler, ReactNode, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useUserPreference } from './UserPreferences';
import { isEqual } from 'lodash';


export interface DashboardSettingsMenuProps {
  disabled?: boolean;
  children: ReactNode;
}

export const DashboardSettingsMenuContext = createContext<( () => void ) | null>( null );

export const DashboardSettingsMenu: FC<DashboardSettingsMenuProps> = props => {
  const { children, disabled } = props;
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>( null );
  const openMenu = Boolean( anchorEl );
  const handleClick = ( event: React.MouseEvent<HTMLButtonElement> ) => {
    setAnchorEl( openMenu ? null : event.currentTarget );
  };
  const handleClose = () => {
    console.log( 'close menu' );
    setAnchorEl( null );
  };

  return (
    <ClickAwayListener onClickAway={ handleClose }>
      <IconButton
        onClick={ handleClick }
        disabled={ disabled }
      >
        <Settings />
        <DashboardSettingsMenuContext.Provider
          value={ handleClose }
        >
          <Menu
            id="basic-menu"
            anchorEl={ anchorEl }
            open={ openMenu }
            onClose={ handleClose }
            MenuListProps={ {
              'aria-labelledby': 'basic-button',
            } }
          >
            { children }
          </Menu>
        </DashboardSettingsMenuContext.Provider>
      </IconButton>
    </ClickAwayListener>
  )
}

export interface DashboardSettingsMenuItemProps extends MenuItemProps { }

export const DashboardSettingsMenuItem: FC<DashboardSettingsMenuItemProps> = props => {
  const menuClose = useContext( DashboardSettingsMenuContext );
  const { onClick, ...rest } = props;

  return (
    <MenuItem
      onClick={ ( e ) => {
        if( onClick ) onClick( e );
        if( menuClose ) menuClose();
      } }
      { ...rest }
    />
  );
}

export interface DashboardSettingsMenuCheckboxProps {
  preferenceKey: string;
  label: string;
  onClick?: MouseEventHandler<HTMLLIElement>;
}
export const DashboardSettingsMenuCheckbox: FC<DashboardSettingsMenuCheckboxProps> = props => {
  const { preferenceKey, label, onClick } = props;
  const { preferences, isLoading, update } = useUserPreference();

  const value = useMemo( () => !!( preferences && preferences[ preferenceKey ] ), [ preferences ] );

  const handleClick = useCallback<MouseEventHandler<HTMLLIElement>>( e => {
    update && update( preferenceKey, !value );
    onClick && onClick( e );
  }, [ value, preferenceKey, onClick, update ] )

  if( isLoading ) return null;
  return (
    <DashboardSettingsMenuItem onClick={ handleClick } >
      <FormControlLabel control={ <Checkbox checked={ value } disableRipple /> } label={ label } />
    </DashboardSettingsMenuItem>
  );
}

export interface DashboardSettingsMenuToggleProps extends BoxProps {
  preferenceKey: string,
  label: string,
  options: string[] | number[],
  defaultValue: string | number,
}
export const DashboardSettingsMenuToggle: FC<DashboardSettingsMenuToggleProps> = props => {
  const { preferenceKey: key, label, options, defaultValue, ...rest } = props;
  const { preferences, isLoading, update: updatePreference } = useUserPreference();
  const [ value, setValue ] = useState( defaultValue );

  useEffect( () => {
    if( isLoading || !preferences || !preferences[ key ] ) return;
    setValue( preferences[ key ] as typeof defaultValue );
  }, [ isLoading, key, preferences ] )

  const handleChange = useCallback( ( _event: SyntheticEvent, newValueStr: string ) => {
    const newValue = typeof defaultValue == 'number' ? parseInt( newValueStr ) : newValueStr;
    updatePreference && updatePreference( key, newValue );
    setValue( newValue );
  }, [ key, setValue, updatePreference ] );

  if( isLoading ) return null;
  return (
    <Box
      margin={ 1 }
      { ...rest }
    >
      <Typography
      >{ label }</Typography>
      <ToggleButtonGroup
        exclusive
        value={ value }
        onChange={ handleChange }
      >
        { options.map( o => (
          <ToggleButton
            key={ o }
            value={ `${ o }` }
            selected={ value == o }
          >{ o }</ToggleButton>
        ) ) }
      </ToggleButtonGroup>
    </Box>
  );
}

export interface DashboardSettingsMenuToggleListProps extends BoxProps {
  preferenceKey: string,
  label: string,
  options: Array<{
    value: string,
    label?: string,
  }>,
  disabled?: boolean,
}
export const DashboardSettingsMenuToggleList: FC<DashboardSettingsMenuToggleListProps> = props => {
  const { preferenceKey: key, label, options, disabled, ...rest } = props;
  const { preferences, isLoading, update, reset } = useUserPreference();
  const [ value, setValue ] = useState<string[]>( [] );
  const [ isOpen, setIsOpen ] = useState( false );

  const loadPreference = useCallback( () => {
    if( isLoading || !preferences || !preferences[ key ] ) return;
    setValue( preferences[ key ] as string[] );
  }, [ isLoading, key, preferences ] );

  useEffect( () => {
    loadPreference();
  }, [ loadPreference ] );

  const handleChange = useCallback( ( _event: SyntheticEvent, newValue: string[] ) => {
    setValue( newValue );
  }, [ setValue ] );

  const handleClose = useCallback( () => {
    setIsOpen( false );
    loadPreference();
  }, [ loadPreference, setIsOpen ] );

  const handleSave = useCallback( () => {
    update && update( key, value );
    handleClose();
  }, [ key, value, handleClose, update ] );

  const handleReset = useCallback( () => {
    setValue( [] );
  }, [ setValue ] );

  const isUnchanged = useMemo( () => {
    const pref = ( ( preferences && preferences[ key ] ) || [] ) as string[];
    return isEqual( value.sort(), pref.sort() );
  }, [ key, preferences, value ] );

  return (
    <Box {...rest}>
      <Tooltip title={ label }>
        <IconButton onClick={ () => setIsOpen( true ) } disabled={ disabled }>
          <VisibilityOff />
        </IconButton>
      </Tooltip>
      <Modal
        open={ isOpen }
        onClose={ handleClose }
      >
        <Box
          sx={ {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            padding: 2,
            boxShadow: 24,
            borderRadius: '4px',
            bgcolor: 'background.paper',
          } }
        >
          <ToggleButtonGroup
            orientation='vertical'
            value={ value }
            onChange={ handleChange }
            sx={ {
              overflowY: 'scroll',
              maxHeight: '400px',
            } }
          >
            { options.filter( o => o.label ).map( option => (
              <Grid container direction='row' alignItems='center' gap={ 1 } key={ option.value } >
                <ToggleButton
                  value={ option.value }
                >
                  { value.includes( option.value ) ? <VisibilityOff/> : <Visibility /> }
                </ToggleButton>
                <Typography>{ option.label ?? option.value }</Typography>
              </Grid>
            ) ) }
          </ToggleButtonGroup>
          <Grid container direction='row' gap={ 1 } marginTop={ 1 }>
            <Grid item flexGrow={ 1 } />
            <Button variant='outlined' color='primary' onClick={ handleClose }> Cancel </Button>
            <Button variant='contained' onClick={ handleReset } color='error' disabled={ !value.length }> Reset </Button>
            <Button variant='contained' color='primary' onClick={ handleSave } disabled={ isUnchanged }> Save </Button>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
}
