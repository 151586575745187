import { Box, ThemeOptions, Typography } from '@mui/material';
import { PointTooltipProps, SliceTooltipProps } from '@nivo/line';
import { PieTooltipProps } from '@nivo/pie';
import _ from 'lodash';
import { FC } from 'react';



export const makePieChartTooltip = <T extends { value: number }>( theme: ThemeOptions, total: number ): FC<PieTooltipProps<T>> => ( { datum: d } ) => (
  <Box
    sx={ {
      borderRadius: '8px',
      border: `0.5px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
      boxShadow: `${ theme.palette?.grey?.A400 ?? 'grey' } 0px 2px 2px`,
      backgroundColor: theme.palette?.background?.default,
      color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
      padding: '5px 9px',
    } }
  >
    <Box
      sx={ {
        whiteSpace: 'pre',
        display: 'flex',
        alignItems: 'center',
        padding: '2px',
      } }
    >
      <Box
        component='span'
        sx={ {
          width: '1.2rem',
          height: '1.2rem',
          margin: '0 0.5rem',
          background: d.color,
          border: `1px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
        } }
      />
      <Typography>{ d.id }: </Typography>
      <Typography sx={ { fontWeight: 'bold' } } > { `${ ( 100 * d.value / total ).toFixed( 0 ) }% (${ d.value })` } </Typography>
    </Box>
  </Box>
)


export const makeLineChartTooltip = ( theme: ThemeOptions ): FC<PointTooltipProps> => ( { point: d } ) => (
  <Box
    sx={ {
      borderRadius: '8px',
      border: `0.5px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
      boxShadow: `${ theme.palette?.grey?.A400 ?? 'grey' } 0px 2px 2px`,
      backgroundColor: theme.palette?.background?.default,
      color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
      padding: '5px 9px',
    } }
  >
    <Box
      sx={ {
        whiteSpace: 'pre',
        display: 'flex',
        alignItems: 'center',
        padding: '2px',
      } }
    >
      <Box
        component='span'
        sx={ {
          width: '1.2rem',
          height: '1.2rem',
          margin: '0 0.5rem',
          background: d.color,
          border: `1px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
        } }
      />
      <Typography>{ d.serieId } - { d.data.xFormatted }: </Typography>
      <Typography sx={ { fontWeight: 'bold' } } >{ d.data.yFormatted }</Typography>
    </Box>
  </Box>
)


export const LineChartSliceTooltipHeaderDefault: FC<SliceTooltipProps> = ( { slice } ) => {
  const [ point ] = slice.points;
  const { xFormatted } = point.data;

  return (
    <Box
      key='h0'
      className='tooltip-row'
    >
      <Typography sx={ { fontWeight: 'bold' } }>{ xFormatted } </Typography>
    </Box>
  );
}

export const DailyLineChartSliceTooltipHeader: FC<SliceTooltipProps> = ( { slice } ) => {
  const [ point ] = slice.points;
  // @ts-ignore: 2339
  const { ymd } = point.data;
  const date = new Date( ymd );
  const title = `${ date.toUTCString().slice( 8, 11 ) } ${ date.getUTCDate() } ${ date.getUTCFullYear() }`; // Aug 1 2024

  return (
    <Box key='h0' className='tooltip-row' >
      <Typography sx={ { fontWeight: 'bold' } }>{ title } </Typography>
    </Box>
  );
}

export const MonthlyLineChartSliceTooltipHeader: FC<SliceTooltipProps> = ( { slice } ) => {
  const [ point ] = slice.points;
  // @ts-ignore: 2339
  const { ymd } = point.data;
  const date = new Date( ymd );
  const title = `${ date.toUTCString().slice( 8, 11 ) } ${ date.getUTCFullYear() }`; // Aug 2024

  return (
    <Box key='h0' className='tooltip-row' >
      <Typography sx={ { fontWeight: 'bold' } }>{ title } </Typography>
    </Box>
  );
}

export interface MakeLineChartSliceTooltipOpts {
  theme: ThemeOptions;
  renderHeader?: FC<SliceTooltipProps>;
}

export const makeLineChartSliceTooltip = ( opts: MakeLineChartSliceTooltipOpts ): FC<SliceTooltipProps> => ( props ) => {
  const { theme, renderHeader = LineChartSliceTooltipHeaderDefault } = opts;
  const { points } = props.slice;

  return (
    <Box
      sx={ {
        borderRadius: '8px',
        border: `0.5px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
        boxShadow: `${ theme.palette?.grey?.A400 ?? 'grey' } 0px 2px 2px`,
        backgroundColor: theme.palette?.background?.default,
        color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
        padding: '5px 9px',
        '& .tooltip-row': {
          whiteSpace: 'pre',
          display: 'flex',
          alignItems: 'center',
          padding: '2px',

        }
      } }
    >
      { renderHeader( props ) }
      { _.sortBy( points, d => d.y ).map( ( d, key ) => (
        <Box
          key={ key }
          className='tooltip-row'
        >
          <Box
            component='span'
            sx={ {
              width: '1.2rem',
              height: '1.2rem',
              margin: '0 0.5rem',
              background: d.serieColor,
              border: `1px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
            } }
          />
          <Typography>{ d.serieId }: </Typography>
          <Typography sx={ { fontWeight: 'bold' } } >{ d.data.yFormatted }</Typography>
        </Box>
      ) )
      }
    </Box >
  );
}
