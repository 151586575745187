import Icon from '@mui/icons-material/Image';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MarkdownField, MarkdownInput } from '@react-admin/ra-markdown';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { IfCanAccess } from '@react-admin/ra-rbac';
import pickBy from 'lodash/pickBy';
import set from 'lodash/set';
import { FC, useCallback, useEffect, useState } from 'react';
import { AutocompleteArrayInput, Create, CreateProps, Datagrid, Edit, EditButton, EditProps, Filter, FilterProps, FunctionField, Identifier, ImageField, List, ListProps, ListViewProps, RaRecord, ReferenceArrayInput, SaveButton, SelectInput, Show, ShowProps, SimpleForm, SimpleShowLayout, TextField, TextInput, Toolbar, ToolbarProps, TransformData, useCreate, useGetMany, useGetOne, useNotify, useRecordContext, useRedirect, useRefresh, useUpdate } from 'react-admin';
import { useFormState } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { convertDataUrlToBlob, markdownEditorOptions } from './AssetInput';
import { buildImageUrl, convertFileContainerToBase64 } from './DataProvider';
import { aspectRatioString, defaultAspect, defaultCrop, defaultRotation } from './ImageCropper';
import { ImageInputEdit } from './ImageInputEdit';
import { ShowViewProps } from './Practitioners';
import { TimestampsField } from './TimestampsField';


export const AssetIcon = Icon;

export const AssetFilter: FC<FilterProps> = props => (
  <Filter { ...props }>
    {/* <TextInput label='Search' source='q' alwaysOn /> -- disabled because q filter on tags, not text, just like images. decide  */ }
    <ReferenceArrayInput label='Tag' source='tags' reference='tags' allowEmpty alwaysOn >
      <SelectInput optionText='name' />
    </ReferenceArrayInput>
  </Filter>
);

export const AssetList: FC<ListProps> = props => {
  // const { loading, permissions } = usePermissions();
  // const { record: filter } = ( permissions || [] ).find( p => isMatch( p, { resource: props.resource, action: [ 'write' ] } ) ) || { owner: 'unknown' };

  // if( loading ) return null;

  // filter={ filter }
  // filters={ <AssetFilter /> }

  return (
    <List
      // actions={ <ListActions breadcrumb={ <MyBreadcrumb variant='actions' /> } /> }
      { ...props }
    >
      <AssetListGrid />
    </List >
  );
}

export const AssetListGrid: FC<Omit<ListViewProps, 'children'>> = () => {
  // const inferredOrgId = get( props, [ 'data', get( props, 'ids.0' ), 'organization' ] );
  // const { tags } = props.filterValues;

  //  useAssetAppLocation( { tags }, 'list' );

  return (
    <Datagrid rowClick='show' bulkActionButtons={ false } >
      <TextField source='subject' />
      <MarkdownField source='body' />
      <FunctionField label='Images' source='images'
        render={ ( record ): string => `${ ( record?.images?.length || 0 ).toString() }` } />
      {/* 
          <FunctionField label='Tag Count' source='tags'
          render={ ( record?: RaRecord ): string => `${ ( record?.tags?.length || 0 ).toString() }` } />
          <ArrayField label='Tags' source='tags' >
          <SingleFieldList >
          <ReferenceField reference='tags' source='id' link='show'>
          <ChipField source='name' />
          </ReferenceField>
          </SingleFieldList>
          </ArrayField>      
          <ArrayField label='Tag Locations' source='tags' >
          <SingleFieldList >
          <ReferenceField reference='tags' source='id' link='show'>
          <TextField source='location' />
          </ReferenceField>
          </SingleFieldList>
          </ArrayField>
        */}
      <IfCanAccess action='write' >
        <EditButton />
      </IfCanAccess>
    </Datagrid>
  );
}

const AssetTitle: FC = () => {
  const record = useRecordContext();
  // @ts-ignore: 2339
  const { subject, body, id } = record || {};
  const label = subject ? subject : body ? ( body.length > 20 ? `${ body.slice( 0, 20 ) }...` : body ) : id;
  return <span>Asset '{ label }'</span>;
};

// removes the Delete button
const AssetToolbar = ( props: ToolbarProps ) => {
  // const { resource, saving } = props;
  const { isValid, isDirty } = useFormState();
  return (
    <Toolbar { ...props } >
      <SaveButton disabled={ !isDirty } invalid={ !isValid } />
    </Toolbar>
  );
}

// export const useAssetAppLocation = ( record?: Partial<RaRecord>, mode: 'edit' | 'create' | 'show' | 'list' = 'show' ): void => {
//   const { data: organizations, loaded: orgLoaded } = useGetListAsArray( 'organizations', undefined, undefined, { id: record?.tags }, { enabled: !!record } );
//   const { data: locations, loaded: locLoaded } = useGetListAsArray( 'locations', undefined, undefined, { id: record?.tags }, { enabled: !!record } );
//   const { data: locOrganization, loaded: locOrgLoaded } = useGetOne( 'organizations', locations[ 0 ]?.organization, { enabled: locLoaded && !!locations[ 0 ]?.organization } );
//   const [ location, organization ] = [ locations, organizations ].map( r => r[ 0 ] );
//   const suffix = mode == 'create' ? '.create' : mode == 'edit' ? '.asset.edit' : mode == 'list' ? '' : '.asset';
//   const [ path, values ] = orgLoaded && organization
//     ? [ 'organizations.organization.assets' + suffix, { organization, asset: record } ]
//     : location && locOrgLoaded && locOrganization
//       ? [ 'organizations.organization.locations.location.assets' + suffix, { organization: locOrganization, location, asset: record } ]
//       : [ 'assets' + suffix, {} ];
//   useDefineAppLocation( path, values );
// }



export const AssetShow: FC<ShowProps> = props => {

  return (
    <Show
      title={ <AssetTitle /> }
      // actions={ <ShowActions breadcrumb={ <MyBreadcrumb variant='actions' /> } /> }
      { ...props }>
      <AssetShowLayout />
    </Show>
  );
}



export const AssetShowLayout: FC<Omit<ShowViewProps, 'children'>> = props => {
  const theme = useTheme();
  // useAssetAppLocation( record, 'show' );
  const record = useRecordContext( props );

  if( !record ) return null;

  return (
    <SimpleShowLayout >
      <TextField source='subject' style={ { fontWeight: 'bold' } } />
      <MarkdownField source='body'
        // className={ classes.viewer }
        sx={ {
          '& .tui-editor-contents': {
            fontSize: '1.0rem',
            '& p,  ul,  menu,  ol,  dir':
            {
              color: theme.palette.text.primary,
            },
            '&  pre': {
              backgroundColor: theme.palette.background.default,
              color: theme.palette.text.primary,
            },
            '&  a': {
              color:
                theme.palette.mode === 'light'
                  ? theme.palette.primary.dark
                  : theme.palette.primary.light,
            },
          },
        } }
      />
      <FunctionField source='images' render={ ( r ) => {
        const data = r?.images.map( ( i: Identifier ) => ( { title: r?.subject || 'image', url: buildImageUrl( i ) } ) );
        return (
          <Box
            // className={ classes.image }
            sx={ {
              '& img': {
                maxWidth: '20rem',
                maxHeight: '20rem',
                paddingRight: '0.5rem',
                paddingBottom: '0.5rem',
                verticalAlign: 'top',
              }
            } }
          >
            { data.map( ( item: { title: string, url: string } ) => (
              <img key={ item.url } src={ item.url } alt={ item.title } />
            ) ) }
          </Box>
        );
      } } />

      < TimestampsField source='updatedAt' label='Updated' />
      <TimestampsField source='createdAt' label='Created' />
    </SimpleShowLayout >
  );
}

interface AssetFormProps {
  returnTo?: string;
  hideTags?: boolean;
  multiple?: boolean;
  hideSubject?: boolean;
  imageAspect?: number;
  parentResource?: string;
  parent?: RaRecord;
  basePath?: string;
}

export const AssetCreate: FC<CreateProps> = props => {
  const location = useLocation();
  const transform = useAssetCreateTransform();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const { returnTo, hideTags = true, multiple = true, imageAspect, parent, parentResource, basePath } = ( location?.state || {} ) as AssetFormProps;
  // useDefineAppLocation( `${ parentResource }.show.amenities.create`, { record: parent } );
  const [ _appLocation, setAppLocation ] = useAppLocationState();

  useEffect( () => {
    setAppLocation( `${ basePath }.${ parentResource }.show.amenities.create`, { record: parent } );
  }, [ basePath, parentResource, parent ] );

  // overriding default Create/Edit redirect call
  const onSuccess = () => {
    notify( 'ra.notification.created', { messageArgs: { smart_count: 1 } } );
    if( returnTo ) {
      redirect( returnTo );
      refresh();
    }
  };

  return (
    <Create
      { ...props }
      mutationOptions={ { onSuccess } }
      transform={ transform }
    >
      <AssetCreateLayout
        hideTags={ hideTags }
        multiple={ multiple }
        imageAspect={ imageAspect }
      />
    </Create>
  );
}

export const AssetCreateLayout: FC<Omit<Parameters<typeof SimpleForm>[ 0 ], 'children'> & { hideTags: boolean, multiple: boolean, imageAspect?: number }> = props => {
  const { hideTags, multiple, imageAspect = defaultAspect, ...rest } = props;
  // useAssetAppLocation( props.record, 'create' );
  const height = 200;
  const label = `${ ( multiple ? 'Images' : 'Image' ) } (${ aspectRatioString( imageAspect ) } aspect ratio)`;

  return (
    <SimpleForm sanitizeEmptyValues  { ...rest } toolbar={ <AssetToolbar /> } >
      <TextInput source='subject' />

      <MarkdownInput
        label='Body'
        source='body'
        { ...markdownEditorOptions }
        isRequired
      />

      <ImageInputEdit source='url' label={ label } accept={ { 'image/*': [] } } multiple={ multiple } aspect={ imageAspect }>
        <ImageField source='url' title='image'
          // className={ classes.image }
          sx={ {
            margin: '0.5rem',
            maxHeight: '30rem',
            '& .RaImageField-image': {
              height,
              width: height * imageAspect,
              objectFit: 'cover',
              // border: '1px solid purple',
            }
          } }
        />
      </ImageInputEdit>

      { !hideTags &&
        <ReferenceArrayInput label='Tags' source='tags' reference='tags' >
          <AutocompleteArrayInput optionText='name' />
        </ReferenceArrayInput>
      }
    </SimpleForm>
  );
}

export const AssetEdit: FC<EditProps> = props => {
  const location = useLocation();
  const transform = useAssetEditTransform(); // handles all the data manip involved in saving results
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const { returnTo, hideTags = true, multiple = true, imageAspect, parent, parentResource, basePath } = ( location?.state || {} ) as AssetFormProps;
  const [ _appLocation, setAppLocation ] = useAppLocationState();

  useEffect( () => {
    setAppLocation( `${ basePath }.edit`, { record: parent } );
  }, [ basePath, parentResource, parent ] );

  // overriding default Create/Edit redirect call
  const onSuccess = () => {
    notify( 'ra.notification.updated', { messageArgs: { smart_count: 1 } } );
    if( returnTo ) {
      redirect( returnTo );
      refresh();
    }
  };

  if( !transform ) return null;

  return (
    <Edit
      { ...props }
      mutationOptions={ { onSuccess } }
      mutationMode='pessimistic' // fix image delete for multiple images
      title={ <AssetTitle /> }
      transform={ transform }
      actions={ <></> }
    >
      <AssetEditLayout
        hideTags={ hideTags }
        multiple={ multiple }
        imageAspect={ imageAspect }
      />
    </Edit>
  );
}

export const AssetEditLayout: FC<Omit<Parameters<typeof SimpleForm>[ 0 ], 'children'> & { hideTags: boolean, multiple: boolean, imageAspect?: number }> = props => {
  const { hideTags, multiple, imageAspect = defaultAspect, ...rest } = props;
  // useAssetAppLocation( props.record, 'edit' );
  const height = 200;
  const label = `${ ( multiple ? 'Images' : 'Image' ) } (${ aspectRatioString( imageAspect ) } aspect ratio)`;

  return (
    <SimpleForm sanitizeEmptyValues { ...rest } toolbar={ <AssetToolbar /> } >
      <TextInput source='subject' fullWidth />

      <MarkdownInput
        label='Body'
        source='body'
        { ...markdownEditorOptions }
        height='360px'
        isRequired
      />

      <ImageInputEdit source='url' label={ label } accept={ { 'image/*': [] } } multiple={ multiple } aspect={ imageAspect }>
        <ImageField source='url' title='image'
          sx={ {
            margin: '0.5rem',
            maxHeight: '30rem',
            '& .RaImageField-image': {
              height,
              width: height * imageAspect,
              objectFit: 'cover',
              // border: '1px solid purple',
            }
          } }
        />
      </ImageInputEdit>

      { !hideTags &&
        <ReferenceArrayInput label='Tags' source='tags' reference='tags' >
          <AutocompleteArrayInput optionText='name' />
        </ReferenceArrayInput>
      }
    </SimpleForm>
  );
}


export const useAssetEditTransform = (): TransformData | undefined => {
  const source = 'url';
  const [ crop, rotation ] = [ defaultCrop, defaultRotation ];
  const { id } = useParams<'id'>();
  const { data: record, isLoading: isRecordLoading } = useGetOne( 'assets', { id }, { enabled: !!id } );
  const { data: images, isLoading } = useGetMany( 'images', { ids: record?.images || [] }, { enabled: !isRecordLoading && !!( record?.images && record?.images.length > 0 ) } );
  const [ ready, setReady ] = useState( false );
  const [ update ] = useUpdate();
  const [ create ] = useCreate();

  const saveImagesFromData = useCallback<TransformData>( async ( assetData, _options ) => {
    const mutationMode = 'pessimistic';
    const returnPromise = true;
    const _images = [ ...( images || [] ) ];
    if( !assetData.images ) assetData.images = [];
    if( !assetData[ source ] ) assetData[ source ] = [];
    if( !Array.isArray( assetData[ source ] ) ) {  // multiple==false
      assetData[ source ] = [ assetData[ source ] ];
    }

    for( const imageData of assetData[ source ] ) {
      const base64 = await convertFileContainerToBase64( imageData );
      if( !base64 ) continue;
      // pickBy because crop/rotation coming in as null on new imageData
      const { id, crop = defaultCrop, rotation = defaultRotation } = pickBy( imageData );
      const data: Partial<RaRecord> = { [ source ]: base64, crop, rotation };
      if( id ) {
        const previousData = _images.find( i => i.id == id ) || {};
        if( previousData?.data && previousData.data == base64.slice( base64.toString().indexOf( ',' ) + 1 ) ) {
          continue;
        }
        await update( 'images', { id, data, previousData }, { returnPromise, mutationMode } );
      } else {
        const newImage = await create( 'images', { data }, { returnPromise } );
        if( newImage?.id ) {
          assetData.images.push( newImage.id );
        }
      }
    }
    const processedIds = assetData[ source ]
      .map( ( i: { id?: string } ) => i.id )
      .filter( ( id: string | undefined ) => id );
    const imageIdsToDelete = _images
      .map( ( i ) => i.id )
      .filter( id => !processedIds.includes( id ) );
    assetData.images = assetData.images
      .filter( ( id: string ) => !imageIdsToDelete.includes( id ) );

    delete assetData[ source ];
    return assetData;
  }, [ update, create, images, source ] );


  // The purpose of this is to wait for download of existing images and keep them to
  // compare against for changes in Edit.
  useEffect( () => {
    ( async () => {
      if( ready || !images || !record ) return;
      if( !record.images ) record.images = [];
      if( record.images.length > 0 && isLoading ) return;
      for( let idx = 0; idx < record.images.length; idx++ ) {
        const id = record.images[ idx ]
        const image = images.find( image => image.id == id );
        if( !image ) continue;
        const url = `data:${ image.mimeType };base64,${ image.data }`;
        const rawFile = await convertDataUrlToBlob( url );
        set( record, [ source, idx ], { id, url, crop, rotation, rawFile, image } )
      }
      setReady( true );
    } )();
  }, [ images, isLoading, record, setReady ] );

  return ready ? saveImagesFromData : undefined;
}

export const useAssetCreateTransform = (): TransformData => {
  const source = 'url';
  const [ create ] = useCreate();

  const saveImagesFromData = useCallback<TransformData>( async ( assetData ) => {
    const returnPromise = true;
    if( !assetData.images ) assetData.images = [];
    if( !assetData[ source ] ) assetData[ source ] = [];
    if( !Array.isArray( assetData[ source ] ) ) assetData[ source ] = [ assetData[ source ] ]; // cover multiple true|false

    for( const imageData of assetData[ source ] ) {
      const base64 = await convertFileContainerToBase64( imageData );
      if( !base64 ) continue;
      // pickBy because crop/rotation coming in as null on new imageData
      const { crop = defaultCrop, rotation = defaultRotation } = pickBy( imageData );
      const data: Partial<RaRecord> = { [ source ]: base64, crop, rotation };
      const newImage = await create( 'images', { data }, { returnPromise } );
      if( newImage?.id ) {
        assetData.images.push( newImage.id );
      }
    }

    delete assetData[ source ];
    return assetData;
  }, [ create, source ] );

  return saveImagesFromData;
}
