import { useAppLocationState } from '@react-admin/ra-navigation';
import { FC, useEffect } from 'react';
import { Datagrid, Edit, EditProps, List, ListProps, Show, ShowProps, SimpleFormProps, TextField, TextInput, WithRecord } from 'react-admin';
import { AdminShowLayout, AdminSimpleForm } from './Admin';
import { CustomToolbar } from './ReminderTemplateLists';
import { ShowActionsWithReset } from './ShowActionsWithReset';
import { TimestampsField } from './TimestampsField';
import { Flow, LayoutFlow, LayoutFlowView } from './Flow';
import { ReactFlowProvider } from '@xyflow/react';
import Box from '@mui/material/Box';


export { default as FlowIcon } from '@mui/icons-material/MoreVert'; // Schedule

export const FlowList: FC<ListProps> = () => {
  const [ _location, setLocation ] = useAppLocationState();
  useEffect( () => {
    setLocation( 'admin.flows' );
    return () => setLocation( null );
  }, [] );

  return (
    <List
      exporter={ false }
      perPage={ 25 }
    >
      <Datagrid
        rowClick="show"
        // expand={ <FlowExpandEdit /> }
        bulkActionButtons={ false }
      >

        <TextField label="Name" source="name" />
        <TextField label="ID" source="id" />
        <TimestampsField label="Updated" source="updatedAt" />
      </Datagrid>
    </List>
  );
}

export const FlowShow: FC<ShowProps> = () => {
  return (
    <Show
    // actions={ <ShowActionsWithReset /> }
    >
      <AdminShowLayout>
        <TextField label='Name' source='name' />
        <TextField label='ID' source='id' />
        <TimestampsField label="Updated" source="updatedAt" />
        <TimestampsField label="Created" source="createdAt" />
        <WithRecord render={ record => {
          const { nodes, edges } = record;
          return (
            <ReactFlowProvider>
              <Box
                sx={ {
                  width: '80%',
                  height: '50%',
                } }
              >
                <LayoutFlowView
                  nodes={ nodes }
                  edges={ edges }
                />
              </Box>
            </ReactFlowProvider>
          )
        } } />
      </AdminShowLayout>
    </Show>
  );
}

const FlowForm: FC<Omit<SimpleFormProps, 'children'>> = props => (
  <AdminSimpleForm sanitizeEmptyValues  { ...props } toolbar={ <CustomToolbar /> } >

    { /* <TextField label="Name" source="id" /> */ }

    <TextInput source="value" label="Flow" multiline rows={ 16 }
      sx={ {
        '& .MuiInputBase-input': { fontFamily: 'monospace' }
      } }
    />
    <TextField source="comment" />

  </AdminSimpleForm>
);

export const FlowExpandEdit: FC<EditProps> = props => (
  <Edit
    actions={ false }
    title={ '&nbsp;' }
    redirect='list'
    { ...props } >
    <FlowForm />
  </Edit>
);

export const FlowEdit: FC<EditProps> = props => (
  <Edit
    // actions={ <EditActions breadcrumb={ <MyBreadcrumb variant="actions" /> } /> }
    redirect='list'
    { ...props }
  >
    <FlowForm />
  </Edit>
);


