/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { FC } from 'react';
import { TextFieldProps, TextField, useRecordContext } from 'react-admin';
import get from 'lodash/get';
import { formatDistanceToNow } from 'date-fns';

export const cleanFormatDistanceToNow = ( date?: Date | string ): string => {
  const d = typeof date === 'string' ? new Date( Date.parse( date ) ) : date;
  if( !d ) return '';
  return formatDistanceToNow( d, { addSuffix: true } ).replace( /about /, '' );
}

export const TimestampsField: FC<TextFieldProps> = props => {
  const { source } = props;
  const record = useRecordContext( props );

  const pretty = ( source?: string ): string | undefined => {
    if( !source ) return;
    const ts = get( record, source );
    const date = ts && Date.parse( ts );
    if( !date ) return;
    return cleanFormatDistanceToNow( date );
  }

  return (
    pretty( source )
      ? <TextField
        { ...props }
        record={ { id: source || '', date: pretty( source ) } }
        source="date"
      />
      : <></>
  )
}
