import { Warning } from '@mui/icons-material';
import { Box, Popover, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { IconButtonWithTooltip } from 'react-admin';
import { apiUrl, httpClient } from './DataProvider';


export const AppBarWarning: FC = () => {
  const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement | null>( null );
  const [ warning, setWarning ] = useState( '' );

  const fetchWarning = useCallback( async () => {
    const { body } = await httpClient( `${ apiUrl }/system/warning` );
    return body;
  }, [ httpClient ] );

  useEffect( () => {
    ( async () => {
      try {
        const body = await fetchWarning();
        setWarning( body );
      } catch( e ) {
        setWarning( '' );
      }
    } )()
  }, [ setWarning, fetchWarning ] );

  const handleClick = ( event: React.MouseEvent<HTMLButtonElement> ) => {
    setAnchorEl( event.currentTarget );
  };

  const handleClose = () => {
    setAnchorEl( null );
  };

  const open = Boolean( anchorEl );
  const id = open ? 'simple-popover' : undefined;

  if( !warning ) return null;

  return (
    <div>
      <IconButtonWithTooltip label='Tap to see system warning message' onClick={ handleClick }>
        <Warning color='warning' />
      </IconButtonWithTooltip>
      <Popover
        id={ id }
        open={ open }
        anchorEl={ anchorEl }
        onClose={ handleClose }
        anchorOrigin={ {
          vertical: 'bottom',
          horizontal: 'center',
        } }
        transformOrigin={ {
          vertical: 'top',
          horizontal: 'right',
        } }

      >
        <Box sx={ { p: 2 } }>
          { warning.split( /\n/ ).map( ( w, key ) => (
            <Typography
              key={ key }
              sx={ {
                fontFamily: 'monospace',
              } }
            >
              { w }
            </Typography>
          ) ) }
        </Box>
      </Popover>
    </div>
  );
}
