import { FC } from 'react';
import { Datagrid, Edit, EditProps, List, ListProps, Show, ShowProps, SimpleFormProps, TextField, TextInput } from 'react-admin';
import { CustomToolbar } from './ReminderTemplateLists';
import { ShowActionsWithReset } from './ShowActionsWithReset';
import { TimestampsField } from './TimestampsField';
import { AdminShowLayout, AdminSimpleForm, useAdminListAppLocation } from './Admin';

export { RecordVoiceOver as TwimlIcon } from '@mui/icons-material';

export const TwimlList: FC<ListProps> = props => {
  useAdminListAppLocation();
  return (
    <List
      { ...props }
      exporter={ false }
      perPage={ 25 }
    >
      <Datagrid rowClick="edit"
        //expand={ <TwimlExpandEdit /> }
        bulkActionButtons={ false }
      >

        <TextField label="Name" source="id" />
        <TextField label="Comment" source="comment" />
        {/* <TextField source="value"  /> */ }
        <TimestampsField label="Updated" source="updatedAt" />
      </Datagrid>
    </List>
  );
}

export const TwimlShow: FC<ShowProps> = () => {

  return (
    <Show actions={ <ShowActionsWithReset /> }>
      <AdminShowLayout>
        <TextField label='Name' source='id' />
        <TextField source='value' />
        <TextField source='comment' />

        <TimestampsField label="Updated" source="updatedAt" />
        <TimestampsField label="Updated" source="createdAt" />
      </AdminShowLayout>
    </Show>
  );
}



// TODO set comment to edited in UI
const TwimlForm: FC<Omit<SimpleFormProps, 'children'>> = props => (
  <AdminSimpleForm sanitizeEmptyValues  { ...props } toolbar={ <CustomToolbar /> } >


    <TextField label="Name" source="id" />
    <TextInput source="comment" />

    <TextInput source="value" label="Message" multiline rows={ 16 }
      sx={ {
        '& .MuiInputBase-input': { fontFamily: 'monospace' }
      } }
    />
    { /*
       <TwimlEditor source="value" label="Message" width={ 600 } height={ 600 } />
     */}


  </AdminSimpleForm>
);

export const TwimlExpandEdit: FC<EditProps> = props => (
  <Edit
    actions={ false }
    title={ '&nbsp;' }
    redirect='list'
    { ...props } >
    <TwimlForm />
  </Edit>
);

export const TwimlEdit: FC<EditProps> = props => (
  <Edit
    // actions={ <EditActions breadcrumb={ <MyBreadcrumb variant="actions" /> } /> }
    redirect='list'
    { ...props }
  >
    <TwimlForm />
  </Edit>
);
// export const TwimlEdit: FC<EditProps> = props => ( <Edit { ...props } ><TwimlForm /></Edit> );

