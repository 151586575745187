import { patternDotsDef, patternLinesDef, patternSquaresDef, SvgDefsAndFill } from '@nivo/core';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useUserPreference } from './UserPreferences';
import { Datum } from '@nivo/line';

export interface Patterns extends SvgDefsAndFill<Datum> {
  fillByKeys: ( keys: string[] ) => SvgDefsAndFill<Datum>[ 'fill' ],
}

const usePatterns = (): Patterns => {
  const theme = useTheme();
  const chartBackground = theme.palette?.background?.default;
  const { preferences, isLoading } = useUserPreference();

  const isEnabled = useMemo( () => {
    if( isLoading || !preferences ) return;
    return !!preferences.dashboardEnablePatterns;
  }, [ isLoading, preferences ] );

  if( !isEnabled ) {
    return {
      fillByKeys: () => [],
    };
  }

  const defs = [
    patternSquaresDef( '1', {
      background: 'inherit',
      color: chartBackground,
      size: 6,
      padding: 4,
    } ),
    patternLinesDef( '2', {
      background: 'inherit',
      color: chartBackground,
      rotation: -30,
    } ),
    patternDotsDef( '3', {
      background: 'inherit',
      color: chartBackground,
      size: 3,
      padding: 5,
    } ),
    patternLinesDef( '4', {
      background: 'inherit',
      color: chartBackground,
      rotation: 30,
    } ),
    patternDotsDef( '5', {
      background: 'inherit',
      color: chartBackground,
      stagger: true,
      size: 4,
      padding: 2,
    } ),
  ];
  return {
    defs,
    fill: [
      { id: '1', match: { id: '1 star' } },
      { id: '2', match: { id: '2 stars' } },
      { id: '3', match: { id: '3 stars' } },
      { id: '4', match: { id: '4 stars' } },
      { id: '5', match: { id: '5 stars' } },
    ],
    fillByKeys: keys => keys.map( ( key, index ) => {
      const id = defs[ index ]?.id;
      return id ? { id, match: { id: key } } : undefined;
    } ).filter( f => !!f ),
  };
};

export default usePatterns;
