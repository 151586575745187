import { Button, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { DataProviderContext, RaRecord, useGetList } from 'react-admin';

interface AuthorizationInterface extends RaRecord {
  name?: string,
  primaryEmail?: string,
  platform: string,
}

export const ReputationConnectionsShow: FC = () => {
  const { data: authorizationsData, isLoading: isAuthorizationsLoading } = useGetList<AuthorizationInterface>( 'reputationauthorizations' );
  const [ googleAuthUrl, setGoogleAuthUrl ] = useState( '' );
  const dataProvider = useContext( DataProviderContext );

  useEffect( () => {
    ( async () => {
      if( !dataProvider ) return;
      const query = new URLSearchParams( {
        redirect_uri: window.location.href,
      } );
      const response = await dataProvider.fetchJson( `${ dataProvider.apiUrl }/reputationoauth/google/init?${ query }` );
      setGoogleAuthUrl( response.json.url );
    } )();
  }, [ dataProvider ] );

  if( isAuthorizationsLoading ) return <></>;
  return (
    <>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Platform</TableCell>
            <TableCell>Account Name</TableCell>
            <TableCell>Primary Email</TableCell>
            {/* <TableCell /> */ }
          </TableRow>
        </TableHead>
        <TableBody>
          { authorizationsData?.map( authorization => (
            <TableRow>
              <TableCell> Google </TableCell>
              <TableCell> { authorization.name } </TableCell>
              <TableCell> { authorization.primaryEmail } </TableCell>
              {/* <TableCell> <Button variant='contained' color='error' disabled>Disconnect</Button> </TableCell> */ }
            </TableRow>
          ) ) }
        </TableBody>
      </Table>
      <br />
      <Button variant='contained' >
        <Link underline='none' href={ googleAuthUrl } color="inherit" >
          Add Google account
        </Link>
      </Button>
    </>
  );
}
