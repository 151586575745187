/*eslint @typescript-eslint/no-unused-vars: "off" */
import { Help } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Grid, ThemeOptions, Tooltip, Typography, useTheme } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { Bar, BarDatum } from '@nivo/bar';
import { Datum, Line, LineProps, PointTooltipProps, Serie } from '@nivo/line';
import { Pie, PieTooltipProps } from '@nivo/pie';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { IfCanAccess, useCanAccess } from '@react-admin/ra-rbac';
import { addDays } from 'date-fns';
import { capitalize } from 'inflection';
import { chunk, get, reverse, set, sortBy, sumBy } from 'lodash';
import { default as queryString } from 'query-string';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Loading, Title, useGetList, useRedirect } from 'react-admin';
import { AnomalyReportDocument } from './AnomalyReports';
import { apiUrl, httpClient } from './DataProvider';
import { useHasNoCharity, useHasNoEmr } from './Organizations';
import { PickByPropertyType } from './types';
import { ChartProps, elevation } from './DashboardWidget';
import useSummary from './use-summary';
import { DashboardTable } from './DashboardDatagrid';

interface NonprofitTrendDatum extends BarDatum {
  pledgeId: string;
  count: number;
  feedback: string;
}


export const DashboardTopPatientCharities: FC<ChartProps> = ( { date } ) => {
  const theme = useTheme();
  const cacheKey = 'dashboard-nonprofit-trend';
  const [ isLoading, setIsLoading ] = useState( true );
  const [ nonprofitMap, setNonprofitMap ] = useState<Record<string, string> | undefined>();
  const top = 10;

  const summary = useSummary<NonprofitTrendDatum[]>( 'nonprofit7dayTrend', { date } );

  const data = useMemo<NonprofitTrendDatum[]>( () => summary.data ?? [] , [ summary.data ] );

  const fetchNonprofitName = useCallback( async ( id: string | undefined, feedback: string | undefined ): Promise<string | undefined> => {
    if( !id || !feedback ) return;
    const { body } = await httpClient( `${ apiUrl }/data/nonprofits/${ id }?t=${ feedback }` );
    try {
      const { name } = JSON.parse( body ) as { name: string };
      return name;
    } catch( e ) { return; }
  }, [ httpClient ] );

  useEffect( () => {
    if( nonprofitMap || data.length || !isLoading ) return;
    ( async () => {
      if( data.length || !isLoading ) {
        setNonprofitMap( {} );
        return;
      }
      try {
        const map = localStorage.getItem( 'dashboard-nonprofit-map' );
        setNonprofitMap( map ? JSON.parse( map ) : {} );
        setIsLoading( false );
      } catch( e ) {
        setNonprofitMap( {} );
        return;
      }
    } )()
  }, [ data, isLoading, setIsLoading, nonprofitMap, setNonprofitMap ] );

  useEffect( () => {
    ( async () => {
      const map = localStorage.getItem( 'dashboard-nonprofit-map' );
      setNonprofitMap( map ? JSON.parse( map ) : {} );
      // setIsLoading( false );
    } )()
  }, [] );

  useEffect( () => {
    ( async () => {
      if( !nonprofitMap || !data.length ) return;
      const map = nonprofitMap;
      const feedback = data.find( d => d.feedback )?.feedback || '';
      const ids = Array.from( new Set( data.slice( 0, top ).map( d => d.pledgeId || '' ) ) );
      // setIsLoading( true );
      for( const id of ids ) {
        if( map[ id ] ) continue;
        const name = await fetchNonprofitName( id, feedback );
        if( name ) map[ id ] = name;
      }
      setNonprofitMap( map );
      setIsLoading( false );
      localStorage.setItem( 'dashboard-nonprofit-map', JSON.stringify( map ) );
    } )();
  }, [ data, setNonprofitMap, nonprofitMap, fetchNonprofitName, setIsLoading, date ] );


  return (
    ( <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title="Top Patient Charities"
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `7 Days Ending ${ date.format( 'MMM D' ) }` : 'Previous 7 Days' }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        <DashboardTable
          rows={ isLoading || summary.isLoading || !nonprofitMap
            ? []
            : data.slice( 0, top ).map( ( d, id ) => {
              const { pledgeId, count } = d;
              const charity = nonprofitMap[ pledgeId ] || '';
              return { id, charity, count };
            } ) }
          columns={ [
            { field: 'count', headerName: 'Count', type: 'number', sortable: false, flex: 0.5 },
            { field: 'charity', headerName: 'Charity', flex: 2, sortable: false },
          ] }
          hideFooter
          sx={ {
            height: 400,
            marginTop: '-0.5rem',
            marginBottom: '1rem',
          } }
        />
      </CardContent>
    </Card > )
  );

}

