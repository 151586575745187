import { QueryClient } from '@tanstack/react-query';
import { getTimeZones } from '@vvo/tzdb';
import { titleize } from 'inflection';
import _ from 'lodash';
import { CreateParams, DataProvider, fetchUtils, Identifier, UpdateParams } from 'react-admin';
import { Area, defaultCrop, defaultRotation } from './ImageCropper';
import { jsonapiDataProvider, ResourceMap } from './jsonapi-data-provider';
// import { authProvider } from './SmartProvider';

export const queryClient = new QueryClient( {
  defaultOptions: {
    queries: {
      staleTime: 7 * 60 * 1000, // 7 mins
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
} );

const {
  REACT_APP_API_SERVER: apiServer = location.protocol == 'https:' ? location.origin.replace( /admin\./, '' ) : '',
  // REACT_APP_ACCEPT_HEADER: accept = 'application/vnd.api+json',
} = process.env;
export const apiUrl = `${ apiServer }/api/v1`;
export const dataApiUrl = location.origin.match( /localhost/ ) ? `${ apiServer.replace( /3400/, '3300' ) }/api/v1/data` : `${ apiServer }/api/v1/data`;
export const apiHost = apiServer;
// console.log( 'apiUrl', apiUrl );

export const buildImageUrl = ( imageId: Identifier ): string => `${ apiUrl }/image/${ imageId }.png`;

// http -b :3400/api/v1/spec | jq '[ .paths | to_entries? | .[] | select( .key | match( "/{id}" ) ) | .value = ( .value.patch.requestBody.content | to_entries? | .[0].value | .schema.properties.data | to_entries? | .[0].value | { attributes: ( .. | .attributes?.properties | select( . ) | keys ), relationships: ( [ .. | .relationships?.properties | select( . ) | keys[] ] ) } ) | .key = ( .key[1:-5] ) ] | sort_by( .key ) | from_entries' | perl -p -e 's/"/'"'"'/g' | pbcopy

export const resourceMap: ResourceMap = {
  'actionlogs': {
    'attributes': [
      'action',
      'category',
      'createdAt',
      'updatedAt'
    ],
    'relationships': [
      'appointment',
      'botContext',
      'feedback',
      'practitioner',
      'recipient'
    ]
  },
  'alertrecipients': {
    'attributes': [
      'createdAt',
      'enable',
      'updatedAt'
    ],
    'relationships': [
      'appointment',
      'location',
      'outboundAlert',
      'patient',
      'practitioner'
    ]
  },
  'alerttemplates': {
    'attributes': [
      'alertEmailText',
      'alertSmsText',
      'alertTwimlText',
      'comment',
      'createdAt',
      'enabledStatuses',
      'locationIdArray',
      'name',
      'patientIdArray',
      'practitionerIdArray',
      'sendPatientAlert',
      'sendPatientRecipientsAlert',
      'updatedAt'
    ],
    'relationships': []
  },
  'anomalyreportdefinitions': {
    'attributes': [
      'addCsvHeader',
      'alertExceptionsSort',
      'apptStartTimeRange',
      'apptStopTimeRange',
      'comment',
      'countPreviousDaysToReportOn',
      'createdAt',
      'displayTxtFile',
      'emailList',
      'enable',
      'estimatedValueOfAppointment',
      'lastReportFiredDate',
      'name',
      'nextFireDate',
      'nextFireDateWindowHours',
      'outputCsvFile',
      'outputJsonDb',
      'outputJsonFile',
      'outputTxtFile',
      'reportIntervalType',
      'reportType',
      'statusArray',
      'timeHoursToRunReport',
      'timeMinutesToRunReport',
      'updatedAt',
      'weekdaysToRunReport',
      'zipFiles',
      'zipPassword'
    ],
    'relationships': []
  },
  'anomalyreportemails': {
    'attributes': [
      'comment',
      'createdAt',
      'emailList',
      'name',
      'updatedAt'
    ],
    'relationships': []
  },
  'anomalyreports': {
    'attributes': [
      'appointmentStartTime',
      'appointmentStopTime',
      'appointmentsWithErrors',
      'comment',
      'createdAt',
      'errorPercentage',
      'errorPercentageString',
      'estimatedDollarLoss',
      'estimatedDollarLossString',
      'estimatedValueOfAppointment',
      'humanAppointmentStartTime',
      'humanAppointmentStopTime',
      'jsonReportComment',
      'name',
      'reportIntervalType',
      'reportType',
      'reports',
      'sortByField',
      'sortByOrder',
      'totalAppointmentsAnalyzed',
      'updatedAt'
    ],
    'relationships': []
  },
  'appointments': {
    'attributes': [
      'active',
      'appointmentReminderLastAppointmentDate',
      'appointmentTypeCode',
      'appointmentTypeDisplay',
      'atOpCalendarData',
      'calendarData',
      'calendarUid',
      'cancellationReasonCode',
      'cancellationReasonDisplay',
      'cancellationReasonText',
      'comment',
      'createdAt',
      'encounterChanged',
      'encounterCount',
      'encounterExists',
      'encounterFhirIdArray',
      'endTime',
      'entryMethod',
      'fhirComment',
      'fhirId',
      'fhirLocationId',
      'fhirPatientId',
      'fhirPractitionerId',
      'fhirSync',
      'lastAppointmentTypeCode',
      'lastAppointmentTypeDisplay',
      'lastFhirLocationId',
      'lastFhirPractitionerId',
      'lastReviewSubmittedDate',
      'lastStartTime',
      'lastStatus',
      'lastUpdatedPrev',
      'locationFullName',
      'newPatient',
      'patientFullName',
      'postOpCalendarData',
      'practitionerFullName',
      'preOpCalendarData',
      'realTimeUpdates',
      'reason',
      'reminderSendingPhase',
      'semaphore.expires',
      'semaphore.id',
      'startTime',
      'status',
      'timeZoneName',
      'updatedAt'
    ],
    'relationships': [
      'location',
      'patient',
      'practitioner',
      'relatedAppointment'
    ]
  },
  'appointmenttypepackages': {
    'attributes': [
      'atOpCareHtml',
      'atOpCareShortSms',
      'atOpCareSms',
      'cancelledHtml',
      'cancelledShortSms',
      'cancelledSms',
      'comment',
      'createdAt',
      'name',
      'noShowHtml',
      'noShowShortSms',
      'noShowSms',
      'postOpCareHtml',
      'postOpCareShortSms',
      'postOpCareSms',
      'preOpCareHtml',
      'preOpCareShortSms',
      'preOpCareSms',
      'reviewHtml',
      'reviewShortSms',
      'reviewSms',
      'updatedAt'
    ],
    'relationships': [
      'atOpCalendarPackage',
      'atReminderTemplateList',
      'cancelledTemplateList',
      'noShowTemplateList',
      'postOpCalendarPackage',
      'postReminderTemplateList',
      'preOpCalendarPackage',
      'preReminderTemplateList',
      'reviewTemplateList'
    ]
  },
  'appointmenttypes': {
    'attributes': [
      'comment',
      'createdAt',
      'dayAvailableTimes',
      'dayAvailables',
      'name',
      'overbookLevelMax',
      'overbookSameCount',
      'overbookTagCount',
      'sameSpacing',
      'tagSpacing',
      'units',
      'updatedAt'
    ],
    'relationships': []
  },
  'appointmentupdatetexts': {
    'attributes': [
      'comment',
      'createdAt',
      'text',
      'updatedAt'
    ],
    'relationships': []
  },
  'assets': {
    'attributes': [
      'body',
      'createdAt',
      'subject',
      'updatedAt'
    ],
    'relationships': [
      'images',
      'tags'
    ]
  },
  'authlogs': {
    'attributes': [
      'action',
      'appName',
      'authMethod',
      'authMethodUserId',
      'createdAt',
      'createdNewMethodUser',
      'createdNewUser',
      'email',
      'externalId',
      'flowType',
      'phone',
      'preAuthSessionId',
      'reason',
      'status',
      'tenantId',
      'updatedAt'
    ],
    'relationships': [
      'user'
    ]
  },
  'calendarevents': {
    'attributes': [
      'createdAt',
      'description',
      'emr',
      'emrId',
      'emrLocationId',
      'emrOrganizationId',
      'emrParticipantIds',
      'emrPatientId',
      'end',
      'eventType',
      'notes',
      'otherPersonnel',
      'repeating',
      'resources',
      'start',
      'timeZoneName',
      'updatedAt'
    ],
    'relationships': [
      'location',
      'organization',
      'participants',
      'patient'
    ]
  },
  'calendaroccurrences': {
    'attributes': [
      'createdAt',
      'description',
      'emr',
      'emrEventId',
      'emrId',
      'emrLocationId',
      'emrOrganizationId',
      'emrParticipantIds',
      'emrPatientId',
      'end',
      'eventType',
      'notes',
      'otherPersonnel',
      'resources',
      'start',
      'timeZoneName',
      'updatedAt'
    ],
    'relationships': [
      'attendees',
      'calendarEvent',
      'location',
      'organization',
      'participants',
      'patient'
    ]
  },
  'calendarpackages': {
    'attributes': [
      'alarmAudioBeforeDescription',
      'alarmAudioBeforeSecs',
      'alarmAudioEnable',
      'alarmDisplayBeforeDescription',
      'alarmDisplayBeforeSecs',
      'alarmDisplayEnable',
      'busyStatus',
      'categories',
      'comment',
      'createdAt',
      'deliveryLocationAddress',
      'deliveryLocationTitle',
      'description',
      'events',
      'name',
      'priority',
      'summary',
      'updatedAt'
    ],
    'relationships': []
  },
  'configs': {
    'attributes': [
      'comment',
      'value'
    ],
    'relationships': []
  },
  'dashboardalerts': {
    'attributes': [
      'adminLink',
      'comment',
      'completeDate',
      'createdAt',
      'detail',
      'issue',
      'resourceType',
      'status',
      'tab',
      'updatedAt'
    ],
    'relationships': [
      'resource'
    ]
  },
  'emreventtypes': {
    'attributes': [
      'category',
      'createdAt',
      'description',
      'disable',
      'disableOutgoingClinical',
      'disablePatient',
      'disablePatientRecipients',
      'disablePractitioner',
      'disablePractitionerRecipients',
      'emr',
      'emrSubscribed',
      'label',
      'name',
      'updatedAt'
    ],
    'relationships': []
  },
  'encounterservicerequests': {
    'attributes': [
      'comment',
      'consensusStartTime',
      'createdAt',
      'encounterEndDate',
      'encounterLastUpdated',
      'encounterStartDate',
      'lastTestCompletedCount',
      'lastTestCount',
      'locationId',
      'messageTypeToSend',
      'patientFhirId',
      'practitionerFhirId',
      'practitionerName',
      'recordState',
      'recordStateUpdateDate',
      'recordUpdated',
      'serviceRequestsArray',
      'testCompletedCount',
      'testCount',
      'updatedAt',
      'welcomeMessageSent'
    ],
    'relationships': []
  },
  'evaluators': {
    'attributes': [
      'createdAt',
      'emailAddress',
      'emailEval',
      'name',
      'phoneNumber',
      'smsEval',
      'updatedAt',
      'voiceEval'
    ],
    'relationships': []
  },
  'events': {
    'attributes': [
      'completedAt',
      'createdAt',
      'name',
      'status',
      'updatedAt'
    ],
    'relationships': []
  },
  'feedbackmessages': {
    'attributes': [
      'createdAt',
      'severity',
      'text',
      'updatedAt'
    ],
    'relationships': [
      'feedback'
    ]
  },
  'feedbacks': {
    'attributes': [
      'createdAt',
      'messages',
      'pledgeId',
      'recipientType',
      'reputationUrls',
      'status',
      'updatedAt'
    ],
    'relationships': [
      'appointment',
      'location',
      'practitioner',
      'recipient'
    ]
  },
  'files': {
    'attributes': [
      'createdAt',
      'data',
      'mimeType',
      'name',
      'updatedAt'
    ],
    'relationships': [
      'tags'
    ]
  },
  'flows': {
    'attributes': [
      'createdAt',
      'edges',
      'enabled',
      'name',
      'nodes',
      'protected',
      'triggerEvent',
      'updatedAt',
      'version'
    ],
    'relationships': []
  },
  'formdefinitions': {
    'attributes': [
      'createdAt',
      'json',
      'name',
      'saveNo',
      'updatedAt'
    ],
    'relationships': []
  },
  'formsubmissions': {
    'attributes': [
      'createdAt',
      'email',
      'formName',
      'pdf',
      'phone',
      'processedAt',
      'recipientType',
      'signedAt',
      'status',
      'submission',
      'updatedAt'
    ],
    'relationships': [
      'appointment',
      'form',
      'location',
      'practitioner',
      'recipient'
    ]
  },
  'golives': {
    'attributes': [
      'createdAt',
      'langs',
      'messages',
      'recipientTypes',
      'semaphore.expires',
      'semaphore.id',
      'status',
      'targetEmails',
      'updatedAt'
    ],
    'relationships': [
      'user'
    ]
  },
  'htmlcomponents': {
    'attributes': [
      'comment',
      'createdAt',
      'isParent',
      'type',
      'updatedAt',
      'value'
    ],
    'relationships': []
  },
  'htmlemailtitles': {
    'attributes': [
      'comment',
      'createdAt',
      'updatedAt',
      'value'
    ],
    'relationships': []
  },
  'images': {
    'attributes': [
      'createdAt',
      'data',
      'mimeType',
      'updatedAt',
      'url'
    ],
    'relationships': [
      'tags'
    ]
  },
  'inboundemails': {
    'attributes': [
      'attachments',
      'cc',
      'charsets',
      'createdAt',
      'dateReceived',
      'dateSent',
      'dkim',
      'externalId',
      'from',
      'headers',
      'html',
      'isResolved',
      'preview',
      'processDate',
      'processLog',
      'processType',
      'replyTo',
      'semaphore.expires',
      'semaphore.id',
      'senderIp',
      'spamReport',
      'spamScore',
      'spf',
      'subject',
      'text',
      'to',
      'updatedAt'
    ],
    'relationships': [
      'recipient',
      'recipients'
    ]
  },
  'inboundtexts': {
    'attributes': [
      'createdAt',
      'dateReceived',
      'dateSent',
      'isResolved',
      'message',
      'messageSid',
      'outboundMessageText',
      'phone',
      'preview',
      'processDate',
      'processLog',
      'processType',
      'semaphore.expires',
      'semaphore.id',
      'senderName',
      'updatedAt'
    ],
    'relationships': [
      'location',
      'outboundMessage',
      'practitioner',
      'recipient',
      'recipients'
    ]
  },
  'languages': {
    'attributes': [
      'createdAt',
      'encoding',
      'flag',
      'isEnabled',
      'isSupported',
      'name',
      'nativeName',
      'rtl',
      'svg',
      'translators',
      'updatedAt'
    ],
    'relationships': []
  },
  'locations': {
    'attributes': [
      'addressComponents',
      'amenityUrl',
      'comment',
      'createdAt',
      'daysOpenClose',
      'description',
      'email',
      'facebook',
      'fax',
      'fhirAddress',
      'fhirAddressId',
      'fhirId',
      'geoCoded',
      'geolocation',
      'humanAddress',
      'lastUpdateMethod',
      'lat',
      'lng',
      'locationAmenitiesUrlLink',
      'locationEnabledForMessageSend',
      'locationEnabledForReviewGating',
      'locationSignature',
      'logoUrl',
      'name',
      'nickname',
      'reputationServices',
      'slug',
      'status',
      'telephone',
      'timeZoneName',
      'twitter',
      'updatedAt',
      'webShortUrl',
      'webUrl',
      'weekdaysHours'
    ],
    'relationships': [
      'appointmentTypeCodesEnabledForCancel',
      'appointmentTypeCodesEnabledForReschedule',
      'appointmentTypeCodesEnabledForSchedule',
      'organization',
      'tags'
    ]
  },
  'messageconfigs': {
    'attributes': [
      'createdAt',
      'enableEmail',
      'enableSms',
      'enableVoice',
      'enableVoiceCallTransfers',
      'expirationPeriodHours',
      'maskComment',
      'maximumHighPrioritySimultaneousSends',
      'maximumLowPrioritySimultaneousSends',
      'maximumNormalSimultaneousSends',
      'messagePriority',
      'messagePurpose',
      'name',
      'preferredCommMode',
      'priorityEmail',
      'prioritySms',
      'priorityVoice',
      'requestAppointmentCancellation',
      'requestAppointmentConfirmation',
      'sendAsMms',
      'smsMessageLength',
      'updatedAt'
    ],
    'relationships': []
  },
  'messagetemplates': {
    'attributes': [
      'assemblies',
      'comment',
      'createdAt',
      'emailSubjectLine',
      'expirationPeriodHours',
      'messagePriority',
      'messagePurpose',
      'messageSubtype',
      'name',
      'preferredCommMode',
      'recipientType',
      'smsMessage',
      'smsShortMessage',
      'twimlHasCallTransfer',
      'updatedAt'
    ],
    'relationships': [
      'twimlAnsweringMachine',
      'twimlDigit0',
      'twimlDigit1',
      'twimlDigit2',
      'twimlDigit3',
      'twimlDigit4',
      'twimlDigit5',
      'twimlDigit6',
      'twimlDigit7',
      'twimlDigit8',
      'twimlDigit9',
      'twimlMenu',
      'twimlStart'
    ]
  },
  'organizations': {
    'attributes': [
      'amenityLayout',
      'colorPrimary',
      'colorSecondary',
      'comment',
      'createdAt',
      'description',
      'disableCharities',
      'email',
      'fontBody',
      'fontHeader',
      'iconStyle',
      'launchDate',
      'name',
      'overrideEmrSlotsScheduling',
      'scheduleHolidays',
      'scheduleUnitMinutes',
      'startupDate',
      'telephone',
      'updatedAt',
      'valuePerImpression',
      'valuePerReview',
      'webUrl'
    ],
    'relationships': [
      'amenityTags'
    ]
  },
  'outboundalerts': {
    'attributes': [
      'alertCompletedDate',
      'alertCompletedErrorMessage',
      'alertDataSource',
      'alertRequestedFireDate',
      'alertScheduleType',
      'alertState',
      'alertTemplate',
      'comment',
      'createdAt',
      'name',
      'startTime',
      'stopTime',
      'updatedAt'
    ],
    'relationships': [
      'appointments',
      'baseAlertTemplate',
      'locations',
      'patients',
      'practitioners'
    ]
  },
  'outboundmessages': {
    'attributes': [
      'active',
      'callAppointmentConfirmed',
      'callDeliveredDate',
      'callEnqueDate',
      'callErrorCode',
      'callPhoneNumberSid',
      'callSid',
      'callStatus',
      'callToAnsweringMachine',
      'comment',
      'createdAt',
      'emailAddress',
      'emailDeliveredDate',
      'expirationPeriodHours',
      'fhirAppointmentId',
      'fhirEncounterId',
      'mediaUrl',
      'message',
      'messageHash',
      'patientFhirId',
      'patientName',
      'phoneNumber',
      'postedDate',
      'recipientDoNotDisturb',
      'recipientName',
      'recipientRole',
      'recipientTimeZoneName',
      'replyingUserName',
      'requestAppointmentConfirmation',
      'sendAsMms',
      'simulateKeyActions',
      'smsDeliveredDate',
      'smsEnqueDate',
      'smsErrorCode',
      'smsSid',
      'smsStatus',
      'subject',
      'templateName',
      'transmitCompleted',
      'transmitCompletedComment',
      'transmitCompletedDate',
      'transmitMethod',
      'updatedAt'
    ],
    'relationships': [
      'appointment',
      'patient',
      'recipient',
      'replyingUser'
    ]
  },
  'practitioners': {
    'attributes': [
      'active',
      'badEmailAddresses',
      'badEmailFormat',
      'badPhoneNumber',
      'badPhoneNumbersList',
      'commChannelsFound',
      'comment',
      'configuredBySourceType',
      'createdAt',
      'dayLocationTimes',
      'dayLocations',
      'dayReservedTimes',
      'dayReserveds',
      'disableAppointmentRealTimeUpdates',
      'disableLabUpdates',
      'disableOfficeCommunication',
      'disablePatientRealTimeUpdates',
      'entryMethod',
      'fhirId',
      'fhirName',
      'fhirPreSuffix',
      'fhirTelecom',
      'firstName',
      'fullName',
      'invalidTelecom',
      'invalidTelecomValue',
      'isExternalReviewEnabled',
      'isInternalReviewOnly',
      'lastName',
      'lastReferenced',
      'lastTelecomScanDate',
      'middleName',
      'missingEmail',
      'missingLandlineVoice',
      'missingSms',
      'missingSmsVoice',
      'noTelecomEntries',
      'noValidTelecomEntries',
      'npi',
      'prefix',
      'profileImageUrl',
      'providerType',
      'realTimeUpdates',
      'recipientType',
      'reputationServices',
      'suffix',
      'telecomOptions',
      'timeZoneName',
      'unsubscribeDate',
      'unsubscribeFlag',
      'unsubscribeReason',
      'updatedAt',
      'verifiedByMfa',
      'verifiedByMfaDate',
      'verifiedByMfaMethod',
      'welcomeMessageSent',
      'welcomeMessageSentDate'
    ],
    'relationships': [
      'appointmentTypeCodesEnabledForSchedule',
      'location',
      'tags'
    ]
  },
  'profilelogs': {
    'attributes': [
      'action',
      'appName',
      'authMethod',
      'authMethodUserId',
      'createdAt',
      'createdNewMethodUser',
      'createdNewUser',
      'email',
      'externalId',
      'flowType',
      'phone',
      'preAuthSessionId',
      'profileId',
      'reason',
      'status',
      'tenantId',
      'updatedAt'
    ],
    'relationships': [
      'user'
    ]
  },
  'recipients': {
    'attributes': [
      'active',
      'badEmailAddresses',
      'badEmailFormat',
      'badPhoneNumber',
      'badPhoneNumbersList',
      'bed',
      'birthDate',
      'botContext',
      'cancelledReminderDisabled',
      'cancelledReminderInProgress',
      'cancelledReminderStartDate',
      'cancelledReminderState',
      'cancelledReminderStopDate',
      'commChannelsFound',
      'comment',
      'configuredBySourceType',
      'createdAt',
      'deceasedBoolean',
      'disableAppointmentRealTimeUpdates',
      'disableAppointmentReminders',
      'disableBirthdays',
      'disableLabUpdates',
      'disableMarketingUpdates',
      'disableOfficeCommunication',
      'disablePatientRealTimeUpdates',
      'disablePractitionerCommunication',
      'disablePrePostOpCareInstructions',
      'disableReviewReminders',
      'doNotDisturb',
      'entryMethod',
      'fhirId',
      'fhirName',
      'fhirPreSuffix',
      'fhirTelecom',
      'firstName',
      'floor',
      'fullName',
      'givenName',
      'identifier',
      'ignoreEmrLang',
      'initials',
      'invalidTelecom',
      'invalidTelecomValue',
      'isDeceased',
      'lang',
      'lang3',
      'lang3s',
      'langs',
      'lastCancelledAppointmentFhirId',
      'lastCancelledAppointmentId',
      'lastCancelledAppointmentStartDate',
      'lastCancelledAppointmentType',
      'lastName',
      'lastNoShowAppointmentFhirId',
      'lastNoShowAppointmentId',
      'lastNoShowAppointmentStartDate',
      'lastNoShowAppointmentType',
      'lastReferenced',
      'lastReviewAppointmentFhirId',
      'lastReviewAppointmentId',
      'lastReviewAppointmentStartDate',
      'lastReviewAppointmentType',
      'lastTelecomScanDate',
      'locationFhirId',
      'medicalRecordNumber',
      'middleName',
      'missingEmail',
      'missingLandlineVoice',
      'missingSms',
      'missingSmsVoice',
      'noShowReminderDisabled',
      'noShowReminderInProgress',
      'noShowReminderStartDate',
      'noShowReminderState',
      'noShowReminderStopDate',
      'noTelecomEntries',
      'noValidTelecomEntries',
      'openEmrId',
      'parentType',
      'practitionerFhirId',
      'prefix',
      'profileId',
      'realTimeUpdates',
      'recipientBirthdayMessage',
      'recipientType',
      'reviewReminderDisabled',
      'reviewReminderInProgress',
      'reviewReminderLastCompletedDate',
      'reviewReminderStartDate',
      'reviewReminderState',
      'reviewReminderStopDate',
      'room',
      'status',
      'suffix',
      'telecomOptions',
      'timeZoneName',
      'unit',
      'unsubscribeDate',
      'unsubscribeFlag',
      'unsubscribeReason',
      'updatedAt',
      'verifiedByMfa',
      'verifiedByMfaDate',
      'verifiedByMfaMethod',
      'welcomeMessageSent',
      'welcomeMessageSentDate'
    ],
    'relationships': [
      'lastLocation',
      'parent'
    ]
  },
  'reminders': {
    'attributes': [
      'appointmentTypePackageId',
      'comment',
      'createdAt',
      'fhirAppointmentId',
      'messagePurpose',
      'reminderCompletedDate',
      'reminderFireDateDayOffset',
      'reminderFireDateDeliveryTargetHour',
      'reminderFireDateDeliveryTargetMinute',
      'reminderFireDateHourOffset',
      'reminderFireDateMinuteOffset',
      'reminderFunctionArg',
      'reminderFunctionName',
      'reminderMsgOrFunction',
      'reminderRequestedFireDate',
      'reminderState',
      'semaphore.expires',
      'semaphore.id',
      'timeZoneName',
      'updatedAt'
    ],
    'relationships': [
      'appointment',
      'reminderGuardianTemplate',
      'reminderNonPatientTemplate',
      'reminderPatientTemplate'
    ]
  },
  'remindertemplatelists': {
    'attributes': [
      'comment',
      'createdAt',
      'messagePurpose',
      'name',
      'reminderTemplate',
      'updatedAt'
    ],
    'relationships': []
  },
  'reputationauthorizations': {
    'attributes': [
      'accountId',
      'createdAt',
      'expiresAt',
      'locations',
      'name',
      'organizationId',
      'primaryEmail',
      'refreshToken',
      'scopes',
      'updatedAt',
      'userId'
    ],
    'relationships': [
      'platform'
    ]
  },
  'reputationplatforms': {
    'attributes': [
      'createdAt',
      'logo',
      'name',
      'profileUrlTemplate',
      'referralUrlTemplate',
      'updatedAt'
    ],
    'relationships': []
  },
  'reputationprofiles': {
    'attributes': [
      'createdAt',
      'referralUrl',
      'resource',
      'updatedAt',
      'url'
    ],
    'relationships': [
      'platform'
    ]
  },
  'reputationreportschedules': {
    'attributes': [
      'comment',
      'createdAt',
      'frequency',
      'kind',
      'lastReportedAt',
      'nextScheduledAt',
      'owner',
      'recipients',
      'updatedAt'
    ],
    'relationships': [
      'profile',
      'visualization'
    ]
  },
  'reputationresponsetemplates': {
    'attributes': [
      'body',
      'createdAt',
      'description',
      'organization',
      'ratings',
      'title',
      'updatedAt'
    ],
    'relationships': []
  },
  'reputationreviews': {
    'attributes': [
      'author',
      'body',
      'createdAt',
      'datePublished',
      'itemReviewed',
      'rating',
      'response',
      'updatedAt'
    ],
    'relationships': [
      'practitioner',
      'reputationService'
    ]
  },
  'reputationsubjects': {
    'attributes': [
      'createdAt',
      'kind',
      'name',
      'organization',
      'reference',
      'updatedAt'
    ],
    'relationships': [
      'profiles'
    ]
  },
  'reputationsummaries': {
    'attributes': [
      'address',
      'categories',
      'claimLink',
      'count',
      'createdAt',
      'distribution',
      'isClaimed',
      'name',
      'phone',
      'rating',
      'updatedAt'
    ],
    'relationships': [
      'profile'
    ]
  },
  'reputationvisualizations': {
    'attributes': [
      'charts',
      'createdAt',
      'title',
      'updatedAt',
      'userId'
    ],
    'relationships': []
  },
  'serverlogs': {
    'attributes': [
      'cIp',
      'cPort',
      'createdAt',
      'csBytes',
      'csHost',
      'csMethod',
      'csProtocol',
      'csProtocolVersion',
      'csReferer',
      'csUriQuery',
      'csUriStem',
      'csUserAgent',
      'fleEncryptedFields',
      'fleStatus',
      'scBytes',
      'scContentLen',
      'scContentType',
      'scRangeEnd',
      'scRangeStart',
      'scStatus',
      'sslCipher',
      'sslProtocol',
      'timeTaken',
      'timeToFirstByte',
      'updatedAt',
      'xEdgeDetailedResultType',
      'xEdgeLocation',
      'xEdgeResponseResultType',
      'xEdgeResultType',
      'xForwardedFor',
      'xHostHeader'
    ],
    'relationships': []
  },
  'snippets': {
    'attributes': [
      'comment',
      'createdAt',
      'updatedAt',
      'value'
    ],
    'relationships': []
  },
  'states': {
    'attributes': [
      'createdAt',
      'resource',
      'resourceType',
      'status',
      'triggerDate',
      'triggerEvent',
      'updatedAt'
    ],
    'relationships': [
      'event',
      'flow'
    ]
  },
  'subscriptionchanges': {
    'attributes': [
      'action',
      'clickedLink',
      'comment',
      'createdAt',
      'email',
      'phone',
      'profileApp',
      'smsRequest',
      'updatedAt',
      'voiceRequest'
    ],
    'relationships': []
  },
  'tags': {
    'attributes': [
      'addressComponents',
      'createdAt',
      'geolocation',
      'name',
      'type',
      'updatedAt'
    ],
    'relationships': []
  },
  'texts': {
    'attributes': [
      'createdAt',
      'enHash',
      'lang',
      'locked',
      'name',
      'translator',
      'updatedAt',
      'value'
    ],
    'relationships': []
  },
  'twimls': {
    'attributes': [
      'comment',
      'createdAt',
      'updatedAt',
      'value'
    ],
    'relationships': []
  },
  'unsubscribeds': {
    'attributes': [
      'comment',
      'createdAt',
      'email',
      'phone',
      'updatedAt'
    ],
    'relationships': []
  },
  'userpreferences': {
    'attributes': [
      'createdAt',
      'key',
      'updatedAt',
      'value'
    ],
    'relationships': [
      'user'
    ]
  },
  'users': {
    'attributes': [
      'blocked',
      'createdAt',
      'email',
      'emailVerified',
      'emails',
      'externalIds',
      'lastIp',
      'lastLogin',
      'loginsCount',
      'name',
      'nickname',
      'phoneNumber',
      'phoneNumbers',
      'phoneVerified',
      'pictureUrl',
      'roles',
      'updatedAt'
    ],
    'relationships': []
  }
};

// Fix these up from above generated resourceMap
resourceMap.images.attributes.push( 'url', 'crop', 'rotation', 'tags' );
resourceMap.assets.attributes.push( 'subject', 'body', 'images', 'url', 'crop', 'rotation', 'tags' );
resourceMap.assets.relationships = []; // populate disabled in service
for( const resource in resourceMap ) {
  resourceMap[ resource ].attributes = resourceMap[ resource ].attributes.concat( resourceMap[ resource ].relationships );
}

// http -b :3400/api/v1/spec | jq -rc ' [ .. | .enum? | select(.) | select( length > 1 ) ] | unique | sort_by( . | length ) | .[]'
// The following isn't usable as-is, but does include the property name as a hint to the enum name:
//  http -b :3400/api/v1/spec | jq ' [ .. | .properties? | objects | [ to_entries[] | select( .value.enum?) | .value = .value.enum ] | select( length > 0 ) | .[]| select( .value | length > 1 )  ] | sort  | from_entries' | perl -p -e 's/"/'"'"'/g'
// http -b :3400/api/v1/spec | jq -r ' [ .. | .properties? | objects | [ to_entries[] | select( .value.enum?) | .value = .value.enum ] | select( length > 0 ) | .[]| select( .value | length > 1 )  ] | sort  | from_entries | to_entries[] | .key + ": " + ( .value | tojson ) + ","' | perl -p -e 's/"/'"'"'/g'
export const options = {
  addressType: [ 'postal', 'physical', 'both' ],
  addressUse: [ 'home', 'work', 'temp', 'old', 'billing' ],
  appointmentStatus: [ 'proposed', 'pending', 'booked', 'arrived', 'checked-in', 'fulfilled', 'cancelled', 'noshow', 'entered-in-error', 'waitlist' ], //, 'BAD VALUE ENTERED', 'Any Status OK', 'Unknown' ],
  alertResourceType: [ 'Location', 'Organization', 'Practitioner', 'Recipient', 'Appointment' ],
  alertSource: [ 'Appointment List', 'Patient List' ],
  alertStatus: [ 'active', 'ignore', 'complete' ],
  amenitLayout: [ 'card', 'accordion' ],
  busyStatus: [ 'FREE', 'TENTATIVE', 'BUSY', 'OOF' ],
  contactSystem: [ 'email', 'phone' ], //, 'sms', 'fax', 'pager' ], //, 'url', 'other' ],
  contactUse: [ 'mobile', 'home', 'work' ], //, 'old', 'temp' ],
  entryMethod: [ 'unknown', 'manual', 'fhir import' ],
  fsmStatus: [ 'unknown', 'initialized', 'running', 'completed', 'waiting', 'expired', 'failed' ],
  iconStyle: [ 'black', 'secondary', 'primary', 'default' ],
  locationUpdateMethod: [ 'imported from emr', 'entered manually by human', 'unknown' ],
  messagePriority: [ 'low', 'normal', 'high' ],
  messagePurpose: [ 'Unknown', 'Reminder', 'Appointment Update', 'Patient Update', 'Appointment Rescheduled', 'Welcome Message', 'Appointment Reminder', 'Thank You From Staff', 'Thank You From Doctor Visit', 'Thank You From Doctor Post-Op', 'Pre-op Reminder', 'Post-op Reminder', 'At-Op Reminder', 'Clinical Update', 'Pathology Welcome Message', 'Pathology Update', 'Pathology Completed', 'Operational Update', 'Amenity Update', 'Birthday Update', 'Appointment Reminder Include Confirm Request', 'Alert', 'Performance Review Public', 'Performance Review Internal', 'No-Show to Appointment Reminder', 'Cancelled Appointment Reminder', 'Test Message' ],
  nameUse: [ 'usual', 'official', 'temp', 'nickname', 'anonymous', 'old', 'maiden' ],
  locationStatus: [ 'active', 'suspended', 'inactive' ],
  timeZoneName: [],
  transmitMethod: [ 'unknown', 'ignore', 'sms', 'email', 'voice', 'delete entry' ],
  reminderState: [ 'unknown', 'waiting', 'running', 'completed', 'failed', 'aborted' ],
  reminderType: [ 'unknown', 'pre-appointment', 'at-appointment', 'post-appointment' ],
  reportIntervalType: [ 'Daily', 'Weekly', 'Monthly', 'Yearly' ],
  reportType: [ 'Appointments Billing Audit', 'Unfinished Appointments', 'All Reports' ],
  alertScheduleType: [ 'Immediate', 'Scheduled' ],
  alertState: [ 'Unknown', 'Waiting', 'Processing', 'Completed', 'Failed', 'Aborted', 'New' ],
  alertExceptionsSortField: [ 'Location', 'Patient', 'Practitioner', 'Appointment Date' ],
  alertExceptionsSortOrder: [ 'Ascending', 'Descending' ],
  weekday: [],
  cancelledReminderState: [ 'unknown', 'waiting', 'running', 'completed with no new appointment', 'aborted found future appointment', 'aborted do not disturb', 'skipped', 'expired', 'deceased' ],
  noShowReminderState: [ 'unknown', 'waiting', 'running', 'completed with no new appointment', 'aborted found future appointment', 'aborted do not disturb', 'skipped', 'expired', 'deceased' ],
  recordState: [ 'New', 'Waiting', 'Consensus Delay', 'Updated', 'Completed', 'Expired', 'Failed', 'Unknown' ],
  reminderSendingPhase: [ 'Unknown', 'Pre-Appointment', 'At-Appointment', 'Post-Appointment', 'Cancelled', 'No-Show to Appointment', 'entered-in-error', 'waitlist' ],
  reviewReminderState: [ 'unknown', 'waiting', 'running', 'completed', 'aborted do not disturb', 'skipped', 'expired', 'deceased' ],
  preferredCommMode: [ 'none', 'sms' ],
  reminderMsgOrFunction: [ 'message', 'function' ],
  reminderFunctionName: [ 'performance review timed out - no response received', 'no show reminder sequence ended', 'cancelled reminder sequence ended' ],
  eventCategories: [ 'Red category', 'Yellow category', 'Green category', 'Blue category' ],
  smsMessageLength: [ 'Short-SMS', 'Normal Length-SMS', 'Optimized SMS-MMS', 'MMS with Images w. optimized send size for no image' ],
  recipientType: [ 'Patient', 'Non-Patient', 'Practitioner' ], // 'All', 'unknown', 'Family Member', 'Extended Family Member', 'Neighbor', 'Friend', 'Guardian', 'Caregiver', 'Transportation', 'Skilled Nursing', 'Physician', 'Attorney', 'Other' ],
  font: [ 'montserrat', 'open-sans', 'roboto', 'lato', 'merriweather', 'noto-sans', 'noto-serif', 'nunito-sans', 'oswald', 'raleway', 'source-sans-pro' ],
  configScopes: [ 'root', 'aicDev', 'aicSupport', 'aicSales', 'tenantAdmin', 'tenantUser', 'reseller' ],
  feedbackMessageSeverity: [ 'slightly', 'notAtAll' ],
  actionLogAction: [ 'confirm', 'cancel', 'reschedule', 'subscribe', 'unsubscribe', 'viewProfile', 'editProfile', 'addRecipient', 'editRecipient', 'calendar', 'feedbackMessage' ],
  actionLogCategory: [ 'profile', 'appointment', 'feedback' ],
  htmlComponentType: [ 'section', 'presentation' ],
  processType: [ 'undeliverable', 'temporaryUndeliverable', 'messageReply', 'spam', 'newEmail', 'unknown' ],
  patientStatus: [ 'Current', 'New', 'Discharged' ],
  translators: [ 'preset', 'manual', 'aws', 'google', 'googleV2' ],
  stateStatus: [ 'waiting', 'ready', 'done', 'aborted' ],
  eventStatus: [ 'pending', 'done' ],
};

const makeChoiceDisplayName = ( s: string ): string => {
  const cleanupMap: Record<string, string> = {
    'noshow': 'no show',
    'temp': 'temporary',
  };
  return titleize( cleanupMap[ s ] || s );
}
export const choices: Record<keyof typeof options, { id: string, name: string }[]> = _( options ).mapValues( a => a.map( s => ( { id: s, name: makeChoiceDisplayName( s ) } ) ) ).value();
choices.timeZoneName = _( getTimeZones()
  .filter( tz => tz.countryCode == 'US' )  // start with USA
  .flatMap( tz => tz.group.map( tg => ( { id: tg, name: tg } ) ) ) )
  .sortBy( 'name' )
  .value();
choices.weekday = 'Sunday Monday Tuesday Wednesday Thursday Friday Saturday'.split( / +/ ).map( ( name, idx ) => ( { id: idx.toString(), name } ) );
choices.appointmentStatus = choices.appointmentStatus.map( c => c.id == 'booked' ? { ...c, name: 'Confirmed' } : c ); // ModMed
choices.feedbackMessageSeverity = [ { id: 'slightly', name: 'Slightly' }, { id: 'notAtAll', name: 'Not at all' } ];
export const choicesYesNo = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
];

export const delay = async ( ms: number ): Promise<void> => new Promise( resolve => setTimeout( resolve, ms ) );

export type HttpClientResponse = Awaited<ReturnType<typeof fetchUtils.fetchJson>>;

export const httpClient = async ( url: RequestInfo, options: fetchUtils.Options = {} ): Promise<HttpClientResponse> => {

  /// // @ts-ignore: 2339
  /// const token = await window.authProvider.getToken(); // TODO FIX THIS GLOBAL
  /// if( !token ) {
  ///   // @ts-ignore: 2339
  ///   await window.authProvider.checkError( { status: 401 } );
  /// }
  /// if( !options.headers ) {
  ///   options.headers = new Headers( { accept } );
  /// }
  /// options.user = {
  ///   authenticated: true,
  ///   token: `Bearer ${ token }`,
  /// };


  /// try {
  return await fetchUtils.fetchJson( url, options );
  /// } catch( e ) {
  ///   console.log( e );
  ///   // @ts-ignore:2339
  ///   const { body, status } = e;
  ///   //    // @ts-ignore:2322
  ///   return {
  ///     status,
  ///     body: JSON.stringify( body ),
  ///     json: body,
  ///     headers: new Headers(),
  ///   };
  /// }
};


export type FileWithPreview = ( File | Blob ) & { preview: string };
export interface FileContainer {
  rawFile?: FileWithPreview;
  encodedFileField?: string;
  crop?: Area;
  rotation?: number;
  preview?: string;
}

const convertFileParams = async<T extends UpdateParams | CreateParams>( params: T ): Promise<T> => {
  const newData: { [ key: string ]: unknown } = {};

  for( const fieldName of Object.keys( params.data ) ) {
    if( Array.isArray( params.data[ fieldName ] ) ) {
      newData[ fieldName ] = params.data[ fieldName ].slice();
      for( let idx = 0; idx < params.data[ fieldName ].length; idx++ ) {
        const element = params.data[ fieldName ][ idx ];
        if( !( typeof element == 'object' && 'rawFile' in element ) ) {
          continue;
        }
        const base64 = await convertFileContainerToBase64( element );
        if( base64 ) {
          _.set( newData, [ fieldName, idx ], base64 );
          _.set( newData, [ 'crop', idx ], element.crop || defaultCrop );
          _.set( newData, [ 'rotation', idx ], element.rotation || defaultRotation );
        }
      }
      continue;
    }
    const element = params.data[ fieldName ];
    if( !element || !( typeof element == 'object' && 'rawFile' in element ) ) {
      continue;
    }
    const base64 = await convertFileContainerToBase64( element );
    if( base64 ) {
      newData[ fieldName ] = base64;
      if( element.crop ) newData.crop = element.crop;
      if( element.rotation ) newData.rotation = element.rotation;
    }
  }
  return { ...params, data: { ...params.data, ...newData } };
}

type FileReaderResult = FileReader[ 'result' ];
export const convertFileContainerToBase64 = ( file: FileContainer ): Promise<FileReaderResult> =>
  new Promise( ( resolve, reject ) => {
    if( !file.rawFile ) {
      return null;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const ret = ( reader.result instanceof ArrayBuffer ) ? reader.result.toString() : reader.result;
      resolve( ret )
    };
    reader.onerror = reject;

    reader.readAsDataURL( file.rawFile );
  } );

export const convertFileToBase64 = ( file: File | Blob ): Promise<FileReaderResult> =>
  new Promise( ( resolve, reject ) => {
    if( !file ) {
      return null;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const ret = ( reader.result instanceof ArrayBuffer ) ? reader.result.toString() : reader.result;
      resolve( ret )
    };
    reader.onerror = reject;

    reader.readAsDataURL( file );
  } );

const dataProvider = jsonapiDataProvider( resourceMap, apiUrl, queryClient, httpClient ); // jsonapiDataProviderWithTree( resourceMap, apiUrl, httpClient );

const {
  create: originalCreate,
  update: originalUpdate,
} = dataProvider;

const wrappedCreate: typeof dataProvider.create = async ( resource, params ) => {
  return await originalCreate( resource, await convertFileParams( params ) );
}

const wrappedUpdate: typeof dataProvider.update = async ( resource, params ) => {
  return await originalUpdate( resource, await convertFileParams( params ) );
}

const myDataProvider: DataProvider = {
  ...dataProvider,
  create: wrappedCreate as typeof dataProvider.create,
  update: wrappedUpdate as typeof dataProvider.update,
  fetchJson: httpClient,
  apiUrl,
};

export { myDataProvider as dataProvider };
export default myDataProvider;
