import { Grid, InputAdornment } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { ArrayField, AutocompleteArrayInput, BooleanInput, Datagrid, DateField, Edit, EditProps, FunctionField, List, ListProps, NumberField, NumberInput, PasswordInput, RaRecord, SaveButton, SaveHandler, SelectField, SelectInput, Show, ShowProps, SimpleFormProps, TextField, TextInput, TitleProps, Toolbar, useRecordContext, useRedirect, useResourceContext, useUpdate } from 'react-admin';
import { BooleanColorField } from './BooleanColorField';
import { choices } from './DataProvider';
import { FormWithLocation, SimpleShowLayoutWithLocation, useListAppLocation } from './MyBreadcrumb';
import { ShowActionsWithReset } from './ShowActionsWithReset';
import TextArrayField from './TextArrayField';

export { default as AnomalyReportDefinitionIcon } from '@mui/icons-material/Report';


const AnomalyReportDefinitionListTitle: FC<TitleProps> = props => {
  return <span>ReminderTemplateList { props.record?.name ? `"${ props.record.name }"` : '' }</span>;
};
export const AnomalyReportDefinitionList: FC<Omit<ListProps, 'children'>> = props => {
  useListAppLocation( 'anomalies' );
  return (
    <List
      { ...props }
      exporter={ false }
      perPage={ 25 }
      sort={ { field: 'name', order: 'ASC' } }
    >
      <Datagrid rowClick="edit"
        // expand={ <AnomalyReportDefinitionExpandEdit /> }
        bulkActionButtons={ false }
      >
        <BooleanColorField label="Enabled" source='enable' />
        <TextField source="name" />

        <TextField source="reportIntervalType" label='Interval' />
        <TextField source="reportType" label='Type' />

        <TextField source="comment" />

      </Datagrid>
    </List>
  );
}

export const AnomalyReportDefinitionShow: FC<ShowProps> = props => {
  const weekdayMap = useMemo<Record<number, string>>( () => {
    return Object.fromEntries( choices.weekday.map( ( { id, name } ) => [ parseInt( id ), name ] ) );
  }, [ choices.weekday ] );

  const getWeekdayName = useCallback( ( i?: number ): string | null => {
    return i !== undefined && weekdayMap[ i ] || null;
  }, [ weekdayMap ] );

  return (
    <Show
      actions={ <ShowActionsWithReset /> }
      title={ <AnomalyReportDefinitionListTitle /> }
      { ...props }>
      <Grid
        container
        m={ 1 }
        justifyContent='flex-start'
        columnSpacing={ 4 }
        rowSpacing={ 2 }
        columns={ 10 }
      >

        <SimpleShowLayoutWithLocation basePath='anomalies'>


          <TextField source="name" fullWidth />

          <TextField source="comment" fullWidth />


          <SelectField source="reportIntervalType" label='Interval' choices={ choices.reportIntervalType } />
          <SelectField source="reportType" label='Type' choices={ choices.reportType } />
          <TextArrayField source='statusArray' />
          <DateField
            source='apptStartTimeRange'
            label='Appointment range start'
            emptyText='None'
            showTime
            sx={ {
              width: '100%',
            } }
          />
          <DateField
            source='apptStopTimeRange'
            label='Appointment range end'
            emptyText='None'
            showTime
            sx={ {
              width: '100%',
            } }
          />
          <ArrayField source='alertExceptionsSort' >
            <Datagrid bulkActionButtons={ false } >
              <TextField source="field" />
              <TextField source="order" />
            </Datagrid>
          </ArrayField>

          <DateField
            source='lastReportedFiredDate'
            emptyText='None'
            showTime
            sx={ {
              width: '100%',
            } }
          />
          <DateField
            source='nextFiredDate'
            emptyText='None'
            showTime
            sx={ {
              width: '100%',
            } }
          />
          <NumberField source='nextFireDateWindowHours' />
          <FunctionField
            source='weekdaysToRunReport'
            render={ ( r: RaRecord ) => {
              if( !r?.weekdaysToRunReport ) return null;
              const record = {
                weekdays: r.weekdaysToRunReport.map( ( d: number ) => getWeekdayName( d ) ),
              };
              return (
                <TextArrayField source='weekdays' record={ record } />
              )
            } }
          />

          <NumberField source='timeHoursToRunReport' />
          <NumberField source='timeMinutesToRunReport' />
          <NumberField source='countPreviousDaysToReportOn' />
          <NumberField source='estimatedValueOfAppointment' />
          <BooleanColorField source='addCsvHeader' />
          <BooleanColorField source='displayTxtFile' />
          <BooleanColorField source='outputTxtFile' />
          <BooleanColorField source='outputCsvFile' />
          <BooleanColorField source='outputJsonFile' />
          <BooleanColorField source='outputJsonDb' />
          <BooleanColorField source='zipFiles' />
          <FunctionField
            source='zipPassword'
            render={ ( r: RaRecord ) => {
              const record = { hasPassword: !!r.zipPassword };
              return (
                <BooleanColorField source='hasPassword' label='Has zip password' record={ record } />
              )
            } }
          />
        </SimpleShowLayoutWithLocation>
      </Grid>
    </Show>
  );
}

const AnomalyReportDefinitionForm: FC<Omit<SimpleFormProps, 'children'>> = () => {
  const [ update ] = useUpdate();
  const resource = useResourceContext();
  const record = useRecordContext();
  const redirect = useRedirect();
  const onSubmit: SaveHandler<RaRecord<string>> = ( data ) => {
    // console.log( data );
    return update( resource, {
      id: record?.id,
      data,
      previousData: record,
    }, {
      onSuccess: async ( data ) => {
        redirect( 'show', resource, data.id );
      },
      onError: async ( error ) => {
        console.log( error );
      },
      onSettled: async ( data ) => {
        console.log( 'settled', data );
      },
    } );
  }

  // if( !record ) return null;

  return (
    <FormWithLocation
      basePath='anomalies'
      shouldUnregister
      sanitizeEmptyValues
      warnWhenUnsavedChanges
      onSubmit={ onSubmit }
    >
      <Grid
        container
        m={ 1 }
        justifyContent='flex-start'
        columnSpacing={ 4 }
        rowSpacing={ 2 }
        columns={ 10 }
      >

        <Grid item xs={ 5 } >
          <TextInput
            source="name"
            required
            sx={ {
              width: '100%',
            } }
          />
        </Grid>

        <Grid item xs={ 4 } >
          <TextInput
            source="comment"
            required
            fullWidth={ false }
            sx={ {
              width: '100%',
            } }
          />
        </Grid>

        <Grid item xs={ 2 } >
          <BooleanInput label="Enabled" source='enable' />
        </Grid>

        <Grid item xs={ 3 } >
          <SelectInput
            source="reportIntervalType"
            label='Interval'
            choices={ choices.reportIntervalType }
            required
            sx={ {
              width: '100%',
            } }
          />
        </Grid>

        <Grid item xs={ 3 } >
          <SelectInput
            source="reportType"
            label='Type'
            choices={ choices.reportType }
            required
            sx={ {
              width: '100%',
            } }
          />
        </Grid>

        { /* // appears to be a v5 bug w ArrayInput/SimpleFormIterator
        <ArrayInput source='alertExceptionsSort'  >
          <SimpleFormIterator inline >
            <SelectInput source="field" choices={ choices.alertExceptionsSortField } validate={ required() } helperText={ false } translateChoice={ false } />
            <SelectInput source="order" choices={ choices.alertExceptionsSortOrder } validate={ required() } helperText={ false } translateChoice={ false } />
          </SimpleFormIterator>
        </ArrayInput>
        */ }
        <Grid item xs={ 3 } >
          <AutocompleteArrayInput
            source='weekdaysToRunReport'
            choices={ choices.weekday }
          />

        </Grid>

        <Grid item xs={ 2 } >
          <NumberInput source='timeHoursToRunReport' />
        </Grid>

        <Grid item xs={ 2 } >
          <NumberInput source='timeMinutesToRunReport' />
        </Grid>

        <Grid item xs={ 2 } >
          <NumberInput source='countPreviousDaysToReportOn' />
        </Grid>

        <Grid item xs={ 10 } >
          <NumberInput
            source='estimatedValueOfAppointment'
            fullWidth={ false }
            InputProps={ {
              startAdornment: (
                <InputAdornment position="start">
                  $
                </InputAdornment>
              ),
            } }
          />
        </Grid>

        <Grid item xs={ 2 } >
          <BooleanInput source='addCsvHeader' />
        </Grid>

        <Grid item xs={ 2 } >
          <BooleanInput source='displayTxtFile' />
        </Grid>

        <Grid item xs={ 2 } >
          <BooleanInput source='outputTxtFile' />
        </Grid>

        <Grid item xs={ 4 } >
          <BooleanInput source='outputCsvFile' />
        </Grid>

        <Grid item xs={ 2 } >
          <BooleanInput source='outputJsonFile' />
        </Grid>

        <Grid item xs={ 8 } >
          <BooleanInput source='outputJsonDb' />
        </Grid>

        <Grid item xs={ 2 } >
          <BooleanInput source='zipFiles' />
        </Grid>

        <Grid item xs={ 4 } >
          <PasswordInput source='zipPassword' fullWidth autoComplete='new-password' />
        </Grid>
      </Grid>
      <Toolbar>
        <SaveButton />
      </Toolbar>
    </FormWithLocation >
  );
}

export const AnomalyReportDefinitionExpandEdit: FC<EditProps> = props => (
  <Edit
    actions={ false }
    title={ '&nbsp;' }
    redirect='list'
    { ...props } >
    <AnomalyReportDefinitionForm />
  </Edit>
);

export const AnomalyReportDefinitionEdit: FC<EditProps> = props => (
  <Edit
    // actions={ <EditActions breadcrumb={ <MyBreadcrumb variant="actions" /> } /> }
    redirect='list'
    { ...props }
  >
    <AnomalyReportDefinitionForm />
  </Edit>
);
// export const AnomalyReportDefinitionCreate: FC<CreateProps> = props => <Create { ...props } ><AnomalyReportDefinitionForm /></Create>;

