import { Dashboard as DashboardIcon, Email, Person, Settings as SettingsIcon } from '@mui/icons-material';
import { IconMenu } from '@react-admin/ra-navigation';
import { IfCanAccess } from '@react-admin/ra-rbac';
import { capitalize, humanize, pluralize, singularize } from 'inflection';
import omit from 'lodash/omit';
import { FC, useMemo } from 'react';
import { MenuProps, ResourceComponentInjectedProps, ResourceProps, Translate, useSidebarState } from 'react-admin';
import { AnomalyReportIcon } from './AnomalyReports';
import { AppointmentIcon } from './Appointments';
import { OrganizationIcon, useEmr, useHasNoEmr } from './Organizations';
import { OutboundAlertIcon } from './OutboundAlerts';
import { PatientIcon } from './Patients';
import { ReputationSubjectReviewIcon } from './ReputationSubjectReviews';
import { ReputationVisualizationIcon } from './ReputationVisualizations';

type MenuResource = Omit<ResourceProps, 'options'> & { options?: { label?: string, hide?: boolean, isAdmin?: boolean; } } & ResourceComponentInjectedProps;

export const MyMenu: FC<MenuProps> = ( props ) => {
  const hasNoEmr = useHasNoEmr();
  const emr = useEmr();
  const [ open ] = useSidebarState();
  const { hostname } = window.location;

  // const resourceDefinitions = useResourceDefinitions();
  // const { adminResources, resources } = useMemo( (): { adminResources: Array<MenuResource>, resources: Array<MenuResource> } => {
  //   const allResources = Object.values( resourceDefinitions )
  //   const visible = allResources.filter( r => r.hasList && !r.options?.hide );
  //   const adminResources = visible.filter( r => r.options?.isAdmin );
  //   const resources = visible.filter( r => !r.options?.isAdmin );
  //   return { adminResources, resources };
  // }, [ resourceDefinitions ] );

  const showContactEditorOverride = useMemo( () => !!hostname.match( /(localhost|admin\.i18n\.)/ ), [ hostname ] );

  return (
    <IconMenu variant="categories" { ...omit( props, 'hasDashboard' ) }
      sx={ {
        '& .RaMenuItemCategory-link': {
          width: open ? '83px' : undefined,
          '& .MuiListItemText-primary': {
            overflowWrap: 'normal',
            wordBreak: 'normal',
            textAlign: 'center',
            whiteSpace: 'normal',
          },
        },
      } }
    >
      <IfCanAccess resource='locations' action='read'>
        <IconMenu.Item key="dashboard" name="dashboard" to="/" label="Dashboard" icon={ <DashboardIcon /> } />
      </IfCanAccess>

      { ( !hasNoEmr || showContactEditorOverride ) &&
        <IfCanAccess resource='recipients' action='read'>
          <IconMenu.Item key="contactEditor" name="contactEditor" to="/contacteditor" label="Contact Editor" icon={ <Person /> } />
        </IfCanAccess>
      }

      <IfCanAccess resource='system' action='manage'>
        <IconMenu.Item
          label="Admin"
          name='admin'
          icon={ <SettingsIcon /> }
          to='/admin'
        />
      </IfCanAccess>

      { !hasNoEmr &&
        <IfCanAccess resource='outboundalerts' action='read'>
          <IconMenu.Item
            label="Broadcasts"
            name='broadcasts'
            icon={ <OutboundAlertIcon /> }
            to='/broadcasts'
          />
        </IfCanAccess>
      }
      { !hasNoEmr &&
        <IfCanAccess resource='anomalyreports' action='read'>
          <IconMenu.Item
            label="Anomalies"
            name='anomalies'
            icon={ <AnomalyReportIcon /> }
            to='/anomalies'
          />
        </IfCanAccess>
      }
      {
        /* resources.filter( r => r.name == 'appointments' ).map( resource => (
           <IconMenu.Item
           key={ resource.name }
           name={ resource.name }
           to={ `/${ resource.name }` }
           label={ translatedResourceName( resource, translate ) }
           icon={ resource.icon ? <resource.icon /> : <DefaultIcon /> }
           />
           ) )
         */
      }

      { !hasNoEmr && ![ 'PointClickCare' ].includes( emr ) &&
        <IfCanAccess resource='appointments' action='read'>
          <IconMenu.Item
            label="Appointment"
            name='appointment'
            icon={ <AppointmentIcon /> }
            to='/appointment'
          />
        </IfCanAccess>
      }
      { !hasNoEmr && [ 'PointClickCare' ].includes( emr ) &&
        <IfCanAccess resource='recipients' action='read'>
          <IconMenu.Item
            label="Patient"
            name='patient'
            icon={ <PatientIcon /> }
            to='/patient/patients'
          />
        </IfCanAccess>
      }
      { !hasNoEmr &&
        <IfCanAccess resource='recipients' action='read'>
          <IconMenu.Item
            label="Messaging"
            name='messaging'
            icon={ <Email /> }
            to='/messaging'
          />
        </IfCanAccess>
      }
      <IfCanAccess resource='locations' action='read'>
        <IconMenu.Item
          label="Organization"
          name='organization'
          icon={ <OrganizationIcon /> }
          to='/organization'
        />
      </IfCanAccess>

      <IfCanAccess resource='reputationsubjectreviews' action='read'>
        <IconMenu.Item
          label="Reviews"
          name='reviews'
          icon={ <ReputationSubjectReviewIcon /> }
          to='/reviews'
        />
      </IfCanAccess>

      <IfCanAccess resource='reputationvisualizations' action='read'>
        <IconMenu.Item
          label="Reputation"
          name='reputation'
          icon={ <ReputationVisualizationIcon /> }
          to='/reputation'
        />
      </IfCanAccess>


      { /*
             resources
             .map( resource => (
             <IconMenu.Item
             key={ resource.name }
             name={ resource.name }
             to={ `/${ resource.name }` }
             label={ translatedResourceName( resource, translate ) }
             icon={ resource.icon ? <resource.icon /> : <DefaultIcon /> }
             />
             ) )
           */ }

    </IconMenu >
  );
};


export const translatedResourceName = ( resource: MenuResource, translate: Translate ): string => {
  return capitalize( translate( `resources.${ singularize( resource.name ) }.name`, {
    smart_count: 2,
    _: humanize( pluralize( resource.options && resource.options.label || resource.name ) ),
  } ) );

  //  translate( `resources.${ resource.name }.name`, {
  //    smart_count: 2,
  //    _:
  //      resource.options && resource.options.label
  //        ? translate( resource.options.label, {
  //          smart_count: 2,
  //          _: resource.options.label,
  //        } )
  //      :
  //      inflection.humanize( inflection.pluralize( resource.name ) ),
  //  } );
}
