import { Typography } from '@mui/material';
import { get } from 'lodash';
import { FC } from 'react';
import { Create, CreateProps, Datagrid, DateField, Edit, EditProps, FunctionField, List, ListProps, RaRecord, ReferenceField, ReferenceInput, SelectInput, Show, ShowProps, SimpleFormProps, TextField, TextInput } from 'react-admin';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import { AdminShowLayout, AdminSimpleForm, useAdminListAppLocation } from './Admin';
import { TimestampsField } from './TimestampsField';
import { Childless } from './types';

export { default as FormSubmissionIcon } from '@mui/icons-material/NoteAlt';

// const FormSubmissionFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
//   <Filter { ...props }>
//     <DateInput label="Date" source="createdAt" alwaysOn />
//   </Filter>
// );

export const FormSubmissionList: FC<Childless<ListProps>> = props => {
  useAdminListAppLocation();

  // const onClickRecipient = ( recipient: RaRecord ) => () => {
  //   redirect( '/contacteditor', undefined, undefined, undefined, { recipient } )
  // };
  //  
  // const filterDefaultValues = {
  //   //transmitMethod: 'sms'
  // };

  return (
    <List { ...props }
      // filterDefaultValues={ filterDefaultValues }
      // filters={ <FormSubmissionFilter /> }
      exporter={ false }
      sort={ { field: 'createdAt', order: 'DESC' } }
    >
      <Datagrid
        rowClick='show'
        // expand={ <HtmlEmailTitleExpandEdit /> }
        bulkActionButtons={ false }
      >

        <TimestampsField label="Updated" source='updatedAt' />
        <TextField source='status' />
        <ReferenceField source='recipient' reference='recipients' >
          <TextField source="fullName" />
        </ReferenceField>
        <ReferenceField source='form' reference='formdefinitions' >
          <FunctionField
            label='Title'
            source='json'
            render={ ( r: RaRecord ) => {
              try {
                const form = JSON.parse( r.json );
                return get( form, 'title', get( form, 'pages.0.name', 'Untitled' ) );
              } catch( e ) {
                return null;
              }
            } }
          />
        </ReferenceField>

        <DateField label="Signed" source='signedAt' showTime />
        <DateField label="Processed" source='processedAt' showTime />

        <ReferenceField source='appointment' reference='appointments' >
          <DateField source="startTime" showTime />
        </ReferenceField>
        <ReferenceField source='practitioner' reference='practitioners' >
          <TextField source="fullName" />
        </ReferenceField>
        <ReferenceField source='location' reference='locations' >
          <TextField source="name" />
        </ReferenceField>



      </Datagrid>
    </List>
  );
}

export const FormSubmissionShow: FC<ShowProps> = () => {

  return (
    <Show >
      <AdminShowLayout>

        <TextField source='id' />
        <TextField source='status' />
        <ReferenceField source='form' reference='formdefinitions' >
          <FunctionField
            label='Title'
            source='json'
            render={ ( r: RaRecord ) => {
              try {
                const form = JSON.parse( r.json );
                return get( form, 'title', get( form, 'pages.0.name', 'Untitled' ) );
              } catch( e ) {
                return null;
              }
            } }
          />
        </ReferenceField>
        <ReferenceField source='recipient' reference='recipients' >
          <TextField source="fullName" />
        </ReferenceField>
        <ReferenceField source='appointment' reference='appointments' >
          <DateField source="startTime" showTime />
        </ReferenceField>
        <ReferenceField source='practitioner' reference='practitioners' >
          <TextField source="fullName" />
        </ReferenceField>
        <ReferenceField source='location' reference='locations' >
          <TextField source="name" />
        </ReferenceField>

        <TimestampsField label="Created" source='createdAt' />
        <TimestampsField label="Updated" source='updatedAt' />
        <DateField label="Signed" source='signedAt' showTime />
        <DateField label="Processed" source='processedAt' showTime />

        <TextField source='submission' />

        <FunctionField
          label='Submission'
          source='json'
          render={ ( r: RaRecord ) => {
            try {
              const submission = JSON.parse( r.submission );
              return <Typography component='pre'>{ submission }</Typography>
            } catch( e ) {
              return null;
            }
          } }
        />

      </AdminShowLayout>
    </Show>
  );
}






export const FormSubmissionForm: FC<Childless<SimpleFormProps>> = props => (
  <AdminSimpleForm sanitizeEmptyValues { ...props } >


    <ReferenceInput source='form' reference='formdefinitions'>
      <SelectInput label="Form" optionText="id" />
    </ReferenceInput>

    <ReferenceInput source='recipient' reference='recipients' >
      <SelectInput optionText="fullName" />
    </ReferenceInput>

    <TextInput source="submission" required />


  </AdminSimpleForm >
);


export const FormSubmissionCreate: FC<CreateProps> = props => ( <Create redirect='show' { ...props } ><FormSubmissionForm /></Create> );
export const FormSubmissionEdit: FC<EditProps> = props => ( <Edit redirect='list'  { ...props } mutationMode='pessimistic' ><FormSubmissionForm /></Edit> );
