import { DragIndicator } from '@mui/icons-material';
import { Box, Divider, Grid, InputProps, List as MuiList, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useDefineAppLocation } from '@react-admin/ra-navigation';
import { transform as inflect } from 'inflection';
import { sortBy } from 'lodash';
import { FC, useCallback, useEffect } from 'react';
import { BooleanInput, Edit, EditProps, Form, NumberInput, NumberInputProps, SelectInput, SimpleFormProps, TextInput, useNotify, useRecordContext, useRedirect } from 'react-admin';
import { Draggable } from 'react-drag-reorder';
import { choices } from './DataProvider';
import { CustomToolbar } from './ReminderTemplateLists';

export { default as MessageConfigIcon } from '@mui/icons-material/Code';

export const SectionHeader: FC<{ sectionName: string }> = ( { sectionName } ) => (
  <Grid item xs={ 12 } >
    <Typography color='primary' sx={ { textTransform: 'uppercase', paddingTop: '3rem' } }>  { sectionName } </Typography>
    <Divider />
  </Grid>
);

// type FieldValues = Partial<RaRecord>;

const CostModelComponents: FC = () => {
  // const simultaneousSendsParseFormat = useMemo( () => ( {
  //   format: ( v: number ) => !v ? '' : String( v ),
  //   parse: ( v: string ) => !v.trim() ? 0 : parseInt( v ),
  // } ), [] );

  const labelize = ( s: string ): string => inflect( s, [ 'underscore', 'humanize' ] );

  const validatePriorities = ( value: number, values: Record<string, number | unknown>, props: NumberInputProps ): string | undefined => {
    const { source } = props;
    const keys = [ 'priorityEmail', 'prioritySms', 'priorityVoice' ];
    for( const key of keys ) {
      if( key == source ) continue;
      if( values[ key ] === value ) {
        return `Cannot have the same value as "${ labelize( key ) }".`
      }
    }
  }


  return (
    <>
      <Grid item container spacing={ 2 }>
        <Grid item xs={ 3 }>
          <NumberInput
            source='maximumHighPrioritySimultaneousSends'
            min={ 0 } max={ 4 }
            label='High priority max simultaneous sends'
            helperText='If non-zero, can reduce the simultaneous sends allowed by the global mask'
          // { ...simultaneousSendsParseFormat }
          />
        </Grid>
        <Grid item xs={ 3 }>
          <NumberInput
            source='maximumNormalSimultaneousSends'
            label='Normal priority max simultaneous sends'
            helperText='If non-zero, can reduce the simultaneous sends allowed by the global mask'
            min={ 0 } max={ 4 }
          // { ...simultaneousSendsParseFormat }
          />
        </Grid>
        <Grid item xs={ 3 }>
          <NumberInput
            source='maximumLowPrioritySimultaneousSends'
            label='Low priority max simultaneous sends'
            helperText='If non-zero, can reduce the simultaneous sends allowed by the global mask'
            min={ 0 } max={ 4 }
          // { ...simultaneousSendsParseFormat }
          />
        </Grid>
      </Grid>
      <Grid item container spacing={ 2 }>
        <Grid item xs={ 3 }>
          <SelectInput label='Priority' source='messagePriority' choices={ choices.messagePriority } required fullWidth />
        </Grid>
        <Grid item xs={ 3 }>
          <SelectInput label='Preference' source="preferredCommMode" choices={ choices.preferredCommMode } required fullWidth />
        </Grid>


        {/*
          <Grid item xs={ 6 } >
          <ArrayInput source='priorities'  >
          <SimpleFormIterator disableClear disableAdd disableRemove
          getItemLabel={ index => `Priority ${ index + 1 }` }
          >
          <ChipField source='name' />
          </SimpleFormIterator>
          </ArrayInput>
          </Grid>
          <ChannelPrioritesInput />
        */ }


        <Grid item xs={ 2 }>
          <NumberInput label='Email priority' source='priorityEmail' min={ 1 } max={ 4 } required validate={ validatePriorities } />
        </Grid>
        <Grid item xs={ 2 }>
          <NumberInput label='SMS priority' source='prioritySms' min={ 1 } max={ 4 } required validate={ validatePriorities } />
        </Grid>
        <Grid item xs={ 2 }>
          <NumberInput label='Voice priority' source='priorityVoice' min={ 1 } max={ 4 } required validate={ validatePriorities } />
        </Grid>
      </Grid>
    </>
  );
}


const MessageConfigForm: FC<Omit<SimpleFormProps, 'children'>> = props => {
  const record = useRecordContext();
  useDefineAppLocation( 'admin.messagetemplates.edit.config', { record } );

  return (
    <Form
      record={ record }
      sanitizeEmptyValues
      warnWhenUnsavedChanges
      { ...props }
    >
      <Grid container
        sx={ {
          padding: '1rem',
        } }
      >

        <Grid item xs={ 12 } >
          <BooleanInput source='enableEmail' />
        </Grid>

        <Grid item xs={ 12 } sm={ 4 } >
          <BooleanInput label='Enable SMS' source='enableSms' />
        </Grid>

        <Grid item xs={ 12 } sm={ 8 }  >
          <SelectInput label='SMS Message Length' source='smsMessageLength' choices={ choices.smsMessageLength } required fullWidth />
        </Grid>

        <Grid item xs={ 12 } sm={ 4 } >
          <BooleanInput source='enableVoice' />
        </Grid>

        <Grid item xs={ 12 } sm={ 8 } >
          <BooleanInput source='enableVoiceCallTransfers' />
        </Grid>

        <SectionHeader sectionName='Patient Control' />
        <Grid item xs={ 8 }>
          <BooleanInput fullWidth source='requestAppointmentConfirmation' label='Enable confirm appointment' />
        </Grid>
        <Grid item xs={ 8 }>
          <BooleanInput fullWidth source='requestAppointmentCancellation' label='Enable manage appointment' />
        </Grid>

        <SectionHeader sectionName='Cost Model' />
        <CostModelComponents />

        <Grid item xs={ 8 }>
          <TextInput source="comment" fullWidth />
        </Grid>

      </Grid >

      <CustomToolbar />
    </Form >
  );
}

export const ChannelPrioritesInput: FC<InputProps> = () => {
  const record = useRecordContext();

  useEffect( () => {
    if( !record ) return;
    record.priorities = getPriorities( record );
  }, [ record ] );

  interface PrioritiesRecord {
    'priorityEmail': number;
    'prioritySms': number;
    'priorityVoice': number;
  }
  // type PriorityChannel = ( 'Email' | 'SMS' | 'Voice' )[];
  const getPriorities = useCallback( ( data: unknown ) => {
    const { priorityEmail, prioritySms, priorityVoice } = data as PrioritiesRecord;
    return sortBy( [
      { id: 'priorityEmail', name: 'Email', value: priorityEmail },
      { id: 'prioritySMS', name: 'SMS', value: prioritySms },
      { id: 'priorityVoice', name: 'Voice', value: priorityVoice },
    ], p => p.value );
  }, [] );

  return (
    <Box>
      <MuiList>
        <Draggable>
          { getPriorities( record ).map( p => (
            <ListItem key={ p.id }>
              <ListItemText sx={ { width: '100%' } } >{ p.name }</ListItemText>
              <ListItemIcon>
                < DragIndicator />
              </ListItemIcon>
            </ListItem>
          ) ) }
        </Draggable>
      </MuiList>
    </Box>
  );
}

export const MessageConfigEdit: FC<EditProps> = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Edit
      { ...props }
      mutationMode='pessimistic'
      actions={ <></> }
      mutationOptions={ {
        onSuccess: () => {
          notify( 'ra.notification.updated', { messageArgs: { smart_count: 1 } } );
          redirect( false );
          window.scrollTo( 0, 0 );
        },
      } }
    >
      <MessageConfigForm />
    </Edit>
  );
}
