/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { FC } from 'react';
import { BooleanField, BooleanInput, Create, CreateProps, Datagrid, Edit, EditProps, List, ListProps, regex, SelectArrayInput, Show, ShowProps, SimpleFormProps, TextField, TextInput, TitleProps, useRecordContext } from 'react-admin';
import { choices } from './DataProvider';
import { EditorSetup } from './HtmlEditor';
import { ssmlTags, templates } from './MessageTemplates';
import { SimpleFormWithLocation, SimpleShowLayoutWithLocation, useListAppLocation } from './MyBreadcrumb';
import { CustomToolbar } from './ReminderTemplateLists';
import { ShowActionsWithReset } from './ShowActionsWithReset';

export { default as AlertTemplateIcon } from '@mui/icons-material/Announcement';


const AlertTemplateListTitle: FC<TitleProps> = props => {
  return <span>ReminderTemplateList { props.record?.name ? `"${ props.record.name }"` : '' }</span>;
};

export const AlertTemplateList: FC<Omit<ListProps, 'children'>> = props => {
  useListAppLocation( 'broadcasts' );
  return (
    <List
      { ...props }
      exporter={ false }
      perPage={ 25 }
      sort={ { field: 'name', order: 'ASC' } }
    >
      <Datagrid rowClick="edit"
        // expand={ <AlertTemplateExpandEdit /> }
        bulkActionButtons={ false }
      >
        <TextField source="name" />

        <BooleanField label="Send Patient" source='sendPatientAlert' looseValue />
        <BooleanField label="Send Recipeints" source='sendPatientRecipientsAlert' looseValue />

        <TextField source="comment" />

      </Datagrid>
    </List>
  );
}

export const AlertTemplateShow: FC<ShowProps> = props => (
  <Show
    title={ <AlertTemplateListTitle /> }
    actions={ <ShowActionsWithReset /> }
    { ...props }>
    <SimpleShowLayoutWithLocation basePath='broadcasts' >
      <TextField source="name" fullWidth />
      <TextField source="comment" fullWidth />

      <BooleanField fullWidth source='sendPatientAlert' looseValue />
      <BooleanField fullWidth source='sendPatientRecipientsAlert' looseValue />

      <TextField source="alertSmsText" label="SMS Message" />
      <TextField source="alertEmailText" label="Email Message" />
      <TextField source="alertTwimlText" label="Spoken Message" />

    </SimpleShowLayoutWithLocation>
  </Show>
);

const setup: EditorSetup = ( editor ) => {
  editor.ui.registry.addMenuButton( 'variables', {
    text: 'Add variable',
    fetch: ( callback ) => {
      callback( templates.map( t => ( {
        type: 'menuitem',
        text: t.title,
        onAction: () => {
          // const bm = editor.selection.getBookmark();
          editor.insertContent( t.content );
          // editor.selection.moveToBookmark( bm );
          // editor.selection.setCursorLocation(); // undefined, t.content.length );
        }
      } ) ) );
    }
  } );
  editor.ui.registry.addMenuButton( 'ssml', {
    text: 'Add ssml markup',
    fetch: ( callback ) => {
      callback( ssmlTags.map( t => ( {
        type: 'menuitem',
        text: t.title,
        onAction: () => {
          editor.insertContent( t.content );
        }
      } ) ) );
    }
  } );

}

export const editorConfig = {
  htmlMessage: {
    plugins: [
      'advlist', 'autolink', 'autoresize', 'lists', 'link',
      'searchreplace', 'code', 'emoticons', // 'paste',
    ],
    toolbar: [
      'undo redo | formatselect | ' +
      'bold italic backcolor | bullist numlist outdent indent | ' +
      'removeformat | emoticons code',
      // 'variables',
    ],
    setup,
  },
  smsMessage: {
    format: ( value: string ): string => `<pre>${ value }</pre>`,
    parse: ( value: string ): string => value.replaceAll( /<p\s*>/g, '' ).replaceAll( /<\/p\s*>/g, ' ' ),
    plugins: [ 'autoresize', 'code' ],
    // toolbar: 'variables', // code
    setup,
  },
  ssmlMessage: {
    format: ( value: string ): string => `<speak>${ value }</speak>`,
    parse: ( value: string ): string => value.replaceAll( /<speak\s*>/g, '' ).replaceAll( /<\/speak\s*>/g, ' ' ),
    plugins: [ 'autoresize', 'code' ],
    toolbar: 'ssml code',
    setup,
    disable: true,
  },
};

const AlertTemplateForm: FC<Omit<SimpleFormProps, 'children'>> = props => {
  const record = useRecordContext();

  return (
    ( <SimpleFormWithLocation basePath='broadcasts' sanitizeEmptyValues  { ...props } toolbar={ <CustomToolbar /> } >
      { !record?.id
        ? <TextInput source="id" label='ID' validate={ regex( /^[a-z][a-zA-Z0-9]+$/, 'Use shortCamelCase' ) } />
        : <></>
      }
      <TextInput source="name" fullWidth />
      <TextInput source="comment" fullWidth />
      <SelectArrayInput source='enabledStatuses' choices={ choices.appointmentStatus } required fullWidth />
      <BooleanInput fullWidth source='sendPatientAlert' />
      <BooleanInput fullWidth source='sendPatientRecipientsAlert' />
      {/* <ReplacementArgsInput
          label='SMS message {alertSMS}'
          source='alertSmsText'
          minRows={ 8 }
          fullWidth
          /> */}
      <TextInput
        label='SMS message {alertSMS}'
        source='alertSmsText'
        multiline
        minRows={ 8 }
        fullWidth
      />
      {/* <HtmlEditor source="alertEmailText" label="Email message {alertEmail}"  { ...editorConfig.htmlMessage } width={ 400 } /> */ }
      <TextInput
        label='Email message {alertEmail}'
        source='alertEmailText'
        multiline
        minRows={ 8 }
        fullWidth
      />
      {/* <HtmlEditor source="alertTwimlText" label="Spoken message {alertTwiml}"  { ...editorConfig.ssmlMessage } width={ 400 } disabled /> */ }
      <TextInput
        label='Voice message {alertTwiml}'
        source='alertTwimlText'
        multiline
        minRows={ 8 }
        fullWidth
      />
    </SimpleFormWithLocation > )
  );
}

export const AlertTemplateExpandEdit: FC<EditProps> = props => (
  <Edit
    actions={ false }
    title={ '&nbsp;' }
    redirect='show'
    { ...props } >
    <AlertTemplateForm />
  </Edit>
);

export const AlertTemplateEdit: FC<EditProps> = props => (
  <Edit
    // actions={ <EditActions breadcrumb={ <MyBreadcrumb variant="actions" /> } /> }
    redirect='show'
    mutationMode='pessimistic'
    { ...props }
  >
    <AlertTemplateForm />
  </Edit>
);


export const AlertTemplateCreate: FC<CreateProps> = props => (
  <Create
    redirect='show'
    { ...props }
  >
    <AlertTemplateForm />
  </Create>
);

