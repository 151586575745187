import { ReactElement } from 'react';
import { ImageFieldProps, useRecordContext } from 'react-admin';

export type ImageDataFieldProps = ImageFieldProps;

export const ImageDataField = ( props: ImageFieldProps ): ReactElement => {
  const { source = 'data', className, title } = props;
  const record = useRecordContext( props );
  const { [ source ]: data, mimeType } = record || {};
  if( !data || !mimeType ) {
    return <></>;
  }
  const dataUrl = `data:${ mimeType };base64,${ data }`
  return (
    <div className={ className } >
      <img src={ dataUrl } title={ title } alt={ title } />
    </div>
  );
}

export default ImageDataField;
