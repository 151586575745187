import { Add, NotInterested } from '@mui/icons-material';
import { pluralize } from 'inflection';
import { FC, MouseEventHandler, useCallback } from 'react';
import { Button, ButtonProps, Datagrid, Filter, FilterProps, FunctionField, List, ListProps, SearchInput, SelectField, SelectInput, Show, ShowProps, SimpleShowLayout, TextField, useRecordContext, useUpdate } from 'react-admin';
import { choices } from './DataProvider';
import { PolymorphicReferenceField } from './PolymorphicReferenceField';
import { TimestampsField } from './TimestampsField';

export { default as DashboardAlertIcon } from '@mui/icons-material/WarningRounded';

// const DashboardAlertTitle: FC<TitleProps> = props => {
//   return <span>DashboardAlert { props.record?.name ? `"${ props.record.name }"` : '' }</span>;
// };

const DashboardAlertFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <SearchInput source="q" alwaysOn />
    <SelectInput source="status" choices={ choices.alertStatus } alwaysOn />
    <SelectInput label="Type" source="resourceType" choices={ choices.alertResourceType } />

  </Filter>
);

export const DashboardAlertExpand: FC<Omit<ShowProps, 'children'>> = props => (
  <Show
    { ...props } >
    <SimpleShowLayout>
      <TextField source="detail" fullWidth />
      <ReactivateAlertButton />
    </SimpleShowLayout>
  </Show>
);


export const IgnoreAlertButton: FC<ButtonProps> = () => {
  const record = useRecordContext();
  const [ update, { isLoading } ] = useUpdate( 'DashboardAlerts' );

  const ignoreAlert = useCallback<MouseEventHandler<HTMLButtonElement>>( ( e ) => {
    if( !record ) return;
    console.log( record );

    update( 'dashboardalerts', { id: record.id, data: { status: 'ignore' }, previousData: record } )
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, [ update ] );

  if( record?.status != 'active' ) return null;

  return (
    <Button
      label='Ignore'
      color='secondary'
      onClick={ ignoreAlert }
      disabled={ isLoading }
    >
      <NotInterested />
    </Button>

  );

}

export const ReactivateAlertButton: FC<ButtonProps> = () => {
  const record = useRecordContext();
  const [ update, { isLoading } ] = useUpdate( 'DashboardAlerts' );

  const reactivateAlert = useCallback<MouseEventHandler<HTMLButtonElement>>( ( e ) => {
    if( !record ) return null;
    console.log( record );

    update( 'dashboardalerts', { id: record.id, data: { status: 'active' }, previousData: record } )
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, [ update ] );

  if( record?.status != 'ignore' ) return null;

  return (
    <Button
      label='Reactivate'
      color='secondary'
      onClick={ reactivateAlert }
      disabled={ isLoading }
    >
      <Add />
    </Button>

  );

}

// export const DatagridBodyTooltip: FC<DatagridBodyProps> = props => {
//   const ref = useRef<HTMLTableRowElement>();
//  
//   useEffect( () => {
//     ref.current?.addEventListener( 'mouseenter', () => {
//  
//     });
//     ref.current?.addEventListener( 'mouseleave', () => {
//  
//     });
//  
//   }, [ ref.current ] );
//   
//  
//   return (
//     <DatagridBody
//       { ...props }
//       row={ <DatagridRow ref={ ref }/> }
//     />
//   );
//  
// }

export const DashboardAlertList: FC<Omit<ListProps, 'children'>> = ( props ) => {

  const filterDefaultValues = { status: 'active' };
  // const filter = { status: 'active' };      // filter={ filter }

  return (
    <List
      exporter={ false }
      filters={ <DashboardAlertFilter /> }
      filterDefaultValues={ filterDefaultValues }
      empty={ false }
      perPage={ 10 }
      sort={ { field: 'resourceType', order: 'ASC' } }
      { ...props }
    >
      <Datagrid
        bulkActionButtons={ false }
        // expand={ <DashboardAlertExpand /> }
        // body={ DatagridBodyTooltip }
        rowClick={ ( _id, _resource, record ) => record.adminLink }
      >
        <TextField label="Type" source="resourceType" />
        <PolymorphicReferenceField
          source="resource"
          reference={ ( record ) => record ? pluralize( record.resourceType.toLowerCase() ) : '' }
          link='edit'
        // adminLink
        >
          <FunctionField source="name" render={ ( r: { name?: string; fullName?: string, id: string } ) => {
            if( !r ) return null;
            return (
              <>
                { r.fullName || r.name || r.id }
              </>
            );
          } } />
        </PolymorphicReferenceField>

        <TextField source="issue" />
        {/* <UrlField source="adminLink" /> */ }
        <TimestampsField label="Updated" source="updatedAt" />
        <SelectField source="status" choices={ choices.alertStatus } />
        <IgnoreAlertButton />
      </Datagrid>
    </List >
  );
}
