/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { FC } from 'react';
import { BooleanInput, Datagrid, Edit, EditProps, Filter, FilterProps, FormDataConsumer, Labeled, List, ListProps, SearchInput, Show, ShowProps, SimpleFormProps, TextField, TextInput } from 'react-admin';
import { BooleanColorField } from './BooleanColorField';
import { SimpleFormWithLocation, SimpleShowLayoutWithLocation, useListAppLocation } from './MyBreadcrumb';
import { CustomToolbar } from './ReminderTemplateLists';
import { TimestampsField } from './TimestampsField';
import { Childless } from './types';

export { default as EmrEventTypeIcon } from '@mui/icons-material/Announcement';

const EmrEventTypeFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export const EmrEventTypeList: FC<Childless<ListProps>> = props => {
  useListAppLocation( 'patient' );

  return (
    <List
      { ...props }
      exporter={ false }
      perPage={ 25 }
      filters={ <EmrEventTypeFilter /> }
      filter={ { emrSubscribed: true } }
    >
      <Datagrid
        rowClick="edit"
        // expand={ <EmrEventTypeExpandEdit /> }
        bulkActionButtons={ false }
      >

        <TextField label="Category" source="category" />
        <TextField label="Name" source="name" />
        <TextField label="Label" source="label" />
        <TextField label="Description" source="description" />
        {/* <TextField source="value" fullWidth /> */ }
        { /* <TimestampsField label="Updated" source="updatedAt" /> */ }
      </Datagrid>
    </List >
  );
}

export const EmrEventTypeShow: FC<ShowProps> = () => {

  return (
    <Show>
      <SimpleShowLayoutWithLocation basePath='patient'>
        <TextField label='Name' source='name' />
        <TextField label="Category" source="category" />
        <TextField label="Label" source="label" />
        <TextField label="Description" source="description" />
        <BooleanColorField label='Enabled' source="disable" invert />


        <BooleanColorField label='Patient' source="disablePatient" invert />
        <BooleanColorField label='Contacts' source="disablePatientRecipients" invert />
        <BooleanColorField label='Outgoing Clinical' source="disableOutgoingClinical" invert />
        <BooleanColorField label='Practitioner' source="disablePractitioner" invert />
        <BooleanColorField label='Assistants' source="disablePractitionerRecipients" invert />

        <TimestampsField label="Updated" source="updatedAt" />
        <TimestampsField label="Updated" source="createdAt" />
      </SimpleShowLayoutWithLocation>
    </Show>
  );
}


// TODO set comment to edited in UI
const EmrEventTypeForm: FC<Omit<SimpleFormProps, 'children'>> = props => (
  <SimpleFormWithLocation
    basePath='patient'
    sanitizeEmptyValues  { ...props }
    toolbar={ <CustomToolbar /> }
  >


    <Labeled label='Name'>
      <TextField label="Name" source="name" />
    </Labeled>
    <Labeled label='Description'>
      <TextField source="description" fullWidth />
    </Labeled>

    <TextInput label="Label" source="label" fullWidth />

    <BooleanInput label='Enable' source="disable" parse={ v => !v } format={ v => !v } />

    <FormDataConsumer>
      { ( { formData } ) => (
        <>
          <BooleanInput label='Patient' source="disablePatient" parse={ v => !v } format={ v => !v } disabled={ formData.disable } />
          <BooleanInput label='Contacts' source="disablePatientRecipients" parse={ v => !v } format={ v => !v } disabled={ formData.disable } />
          <BooleanInput label='Outgoing Clincial Data' source="disableOutgoingClinical" parse={ v => !v } format={ v => !v } disabled={ formData.disable || ( formData.disablePatient && formData.disablePatientRecipients ) } />
          <BooleanInput label='Practitioners' source="disablePractitioner" parse={ v => !v } format={ v => !v } disabled={ formData.disable } />
          <BooleanInput label='Assistants' source="disablePractitionerRecipients" parse={ v => !v } format={ v => !v } disabled={ formData.disable } />
        </>
      ) }
    </FormDataConsumer>
  </SimpleFormWithLocation>
);

export const EmrEventTypeExpandEdit: FC<EditProps> = props => (
  <Edit
    actions={ false }
    title={ '&nbsp;' }
    redirect='list'
    { ...props } >
    <EmrEventTypeForm />
  </Edit>
);

export const EmrEventTypeEdit: FC<EditProps> = props => (
  <Edit
    redirect='show'
    mutationMode='pessimistic'
    { ...props }
  >
    <EmrEventTypeForm />
  </Edit>
);

