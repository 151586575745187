/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { Typography } from '@mui/material';
import { debounce } from 'lodash';
import { FC, useCallback, useEffect } from 'react';
import { ChipField, Datagrid, DateField, Edit, EditProps, List, ListProps, ReferenceArrayField, ReferenceArrayInput, ReferenceInput, SaveButton, SelectArrayInput, SelectInput, SimpleFormProps, SingleFieldList, TextField, TextInput, Toolbar, ToolbarProps } from 'react-admin';
import { useFormState } from 'react-hook-form';
import { AdminSimpleForm, useAdminListAppLocation } from './Admin';
import { apiUrl, httpClient } from './DataProvider';
import { HtmlEditor } from './HtmlEditor';
import { editorConfig } from './MessageTemplates';
import { useListAppLocation } from './MyBreadcrumb';
import { ReplacementArgsInput } from './ReplacementArgsInput';
import { Childless } from './types';

export { default as AppointmentTypePackageIcon } from '@mui/icons-material/SpeakerNotes';


export const AppointmentTypePackageList: FC<Childless<ListProps>> = props => {
  useAdminListAppLocation();

  const fetchNewEmr = useCallback( debounce( () => {
    httpClient( `${ apiUrl }/appointmenttypepackages/actions/fetchNewEmr`, { method: 'POST' } );
  }, 10000, { leading: true, trailing: true } ), [] )

  useEffect( () => {
    fetchNewEmr();
  }, [ fetchNewEmr ] );

  // const filterDefaultValues = {};

  return (
    <List { ...props }
      exporter={ false }
      sort={ { field: 'name', order: 'ASC' } }
      perPage={ 25 }
    >
      <Datagrid rowClick="edit" bulkActionButtons={ false } >
        <TextField label="Code" source="id" />
        <TextField source="name" />
        <ReferenceArrayField label="Pre-appointment" source="preReminderTemplateList" reference="remindertemplatelists" >
          <SingleFieldList linkType='show' >
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField label="Post-appointment" source="postReminderTemplateList" reference="remindertemplatelists" >
          <SingleFieldList linkType='show' >
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField label="Updated" source="updatedAt" showTime />
      </Datagrid>
    </List >
  );
}

// // This is read-only Tenant staff view in Appointment page "Types" tab
// export const AppointmentTypeList: FC<Childless<ListProps>> = props => {
//   useListAppLocation( 'appointment' );
//  
//   const fetchNewEmr = useCallback( debounce( () => {
//     httpClient( `${ apiUrl }/appointmenttypepackages/actions/fetchNewEmr`, { method: 'POST' } );
//   }, 10000, { leading: true, trailing: true } ), [] )
//  
//   useEffect( () => {
//     fetchNewEmr();
//   }, [ fetchNewEmr ] );
//  
//  
//   // const filterDefaultValues = {};
//  
//   return (
//     <List { ...props }
//       exporter={ false }
//       sort={ { field: 'name', order: 'ASC' } }
//       perPage={ 25 }
//     >
//       <Datagrid bulkActionButtons={ false } >
//         <TextField label="Code" source="id" />
//         <TextField source="name" />
//         <ReferenceArrayField label="Pre-appointment" source="preReminderTemplateList" reference="remindertemplatelists" >
//           <SingleFieldList linkType={ false } >
//             <ChipField source="name" />
//           </SingleFieldList>
//         </ReferenceArrayField>
//         <ReferenceArrayField label="Post-appointment" source="postReminderTemplateList" reference="remindertemplatelists" >
//           <SingleFieldList linkType={ false } >
//             <ChipField source="name" />
//           </SingleFieldList>
//         </ReferenceArrayField>
//         <DateField label="Updated" source="updatedAt" showTime />
//       </Datagrid>
//     </List >
//   );
// }

const CustomToolbar: FC<ToolbarProps> = () => {
  // const { saving } = props;
  const { isValid } = useFormState();

  //className={ useStyles() }>  // TODO v4

  return (
    <Toolbar>
      <SaveButton
        invalid={ !isValid }
      // saving={ saving }
      />
    </Toolbar>
  );
}


// preOpCareSms: '\n' +
//         'Please follow all written instructions provided to you before your appointment.',
// preOpCareShortSms: '\n' +
//                    'Please follow all written instructions provided to you before your appointment.',
// preOpCareHtml: 'Please follow all written instructions provided to you before your appointment.',
// atOpCareSms: '\nPatient is with the doctor.',
// atOpCareShortSms: '\nPatient is with the doctor.',
// atOpCareHtml: 'Patient is with the doctor.',
// postOpCareSms: '\n' +
//                'For the best results of your procedure, please follow all written instructions provided to you.',
// postOpCareShortSms: '\n' +
//                     'For the best results of your procedure, please follow all written instructions provided to you.',
// postOpCareHtml: 'For the best results of your procedure, please follow all written instructions provided to you.',
// reviewSms: '\n' +
//            'Thank you for your recent visit.\n' +
//            '\n' +
//            'Please review our practice by clicking here:\n' +
//            '{{feedbackUrlLink}}',
// reviewShortSms: '\n' +
//                 'Thank you for your recent visit.\n' +
//                 '\n' +
//                 'Please review our practice by clicking here:\n' +
//                 '{{feedbackUrlLink}}',
// reviewHtml: 'Thank you for your recent visit.<br>Please review our practice by clicking: <a href={{feedbackUrlLink}}>here</a>',
// noShowSms: '\nPlease contact our office to reschedule your appointment',
// noShowShortSms: '\nPlease contact our office to reschedule your appointment',
// noShowHtml: 'Please contact our office to reschedule your appointment',
// cancelledSms: '\nIf you have not already rescheduled, please contact us.',
// cancelledShortSms: '\nIf you have not already rescheduled, please contact us.',
// cancelledHtml: 'If you have not already rescheduled, please contact us.',

const AppointmentTypePackageForm: FC<Omit<SimpleFormProps, 'children'>> = props => (
  <AdminSimpleForm
    sanitizeEmptyValues
    toolbar={ <CustomToolbar /> }
    sx={ {
      '& .MuiDivider-root': {
        fontWeight: 'bold',
        fontSize: '110%',
        marginTop: '1rem',
      }
    } }
    { ...props }
  >
    <TextInput source="comment" fullWidth />

    <Typography color='primary' sx={ { textTransform: 'uppercase' } }>
      Pre-appointment
    </Typography>
    <ReferenceArrayInput source="preReminderTemplateList" reference="remindertemplatelists" allowEmpty      >
      <SelectArrayInput label="Pre-appointment template" fullWidth style={ { width: '100%' } } />
    </ReferenceArrayInput>
    <ReferenceInput source="preOpCalendarPackage" reference="calendarpackages" allowEmpty      >
      <SelectInput label="Pre-appointment calendar package" fullWidth style={ { width: '100%' } } />
    </ReferenceInput>
    <ReplacementArgsInput source="preOpCareSms" label="Pre-appointment care SMS" minRows={ 4 } fullWidth />
    <ReplacementArgsInput source="preOpCareShortSms" label="Pre-appointment care short SMS" minRows={ 4 } fullWidth />
    <HtmlEditor source="preOpCareHtml" label="Pre-appointment care email" { ...editorConfig.htmlMessage } width={ 600 } height={ 200 } />

    <Typography color='primary' sx={ { textTransform: 'uppercase' } }>
      At-appointment
    </Typography>
    <ReferenceArrayInput source="atReminderTemplateList" reference="remindertemplatelists" allowEmpty  >
      <SelectArrayInput label="At-appointment template" fullWidth style={ { width: '100%' } } />
    </ReferenceArrayInput>
    <ReferenceInput source="atOpCalendarPackage" reference="calendarpackages" allowEmpty      >
      <SelectInput label="At-appointment calendar package" fullWidth style={ { width: '100%' } } />
    </ReferenceInput>
    <ReplacementArgsInput source="atOpCareSms" label="At-appointment care SMS" minRows={ 4 } fullWidth />
    <ReplacementArgsInput source="atOpCareShortSms" label="At-appointment care short SMS" minRows={ 4 } fullWidth />
    <HtmlEditor source="atOpCareHtml" label="At-appointment care email" { ...editorConfig.htmlMessage } width={ 600 } height={ 200 } />


    <Typography color='primary' sx={ { textTransform: 'uppercase' } }>
      Post-appointment
    </Typography>
    <ReferenceArrayInput source="postReminderTemplateList" reference="remindertemplatelists" allowEmpty >
      <SelectArrayInput label="Post-appointment template" fullWidth style={ { width: '100%' } } />
    </ReferenceArrayInput>
    <ReferenceInput source="postOpCalendarPackage" reference="calendarpackages" allowEmpty      >
      <SelectInput label="Post-appointment calendar package" fullWidth style={ { width: '100%' } } />
    </ReferenceInput>
    <ReplacementArgsInput source="postOpCareSms" label="Post-appointment care SMS" minRows={ 4 } fullWidth />
    <ReplacementArgsInput source="postOpCareShortSms" label="Post-appointment care short SMS" minRows={ 4 } fullWidth />
    <HtmlEditor source="postOpCareHtml" label="Post-appointment care email" { ...editorConfig.htmlMessage } width={ 600 } height={ 200 } />

    <Typography color='primary' sx={ { textTransform: 'uppercase' } }>
      Review
    </Typography>
    <ReferenceArrayInput source="reviewTemplateList" reference="remindertemplatelists" allowEmpty  >
      <SelectArrayInput label="Review" fullWidth style={ { width: '100%' } } />
    </ReferenceArrayInput>
    <ReplacementArgsInput source="reviewSms" label="Review SMS" minRows={ 4 } fullWidth />
    <ReplacementArgsInput source="reviewShortSms" label="Review short SMS" minRows={ 4 } fullWidth />
    <HtmlEditor source="reviewHtml" label="Review email" { ...editorConfig.htmlMessage } width={ 600 } height={ 200 } />

    <Typography color='primary' sx={ { textTransform: 'uppercase' } }>
      No-show appointment
    </Typography>
    <ReferenceArrayInput source="noShowTemplateList" reference="remindertemplatelists" allowEmpty  >
      <SelectArrayInput label="No-show" fullWidth style={ { width: '100%' } } />
    </ReferenceArrayInput>
    <ReplacementArgsInput source="noShowSms" label="No-Show SMS" minRows={ 4 } fullWidth />
    <ReplacementArgsInput source="noShowShortSms" label="No-Show short SMS" minRows={ 4 } fullWidth />
    <HtmlEditor source="noShowHtml" label="No-Show email" { ...editorConfig.htmlMessage } width={ 600 } height={ 200 } />


    <Typography color='primary' sx={ { textTransform: 'uppercase' } }>
      Cancelled appointment
    </Typography>
    <ReferenceArrayInput source="cancelledTemplateList" reference="remindertemplatelists" allowEmpty  >
      <SelectArrayInput label="Cancelled" fullWidth style={ { width: '100%' } } />
    </ReferenceArrayInput>
    <ReplacementArgsInput source="cancelledSms" label="Cancelled SMS" minRows={ 4 } fullWidth />
    <ReplacementArgsInput source="cancelledShortSms" label="Cancelled short SMS" minRows={ 4 } fullWidth />
    <HtmlEditor source="cancelledHtml" label="Cancelled email" { ...editorConfig.htmlMessage } width={ 600 } height={ 200 } />

  </AdminSimpleForm >
);

export const AppointmentTypePackageEdit: FC<EditProps> = props => (
  <Edit
    redirect='list'
    mutationMode='pessimistic'
    { ...props }
  >
    <AppointmentTypePackageForm />
  </Edit>
);
