import { MoreHoriz as DragHandle } from '@mui/icons-material';
import { Box } from '@mui/material';
import { FC, ReactElement, useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';


export interface DropPreview {
  isOver: boolean;
  border?: string;
}

export interface DragObject {
  tag: string;
}

export interface DragPreview {
  opacity: number;
  border?: string;
}

export interface DropResult {
}

export type OnDrop = ( tag: string ) => ( item: DragObject, monitor: DropTargetMonitor<DragObject, DropResult> ) => Promise<DropResult>;

export interface DashboardWidgetDragDropProps {
  children: ReactElement;
  tag: string;
  idx: number;
  disabled?: boolean;
  onDrop: OnDrop; // ( idx: number ) => ( item: DragObject, monitor: DropTargetMonitor<DragObject, DropResult> ) => DropResult;
}


export const DashboardWidgetDragDrop: FC<DashboardWidgetDragDropProps> = props => {
  const { tag, children, onDrop, idx, disabled = false } = props;
  const ref = useRef<HTMLDivElement | null>( null );
  // useEffect( () => { console.log( `DashboardWidgetDragDrop ${ idx } ${ tag }` ) }, [ idx, tag ] );

  const [ { opacity, border }, dragRef ] = useDrag<DragObject, DropResult, DragPreview>( () => ( {
    type: 'widget',
    item: { tag },
    canDrag: !disabled,
    collect: ( monitor ) => ( {
      opacity: monitor.isDragging() ? 0.5 : 1,
      border: monitor.isDragging() ? '1ps solid purple' : undefined,
    } )
  } ), [ idx, tag, disabled ] );

  const [ { isOver }, dropRef ] = useDrop<DragObject, DropResult | undefined, DropPreview>( () => ( {
    accept: 'widget',
    drop: onDrop( tag ),
    collect: monitor => ( {
      isOver: !!monitor.isOver(),
    } ),
  } ), [ idx, tag, disabled ] );


  const dragDropRef = dragRef( dropRef( ref ) );

  return (
    <Box
      sx={ {
        position: 'relative',
        width: '100%',
        height: '100%',
        opacity,
        border,
        '&:hover .DragHandle': disabled
          ? {}
          : {
            // color: 'red',
            display: 'initial',
            '&:hover': {
              cursor: 'move',
            },
          }
      } }
      // @ts-ignore
      ref={ dragDropRef }
    >
      { children }
      { isOver &&
        <Box
          sx={ {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            opacity: 0.3,
            backgroundColor: 'blue',
          } }
        />
      }
      <Box
        className='DragHandle'
        sx={ {
          position: 'absolute',
          top: 0,
          left: '50%',
          height: '1.5rem',
          width: '3rem',
          display: 'none',
        } }
      >
        <DragHandle />
      </Box>

    </Box>

  );
}


