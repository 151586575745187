import { TypographyProps } from '@mui/material';
import Divider from '@mui/material/Divider';
import { MarkdownField } from '@react-admin/ra-markdown';
import { reverse, sortBy } from 'lodash';
import get from 'lodash/get';
import { FC, useEffect, useState } from 'react';
import { ArrayField, ChipField, Datagrid, FunctionField, Labeled, FieldProps, RaRecord, ReferenceArrayField, SingleFieldList, TextField, useGetList, useRecordContext } from 'react-admin';
import { ImageDataField } from './ImageDataField';
import { TimestampsField } from './TimestampsField';

export interface RelatedAssetsProps extends Pick<FieldProps, 'source' | 'label'>, Omit<TypographyProps, 'textAlign'> {
  // sources?: string | string[]; // comma delimited? array?
  hideTags?: boolean;
}

export interface AssetRecord extends RaRecord {
  id: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  body?: string;
  subject?: string;
  // tags
  data?: string;
}

export const RelatedAssets: FC<RelatedAssetsProps> = ( props ) => {
  const { source = 'id', label, hideTags } = props;
  const record = useRecordContext( props );
  const tagId = get( record, source );
  const filter = tagId && { tags: tagId };
  const { data: assets } = useGetList( 'assets', { filter }, { enabled: !!filter } );
  const [ data, setData ] = useState( [] as RaRecord[] );

  useEffect( () => {
    setData( reverse( sortBy( assets, o => o.updatedAt ) ) );
  }, [ assets ] );

  if( !data ) return null;

  const contents = (
    <ArrayField source="data" record={ { data, id: 'assets' } }  >
      <Datagrid empty={ <div>N/A</div> } bulkActionButtons={ false }>
        <FunctionField label="Content" source="type" render={
          ( r ) => !r
            ? <></>
            : <>
              <TextField source="subject" style={ { fontWeight: 'bold' } } />
              <Divider />
              <MarkdownField source="body" />
              <ReferenceArrayField reference="images" source="images" >
                <SingleFieldList >
                  <ImageDataField source="data"
                    sx={ {
                      margin: '0.5em',
                      '& img': {
                        'max-width': '10em',
                        'max-height': '10em',
                      }
                    } }
                  />
                </SingleFieldList>
              </ReferenceArrayField>
            </>
        } />
        { !hideTags &&
          <ReferenceArrayField reference="tags" source="tags" >
            <SingleFieldList >
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        }
        <TimestampsField label="Updated" source="updatedAt" />

      </Datagrid>
    </ArrayField >
  )

  if( label ) {
    return (
      <Labeled label={ label } >
        { contents }
      </Labeled >
    );
  }
  return contents;
}

RelatedAssets.defaultProps = {
  hideTags: false,
  label: ' ',
}

export default RelatedAssets;
