import EmailIcon from '@mui/icons-material/Email';
import { Box, Button, Card, CardActionArea, CardContent, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip, Typography } from '@mui/material';
import { Datagrid, Edit, List, Show } from '@react-admin/ra-rbac';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { ArrayField, ArrayInput, AutocompleteArrayInput, ChipField, Create, CreateProps, DeleteWithConfirmButton, EditButton, EditProps, FormDataConsumer, FunctionField, Identifier, ListProps, NumberInput, Pagination, RadioButtonGroupInput, RaRecord, ReferenceArrayField, ReferenceArrayInput, ReferenceInput, SaveButton, ShowButton, ShowProps, SimpleFormIterator, SingleFieldList, TextField, TextInput, TopToolbar, useGetIdentity, useGetList, useGetMany, useListContext, useRecordContext, useRedirect, useSaveContext, WithRecord } from 'react-admin';

import { apiUrl, httpClient } from './DataProvider';
import { EmailsInput } from './EmailsInput';
import { FormWithLocation, SimpleShowLayoutWithLocation } from './MyBreadcrumb';
import { ReputationSubjectReviewChart } from './ReputationSubjectReviews';
import { Childless } from './types';

export { default as ReputationVisualizationIcon } from '@mui/icons-material/BarChart';

export interface ReputationVisualizationInterface extends RaRecord {
  title?: string;
  charts: Array<{
    title?: string;
    subjects?: string[];
    organizations?: string[];
    kinds?: string[];
    platforms?: string[];
    duration: {
      count: number;
      type: 'years' | 'months';
    }
  }>;
}

export const ReputationVisualizationCardList: FC = () => {
  const { data } = useListContext<ReputationVisualizationInterface>();
  const redirect = useRedirect();
  return (
    <Grid container spacing={ 2 } padding={ 2 }>
      { data && data.map( record => (
        <Grid item key={ record.id }>
          <Card raised sx={ { borderRadius: 2 } }>
            <CardActionArea onClick={ () => redirect( 'show', 'reputationvisualizations', record.id ) } disableRipple >
              <CardContent sx={ { paddingBottom: 0 } }>
                <Typography gutterBottom variant='body1' component='div'>
                  { record.title }
                </Typography>
                <Box width={ 250 }>
                  { record.charts[ 0 ] && <ReputationSubjectReviewChart small { ...record.charts[ 0 ] } /> }
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ) ) }
    </Grid>
  );
}

export const ReputationVisualizationList: FC<Childless<ListProps>> = props => {

  const { identity, isLoading } = useGetIdentity();
  const userId = identity?.id;

  if( isLoading ) return <></>;
  return (
    <List { ...props }
      exporter={ false }
      filter={ { userId } }
    >
      <ReputationVisualizationCardList />
    </List>

  )
};

export const ReputationVisualizationExpandShow: FC = () => {
  return (
    <ArrayField source="charts" >
      <Datagrid
        bulkActionButtons={ false }
      >
        <TextField source="title" label="Chart" />
        <ReferenceArrayField source="subjects" reference="reputationsubjects">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ArrayField source="organizations">
          <SingleFieldList>
            <FunctionField render={ ( org: RaRecord ) => (
              <Chip label={ org.name } sx={ { margin: 0.5 } } />
            ) } />
          </SingleFieldList>
        </ArrayField>
        <ReferenceArrayField source="platforms" reference="reputationplatforms">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <FunctionField
          source="duration"
          render={ ( record: RaRecord ) => `${ record.duration.count } ${ record.duration.type }` }
        />
      </Datagrid>
    </ArrayField>
  );
}


interface ExportFormProps {
  id?: string;
  disabled?: boolean;
}
const VisualizationExportFormDialog: FC<ExportFormProps> = ( props ) => {
  const [ open, setOpen ] = useState( false );
  const [ recipients, setRecipients ] = useState<string[]>( [] );
  const [ isValidInput, setIsValidInput ] = useState( true );
  const record = useRecordContext();

  const handleClickOpen = () => setOpen( true );
  const handleClose = () => setOpen( false );
  const handleSubmit = async () => {
    if( !record ) return;
    await httpClient( `${ apiUrl }/reputationreports`, {
      method: 'POST',
      body: JSON.stringify( {
        kind: 'visualization',
        id: record.id,
        recipients,
      } ),
    } );
    handleClose();
  };

  return (
    <div>
      <Button
        id={ `${ props.id }_button` }
        variant="text"
        onClick={ handleClickOpen }
        disabled={ props.disabled }
        sx={ { marginLeft: '2em' } }
        size='small'
        startIcon={ <EmailIcon /> }
      > Email </Button>
      <Dialog open={ open } onClose={ handleClose }>
        <DialogTitle>Recipients</DialogTitle>
        <DialogContent sx={ { width: '400px' } }>
          <EmailsInput
            value={ recipients }
            onChange={ setRecipients }
            setValidity={ setIsValidInput }
            tabIndex={ 0 }
          />
        </DialogContent>
        <DialogActions>
          <Button
            id={ `${ props.id }_cancel` }
            onClick={ handleClose }
            color='inherit'
            variant='contained'
            disableFocusRipple
            tabIndex={ 2 }
          >Cancel</Button>
          <Button
            id={ `${ props.id }_submit` }
            onClick={ handleSubmit }
            color='primary'
            variant='contained'
            disableFocusRipple
            disabled={ !isValidInput }
            tabIndex={ 1 }
          >Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


const ReputationVisualizationTitle: FC = () => {
  const record = useRecordContext();
  if( !record ) return null;
  return <span>Visualization "{ record.title }"</span>
}

const DeleteVisualizationButton: FC = () => (
  <WithRecord render={ record =>
    <DeleteWithConfirmButton
      confirmTitle={ `Delete Visualization "${ record?.title }"` }
      redirect={ () => 'reputation/reputationvisualizations' }
    />
  } />
);

export const ReputationVisualizationShow: FC<ShowProps> = props => {
  const pageSize = 12;
  return (
    <Show
      { ...props }
      title={ <ReputationVisualizationTitle /> }
      actions={
        <TopToolbar>
          <VisualizationExportFormDialog />
          <EditButton />
          <DeleteVisualizationButton />
        </TopToolbar>
      }
    >
      <SimpleShowLayoutWithLocation basePath='reputation'>
        <TextField source="title" />
        <ArrayField source="charts" perPage={ pageSize }>
          <SingleFieldList linkType={ false }>
            <FunctionField render={ ( record: RaRecord ) => (
              <Box width={ 500 } boxShadow={ 3 } borderRadius={ 2 } margin={ 1 }>
                <ReputationSubjectReviewChart
                  { ...record }
                  duration={ record.duration }
                />
              </Box>
            ) } />
          </SingleFieldList>
          <Pagination rowsPerPageOptions={ [ pageSize ] } />
        </ArrayField>
      </SimpleShowLayoutWithLocation>
    </Show>
  );
}

const ReputationVisualizationChartTitleInput: FC<{ subjects?: Identifier[], platforms?: Identifier[], source: string }> = ( params ) => {
  const { data: subjects } = useGetMany<{ name: string, id: Identifier }>( 'reputationsubjects', { ids: params.subjects } );
  const { data: platforms } = useGetMany<{ name: string, id: Identifier }>( 'reputationplatforms', { ids: params.platforms } );
  const defaultTitle = useMemo( () => {
    if( subjects?.length ) {
      const firstSubjectName = subjects[ 0 ].name;
      return subjects.length > 1 ? `${ firstSubjectName }, et al.` : firstSubjectName;
    }
    return platforms?.map( p => p.name ).join( ', ' );
  }, [ platforms, subjects ] );
  return (
    <TextInput
      source={ params.source }
      label="Chart Label"
      fullWidth
      placeholder={ defaultTitle }
      resettable
    />
  );
}

export const ReputationVisualizationForm: FC = () => {
  const { data: locations, isLoading: isLoadingLocations } = useGetList<RaRecord>( 'locations', { filter: { status: 'active' } } );
  const subjectsFilter = useMemo( () => {
    if( isLoadingLocations || !locations ) return { reference: 'null' }; // intentionally matches nothing
    return {
      kind: 'location',
      reference: locations.map( l => l.id ).join(),
    }
  }, [ locations ] );

  const saveContext = useSaveContext();
  const queryClient = useQueryClient();

  return (
    ( <FormWithLocation
      basePath='reputation'
      sanitizeEmptyValues
      // @ts-ignore: 7006
      onSubmit={ ( data ) => {
        if( !saveContext?.save ) return;
        saveContext.save( data );
        queryClient.invalidateQueries( {
          queryKey: [ 'reputationvisualizations', 'getList' ]
        } );
      } }
    >
      <Grid container
        sx={ {
          padding: '1rem',
        } }
      >
        <Grid container>
          <Grid item xs={ 12 } sm={ 6.6 } md={ 5 }>
            <TextInput source="title" fullWidth required />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={ 12 } sm={ 8 } md={ 6 }>
            <ArrayInput source="charts" defaultValue={ [ {} ] }>
              <SimpleFormIterator getItemLabel={ index => `Chart ${ index + 1 }` }>
                <FormDataConsumer>
                  { ( { scopedFormData } ) => scopedFormData !== undefined ?
                    <>
                      <ReferenceArrayInput source='subjects' reference='reputationsubjects' filter={ subjectsFilter }>
                        <Tooltip title={ <>Filter by individual Locations.<br />Leave blank to include all Locations.</> } placement='top' arrow>
                          <AutocompleteArrayInput label='Locations' fullWidth
                          // placeholder='All Locations'
                          />
                        </Tooltip>
                      </ReferenceArrayInput>
                      <ReferenceInput source='platforms' reference='reputationplatforms'>
                        <Tooltip title={ <>Filter by Platforms that collect reviews.<br />Leave blank to include all Platforms.</> } placement='top' arrow>
                          <AutocompleteArrayInput fullWidth
                          //placeholder='All Platforms'
                          />
                        </Tooltip>
                      </ReferenceInput>
                      <Grid container columnSpacing={ 2 } >
                        <Grid item xs={ 12 } sm={ 4 } >
                          <NumberInput source='duration.count' label="Duration" min={ 1 } defaultValue={ 6 } fullWidth required />
                        </Grid>
                        <Grid item xs={ 12 } sm={ 8 } >
                          <RadioButtonGroupInput
                            source='duration.type'
                            label=""
                            defaultValue='months'
                            choices={ [
                              { id: 'months', name: 'Months' },
                              { id: 'years', name: 'Years' },
                            ] }
                          />
                        </Grid>
                      </Grid>
                      <ReputationVisualizationChartTitleInput
                        source='title'
                        subjects={ scopedFormData.subjects }
                        platforms={ scopedFormData.platforms }
                      />
                    </>
                    : null }
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>
        <SaveButton />
      </Grid>
    </FormWithLocation> )
  );
}

export const ReputationVisualizationEdit: FC<EditProps> = props => {
  const { data: organizations } = useGetList<{ name: string } & RaRecord>( 'organizations' );
  const transform = useCallback( ( data: RaRecord ) => {
    return {
      ...data,
      charts: _.map( data.charts, chart => {
        const value = _.pickBy( chart, x => x );
        return {
          ...value,
          organizations: value.organizations?.length ? value.organizations : organizations && [ organizations[ 0 ].name ],
        };
      } ),
    };
  }, [ organizations ] );

  return (
    <Edit { ...props }
      actions={
        <TopToolbar>
          <ShowButton />
          <DeleteVisualizationButton />
        </TopToolbar>
      }
      title={ <ReputationVisualizationTitle /> }
      redirect='show'
      transform={ transform }
    >
      <ReputationVisualizationForm />
    </Edit>
  );
}

export const ReputationVisualizationCreate: FC<CreateProps> = props => {
  const { identity, isLoading } = useGetIdentity();
  const userId = identity?.id;
  const { data: organizations } = useGetList<{ name: string } & RaRecord>( 'organizations' );

  const transform = useCallback( ( data: RaRecord ): RaRecord => ( {
    ...data,
    charts: _.map( data.charts, chart => {
      const value = _.pickBy( chart, x => x );
      return {
        ...value,
        organizations: value.organizations?.length ? value.organizations : organizations && [ organizations[ 0 ].name ],
      }
    } ),
    userId,
  } ), [ organizations ] );

  if( isLoading ) return <></>;
  return (
    <Create { ...props }
      transform={ transform }
      redirect='show'
    >
      <ReputationVisualizationForm />
    </Create>
  )
};
