import { FC } from 'react';
import { AutocompleteInput, Datagrid, DateField, DateInput, Filter, FilterProps, ImageField, List, ListProps, ReferenceField, ReferenceInput, TextField, WithRecord } from 'react-admin';
import MyUrlField from './MyUrlField';
import { Childless } from './types';

export { default as ReputationReferralIcon } from '@mui/icons-material/Mail';

const ReputationReferralFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <DateInput label="Date" source="createdAt" alwaysOn />
    <ReferenceInput label="Location" source="location" reference="locations" allowEmpty filter={ { status: 'active' } } sort={ { field: 'name', order: 'ASC' } }>
      <AutocompleteInput source="name" />
    </ReferenceInput>
    { /*
       <ReferenceInput label="Recipient" source="recipient" reference="recipients" alwaysOn allowEmpty filter={ { active: true } } sort={ { field: 'fullName', order: 'ASC' } } >
       <AutocompleteInput source="fullName" />
       </ReferenceInput>
       <ReferenceInput label="Service" source="reputationService" reference="reputationplatforms" alwaysOn allowEmpty >
       <AutocompleteInput source="name"
       sx={ {
       width: '15rem',
       } }
       />
       </ReferenceInput>
     */}
  </Filter>
);

export const ReputationReferralList: FC<Childless<ListProps>> = props => (
  <List { ...props }
    exporter={ false }
    filters={ <ReputationReferralFilter /> }
    sort={ { field: 'createdAt', order: 'DESC' } }
  >
    <Datagrid bulkActionButtons={ false }>
      <DateField label="Last Viewed" source="createdAt" showTime={ true } />
      <ReferenceField label="Recipient" source="recipient" reference="recipients" link={ false }>
        <TextField source="fullName" />
      </ReferenceField>
      <ReferenceField label="Location" source="location" reference="locations" link={ false }>
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField label='Service' source='reputationService.id' reference='reputationplatforms' >
        <WithRecord render={ ( record ) => (
          <ImageField source='src' record={ { src: `/${ record.id }.png` } } sx={ {
            '& .RaImageField-image': {
              width: '60px',
              height: '30px',
            }
          } } />
        ) } />
      </ReferenceField>

      <MyUrlField label="Review page" source='reputationService.url'
        style={ {
          textDecoration: 'none',
        } }
        rel="noopener"
        target="_blank"
        onClick={ e => { e.stopPropagation(); } }
      />


    </Datagrid>
  </List>
);
