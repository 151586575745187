import { createContext, FC, ReactNode, useCallback, useContext, useMemo } from 'react';
import { RaRecord, useCreate, useDelete, useGetList, useUpdate } from 'react-admin';

export interface UserPreference extends RaRecord {
  key: string;
  value: string;
}

export interface UserPreferenceUpdateResponse {
  error?: string;
  message?: string;
}

export type UserPreferences = Record<string, unknown>;
export type UserPreferenceUpdate = ( key: string, value: unknown ) => Promise<UserPreferenceUpdateResponse>;
export type UserPreferenceReset = ( key: string ) => Promise<void>;

export interface UserPreferenceProviderContext {
  preferences?: UserPreferences;
  isLoading?: boolean;
  isUpdating?: boolean;
  update?: UserPreferenceUpdate;
  reset?: UserPreferenceReset;
}

export const UserPreferenceContext = createContext<UserPreferenceProviderContext>( {} );

export const useUserPreference = () => useContext( UserPreferenceContext );

interface UserPreferenceProviderProps {
  children: ReactNode;
}

export const UserPreferenceProvider: FC<UserPreferenceProviderProps> = ( { children } ) => {
  const resource = 'userpreferences';
  const { data: userPreferences, isLoading, refetch } = useGetList<UserPreference>( resource );
  const [ _update, { isPending } ] = useUpdate();
  const [ _create, { isPending: isCreating } ] = useCreate();
  const [ _delete, { isPending: isDeleting } ] = useDelete();

  const preferences = useMemo<UserPreferences>( () => {
    if( !userPreferences ) return {};
    return Object.fromEntries( userPreferences.map( ( { key, value } ) => {
      try {
        return [ key, value ? JSON.parse( value ) : value ]
      } catch( _e ) {
        return [ key, undefined ];
      }
    } ) );
  }, [ userPreferences ] );

  const update = useCallback<UserPreferenceUpdate>( async ( key, _value ) => {
    const returnPromise = true;
    const mutationMode = 'pessimistic';
    const value = JSON.stringify( _value );
    try {
      const previousData = userPreferences?.find( p => p.key == key );
      // const previousData = preferences ? preferences[ key ] : undefined;
      if( previousData ) {
        const { id } = previousData;
        await _update( resource, { id, previousData, data: { ...previousData, value } }, { returnPromise, mutationMode } );
      } else {
        await _create( resource, { data: { key, value } }, { returnPromise } );
      }
      await refetch();
      return { message: `Saved preference` };
    } catch( _e ) {
      return { error: `Error saving preference` };
    }
  }, [ _create, _update, resource, userPreferences, preferences ] )

  const reset = useCallback<UserPreferenceReset>( async ( key ) => {
    const mutationMode = 'pessimistic';
    try {
      const previousData = userPreferences?.find( p => p.key == key );
      if( previousData ) {
        const { id } = previousData;
        await _delete( resource, { id }, { mutationMode } );
      }
      await refetch();
    } catch( _e ) {
      return;
    }
  }, [ _create, _update, resource, userPreferences, preferences ] )

  const context = useMemo( () => ( {
    preferences,
    isLoading,
    isUpdating: isPending || isCreating || isDeleting,
    update,
    reset,
  } ), [ preferences, isLoading, isPending, isCreating, update ] );

  return (
    <UserPreferenceContext.Provider value={ context }>
      { children }
    </UserPreferenceContext.Provider>
  );

}

