import { FC, ReactNode } from 'react';
import { ResourceContext, ResourceContextValue } from 'react-admin';


export interface ResourceContextProviderProps {
  children: ReactNode;
  value: ResourceContextValue;
}

export const ResourceContextProvider: FC<ResourceContextProviderProps> = ( { children, value } ) => {
  return (
    <ResourceContext.Provider value={ value }>
      { children }
    </ResourceContext.Provider>
  );
}

