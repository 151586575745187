/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { FC } from 'react';
import { Datagrid, Edit, EditProps, Labeled, List, ListProps, SelectField, SimpleFormProps, TextField, TextInput } from 'react-admin';
import { AdminSimpleForm, useAdminListAppLocation } from './Admin';
import { choices } from './DataProvider';
import { CustomToolbar } from './ReminderTemplateLists';
import { TimestampsField } from './TimestampsField';

export { default as AppointmentUpdateTextIcon } from '@mui/icons-material/Title';

export const AppointmentUpdateTextList: FC<ListProps> = props => {
  useAdminListAppLocation();
  return (
    <List
      { ...props }
      exporter={ false }
      perPage={ 25 }
    >
      <Datagrid rowClick="edit" bulkActionButtons={ false } >

        <SelectField label="Status" source="id" choices={ choices.appointmentStatus } />
        <TextField label="Wording" source="text" fullWidth />
        {/* <TextField source="comment" fullWidth /> */ }
        <TimestampsField label="Updated" source="updatedAt" />
      </Datagrid>
    </List>
  );
}

// TODO set comment to edited in UI
const AppointmentUpdateTextForm: FC<Omit<SimpleFormProps, 'children'>> = props => (
  <AdminSimpleForm sanitizeEmptyValues  { ...props } toolbar={ <CustomToolbar /> } >

    <Labeled label='Status {{appointmentStatus}}'>
      <SelectField source="id" choices={ choices.appointmentStatus } />
    </Labeled>

    <TextInput label='Message {{appointmentStatusChangeMessage}}' source="text" fullWidth />

    <TextInput source="comment" fullWidth />

  </AdminSimpleForm>
);

export const AppointmentUpdateTextEdit: FC<EditProps> = props => ( <Edit redirect='list' { ...props } ><AppointmentUpdateTextForm /></Edit> );

