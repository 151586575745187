import { Handle, Position, NodeProps } from '@xyflow/react';
import { AutoFixHigh as MagicWand } from '@mui/icons-material';
import { Box, Card, CardContent, SxProps, Typography } from '@mui/material';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { IfCanAccess } from '@react-admin/ra-rbac';
import { Background, BackgroundVariant, Controls, ReactFlow, Edge, Node, OnNodesChange, OnEdgesChange, OnConnect, addEdge, ReactFlowProvider, useReactFlow, ConnectionLineType, MiniMap, ControlButton, BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath, EdgeChange } from '@xyflow/react';
import { ChangeEvent, FC, ReactElement, ReactNode, useEffect, useRef } from 'react';
import { Title } from 'react-admin';
import '@xyflow/react/dist/style.css';
import { useState, useCallback } from 'react';
import { applyEdgeChanges, applyNodeChanges } from '@xyflow/react';
import { FlowDevTools } from './FlowDevTools';
import './FlowDevTools.css';
import { graphlib, layout } from '@dagrejs/dagre';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';


const triggers = [
  // Timers
  'Every minute',
  'Every hour',
  // Webhooks
  'Sms Sent',
  'Email Sent',
  'Voice Sent',
  // Resource events

  ...( 'Appointment Patient Practitioner Location Encounter'.split( / +/ ).flatMap( r => [
    `${ r } added`,
    `${ r } updated`,
  ] ) ),
  'Incoming email',
  'Incoming sms',
  'Patient birthday',
];

const actions = [
  // EMR
  'EMR import query',

  // Messaging
  'Send message',

  // Scripting
  'If/Else',
  'Condition',
  'Split',
  'Wait',
  'Goal event',
  'Update value',

];


const initialNodes: Node[] = [
  {
    id: '0',
    data: { label: 'Trigger 1' },
    position: { x: 0, y: 0 },
    type: 'input',
  },
  {
    id: '1',
    data: { label: 'Trigger 2' },
    position: { x: 0, y: 0 },
    type: 'input',
  },
  {
    id: '2',
    data: { label: 'Action 1' },
    position: { x: 100, y: 100 },
  },
  {
    id: '3',
    data: { label: 'Action 2' },
    position: { x: 200, y: 100 },
  },
  {
    id: '4',
    data: { label: 'End 1' },
    position: { x: 0, y: 0 },
    type: 'output',
  },
  {
    id: '5',
    data: { label: 'Try' },
    position: { x: 0, y: 0 },
    type: 'selectorNode',
  },
  {
    id: '5',
    data: { label: 'Try', splits: 3 },
    position: { x: 0, y: 0 },
    type: 'split',
  },
  {
    id: '6',
    data: { label: 'Action 6' },
    position: { x: 200, y: 100 },
  },
  {
    id: '11',
    data: { label: 'Wait trigger 11' },
    position: { x: 200, y: 100 },
    type: 'wait',
  },
  {
    id: '7',
    data: { label: 'End 7' },
    position: { x: 0, y: 0 },
    type: 'output',
  },
  {
    id: '8',
    data: { label: 'Wait trigger 8' },
    position: { x: 200, y: 100 },
    type: 'wait',
  },
  {
    id: '12',
    data: { label: 'Action 12' },
    position: { x: 0, y: 0 },
  },
  {
    id: '9',
    data: { label: 'End 9' },
    position: { x: 0, y: 0 },
    type: 'output',
  },
  {
    id: '13',
    data: { label: 'Action 13' },
    position: { x: 0, y: 0 },
  },
  {
    id: '10',
    data: { label: 'End 10' },
    position: { x: 0, y: 0 },
    type: 'output',
  },
];

const initialEdges: Edge[] = [
  { id: '0-2', source: '0', target: '2', type: 'button' },
  { id: '1-5', source: '1', target: '5', type: 'button' },
  { id: '2-3', source: '2', target: '3', type: 'button' },
  { id: '3-4', source: '3', target: '4', type: 'button' },

  { id: '5-6', source: '5', target: '6', type: 'button', sourceHandle: 'b' },
  { id: '6-11', source: '6', target: '11', type: 'button' },
  { id: '11-7', source: '11', target: '7', type: 'button' },

  { id: '5-8', source: '5', target: '8', type: 'button', sourceHandle: 'c' },
  { id: '8-12', source: '8', target: '12', type: 'button' },
  { id: '12-9', source: '12', target: '9', type: 'button' },

  { id: '5-13', source: '5', target: '13', type: 'button', sourceHandle: 'a' },
  { id: '13-10', source: '13', target: '10', type: 'button' },
];



const getLayoutedElements = ( nodes: Node[], edges: Edge[], options = { direction: 'vertical' } ) => {
  const g = new graphlib.Graph().setDefaultEdgeLabel( () => ( {} ) );
  g.setGraph( { rankdir: options.direction } );

  edges.forEach( ( edge ) => g.setEdge( edge.source, edge.target ) );
  nodes.forEach( ( node ) =>
    g.setNode( node.id, {
      ...node,
      width: node.measured?.width ?? 0,
      height: node.measured?.height ?? 0,
    } ),
  );

  layout( g );

  return {
    nodes: nodes.map( ( node ) => {
      const position = g.node( node.id );
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      const x = position.x - ( node.measured?.width ?? 0 ) / 2;
      const y = position.y - ( node.measured?.height ?? 0 ) / 2;

      return { ...node, position: { x, y } };
    } ),
    edges,
  };
};

export interface LayoutFlowProps {
  nodes?: Node[];
  edges?: Edge[];
}

export const LayoutFlow: FC<LayoutFlowProps> = props => {
  const { nodes: startingNodes = initialNodes, edges: startingEdges = initialEdges } = props;
  const [ nodes, setNodes ] = useState( startingNodes );
  const [ edges, setEdges ] = useState( startingEdges );
  const [ autoLayout, setAutoLayout ] = useState( true );
  const { fitView, screenToFlowPosition } = useReactFlow();

  const onDrop: OnDrop = useCallback( ( item, monitor ) => {
    const { type, name: label } = item;
    console.log( 'dropped', item );
    // check if the dropped element is valid
    if( !type ) {
      return;
    }

    const position = screenToFlowPosition( monitor.getClientOffset() || { x: 0, y: 0 } );
    const newNode = {
      id: nodes.length.toString(),
      type,
      position,
      data: { label: label || `${ type } node` },
    };

    setNodes( ( nds ) => nds.concat( newNode ) );
  }, [ nodes, setNodes, screenToFlowPosition ] );

  const [ { isOver }, dropRef ] = useDrop<DragObject, void, DropPreview>( () => ( {
    accept: 'node',
    drop: onDrop,
    collect: monitor => ( {
      isOver: !!monitor.isOver(),
    } ),
  } ), [ onDrop ] );

  useEffect( () => {
    if( !autoLayout ) return;
    const layouted = getLayoutedElements( nodes, edges, { direction: 'vertical' } );
    setNodes( [ ...layouted.nodes ] );
    setEdges( [ ...layouted.edges ] );
    window.requestAnimationFrame( () => {
      fitView();
    } );
  }, [ nodes, edges, autoLayout ] );

  const onNodesChange = useCallback<OnNodesChange>( changes => setNodes( ( nodes ) => applyNodeChanges( changes, nodes ) ), [] );
  const onEdgesChange = useCallback<OnEdgesChange>( changes => setEdges( ( edges ) => applyEdgeChanges( changes, edges ) ), [] );
  const onConnect = useCallback<OnConnect>( params => setEdges( ( edges ) => addEdge( params, edges ) ), [] );

  const edgeTypes = {
    button: ButtonEdge
  };

  const nodeTypes = {
    selectorNode: ColorSelectorNode,
    split: MultisplitNode,
    wait: WaitNode,
  };

  return (
    <Box
      sx={ {
        // minHeight: '60rem',
        // width: '100%',
        border: '1px solid gray',
        display: 'flex',
        '& .react-flow__controls .react-flow__controls-button ': {
          width: '48px',
          height: '48px',
          '& svg': {
            maxWidth: '80%',
            maxHeight: '80%',
          }
        }
      } }>
      <Box
        ref={ dropRef }
        sx={ {
          width: '100%', // '80rem',
          height: '60rem',
          flexGrow: 1,
          border: '1px solid',
          borderColor: isOver ? 'red' : 'white',
          '& .react-flow__node-input': {
            borderColor: 'red',
          },
        } } >
        <ReactFlow

          nodes={ nodes }
          onNodesChange={ onNodesChange }
          edges={ edges }
          onEdgesChange={ onEdgesChange }
          onConnect={ onConnect }
          fitView
          defaultEdgeOptions={ {
            // type: ConnectionLineType.SmoothStep,
            type: 'button',
          } }
          edgeTypes={ edgeTypes }
          nodeTypes={ nodeTypes }
        >
          <Background
            variant={ BackgroundVariant.Cross }
            bgColor={ '#f3f3f9' }
          />
          <Controls orientation='horizontal' >
            <ControlButton onClick={ () => setAutoLayout( auto => !auto ) }>
              <MagicWand color={ autoLayout ? 'error' : undefined } />
            </ControlButton>
          </Controls>
          <MiniMap
            nodeStrokeWidth={ 6 }
            nodeStrokeColor={ ( n: Node ) => {
              if( n.type === 'input' ) return 'red';
              if( n.type === 'selectorNode' ) return 'green';
              if( n.type === 'split' ) return 'purple';
              if( n.type === 'wait' ) return 'red';
              if( n.type === 'output' ) return 'blue';
              return 'black';
            } }
          // nodeColor={ ( n ) => {
          //   // if( n.type === 'selectorNode' ) return bgColor;
          //   return '#fff';
          // } }
          />
          <FlowDevTools />
        </ReactFlow>
      </Box>
      <Sidebar />
    </Box>
  );
}

export const LayoutFlowView: FC<LayoutFlowProps> = props => {
  const { nodes: startingNodes = [], edges: startingEdges = [] } = props;
  const [ nodes, setNodes ] = useState( startingNodes );
  const [ edges, setEdges ] = useState( startingEdges );
  const [ autoLayout, setAutoLayout ] = useState( true );
  const { fitView } = useReactFlow();

  useEffect( () => {
    if( !autoLayout ) return;
    const layouted = getLayoutedElements( nodes, edges, { direction: 'vertical' } );
    setNodes( [ ...layouted.nodes ] );
    setEdges( [ ...layouted.edges ] );
    window.requestAnimationFrame( () => {
      fitView();
    } );
  }, [ autoLayout ] );

  const onNodesChange = useCallback<OnNodesChange>( changes => setNodes( ( nodes ) => applyNodeChanges( changes, nodes ) ), [] );
  const onEdgesChange = useCallback<OnEdgesChange>( changes => setEdges( ( edges ) => applyEdgeChanges( changes, edges ) ), [] );
  const onConnect = useCallback<OnConnect>( params => setEdges( ( edges ) => addEdge( params, edges ) ), [] );

  const edgeTypes = {
    button: ButtonEdge
  };

  const nodeTypes = {
    selectorNode: ColorSelectorNode,
    split: MultisplitNode,
    wait: WaitNode,
  };

  return (
    <Box
      sx={ {
        // minHeight: '60rem',
        // width: '100%',
        border: '1px solid gray',
        display: 'flex',
        '& .react-flow__controls .react-flow__controls-button ': {
          width: '48px',
          height: '48px',
          '& svg': {
            maxWidth: '80%',
            maxHeight: '80%',
          }
        }
      } }>
      <Box
        sx={ {
          width: '100%', // '80rem',
          height: '60rem',
          flexGrow: 1,
          border: '1px solid',
          borderColor: 'white',
          '& .react-flow__node-input': {
            borderColor: 'red',
          },
        } } >
        <ReactFlow

          nodes={ nodes }
          onNodesChange={ onNodesChange }
          edges={ edges }
          onEdgesChange={ onEdgesChange }
          onConnect={ onConnect }
          fitView
          defaultEdgeOptions={ {
            // type: ConnectionLineType.SmoothStep,
            type: 'button',
          } }
          edgeTypes={ edgeTypes }
          nodeTypes={ nodeTypes }
        >
          <Background
            variant={ BackgroundVariant.Cross }
            bgColor={ '#f3f3f9' }
          />
          <Controls orientation='horizontal' >
            <ControlButton onClick={ () => setAutoLayout( auto => !auto ) }>
              <MagicWand color={ autoLayout ? 'error' : undefined } />
            </ControlButton>
          </Controls>
          <MiniMap
            nodeStrokeWidth={ 6 }
            nodeStrokeColor={ ( n: Node ) => {
              if( n.type === 'input' ) return 'red';
              if( n.type === 'selectorNode' ) return 'green';
              if( n.type === 'split' ) return 'purple';
              if( n.type === 'wait' ) return 'red';
              if( n.type === 'output' ) return 'blue';
              return 'black';
            } }
          // nodeColor={ ( n ) => {
          //   // if( n.type === 'selectorNode' ) return bgColor;
          //   return '#fff';
          // } }
          />
          <FlowDevTools />
        </ReactFlow>
      </Box>
    </Box>
  );
}


export const Flow: FC = () => {
  const basePath = 'flow';
  const baseLocation = `admin.${ basePath }`;
  const [ location, setLocation ] = useAppLocationState();

  useEffect( () => {
    setLocation( `${ baseLocation }` );
    return () => setLocation( null );
  }, [] );


  return (
    <IfCanAccess resource='system' action='manage'>
      <ReactFlowProvider>
        <Box>
          <Title title='Text ' />
          <Card
            sx={ {
              minHeight: '25em',
            } }
          >
            <CardContent>
              <Typography color='gray'>{ location.path }</Typography>
              <LayoutFlow />
            </CardContent>
          </Card>


        </Box >
      </ReactFlowProvider>
    </IfCanAccess>
  );
}


export const Sidebar: FC = () => {

  return (
    <Box
      sx={ {
        height: '60rem',
        borderRight: '1px solid #eee',
        padding: '15px 10px',
        fontSize: '15px',
        background: '#fcfcfc',
        overflowY: 'scroll',
        '& .description': {
          marginBottom: '10px',
        },

      } }
    >
      <Box className="description">
        You can drag these nodes to the pane on the right.
      </Box>
      <Typography // variant='h6'
        sx={ {
          // fontWeight: 'bold',
          textTransform: 'uppercase',
          color: '#0041d0',
        } } > Triggers</Typography>
      <FlowMenuNode key='input' type='input' sx={ { borderColor: '#0041d0' } } >
        Input Node
      </FlowMenuNode>
      {
        triggers.map( trigger => (
          <FlowMenuNode key={ trigger } name={ trigger } type='input' sx={ { borderColor: '#0041d0' } } >
            { trigger }
          </FlowMenuNode>
        ) )
      }
      <Typography // variant='h6'
        sx={ {
          // fontWeight: 'bold',
          textTransform: 'uppercase',
          // color: '#0041d0',
        } } >Actions</Typography>
      <FlowMenuNode >
        Default Node
      </FlowMenuNode>
      {
        actions.map( action => (
          <FlowMenuNode key={ action } name={ action } >
            { action }
          </FlowMenuNode>
        ) )
      }
      <Typography // variant='h6'
        sx={ {
          // fontWeight: 'bold',
          textTransform: 'uppercase',
          color: '#ff0072',
        } } >Outputs</Typography>
      <FlowMenuNode type='output' sx={ { borderColor: '#ff0072' } } >
        Output Node
      </FlowMenuNode>

    </Box >
  );
};


export interface DropPreview {
  isOver: boolean;
  border?: string;
}

export interface DragObject {
  type: string;
  name?: string;
}

export interface DragPreview {
  opacity: number;
  border?: string;
}

export interface DropResult {
}

export type OnDrop = ( item: DragObject, monitor: DropTargetMonitor<DragObject, DropResult> ) => void | undefined;

export interface FlowMenuNodeProps {
  children: ReactNode;
  name?: string;
  type?: string;
  sx?: SxProps;
}

export const FlowMenuNode: FC<FlowMenuNodeProps> = props => {
  const { type = 'default', name, children, sx = {} } = props;
  const [ { opacity, border }, dragRef ] = useDrag<DragObject, DropResult, DragPreview>( () => ( {
    type: 'node',
    item: { type, name },
    // canDrag: !disabled,
    collect: ( monitor ) => ( {
      opacity: monitor.isDragging() ? 0.5 : 1,
      border: monitor.isDragging() ? '1ps solid purple' : undefined,
    } )
  } ), [ type ] );

  return (
    <Box
      sx={ {
        // height: '20px',
        padding: '8px',
        border: '2px solid #1a192b',
        borderRadius: '4px',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'grab',
        ...sx
      } }
      ref={ dragRef }
    >
      { children }
    </Box>
  );
}

export function ButtonEdge( {
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}: EdgeProps ) {
  const { getEdges, setEdges, setNodes } = useReactFlow();
  const [ edgePath, labelX, labelY ] = getBezierPath( {
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  } );

  const onEdgeClick = () => {
    const edges = getEdges()
    const edge = edges.find( edge => edge.id == id );
    if( !edge ) return;
    const { source, target, sourceHandle, targetHandle } = edge;
    const inserted: Node = {
      id: edges.length.toString(),
      data: { label: 'New action' },
      position: { x: 0, y: 0 },
    };
    const newEdges: Edge[] = [
      { id: `${ source }-${ inserted.id }`, source, sourceHandle, target: inserted.id, type: 'button' },
      { id: `${ inserted.id } - ${ target }`, source: inserted.id, target, targetHandle, type: 'button' },
    ];
    setNodes( nodes => applyNodeChanges( [ { item: inserted, type: 'add' } ], nodes ) );
    setEdges( edges => applyEdgeChanges( [
      { id: edge.id, type: 'remove' },
      ...newEdges.map( item => ( { item, type: 'add' } as EdgeChange ) ),
    ], edges ) );
    //      
  };

  return (
    <>
      <BaseEdge path={ edgePath } markerEnd={ markerEnd } style={ style } />
      <EdgeLabelRenderer>
        <div
          style={ {
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${ labelX }px,${ labelY }px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all',
          } }
          className="nodrag nopan"
        >
          <button className="edgebutton" onClick={ onEdgeClick }>
            +
          </button>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}


export interface ColorSelectorNodeProps extends NodeProps {
  data: {
    color: string;
    onChange: ( e: ChangeEvent<HTMLInputElement> ) => void;
  };
}

export const ColorSelectorNode: FC<ColorSelectorNodeProps> = props => {
  const { data, isConnectable } = props;

  return (
    <Box
      sx={ {
        fontSize: '12px',
        background: '#eee',
        border: '1px solid #555',
        borderRadius: '5px',
        textAlign: 'center',
      } }
    >
      <Handle
        type="target"
        position={ Position.Left }
        style={ { background: '#555' } }
        onConnect={ ( params ) => console.log( 'handle onConnect', params ) }
        isConnectable={ isConnectable }
      />
      <div>
        Custom Color Picker Node: <strong>{ data.color }</strong>
      </div>
      <input
        className="nodrag"
        type="color"
        onChange={ data.onChange }
        defaultValue={ data.color }
      />
      <Handle
        type="source"
        position={ Position.Right }
        id="a"
        style={ { top: 10, background: '#555' } }
        isConnectable={ isConnectable }
      />
      <Handle
        type="source"
        position={ Position.Right }
        id="b"
        style={ { bottom: 10, top: 'auto', background: '#555' } }
        isConnectable={ isConnectable }
      />
    </Box>
  );
}

export interface MultisplitNodeProps extends NodeProps {
  data: {
    splits: number
    // onChange: ( e: ChangeEvent<HTMLInputElement> ) => void;
  };
}

export const MultisplitNode: FC<MultisplitNodeProps> = props => {
  const { data, isConnectable } = props;
  const { splits = 2 } = data;

  return (
    <Box
      className='splitNode'
      sx={ {
        fontSize: '12px',
        background: '#fff',
        border: '1px solid purple',
        borderRadius: '5px',
        textAlign: 'center',
        minWidth: '12rem',
        padding: '1rem',
      } }
    >
      <Handle
        type="target"
        position={ Position.Top }
        style={ { background: '#555' } }
        onConnect={ ( params ) => console.log( 'handle onConnect', params ) }
        isConnectable={ isConnectable }
      />
      <div>
        Split: <strong>{ data.splits }</strong>
      </div>
      <Handle
        type="source"
        position={ Position.Bottom }
        id="a"
        style={ { left: 10, background: '#555' } }
        isConnectable={ isConnectable }
      />
      <Handle
        type="source"
        position={ Position.Bottom }
        id="b"
        style={ { background: '#555' } }
        isConnectable={ isConnectable }
      />
      <Handle
        type="source"
        position={ Position.Bottom }
        id="c"
        style={ { right: 10, left: 'auto', background: '#555' } }
        isConnectable={ isConnectable }
      />
    </Box>
  );
}

export interface WaitNodeProps extends NodeProps<Node<{ label: string }, 'wait'>> {
  // data: {
  //   // splits: number
  //   // onChange: ( e: ChangeEvent<HTMLInputElement> ) => void;
  // };
}

export const WaitNode: FC<WaitNodeProps> = props => {
  const { data, isConnectable } = props;

  return (
    <Box
      className='waitNode'
      sx={ {
        fontSize: '12px',
        background: '#fff',
        border: '1px solid red',
        borderRadius: '5px',
        textAlign: 'center',
        minWidth: '12rem',
        padding: '1rem',
      } }
    >
      <Handle
        type="target"
        position={ Position.Top }
        style={ { background: '#555' } }
        onConnect={ ( params ) => console.log( 'handle onConnect', params ) }
        isConnectable={ isConnectable }
      />
      <div>
        { data.label }
      </div>
      <Handle
        type="source"
        position={ Position.Bottom }
        style={ { background: '#555' } }
        isConnectable={ isConnectable }
      />
    </Box>
  );
}

