import { Box, Button, Card, CardContent, Link, Tab, Tabs } from '@mui/material';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { useCanAccess } from '@react-admin/ra-rbac';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { ListProps, Title } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReputationConnectionsShow } from './ReputationConnections';
import { ReputationReportScheduleList } from './ReputationReportSchedules';
import { ReputationVisualizationList } from './ReputationVisualizations';
import { ResourceContextProvider } from './ResourceContextProvider';
import { Childless } from './types';

const ReputationExplorer: FC<Childless<ListProps>> = () => (
  <Button variant='contained' sx={ { margin: 16 } }>
    <Link underline='none' href="https://reputation.analoginformation.com/#/demo" target="_blank" rel="noopener" color="inherit" >
      Reputation Explorer
    </Link>
  </Button>
);

export const Reputation: FC = () => {
  const basePath = 'reputation';
  const tabs = [
    'reputationvisualizations',
    'reputationreportschedules',
    // 'reputationconnections',
    'reputationexplorer',
  ];
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [ _location, setLocation ] = useAppLocationState();
  const [ tab, setTab ] = useState( tabs[ 0 ] );
  const { canAccess: isAdmin, isLoading } = useCanAccess( { resource: 'system', action: 'manage' } );

  const handleChange = ( _event: SyntheticEvent, newValue: string ) => {
    navigate( `/${ basePath }/${ newValue }` );
  };

  useEffect( () => {
    setLocation( basePath );
    return () => setLocation( null );
  }, [] );

  useEffect( () => {
    const [ , , tab ] = pathname.split( '/' )
    if( !tabs.includes( tab ) ) return;
    setTab( tab );
  }, [ pathname ] );

  useEffect( () => {
    setLocation( `${ basePath }.${ tab }` );
  }, [ tab ] );

  if( isLoading ) return null;

  return (
    <Box>
      <Title title='Reputation ' />
      <Tabs value={ tab } onChange={ handleChange }>
        <Tab disableRipple value='reputationvisualizations' label='Charts' />
        <Tab disableRipple value='reputationreportschedules' label='Email' />
        {/* <Tab disableRipple value='reputationconnections' label='Connections' /> */ }
        { isAdmin &&
          <Tab disableRipple value='reputationexplorer' label='Explorer' />
        }
      </Tabs>

      <Card
        sx={ {
          minHeight: '25em',
        } }
      >
        <CardContent>
          <ResourceContextProvider value={ tab }>
            { tab == 'reputationexplorer'
              ? <ReputationExplorer title={ <></> } />
              : tab == 'reputationconnections'
                ? <ReputationConnectionsShow />
                : tab == 'reputationreportschedules'
                  ? <ReputationReportScheduleList title={ <></> } />
                  : <ReputationVisualizationList title={ <></> } />
            }
          </ResourceContextProvider>
        </CardContent>
      </Card>

    </Box >
  );
}
