import { FC } from 'react';
import { RaRecord, ReferenceField, ReferenceFieldProps, useRecordContext } from 'react-admin';

export interface PolymorphicReferenceFieldProps extends Omit<ReferenceFieldProps, 'reference'> {
  reference: ( record?: RaRecord ) => ReferenceFieldProps[ 'reference' ];
}

export const PolymorphicReferenceField: FC<PolymorphicReferenceFieldProps> = props => {
  const record = useRecordContext();
  const { reference, ...rest } = props;

  if( !reference( record ) ) return null;

  return (
    <ReferenceField reference={ reference( record ) } { ...rest } />
  );
}
