import { Box, Card, CardContent, Tab, Tabs } from '@mui/material';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Title } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import { IncomingReplyList } from './IncomingReply';
import { IncomingTextList } from './IncomingText';
import { OutboundMessageList } from './OutboundMessages';
import { ReminderList } from './Reminders';
import { ResourceContextProvider } from './ResourceContextProvider';
import { UndeliverableEmailList } from './UndeliverableEmail';

export const Messaging: FC = () => {
  const basePath = 'messaging';
  const tabs = [ 'outboundmessages', 'reminders', 'incomingText', 'incomingReply', 'undeliverableEmail' ];
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [ _location, setLocation ] = useAppLocationState();
  const [ tab, setTab ] = useState( tabs[ 0 ] );

  const handleChange = ( _event: SyntheticEvent, newValue: string ) => {
    navigate( `/${ basePath }/${ newValue }` );
  };

  useEffect( () => {
    setLocation( basePath );
    return () => setLocation( null );
  }, [] );

  useEffect( () => {
    const [ , , tab ] = pathname.split( '/' )
    if( !tabs.includes( tab ) ) return;
    setTab( tab );
  }, [ pathname ] );

  useEffect( () => {
    setLocation( `${ basePath }.${ tab }` );
  }, [ tab ] );

  return (
    <Box>
      <Title title='Messaging ' />
      <Tabs value={ tab } onChange={ handleChange }>
        <Tab disableRipple value='outboundmessages' label='Sent Messages' />
        <Tab disableRipple value='reminders' label='Scheduled Today' />
        <Tab disableRipple value='incomingText' label='Incoming SMS' />
        <Tab disableRipple value='incomingReply' label='Incoming Email' />
        <Tab disableRipple value='undeliverableEmail' label='Undeliverable Email' />
      </Tabs>

      <Card
        sx={ {
          minHeight: '25em',
        } }
      >
        <CardContent>
          <ResourceContextProvider value={ tab }>
            { tab == 'undeliverableEmail'
              ? <UndeliverableEmailList title={ <></> } />
              : tab == 'incomingReply'
                ? <IncomingReplyList title={ <></> } />
                : tab == 'incomingText'
                  ? <IncomingTextList title={ <></> } />
                  : tab == 'reminders'
                    ? <ReminderList title={ <></> } />
                    : <OutboundMessageList title={ <></> } />
            }
          </ResourceContextProvider>
        </CardContent>
      </Card>


    </Box >
  );
}
