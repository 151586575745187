/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { FC } from 'react';
import { BooleanField, BooleanInput, Create, CreateProps, Datagrid, Edit, EditProps, email, EmailField, List, ListProps, SimpleFormProps, TextField, TextInput } from 'react-admin';
import { AdminSimpleForm, useAdminListAppLocation } from './Admin';
import { AwesomePhoneField, AwesomePhoneInput } from './AwesomePhoneInput';
import { TimestampsField } from './TimestampsField';
import { Childless } from './types';

export { default as EvaluatorIcon } from '@mui/icons-material/AssignmentInd';

export const EvaluatorList: FC<ListProps> = props => {
  useAdminListAppLocation();
  return (
    <List
      exporter={ false }
      { ...props }
    >
      <Datagrid rowClick="edit" bulkActionButtons={ false } >

        <TextField source="name" fullWidth />
        <BooleanField source="emailEval" />
        <EmailField source="emailAddress" />

        <BooleanField source="smsEval" />
        <BooleanField source="voiceEval" />
        <AwesomePhoneField source="phoneNumber" />
        <TimestampsField source='updatedAt' />
      </Datagrid>
    </List>
  );
}

export const EvaluatorForm: FC<Childless<SimpleFormProps>> = props => (
  <AdminSimpleForm sanitizeEmptyValues { ...props } >

    <TextInput source="name" fullWidth required />

    <BooleanInput source="emailEval" />
    <TextInput label="Email" source="emailAddress" fullWidth validate={ email() } />

    <BooleanInput source="smsEval" />
    <BooleanInput source="voiceEval" />
    <AwesomePhoneInput source="phoneNumber" fullWidth />


  </AdminSimpleForm >
);


export const EvaluatorCreate: FC<CreateProps> = props => ( <Create redirect='show' { ...props } ><EvaluatorForm /></Create> );
export const EvaluatorEdit: FC<EditProps> = props => ( <Edit redirect='list'  { ...props } mutationMode='pessimistic' ><EvaluatorForm /></Edit> );
