/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { Box, Link as MuiLink, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { getYear } from 'date-fns';
import { FC } from 'react';


export const AppFooter: FC = () => {
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );

  return (
    <Paper
      sx={ {
        position: 'fixed', bottom: 0, left: 0, right: 0,
        zIndex: 1000,  // needs to be under notifications at zIndex 1400 (Mui Snackbar)
      } }
      elevation={ 3 }>
      <Box
        // p={ 1 }
        sx={ {
          backgroundColor: theme.palette.secondary.main, // aicBlue,
          display: 'flex',
          color: 'white', // theme.palette.grey[ '200' ],
          padding: '0.5rem 2rem',
          flexWrap: isXSmall ? 'wrap' : undefined,
        } }
      >
        <Typography variant="caption" color='inherit'  >
          &copy;2021-{ getYear( new Date() ).toString().slice( 2 ) }
          &nbsp;
          <MuiLink href="https://analoginformatics.com/" target="_blank" rel="noopener" color="inherit" underline='hover'>
            Analog Informatics Corporation
          </MuiLink>
        </Typography>
        <Box sx={ { flexGrow: isXSmall ? 0 : 1 } } />
        <Typography variant="caption" color='inherit'>
          Questions? Contact AIC Support:
          &nbsp;
          <MuiLink href="mailto:support@analoginfo.com" target="_blank" rel="noopener" color="inherit" underline='hover' >support@analoginfo.com</MuiLink>
          &nbsp;&nbsp;


          <MuiLink href="tel:+12142102260" target="_blank" rel="noopener" color="inherit" underline='hover' >(214)&nbsp;210-2260</MuiLink>
        </Typography>
        <Box sx={ { flexGrow: isXSmall ? 0 : 1 } } />
        <Typography variant="caption" color='inherit'  >
          <MuiLink href="https://analoginformatics.com/legal/privacy-policy/" target="_blank" rel="noopener" color="inherit" underline='hover'>
            Privacy policy
          </MuiLink>
        </Typography>
      </Box>
    </Paper>
  );
}
