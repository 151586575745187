import { NorthEast as LaunchIcon } from '@mui/icons-material';
import { Link } from '@mui/material';
import get from 'lodash/get';
import { FC } from 'react';
import { UrlFieldProps, useRecordContext } from 'react-admin';

// MyUrlField.defaultProps = {
//   addLabel: true,
// };

// MyUrlField.propTypes = UrlField.propTypes;
// MyUrlField.displayName = 'MyUrlField';

const MyUrlField: FC<UrlFieldProps & { text?: string }> = props => {
  const { source, text } = props;
  const record = useRecordContext( props );
  const value = get( record, source || '', null );
  if( !value ) {
    return null;
  }

  const makePretty = ( url: string ): string => {
    return url
      .replace( /http(s)?:\/\/(www.)?/, '' )
      .replace( /\/.*/, '' )
      .toLowerCase()
      .split( '.' )
      .slice( -2 )
      .join( '.' );
  }

  return (
    <Link
      sx={ {
        textDecoration: 'none',
      } }
      href={ value }
      rel="noopener"
      target="_blank"
      onClick={ e => { e.stopPropagation(); } }
    >
      <span style={ { whiteSpace: 'nowrap' } } >
        { text || makePretty( value ) }
        <LaunchIcon fontSize="small"
          sx={ {
            verticalAlign: 'bottom',
          } }
        />
      </span>
    </Link>
  );
}


export default MyUrlField;

