import _ from 'lodash';
import { FC, useMemo } from 'react';
import { BulkDeleteButton, CheckboxGroupInput, ChipField, Create, CreateProps, Datagrid, Edit, EditButton, EditProps, FunctionField, List, ListProps, RaRecord, Show, ShowProps, SimpleShowLayout, TextField, TextInput, useCreateContext, useEditContext, useGetList } from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';

import { SimpleFormWithLocation, SimpleShowLayoutWithLocation } from './MyBreadcrumb';
import { Childless } from './types';

export interface ReputationResponseTemplate extends RaRecord {
  body: string,
  description?: string,
  title: string,
  ratings: number[],
}

export const ReputationResponseTemplateList: FC<Childless<ListProps>> = props => {
  const { data: organizations } = useGetList<{ name: string } & RaRecord>( 'organizations' );
  const organizationId = useMemo( () => organizations?.length ? organizations[ 0 ].id : undefined, [ organizations ] );

  if( !organizationId ) return null;
  return (
    <List { ...props }
      exporter={ false }
      filter={ { organization: organizationId } }
    >
      <Datagrid
        bulkActionButtons={ <BulkDeleteButton /> }
        expand={
          <SimpleShowLayout>
            <TextField source='body' />
          </SimpleShowLayout>
        }
        rowClick='expand'
      >
        <TextField source='title' />
        <FunctionField source='ratings' render={ ( record: RaRecord ) => (
          Array.isArray( record.ratings )
            ? record.ratings.map( rating => <ChipField key={ `${ record.id }_${ rating }` } record={ { rating } } source='rating' /> )
            : null
        ) } />
        <TextField source='description' />
        <EditButton />
      </Datagrid>
    </List>
  );
}

export const ReputationResponseTemplateShow: FC<ShowProps> = props => {
  return (
    <Show { ...props }>
      <SimpleShowLayoutWithLocation basePath='reviews'>
        <TextField source='title' />
        <TextField source='description' />
        <FunctionField source='ratings' render={ ( record: RaRecord ) => (
          Array.isArray( record.ratings )
            ? record.ratings.map( rating => <ChipField key={ `${ record.id }_${ rating }` } record={ { rating } } source='rating' /> )
            : null
        ) } />
        <TextField source='body' />
      </SimpleShowLayoutWithLocation>
    </Show>
  )
}

const ReputationResponseTemplateCreateForm: FC = () => {
  const createContext = useCreateContext();
  const queryClient = useQueryClient();
  return (
    ( <SimpleFormWithLocation
      basePath='reviews'
      // @ts-ignore: 2339
      onSubmit={ data => {
        if( !createContext.save ) return;
        createContext.save( data );
        queryClient.invalidateQueries( {
          queryKey: [ createContext.resource, 'getList' ]
        } );
      } }
    >
      <TextInput source='title' fullWidth required />
      <TextInput source='description' fullWidth />
      <CheckboxGroupInput source='ratings' label='Relevant User Ratings' optionText='id' choices={ [ 1, 2, 3, 4, 5 ].map( v => ( { id: v } ) ) } />
      <TextInput source='body' fullWidth multiline minRows={ 4 } maxRows={ 12 } required />
    </SimpleFormWithLocation> )
  );
}

export const ReputationResponseTemplateCreate: FC<CreateProps> = props => {
  const { data: organizations, isLoading: isLoadingOrganizations } = useGetList( 'organizations' );
  if( isLoadingOrganizations ) return null;
  return (
    <Create
      { ...props }
      redirect='/reviews/reputationresponsetemplates'
      transform={ ( doc: RaRecord ) => {
        doc.ratings = _.sortBy( doc.ratings ?? [], v => v )
        if( !doc.organization ) doc.organization = organizations?.length && organizations[ 0 ].id;
        return doc;
      } }
    >
      <ReputationResponseTemplateCreateForm />
    </Create>
  );
}

const ReputationResponseTemplateEditForm: FC = () => {
  const editContext = useEditContext();
  const queryClient = useQueryClient();
  return (
    ( <SimpleFormWithLocation
      basePath='reviews'
      // @ts-ignore: 2339
      onSubmit={ data => {
        if( !editContext.save ) return;
        editContext.save( data );
        queryClient.invalidateQueries( {
          queryKey: [ editContext.resource, 'getList' ]
        } );
      } }
    >
      <TextInput source='title' fullWidth required />
      <TextInput source='description' fullWidth />
      <CheckboxGroupInput source='ratings' label='Relevant User Ratings' optionText='id' choices={ [ 1, 2, 3, 4, 5 ].map( v => ( { id: v } ) ) } />
      <TextInput source='body' fullWidth multiline minRows={ 4 } maxRows={ 12 } required />
    </SimpleFormWithLocation> )
  );
}

export const ReputationResponseTemplateEdit: FC<EditProps> = props => {
  return (
    <Edit
      { ...props }
      transform={ ( doc: RaRecord ) => {
        doc.ratings = _.sortBy( doc.ratings ?? [], v => v )
        return doc;
      } }
      redirect='/reviews/reputationresponsetemplates'
    >
      <ReputationResponseTemplateEditForm />
    </Edit>
  );
}
