import Icon from '@mui/icons-material/LocalOfferOutlined';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { IfCanAccess } from '@react-admin/ra-rbac';
import { FC, ReactElement } from 'react';
import { Edit, List, Show, Create, CreateProps, Datagrid, DateField, EditButton, EditProps, Filter, FilterProps, FunctionField, ListProps, SaveButton, SelectArrayInput, ShowProps, SimpleForm, SimpleList, SimpleShowLayout, Tab, TabbedShowLayout, TextField, TextInput, Toolbar, ToolbarProps } from 'react-admin';
import { useFormState } from 'react-hook-form';
import { RelatedAssets } from './RelatedAssets';
import TextArrayField from './TextArrayField';
import { Childless } from './types';

export const TagIcon = Icon;

const typeChoices = 'reference common nav cuisine'.split( /\s+/ ).map( id => ( { id, name: id } ) );

const TagFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <TextInput label="Tag search" source="q" alwaysOn />
    <SelectArrayInput label="Type" source="type" choices={ typeChoices } />
  </Filter>
);

export const TagList: FC<ListProps> = props => {
  const isSmall = useMediaQuery( ( theme: Theme ) => theme.breakpoints.down( 'md' ) );

  return (
    <List
      filters={ <TagFilter /> }
      { ...props }
    >
      { isSmall
        ? (
          <SimpleList
            primaryText={ record => record.name }
            secondaryText={ record => record.createdAt }
          />
        )
        : (
          <Datagrid rowClick="show" bulkActionButtons={ false } >
            <TextField source="name" />
            <TextArrayField label="Type" source="type" />
            <TextField source="location" />
            <TextField source="id" />
            <DateField label="Updated" source="updatedAt" showTime />
            <DateField label="Created" source="createdAt" />
            <IfCanAccess action="write" >
              <EditButton />
            </IfCanAccess>
          </Datagrid>
        ) }
    </List>
  );
}


const TagTitle: FC<Childless<CreateProps>> = ( props ): ReactElement => {
  return <span>Tag { props.record?.name }</span>;
};


// removes the Delete button
const TagToolbar = ( props: ToolbarProps ) => {
  const { isValid, isDirty } = useFormState();
  return (
    <Toolbar { ...props } >
      <SaveButton disabled={ !isDirty } invalid={ isValid } />
    </Toolbar>
  );
}

// actions={ <ResourceShowActions { ...props } /> }
export const TagShow: FC<ShowProps> = props => (
  <Show
    title={ <TagTitle /> }
    { ...props }>
    <TabbedShowLayout>
      <Tab label="detail" >
        <SimpleShowLayout>
          <TextField source="name" />
          <TextArrayField label="Type" source="type" />
          <TextField source="location" />
          <TextField source="id" />
          <DateField showTime source="createdAt" label="Created" />
          <DateField showTime source="updatedAt" label="Updated" />
        </SimpleShowLayout>
      </Tab>
      <Tab label="assets">
        <FunctionField label={ false } source="name" render={ () => <RelatedAssets source="id" /> } />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const TagEdit: FC<EditProps> = props => {

  return (
    <Edit title={ <TagTitle /> } { ...props }>
      <SimpleForm sanitizeEmptyValues toolbar={ <TagToolbar /> }>
        <TextInput source="name" />
        <SelectArrayInput source="type" choices={ typeChoices } />
        <TextInput source="location" helperText="Latitude,longitude" />
      </SimpleForm>
    </Edit>
  );
}

export const TagCreate: FC<CreateProps> = props => (
  <Create { ...props }>
    <SimpleForm sanitizeEmptyValues toolbar={ <TagToolbar /> }>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);


