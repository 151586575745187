import { useAppLocationState } from '@react-admin/ra-navigation';
import { FC, useEffect } from 'react';
import { Datagrid, Edit, EditProps, List, ListProps, ReferenceField, SelectField, Show, ShowProps, SimpleFormProps, TextField, TextInput } from 'react-admin';
import { AdminShowLayout, AdminSimpleForm } from './Admin';
import { CustomToolbar } from './ReminderTemplateLists';
import { TimestampsField } from './TimestampsField';
import { choices } from './DataProvider';

export { default as StateIcon } from '@mui/icons-material/FormatListBulleted'; // Pending

export const StateList: FC<ListProps> = () => {
  const [ _location, setLocation ] = useAppLocationState();
  useEffect( () => {
    setLocation( 'admin.states' );
    return () => setLocation( null );
  }, [] );

  return (
    <List
      exporter={ false }
      perPage={ 25 }
    >
      <Datagrid
        rowClick="show"
        // expand={ <FlowExpandEdit /> }
        bulkActionButtons={ false }
      >
        <SelectField source="status" choices={ choices.stateStatus } />
        <ReferenceField source='event' reference='events' />
        <ReferenceField source='flow' reference='flows' />
        <TimestampsField source="triggerDate" />
        <TextField source="triggerEvent" />

        <TimestampsField label="Updated" source="updatedAt" />
        <TimestampsField label="Created" source="createdAt" />
      </Datagrid>
    </List>
  );
}

export const StateShow: FC<ShowProps> = () => {
  return (
    <Show
    // actions={ <ShowActionsWithReset /> }
    >
      <AdminShowLayout>
        <SelectField source="status" choices={ choices.stateStatus } />
        <ReferenceField source='event' reference='events' />
        <ReferenceField source='flow' reference='flows' />
        <TimestampsField source="triggerDate" />
        <TextField source="triggerEvent" />

        <TimestampsField label="Updated" source="updatedAt" />
        <TimestampsField label="Created" source="createdAt" />
      </AdminShowLayout>
    </Show>
  );
}

const StateForm: FC<Omit<SimpleFormProps, 'children'>> = props => (
  <AdminSimpleForm sanitizeEmptyValues  { ...props } toolbar={ <CustomToolbar /> } >

    { /* <TextField label="Name" source="id" /> */ }

    <TextInput source="value" label="Flow" multiline rows={ 16 }
      sx={ {
        '& .MuiInputBase-input': { fontFamily: 'monospace' }
      } }
    />
    <TextField source="comment" />

  </AdminSimpleForm>
);

export const StateExpandEdit: FC<EditProps> = props => (
  <Edit
    actions={ false }
    title={ '&nbsp;' }
    redirect='list'
    { ...props } >
    <StateForm />
  </Edit>
);

export const StateEdit: FC<EditProps> = props => (
  <Edit
    // actions={ <EditActions breadcrumb={ <MyBreadcrumb variant="actions" /> } /> }
    redirect='list'
    { ...props }
  >
    <StateForm />
  </Edit>
);


