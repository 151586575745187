/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { canAccess } from '@react-admin/ra-rbac';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useEffect } from 'react';
import { AutocompleteInput, BooleanInput, Datagrid, Edit, EditProps, email, Filter, FilterProps, FunctionField, List, ListProps, maxLength, NumberInput, RaRecord, SearchInput, SelectInput, SimpleFormProps, TextInput, usePermissions, useRecordContext } from 'react-admin';
import { AdminSimpleForm, useAdminListAppLocation } from './Admin';
import { AwesomePhoneInput } from './AwesomePhoneInput';
import { LanguageInput } from './LanguageSelect';
import { CustomToolbar } from './ReminderTemplateLists';
import TextArrayField from './TextArrayField';
import { TimestampsField } from './TimestampsField';


export { default as ConfigIcon } from '@mui/icons-material/SettingsApplications';

const ConfigFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export const ConfigList: FC<ListProps> = () => {
  const { permissions } = usePermissions();
  const theme = useTheme();
  useAdminListAppLocation()

  return (
    <List
      exporter={ false }
      perPage={ 25 }
      filters={ <ConfigFilter /> }
      sort={ { field: 'id', order: 'ASC' } }
    >
      <Datagrid
        rowClick="edit"
        // expand={ <ConfigExpandEdit /> }
        bulkActionButtons={ false }
      >

        <FunctionField label="Name/Value" source="id"
          render={ ( record: RaRecord ) => (
            <>
              <Typography>
                { record.id }
              </Typography>
              <Typography variant='body2' ml={ 4 } sx={ {
                // textAlign: 'right',
                fontFamily: 'monospace',
                color: theme.palette.primary.light,
              } }>
                { record.type == 'boolean' ? record.value.toString() : record.value }
                &nbsp;
              </Typography>
            </>
          ) }
        />

        { canAccess( {
          permissions,
          action: 'write',
          resource: 'configs',
        } ) &&
          <TextArrayField source='scopes' label={ false } />
        }

        <TimestampsField label="Updated" source="updatedAt" />
      </Datagrid>
    </List >
  );

}

export const ConfigForm: FC<Omit<SimpleFormProps, 'children'>> = props => {
  // const { permissions } = usePermissions();
  const record = useRecordContext();
  const queryClient = useQueryClient();

  // unmount pattern
  useEffect( () => () => {
    if( !record ) return;
    // When the cost model config is changes, the backend regens the message templates.
    // This code invalidates the cached versions so that the change can be seen.
    if( typeof record.id == 'string' && record.id.match( /costmodel/i ) ) {
      queryClient.invalidateQueries( {
        queryKey: [ 'messagetemplates' ]
      } );
    }
  }, [ record, queryClient ] );

  const allowEmptyString = ( val: string ): string => val; // don't use sanitizeEmptyValues on this form 

  if( !record ) return null;


  return (
    <AdminSimpleForm { ...props } toolbar={ <CustomToolbar /> } >

      { record.type == 'boolean' &&
        <BooleanInput source="value" label={ record.comment } />
      }

      { record.type == 'number' &&
        <NumberInput source="value" min={ record.min } max={ record.max } label={ record.comment }
        // textAlign='right'
        />
      }

      { record.type == 'string' && record.stringType == 'phone' &&
        <AwesomePhoneInput source="value" label={ record.comment } fullWidth parse={ allowEmptyString } />
      }

      { record.type == 'string' && record.stringType == 'email' &&
        <TextInput source="value" label={ record.comment } fullWidth autoComplete={ 'new-password' } validate={ [ email(), maxLength( 255 ) ] } parse={ allowEmptyString } />
      }

      { record.type == 'string' && record.stringType == 'lang' &&
        <LanguageInput source="value" label={ record.comment } />
      }

      { record.type == 'string' && !record.stringType && record.enum.length === 0 &&
        <TextInput source="value" label={ record.comment } fullWidth parse={ allowEmptyString } />
      }

      { record.type == 'string' && record.enum.length > 0 && record.enum.length < 10 &&
        <SelectInput
          source="value"
          choices={ record.enum.map( ( v: string ) => ( { id: v, name: v } ) ) }
          translateChoice={ false }
          fullWidth
          isRequired
        />
      }

      { record.type == 'string' && record.enum.length >= 10 &&
        <AutocompleteInput
          source="value"
          choices={ record.enum.map( ( v: string ) => ( { id: v, name: v } ) ) }
          translateChoice={ false }
          fullWidth
          isRequired
        />
      }

      { /* canAccess( {
           permissions,
           action: 'manage',
           resource: 'system',
           } ) &&
           <AutocompleteTextArrayInput source='scopes' choices={ options.configScopes } />
         */ }

    </AdminSimpleForm >
  );
}

export const ConfigExpandEdit: FC<EditProps> = props => (
  <Edit
    actions={ false }
    title={ '&nbsp;' }
    redirect='list'
    { ...props } >
    <ConfigForm />
  </Edit>
);

export const ConfigEdit: FC<EditProps> = props => {
  return (
    <Edit
      redirect='list'
      mutationMode='pessimistic'
      { ...props }
    >
      <ConfigForm />
    </Edit>
  );
}


