import { Card, CardContent, CardHeader, CardMedia } from '@mui/material';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmailPasswordComponentsOverrideProvider } from 'supertokens-auth-react/recipe/emailpassword';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import { PasswordlessPreBuiltUI } from 'supertokens-auth-react/recipe/passwordless/prebuiltui';
import { AuthPage as OgAuthPage, AuthRecipeComponentsOverrideContextProvider } from 'supertokens-auth-react/ui';
import { dataApiUrl, httpClient } from './DataProvider';

//     redirectOnSessionExists?: boolean;
//     onSessionAlreadyExists?: () => void;
//     preBuiltUIList: RecipeRouter[];
//     factors?: (typeof FactorIds)[keyof Omit<typeof FactorIds, 'TOTP'>][];
//     useSignUpStateFromQueryString?: boolean;
//     isSignUp?: boolean;
//     navigate?: Navigate;
//     userContext?: UserContext;

export const MyAuthPage: FC = () => {
  const navigate = useNavigate();

  return (
    <OgAuthPage
      // factors={ [] }
      preBuiltUIList={ [ EmailPasswordPreBuiltUI, PasswordlessPreBuiltUI ] }
      navigate={ navigate }
      redirectOnSessionExists
      // useSignUpStateFromQueryString
      isSignUp={ false }
      userContext={ {
      } }
    />
  );
}


export const SupertokensUiCustomization: FC<PropsWithChildren<Record<string, unknown>>> = ( { children } ) => {
  const [ organization, setOrganization ] = useState<{ name: string } | undefined>();

  useEffect( () => {
    ( async () => {
      const { json } = await httpClient( `${ dataApiUrl }/locations` );
      setOrganization( json?.organization );
    } )();
  }, [] );

  const orgName = organization?.name || '';


  return (
    <AuthRecipeComponentsOverrideContextProvider
      components={ {
        AuthPageHeader_Override: ( { DefaultComponent, ...props } ) => {
          return (
            <Card

              raised={ false }
              sx={ {
                padding: '2rem 0 4rem',
                // marginTop: '6rem',
              } }
            >
              <CardMedia
                // sx={ {
                //   '& .MuiCardMedia-img': {
                //     minHeight: '120px',
                //     backgroundSize: 'contain',
                //     backgroundPosition: 'center',
                //     backgroundRepeat: 'no-repeat',
                //     // border: '1px red solid',
                //   }
                // } }
                component="img"
                height="140"
                image="/AIC_Logo.png"
              />
              <CardHeader
                title='Analog Concierge&trade; Admin'
                titleTypographyProps={ {
                  align: 'center',
                  // size: 'small',
                  sx: {
                    fontSize: '1.6rem',
                  }
                } }
                sx={ {
                  '& .MuiCardHeader-content': {
                    border: '1px solid purple',
                    // margin: '1rem 0 -1rem',
                    margin: '2rem 0 1rem !important',
                  }
                } }
              />
              <CardHeader
                title={ orgName.toUpperCase() }
                titleTypographyProps={ {
                  align: 'center',
                  sx: {
                    fontSize: '1.6rem',
                  }
                } }
                sx={ {
                  '& .MuiCardHeader-content': {
                    border: '1px solid purple',
                    // margin: '1rem 0 -1rem',
                    margin: '2rem 0 1rem !important',
                  }
                } }
              />
              <CardContent
                sx={ {
                  marginTop: '1rem',
                  // border: '1px solid blue',
                } }
              >

                <DefaultComponent { ...props } />
              </CardContent>
            </Card>
          );
        },
      } }>
      <EmailPasswordComponentsOverrideProvider
        components={ {
          EmailPasswordSignUpForm_Override: ( { DefaultComponent, ...props } ) => {
            // your customisations here for the email password sign up form...
            return <DefaultComponent { ...props } />;
          },
        } }>
        { children }
      </EmailPasswordComponentsOverrideProvider>
    </AuthRecipeComponentsOverrideContextProvider>
  )
}




// // DONT EDIT THIS. SEE APP WRAPPER  SupertokensUiCustomization
// // DONT EDIT THIS. SEE APP WRAPPER  SupertokensUiCustomization
// // DONT EDIT THIS. SEE APP WRAPPER  SupertokensUiCustomization
// // DONT EDIT THIS. SEE APP WRAPPER  SupertokensUiCustomization
// // DONT EDIT THIS. SEE APP WRAPPER  SupertokensUiCustomization
// // DONT EDIT THIS. SEE APP WRAPPER  SupertokensUiCustomization
// // DONT EDIT THIS. SEE APP WRAPPER  SupertokensUiCustomization
export const AuthPage: FC = () => {
  const navigate = useNavigate();

  return (
    <Card
      raised={ false }
      sx={ {
        padding: '2rem 0 4rem',
      } }
    >
      <CardMedia
        sx={ {
          height: 120,
          backgroundSize: 'contain',
        } }
        image="/AIC_Logo.png"
      />
      <CardHeader
        title='Analog Concierge&trade; Admin'
        titleTypographyProps={ {
          align: 'center',
          // size: 'small',
          sx: {
            fontSize: '1.5rem',
          }
        } }
        sx={ {
          '&.MuiCardHeader-root': {
            border: '1px solid purple',
            margin: '2rem 0 1rem',
          }
        } }
      />
      <CardContent
        sx={ {
          // border: '1px solid blue',
        } }
      >
        <OgAuthPage
          // factors={ [] }
          preBuiltUIList={ [ EmailPasswordPreBuiltUI, PasswordlessPreBuiltUI ] }
          navigate={ navigate }
          redirectOnSessionExists
          // useSignUpStateFromQueryString
          isSignUp={ false }
          userContext={ {
          } }
        />
      </CardContent>
    </Card>
  );
}

