import { ordinalize, pluralize } from 'inflection';
import _ from 'lodash';
import { FC } from 'react';
import { ArrayField, ArrayInput, AutocompleteInput, Datagrid, DateField, Edit, EditProps, FormDataConsumer, FunctionField, List, ListProps, ReferenceInput, SaveButton, SelectInput, Show, ShowProps, SimpleFormIterator, SimpleFormProps, TextField, TextInput, TitleProps, Toolbar, ToolbarProps } from 'react-admin';
import { useFormState } from 'react-hook-form';
import { AdminShowLayout, AdminSimpleForm, useAdminListAppLocation } from './Admin';
import { choices } from './DataProvider';
import { ShowActionsWithReset } from './ShowActionsWithReset';


export { default as ReminderTemplateListIcon } from '@mui/icons-material/SpeakerNotes';


export const ReminderTemplateListTitle: FC<TitleProps> = props => {
  return <span>ReminderTemplateList { props.record?.name ? `"${ props.record.name }"` : '' }</span>;
};


export const ReminderTemplateListList: FC<ListProps> = props => {
  useAdminListAppLocation();

  // const filterDefaultValues = {};

  return (
    <List { ...props }
      exporter={ false }
      sort={ { field: 'name', order: 'ASC' } }
      perPage={ 25 }
    >
      <Datagrid rowClick="edit"
        //expand={ <ReminderTemplateListShow /> }
        bulkActionButtons={ false }
      >
        <TextField source="name" />
        <TextField source="id" />
        <TextField source="comment" />
        <DateField label="Updated" source="updatedAt" showTime />
      </Datagrid>
    </List >
  );
}

export const ReminderTemplateListShow: FC<Omit<ShowProps, 'children'>> = props => (
  <Show
    actions={ <ShowActionsWithReset /> }
    title="&nbsp;"
    { ...props }
  >
    <AdminShowLayout>
      <TextField source="id" />
      {/* 
          <TextField source="name" />
          <TextField source="comment"  />
        */}
      <ArrayField source="reminderTemplate" >
        <Datagrid bulkActionButtons={ false } >
          <TextField label="Patient" source='reminderPatientTemplate' />
          <TextField label="Non-patient" source='reminderNonPatientTemplate' />

          <FunctionField label="Offset" source='reminderFireDateDayOffset'
            render={ ( record ): string => {
              if( !record ) return '';
              const { reminderFireDateDayOffset: d, reminderFireDateHourOffset: h, reminderFireDateMinuteOffset: m } = record;
              return [
                ( minuteOffsetChoices.find( c => c.id == m )?.name || '' ),
                ( hourOffsetChoices.find( c => c.id == h )?.name || '' ),
                ( dayOffsetChoices.find( c => c.id == d )?.name || '' ),
              ].join( ', ' );
            } }
          />
          <FunctionField label="Target" source='reminderFireDateDeliveryTargetHour'
            render={ ( record ): string => {
              if( !record ) return '';
              const { reminderFireDateDeliveryTargetHour: h, reminderFireDateDeliveryTargetMinute: min = 0 } = record;
              if( h < 0 ) return 'Disabled';
              const m = `00${ min }`.slice( -2 );
              return h < 12 ? `${ h }:${ m } AM` : h == 12 ? `12:${ m }` : `${ h - 12 }:${ m } PM`;
            } }
          />
        </Datagrid>
      </ArrayField>

    </AdminShowLayout>
  </Show>
);

export const CustomToolbar: FC<ToolbarProps> = props => {
  const { isValid, isDirty } = useFormState();
  return (
    <Toolbar { ...props }
    >
      <SaveButton
        disabled={ !isDirty }
        invalid={ !isValid }
      />
      {/* <Button label="Cancel" redirect={ props.redirect || 'show' }><Cancel /></Button> */ }
    </Toolbar>
  );
}

const offsetChoices = ( values: number[], unit: 'day' | 'hour' | 'minute' ): { id: number, name: string }[] => {
  const units = pluralize( unit );
  return values.map( v => ( {
    id: v,
    name: v < -1 ? `${ Math.abs( v ) } ${ units } before` : v < 0 ? `1 ${ unit } before` : v === 0 || v == -1 ? `Same ${ unit }` : v == 1 ? `1 ${ unit } after` : `${ Math.abs( v ) } ${ units } after`,
  } ) );
}

const targetChoices = ( values: number[], unit: 'hour' | 'minute' ): { id: number, name: string }[] => {
  if( unit == 'minute' ) {
    return values.map( v => ( { id: v, name: v.toString() } ) );
  }
  return values.map( v => ( {
    id: v,
    name: v < 12 ? `${ v } AM` : v == 12 ? `Noon` : `${ v - 12 } PM`
  } ) );


}


const dayOffsetChoices = offsetChoices( _.range( -14, 60 ), 'day' );
const hourOffsetChoices = offsetChoices( _.range( -23, 24 ), 'hour' );
const minuteOffsetChoices = offsetChoices( _.range( -55, 60, 5 ), 'minute' );
const hourTargetChoices = targetChoices( _.range( 6, 22 ), 'hour' );
const minuteTargetChoices = targetChoices( _.range( 0, 55, 15 ), 'minute' );

const ReminderTemplateListForm: FC<Omit<SimpleFormProps, 'children'>> = props => (
  <AdminSimpleForm sanitizeEmptyValues  { ...props } toolbar={ <CustomToolbar /> }>
    <TextInput source="comment" />
    <ArrayInput source="reminderTemplate" label='Reminder Templates' >

      <SimpleFormIterator disableReordering
        getItemLabel={ idx => ordinalize( ( idx + 1 ).toString() + ' reminder' ) }
      >

        <SelectInput source="reminderMsgOrFunction"
          label='Reminder type'
          choices={ choices.reminderMsgOrFunction } required />

        <FormDataConsumer>
          { ( { scopedFormData } ) => scopedFormData && (
            <ReferenceInput
              source='reminderPatientTemplate'
              reference="messagetemplates"
              sort={ { field: 'name', order: 'ASC' } }
              filter={ { recipientType: 'Patient' } }

            >
              <AutocompleteInput
                label="Patient template"
                disabled={ scopedFormData.reminderMsgOrFunction != 'message' }
              />
            </ReferenceInput>
          ) }
        </FormDataConsumer>

        <FormDataConsumer>
          { ( { scopedFormData } ) => scopedFormData && (
            <ReferenceInput
              source='reminderNonPatientTemplate'
              reference="messagetemplates"
              sort={ { field: 'name', order: 'ASC' } }
              filter={ { recipientType: 'Non-Patient' } }

            >
              <AutocompleteInput
                label="Non-patient template"
                TextFieldProps={ { disabled: scopedFormData.reminderMsgOrFunction != 'message' } }
              // disabled={ scopedFormData.reminderMsgOrFunction != 'message' }
              />
            </ReferenceInput>
          ) }
        </FormDataConsumer>

        <FormDataConsumer>
          { ( { scopedFormData } ) => scopedFormData && (

            <ReferenceInput
              source='reminderGuardianTemplate'
              reference="messagetemplates"
              sort={ { field: 'name', order: 'ASC' } }
              filter={ { recipientType: 'Guardian' } }

            >
              <AutocompleteInput
                label="Guardian template"
                TextFieldProps={ { disabled: scopedFormData.reminderMsgOrFunction != 'message' } }
              />
            </ReferenceInput>
          ) }
        </FormDataConsumer>

        <FormDataConsumer>
          { ( { scopedFormData } ) => scopedFormData && (
            <SelectInput source='reminderFunctionName' choices={ choices.reminderFunctionName }
              label='Function'
              InputProps={ { disabled: scopedFormData.reminderMsgOrFunction != 'function' } }
            />
          ) }
        </FormDataConsumer>

        {/*
            <Grid container justifyContent="space-between">
            <Grid item><SelectInput label="Day Offset" source='reminderFireDateDayOffset' choices={ dayOffsetChoices } /></Grid>
            <Grid item><SelectInput label="Hour Offset" source='reminderFireDateHourOffset' choices={ hourOffsetChoices } /></Grid>
            <Grid item><SelectInput label="Minute Offset" source='reminderFireDateMinuteOffset' choices={ minuteOffsetChoices } /></Grid>
            <Grid item><SelectInput label="Hour Target" source='reminderFireDateDeliveryTargetHour' choices={ hourTargetChoices } emptyValue={ -1 } emptyText='Disabled' /></Grid>
            <Grid item><SelectInput label="Hour Target" source='reminderFireDateDeliveryTargetMinute' choices={ minuteTargetChoices } emptyValue={ -1 } emptyText='Disabled' /></Grid>
            </Grid>
          */}
        <SelectInput label="Day Offset" source='reminderFireDateDayOffset' choices={ dayOffsetChoices } />
        <SelectInput label="Hour Offset" source='reminderFireDateHourOffset' choices={ hourOffsetChoices } />
        <SelectInput label="Minute Offset" source='reminderFireDateMinuteOffset' choices={ minuteOffsetChoices } />
        <SelectInput label="Hour Target" source='reminderFireDateDeliveryTargetHour' choices={ hourTargetChoices } emptyValue={ -1 } emptyText='Disabled' required />
        <SelectInput label="Minute Target" source='reminderFireDateDeliveryTargetMinute' choices={ minuteTargetChoices } emptyValue={ -1 } emptyText='Disabled' required />
        <SelectInput label="Message Purpose" source='messagePurpose' choices={ choices.messagePurpose } required />
      </SimpleFormIterator>
    </ArrayInput >


  </AdminSimpleForm >
);

export const ReminderTemplateListEdit: FC<EditProps> = props => (
  <Edit
    redirect='show'
    mutationMode='pessimistic'
    // actions={ <EditActions breadcrumb={ <MyBreadcrumb variant="actions" /> } /> }
    { ...props }
  >
    <ReminderTemplateListForm />
  </Edit>
);
