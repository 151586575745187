import { FC } from 'react';
import { Datagrid, DateField, Filter, FilterProps, List, ListProps, Pagination, PaginationProps, ReferenceField, SelectField, SelectInput, TextField, TitleProps } from 'react-admin';
import { choices } from './DataProvider';
import { Childless } from './types';


export { default as ReminderIcon } from '@mui/icons-material/Notifications';


export const ReminderTitle: FC<TitleProps> = props => {
  return <span>Reminder { props.record?.name ? `"${ props.record.name }"` : '' }</span>;
};



const ReminderFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    { /*
         <DateInput label="Scheduled" source="reminderRequestedFireDate"
         alwaysOn
         // disabled
         validate={ minValue( new Date().toISOString().slice( 0, 10 ) ) }
         />
       */}
    <SelectInput label="Purpose" source="messagePurpose" choices={ choices.messagePurpose } />
  </Filter>
);

export const ReminderPagination: FC<PaginationProps> = props => <Pagination rowsPerPageOptions={ [ 10, 25 ] } { ...props } />;


export const ReminderList: FC<Childless<ListProps>> = props => {

  // const filterDefaultValues = {
  //   reminderRequestedFireDate: new Date().toISOString().slice( 0, 10 ), // TODO Location tz
  // };

  const filter = {
    reminderState: 'waiting',
    reminderRequestedFireDate: new Date().toISOString().slice( 0, 10 ), // TODO Location tz
  };

  return (
    <List { ...props }
      filter={ filter }
      filters={ <ReminderFilter /> }
      // filterDefaultValues={ filterDefaultValues }
      sort={ { field: 'reminderRequestedFireDate', order: 'ASC' } }
      exporter={ false }
      pagination={ <ReminderPagination /> }
    >
      <Datagrid bulkActionButtons={ false } >
        { /* <DateField label="Completed" source="reminderCompletedDate" showTime /> */ }
        <DateField label="Scheduled" source="reminderRequestedFireDate" showTime />
        { /* <SelectField label="State" source="reminderState" choices={ choices.reminderState } /> */ }
        <SelectField label="Purpose" source="messagePurpose" choices={ choices.messagePurpose } />

        <ReferenceField source="appointment" reference="appointments" link='show'>
          <DateField source="startTime" showTime />
        </ReferenceField>

        <ReferenceField label="Patient" source="appointment" reference="appointments" link={ false } >
          <ReferenceField source="patient" reference="recipients" link={ false }>
            <TextField source="fullName" />
          </ReferenceField>
        </ReferenceField>

        <ReferenceField label="Patient template" source="reminderPatientTemplate" reference="messagetemplates" link="show">
          <TextField source="name" />
        </ReferenceField>
        {/*
        <ReferenceField label="Non-Patient template" source="reminderNonPatientTemplate" reference="messagetemplates" link="show">
          <TextField source="name" />
        </ReferenceField>
*/}
      </Datagrid>
    </List>
  );
}

