import { Box, Card, CardHeader, FormControl, Grid, MenuItem, Select, SelectProps, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { InputProps, Labeled, SimpleForm, Title, useInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';

//  unzip -p Replacement\ Variables.docx word/document.xml | xq -c 'include "helpers2"; [ ..|."w:t"? | strings | select( . | test( "^[a-z]+([A-Z][a-z]+)+$" ) ) ] | unique[] | { id: ., title: ( . | camel_to_snake | snake_to_title ) }' | jq -s '[ .[] | { id, title, content: ( "{{" + .id + "}}" ) } ]' | pbcopy
export const templates = [
  { 'id': 'alertEmail', 'title': 'Alert Email', 'content': '{{alertEmail}}' },
  { 'id': 'alertSms', 'title': 'Alert Sms', 'content': '{{alertSms}}' },
  { 'id': 'alertTwiml', 'title': 'Alert Twiml', 'content': '{{alertTwiml}}' },
  { 'id': 'appointmentAltText', 'title': 'Appointment Alt Text', 'content': '{{appointmentAltText}}' },
  { 'id': 'appointmentCancellationUrlLink', 'title': 'Appointment Cancellation Url Link', 'content': '{{appointmentCancellationUrlLink}}' },
  { 'id': 'appointmentConfirmationUrlLink', 'title': 'Appointment Confirmation Url Link', 'content': '{{appointmentConfirmationUrlLink}}' },
  { 'id': 'appointmentDateHuman', 'title': 'Appointment Date Human', 'content': '{{appointmentDateHuman}}' },
  { 'id': 'appointmentDateTimeZoneHuman', 'title': 'Appointment Date Time Zone Human', 'content': '{{appointmentDateTimeZoneHuman}}' },
  { 'id': 'appointmentImageUrl', 'title': 'Appointment Image Url', 'content': '{{appointmentImageUrl}}' },
  { 'id': 'appointmentLocalDate', 'title': 'Appointment Local Date', 'content': '{{appointmentLocalDate}}' },
  { 'id': 'appointmentLocalDateTime', 'title': 'Appointment Local Date Time', 'content': '{{appointmentLocalDateTime}}' },
  { 'id': 'appointmentLocalTime', 'title': 'Appointment Local Time', 'content': '{{appointmentLocalTime}}' },
  { 'id': 'appointmentRescheduleUrlLink', 'title': 'Appointment Reschedule Url Link', 'content': '{{appointmentRescheduleUrlLink}}' },
  { 'id': 'appointmentScheduleUrlLink', 'title': 'Appointment Schedule Url Link', 'content': '{{appointmentScheduleUrlLink}}' },
  { 'id': 'appointmentStatus', 'title': 'Appointment Status', 'content': '{{appointmentStatus}}' },
  { 'id': 'appointmentStatusChangeMessage', 'title': 'Appointment Status Change Message', 'content': '{{appointmentStatusChangeMessage}}' },
  { 'id': 'appointmentTimeHuman', 'title': 'Appointment Time Human', 'content': '{{appointmentTimeHuman}}' },
  { 'id': 'appointmentTimeZoneAbbreviation', 'title': 'Appointment Time Zone Abbreviation', 'content': '{{appointmentTimeZoneAbbreviation}}' },
  { 'id': 'appointmentTitleText', 'title': 'Appointment Title Text', 'content': '{{appointmentTitleText}}' },
  { 'id': 'atOpCalendarBasePath', 'title': 'At Op Calendar Base Path', 'content': '{{atOpCalendarBasePath}}' },
  { 'id': 'atOpCalendarData', 'title': 'At Op Calendar Data', 'content': '{{atOpCalendarData}}' },
  { 'id': 'atOpCalendarFullPath', 'title': 'At Op Calendar Full Path', 'content': '{{atOpCalendarFullPath}}' },
  { 'id': 'atOpCareHtml', 'title': 'At Op Care Html', 'content': '{{atOpCareHtml}}' },
  { 'id': 'atOpCareShortSms', 'title': 'At Op Care Short Sms', 'content': '{{atOpCareShortSms}}' },
  { 'id': 'atOpCareSms', 'title': 'At Op Care Sms', 'content': '{{atOpCareSms}}' },
  { 'id': 'calendarBasePath', 'title': 'Calendar Base Path', 'content': '{{calendarBasePath}}' },
  { 'id': 'calendarData', 'title': 'Calendar Data', 'content': '{{calendarData}}' },
  { 'id': 'calendarFullPath', 'title': 'Calendar Full Path', 'content': '{{calendarFullPath}}' },
  { 'id': 'calendarUid', 'title': 'Calendar Uid', 'content': '{{calendarUid}}' },
  { 'id': 'cancelledEmail', 'title': 'Cancelled Email', 'content': '{{cancelledEmail}}' },
  { 'id': 'cancelledSms', 'title': 'Cancelled Sms', 'content': '{{cancelledSms}}' },
  { 'id': 'emailManageSubscriptionsUrlLink', 'title': 'Email Manage Subscriptions Url Link', 'content': '{{emailManageSubscriptionsUrlLink}}' },
  { 'id': 'emailSubjectLine', 'title': 'Email Subject Line', 'content': '{{emailSubjectLine}}' },
  { 'id': 'emailUnsubscribeUrlLink', 'title': 'Email Unsubscribe Url Link', 'content': '{{emailUnsubscribeUrlLink}}' },
  { 'id': 'feedbackUrlLink', 'title': 'Feedback Url Link', 'content': '{{feedbackUrlLink}}' },
  { 'id': 'htmlAmenityText', 'title': 'Html Amenity Text', 'content': '{{htmlAmenityText}}' },
  { 'id': 'internalEvalSurveyUrl', 'title': 'Internal Eval Survey Url', 'content': '{{internalEvalSurveyUrl}}' },
  { 'id': 'locationAddress', 'title': 'Location Address', 'content': '{{locationAddress}}' },
  { 'id': 'locationAddressLineOne', 'title': 'Location Address Line One', 'content': '{{locationAddressLineOne}}' },
  { 'id': 'locationAddressLineTwo', 'title': 'Location Address Line Two', 'content': '{{locationAddressLineTwo}}' },
  { 'id': 'locationAddressNewLine', 'title': 'Location Address New Line', 'content': '{{locationAddressNewLine}}' },
  { 'id': 'locationAddressNoZip', 'title': 'Location Address No Zip', 'content': '{{locationAddressNoZip}}' },
  { 'id': 'locationEmail', 'title': 'Location Email', 'content': '{{locationEmail}}' },
  { 'id': 'locationFaxNumber', 'title': 'Location Fax Number', 'content': '{{locationFaxNumber}}' },
  { 'id': 'locationName', 'title': 'Location Name', 'content': '{{locationName}}' },
  { 'id': 'locationPhone', 'title': 'Location Phone', 'content': '{{locationPhone}}' },
  { 'id': 'locationPhoneNoSpaces', 'title': 'Location Phone No Spaces', 'content': '{{locationPhoneNoSpaces}}' },
  { 'id': 'locationPhoneNumber', 'title': 'Location Phone Number', 'content': '{{locationPhoneNumber}}' },
  { 'id': 'locationSignature', 'title': 'Location Signature', 'content': '{{locationSignature}}' },
  { 'id': 'locationWebShortUrl', 'title': 'Location Web Short Url', 'content': '{{locationWebShortUrl}}' },
  { 'id': 'locationWebUrl', 'title': 'Location Web Url', 'content': '{{locationWebUrl}}' },
  { 'id': 'messageTitle', 'title': 'Message Title', 'content': '{{messageTitle}}' },
  { 'id': 'noShowEmail', 'title': 'No Show Email', 'content': '{{noShowEmail}}' },
  { 'id': 'noShowShortSms', 'title': 'No Show Short Sms', 'content': '{{noShowShortSms}}' },
  { 'id': 'noShowSms', 'title': 'No Show Sms', 'content': '{{noShowSms}}' },
  { 'id': 'pathAuthor', 'title': 'Path Author', 'content': '{{pathAuthor}}' },
  { 'id': 'pathCountReport', 'title': 'Path Count Report', 'content': '{{pathCountReport}}' },
  { 'id': 'pathCountReportShort', 'title': 'Path Count Report Short', 'content': '{{pathCountReportShort}}' },
  { 'id': 'pathStartDate', 'title': 'Path Start Date', 'content': '{{pathStartDate}}' },
  { 'id': 'pathologyAltText', 'title': 'Pathology Alt Text', 'content': '{{pathologyAltText}}' },
  { 'id': 'pathologyImageUrl', 'title': 'Pathology Image Url', 'content': '{{pathologyImageUrl}}' },
  { 'id': 'pathologyTitleText', 'title': 'Pathology Title Text', 'content': '{{pathologyTitleText}}' },
  { 'id': 'patientFirstName', 'title': 'Patient First Name', 'content': '{{patientFirstName}}' },
  { 'id': 'postOpCalendarBasePath', 'title': 'Post Op Calendar Base Path', 'content': '{{postOpCalendarBasePath}}' },
  { 'id': 'postOpCalendarData', 'title': 'Post Op Calendar Data', 'content': '{{postOpCalendarData}}' },
  { 'id': 'postOpCalendarFullPath', 'title': 'Post Op Calendar Full Path', 'content': '{{postOpCalendarFullPath}}' },
  { 'id': 'postOpCareHtml', 'title': 'Post Op Care Html', 'content': '{{postOpCareHtml}}' },
  { 'id': 'postOpCareShortSms', 'title': 'Post Op Care Short Sms', 'content': '{{postOpCareShortSms}}' },
  { 'id': 'postOpCareSms', 'title': 'Post Op Care Sms', 'content': '{{postOpCareSms}}' },
  { 'id': 'ppointmentUpdateText', 'title': 'Ppointment Update Text', 'content': '{{ppointmentUpdateText}}' },
  { 'id': 'practiceWebSiteAltText', 'title': 'Practice Web Site Alt Text', 'content': '{{practiceWebSiteAltText}}' },
  { 'id': 'practiceWebSiteImageUrl', 'title': 'Practice Web Site Image Url', 'content': '{{practiceWebSiteImageUrl}}' },
  { 'id': 'practiceWebSiteTitleText', 'title': 'Practice Web Site Title Text', 'content': '{{practiceWebSiteTitleText}}' },
  { 'id': 'practiceWebSiteUrl', 'title': 'Practice Web Site Url', 'content': '{{practiceWebSiteUrl}}' },
  { 'id': 'practitionerName', 'title': 'Practitioner Name', 'content': '{{practitionerName}}' },
  { 'id': 'practitionerNameNoFixup', 'title': 'Practitioner Name No Fixup', 'content': '{{practitionerNameNoFixup}}' },
  { 'id': 'practitionerNamePrefix', 'title': 'Practitioner Name Prefix', 'content': '{{practitionerNamePrefix}}' },
  { 'id': 'practitionerNameSuffix', 'title': 'Practitioner Name Suffix', 'content': '{{practitionerNameSuffix}}' },
  { 'id': 'practitionerThankYouAltText', 'title': 'Practitioner Thank You Alt Text', 'content': '{{practitionerThankYouAltText}}' },
  { 'id': 'practitionerThankYouImageUrl', 'title': 'Practitioner Thank You Image Url', 'content': '{{practitionerThankYouImageUrl}}' },
  { 'id': 'practitionerThankYouTitleText', 'title': 'Practitioner Thank You Title Text', 'content': '{{practitionerThankYouTitleText}}' },
  { 'id': 'preOpCalendarBasePath', 'title': 'Pre Op Calendar Base Path', 'content': '{{preOpCalendarBasePath}}' },
  { 'id': 'preOpCalendarData', 'title': 'Pre Op Calendar Data', 'content': '{{preOpCalendarData}}' },
  { 'id': 'preOpCalendarFullPath', 'title': 'Pre Op Calendar Full Path', 'content': '{{preOpCalendarFullPath}}' },
  { 'id': 'preOpCareHtml', 'title': 'Pre Op Care Html', 'content': '{{preOpCareHtml}}' },
  { 'id': 'preOpCareSms', 'title': 'Pre Op Care Sms', 'content': '{{preOpCareSms}}' },
  { 'id': 'preOpShortSms', 'title': 'Pre Op Short Sms', 'content': '{{preOpShortSms}}' },
  { 'id': 'pressFourToConnectToOfficeTwiml', 'title': 'Press Four To Connect To Office Twiml', 'content': '{{pressFourToConnectToOfficeTwiml}}' },
  { 'id': 'pressNineToUnsubscribeTwiml', 'title': 'Press Nine To Unsubscribe Twiml', 'content': '{{pressNineToUnsubscribeTwiml}}' },
  { 'id': 'publicEvalSurveyUrl', 'title': 'Public Eval Survey Url', 'content': '{{publicEvalSurveyUrl}}' },
  { 'id': 'reviewEmail', 'title': 'Review Email', 'content': '{{reviewEmail}}' },
  { 'id': 'reviewShortSms', 'title': 'Review Short Sms', 'content': '{{reviewShortSms}}' },
  { 'id': 'reviewSms', 'title': 'Review Sms', 'content': '{{reviewSms}}' },
  { 'id': 'smsAmenityText', 'title': 'Sms Amenity Text', 'content': '{{smsAmenityText}}' },
  { 'id': 'smsEmailHelpTrailerText', 'title': 'Sms Email Help Trailer Text', 'content': '{{smsEmailHelpTrailerText}}' },
  { 'id': 'smsEmailUnsubscribeManagePreferencesText', 'title': 'Sms Email Unsubscribe Manage Preferences Text', 'content': '{{smsEmailUnsubscribeManagePreferencesText}}' },
  { 'id': 'smsHelpTrailerText', 'title': 'Sms Help Trailer Text', 'content': '{{smsHelpTrailerText}}' },
  { 'id': 'smsPatientHelpText', 'title': 'Sms Patient Help Text', 'content': '{{smsPatientHelpText}}' },
  { 'id': 'smsShortHelpTrailerText', 'title': 'Sms Short Help Trailer Text', 'content': '{{smsShortHelpTrailerText}}' },
  { 'id': 'smsShortPatientHelpText', 'title': 'Sms Short Patient Help Text', 'content': '{{smsShortPatientHelpText}}' },
  { 'id': 'socialMediaInstagramAltText', 'title': 'Social Media Instagram Alt Text', 'content': '{{socialMediaInstagramAltText}}' },
  { 'id': 'socialMediaInstagramImageUrl', 'title': 'Social Media Instagram Image Url', 'content': '{{socialMediaInstagramImageUrl}}' },
  { 'id': 'socialMediaInstagramTitleText', 'title': 'Social Media Instagram Title Text', 'content': '{{socialMediaInstagramTitleText}}' },
  { 'id': 'socialMediaInstagramUrl', 'title': 'Social Media Instagram Url', 'content': '{{socialMediaInstagramUrl}}' },
  { 'id': 'socialMediaLinkedInAltText', 'title': 'Social Media Linked In Alt Text', 'content': '{{socialMediaLinkedInAltText}}' },
  { 'id': 'socialMediaLinkedInImageUrl', 'title': 'Social Media Linked In Image Url', 'content': '{{socialMediaLinkedInImageUrl}}' },
  { 'id': 'socialMediaLinkedInTitleText', 'title': 'Social Media Linked In Title Text', 'content': '{{socialMediaLinkedInTitleText}}' },
  { 'id': 'socialMediaLinkedInUrl', 'title': 'Social Media Linked In Url', 'content': '{{socialMediaLinkedInUrl}}' },
  { 'id': 'socialMediaTwitterAltText', 'title': 'Social Media Twitter Alt Text', 'content': '{{socialMediaTwitterAltText}}' },
  { 'id': 'socialMediaTwitterImageUrl', 'title': 'Social Media Twitter Image Url', 'content': '{{socialMediaTwitterImageUrl}}' },
  { 'id': 'socialMediaTwitterTitleText', 'title': 'Social Media Twitter Title Text', 'content': '{{socialMediaTwitterTitleText}}' },
  { 'id': 'socialMediaTwitterUrl', 'title': 'Social Media Twitter Url', 'content': '{{socialMediaTwitterUrl}}' },
  { 'id': 'timeZoneName', 'title': 'Time Zone Name', 'content': '{{timeZoneName}}' },
  { 'id': 'todayHumanDate', 'title': 'Today Human Date', 'content': '{{todayHumanDate}}' },
  { 'id': 'welcomeAltText', 'title': 'Welcome Alt Text', 'content': '{{welcomeAltText}}' },
  { 'id': 'welcomeImageUrl', 'title': 'Welcome Image Url', 'content': '{{welcomeImageUrl}}' },
  { 'id': 'welcomeTitleText', 'title': 'Welcome Title Text', 'content': '{{welcomeTitleText}}' }
].map( t => ( { ...t, description: `Insert the "${ t.title }" field code into the message.` } ) );

const defaultChoices = templates.map( t => ( { id: t.content, name: t.title } ) );

export interface ReplacementArgsInputProps extends InputProps, Pick<MuiTextFieldProps, 'minRows' | 'maxRows' | 'fullWidth' | 'disabled'> {
  choices?: { id: string, name: string }[];
}

export const ReplacementArgsInput: FC<ReplacementArgsInputProps> = props => {
  const { choices = defaultChoices, disabled, minRows = 3, maxRows = 12, fullWidth, ...rest } = props;
  const theme = useTheme();
  const { getValues, setValue } = useFormContext();
  const ref = useRef<HTMLTextAreaElement>( null );
  const { field } = useInput( rest );
  const [ selectValue, setSelectValue ] = useState( '' );
  const [ newPos, setNewPos ] = useState( 0 );

  const onSelect = useCallback<NonNullable<SelectProps<string>[ 'onChange' ]>>( ( event ) => {
    if( !ref.current /* || !selectRef.current */ ) return;
    const { value } = event.target;
    const text = getValues( props.source )
    const pos = ref.current.selectionStart;//  || text.length;
    setValue(
      props.source,
      text.slice( 0, pos ) + value + text.slice( pos ),
      { shouldValidate: true, shouldTouch: true, shouldDirty: true }
    );
    const newPos = pos + value.length;
    setNewPos( newPos );
    setSelectValue( '' ); // always return to empty
  }, [ ref.current, setSelectValue, setNewPos ] );

  useEffect( () => {
    if( !ref.current || !newPos ) return;
    ref.current.focus( { preventScroll: true } );
    ref.current.setSelectionRange( newPos, newPos );
  }, [ ref.current, newPos ] )

  return (
    <Labeled label={ props.label }
      sx={ {
        width: '100%',
        padding: '1 1 0',
      } }
    >
      <Box
        sx={ {
          width: '100%',
          borderWidth: '1px',
          borderStyle: 'solid ',
          borderColor: theme.palette.divider,
        } }
      >
        <Grid container
          sx={ {
            width: '100%',
          } }>
          <Grid container item
            justifyContent='flex-start'
            sx={ {
              width: '100%'
            } }
          >
            <Grid item xs={ 'auto' }
              sx={ {
                paddingLeft: 1,
                paddingRight: 1,
              } }
            >
              <FormControl
                sx={ {
                  '& .ReplacementVarSelector:before': {
                    borderBottom: '0px solid purple',
                  },
                  '& .ReplacementVarSelector:hover:not(.Mui-disabled, .Mui-error, .MuiSelect-filled):before': {
                    borderBottom: '0px solid red',
                  },
                } }
                focused={ false }
                color='primary'
                margin='dense'
              // fullWidth
              >
                <Select
                  size='small'
                  variant='standard'
                  value={ selectValue }
                  className='ReplacementVarSelector'
                  sx={ {
                    '& .MuiSelect-select.MuiInputBase-input': {
                      // width: '18rem',
                      color: 'gray',
                    },
                    '& .MuiSelect-select.MuiSelect-standard.MuiInputBase-input:before': {
                      fontSize: '80%',
                      content: '"Add replacement variable"',
                      margin: '0 4px',
                    },
                  } }
                  onChange={ onSelect }
                  MenuProps={ { sx: { maxHeight: '40%', left: '105px' } } }
                  disabled={ disabled }
                >
                  <MenuItem disabled value="">Replacement variables</MenuItem>
                  { choices.map( c => (
                    <MenuItem key={ c.id } value={ c.id }>{ c.name }</MenuItem>
                  ) ) }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item sx={ { width: '100%' } }>
            <MuiTextField
              label={ false }
              multiline
              minRows={ minRows }
              maxRows={ maxRows }
              fullWidth={ fullWidth }
              inputRef={ ref }
              disabled={ disabled }
              sx={ {
                marginTop: 0,
                '& .MuiInputBase-root.MuiFilledInput-root': {
                  paddingTop: '4px',
                }
              } }
              { ...field }
            />
          </Grid>
        </Grid>
      </Box>
    </Labeled>

  );
};

export const TryReplacementArgs = () => (
  <>
    <Title title='TryReplacementArgs' />
    <Card
      sx={ {
        marginTop: '1rem',
        width: '100%',
      } }
    >
      <CardHeader title='Try ReplacementArgsInput' />
      <SimpleForm
        record={ {
          smsMessage: 'abcjd\n\ndksjf sdflkiuewr lsafkjasdlf\n\nlkjdfu\n',
        } }
        toolbar={ <></> }>
        <ReplacementArgsInput
          label='SMS Message'
          source='smsMessage'
          minRows={ 8 }
          fullWidth />
      </SimpleForm>
    </Card>
  </>
);
