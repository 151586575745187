import { useAppLocationState } from '@react-admin/ra-navigation';
import { FC, useEffect } from 'react';
import { Datagrid, Edit, EditProps, List, ListProps, Show, ShowProps, SimpleFormProps, TextField, TextInput } from 'react-admin';
import { AdminShowLayout, AdminSimpleForm } from './Admin';
import { CustomToolbar } from './ReminderTemplateLists';
import { ShowActionsWithReset } from './ShowActionsWithReset';
import { TimestampsField } from './TimestampsField';


export { default as SnippetIcon } from '@mui/icons-material/TextSnippet';

export const SnippetList: FC<ListProps> = () => {
  const [ _location, setLocation ] = useAppLocationState();
  useEffect( () => {
    setLocation( 'admin.snippets' );
    return () => setLocation( null );
  }, [] );

  return (
    <List
      exporter={ false }
      perPage={ 25 }
    >
      <Datagrid
        rowClick="edit"
        // expand={ <SnippetExpandEdit /> }
        bulkActionButtons={ false }
      >

        <TextField label="Name" source="id" />
        <TextField label="Comment" source="comment" />
        {/* <TextField source="value"  /> */ }
        <TimestampsField label="Updated" source="updatedAt" />
      </Datagrid>
    </List>
  );
}

export const SnippetShow: FC<ShowProps> = () => {
  return (
    <Show actions={ <ShowActionsWithReset /> }>
      <AdminShowLayout>
        <TextField label='Name' source='id' />
        <TextField label='Snippet' source='value' />
        <TextField source='comment' />
        <TimestampsField label="Updated" source="updatedAt" />
        <TimestampsField label="Updated" source="createdAt" />
      </AdminShowLayout>
    </Show>
  );
}

const SnippetForm: FC<Omit<SimpleFormProps, 'children'>> = props => (
  <AdminSimpleForm sanitizeEmptyValues  { ...props } toolbar={ <CustomToolbar /> } >

    { /* <TextField label="Name" source="id" /> */ }

    <TextInput source="value" label="Snippet" multiline rows={ 16 }
      sx={ {
        '& .MuiInputBase-input': { fontFamily: 'monospace' }
      } }
    />
    { /* <HtmlEditor source="value" label="Snippet"  { ...editorConfig.htmlMessage } width={ 400 } /> */ }
    <TextField source="comment" />

  </AdminSimpleForm>
);

export const SnippetExpandEdit: FC<EditProps> = props => (
  <Edit
    actions={ false }
    title={ '&nbsp;' }
    redirect='list'
    { ...props } >
    <SnippetForm />
  </Edit>
);

export const SnippetEdit: FC<EditProps> = props => (
  <Edit
    // actions={ <EditActions breadcrumb={ <MyBreadcrumb variant="actions" /> } /> }
    redirect='list'
    { ...props }
  >
    <SnippetForm />
  </Edit>
);
// export const SnippetEdit: FC<EditProps> = props => ( <Edit { ...props } ><SnippetForm /></Edit> );

