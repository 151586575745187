import { CancelOutlined as CancelIcon, CheckCircle as OkIcon } from '@mui/icons-material';
import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Radio, RadioGroup, TableCellProps } from '@mui/material';
import { ComponentElement, MouseEventHandler, ReactNode, useEffect, useRef, useState } from 'react';
import { DatagridCellProps, IconButtonWithTooltip, IconButtonWithTooltipProps, RaRecord } from 'react-admin';


export type CopyWithPartial<T, K extends keyof T> = Omit<T, K> & Partial<T>;
export type ResourceActionHandler2<T extends RaRecord = RaRecord> = ( resource?: string, record?: CopyWithPartial<T, 'id'>, value?: string ) => void;

export interface DatagridRowButtonProps<T extends RaRecord = RaRecord>
  extends Omit<DatagridCellProps, keyof TableCellProps>,
  Omit<IconButtonWithTooltipProps, 'onClick'> {
  onClick?: ResourceActionHandler2<T>;
}

export interface DatagridRowButton2Props<T extends RaRecord = RaRecord>
  extends Omit<DatagridCellProps, 'field' | keyof TableCellProps>,
  Omit<IconButtonWithTooltipProps, 'onClick'> {
  resource: string;
  record: T;
  onClick?: ResourceActionHandler2<T>;
  confirm?: boolean | ReactNode;
  confirmContent?: string | ( ( record?: T ) => string | ComponentElement<unknown, any> );  // eslint-disable-line @typescript-eslint/no-explicit-any
  confirmTitle?: string | ( ( record?: T ) => string );
  options: string[];
  value: string;
  source?: string; // used to set class when inside Datagrid
  hide?: boolean | ( ( record?: T ) => boolean );
}


export function DatagridRowButton2<T extends RaRecord = RaRecord>( props: DatagridRowButton2Props<T> ) {
  const [ open, setOpen ] = useState( false );
  const {
    resource,
    record,
    onClick,
    confirm = false,
    confirmContent: content = 'Are you sure you want to delete this item?',
    confirmTitle: title = 'Delete Item',
    className = '',
    hide,
    options,
    value,
    ...rest
  } = props;

  const handleClick: MouseEventHandler = () => {
    if( !confirm ) {
      onClick && onClick( resource, record );
      return;
    }
    setOpen( true );
  }

  const handleConfirm = ( value: string ) => {
    setOpen( false );
    onClick && onClick( resource, record, value );
  }

  const handleClose = () => {
    setOpen( false );
  }

  if( typeof hide == 'function' ? hide( record ) : hide ) return null;

  return (
    <>
      <IconButtonWithTooltip
        { ...rest }
        className={ `rowButton ${ className }` }
        onClick={ handleClick }
      />
      <ConfirmationDialog
        id='here'
        open={ open }
        title={ typeof title == 'function' ? title( record ) : title }
        content={ typeof content == 'function' ? content( record ) : content }
        confirm="Yes"
        confirmColor="primary"
        cancel="Cancel"
        cancelIcon={ <CancelIcon /> }
        options={ options }
        value={ value }
        onConfirm={ handleConfirm }
        onClose={ handleClose }
      />
    </>
  );
}

export interface ConfirmationDialogProps {
  id: string;
  keepMounted?: boolean;
  value: string;
  open: boolean;
  onConfirm: ( value: string ) => void;
  onClose: () => void;
  title: string;
  content?: string | ComponentElement<unknown, any>;  // eslint-disable-line @typescript-eslint/no-explicit-any
  confirm?: string;
  confirmColor?: ButtonProps[ 'color' ];
  cancel?: string;
  cancelIcon?: ReactNode;
  options: string[];
}

export function ConfirmationDialog( props: ConfirmationDialogProps ) {
  const {
    options, title, content,
    confirm = 'Yes',
    confirmColor = 'primary',
    cancel = 'Cancel',
    cancelIcon = <CancelIcon />,
    onClose,
    onConfirm,
    value: valueProp,
    open,
    ...other
  } = props;
  const [ value, setValue ] = useState( valueProp );
  const radioGroupRef = useRef<HTMLElement>( null );

  useEffect( () => {
    if( !open ) {
      setValue( valueProp );
    }
  }, [ valueProp, open ] );

  const handleEntering = () => {
    if( radioGroupRef.current != null ) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onConfirm( value );
  };

  const handleChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
    setValue( ( event.target as HTMLInputElement ).value );
  };

  return (
    <Dialog
      sx={ { '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } } }
      maxWidth="xs"
      TransitionProps={ { onEntering: handleEntering } }
      open={ open }
      { ...other }
    >
      <DialogTitle>{ title }</DialogTitle>

      <DialogContent dividers>
        { content && ( typeof content == 'string'
          ? <DialogContentText>
            { content }
          </DialogContentText>
          : <>{ content }</>
        ) }
        <RadioGroup
          ref={ radioGroupRef }
          aria-label="ringtone"
          name="ringtone"
          value={ value }
          onChange={ handleChange }
        >
          { options.map( ( option ) => (
            <FormControlLabel
              value={ option }
              key={ option }
              control={ <Radio /> }
              label={ option }
            />
          ) ) }
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus startIcon={ cancelIcon } onClick={ handleCancel }>
          { cancel }
        </Button>
        <Button color={ confirmColor } startIcon={ <OkIcon /> } onClick={ handleOk }>{ confirm }</Button>
      </DialogActions>
    </Dialog>
  );
}
