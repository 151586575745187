/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { FC } from 'react';
import { Datagrid, Filter, FilterProps, FunctionField, ImageField, List, ListProps, RaRecord, SearchInput, SelectInput, TextField, TextInput } from 'react-admin';
import { useAdminListAppLocation } from './Admin';
import { BooleanColorField } from './BooleanColorField';
import { choices } from './DataProvider';
import TextArrayField from './TextArrayField';
import { getCountryData } from 'countries-list';
import { useTheme } from '@mui/material';

export { default as LanguageIcon } from '@mui/icons-material/Translate';

const LanguageFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <SearchInput source="q" alwaysOn />
    <TextInput label="Code" source="id" />
    <SelectInput source="translators" choices={ choices.translators } />
  </Filter>
);

export const LanguageList: FC<ListProps> = props => {
  useAdminListAppLocation();
  const theme = useTheme();
  return (
    <List
      { ...props }
      exporter={ false }
      perPage={ 25 }
      filters={ <LanguageFilter /> }
      sort={ { field: 'name', order: 'ASC' } }
    >
      <Datagrid bulkActionButtons={ false }
        sx={ {
          '& .column-flag, & .column-flag span': {
            fontSize: '3rem', // '2rem',
            lineHeight: '1',
          },
          '& .RaDatagrid-headerCell.column-nativeName': {
            textAlign: 'center',
          },
          '& .column-svg .RaImageField-image': {
            border: `1px solid ${ theme.palette.divider }`,
          },

        } }
      >

        <TextField label="Code" source="id" />
        <TextField label="Name" source="name" />

        <TextField label="Native" source="nativeName" textAlign='center' />
        <TextField label='🏳️' source="flag"
        />
        <ImageField source='svg'
          sx={ {
            '& .RaImageField-image': {
              height: '2rem',
              width: 'initial',
            }
          } } />

        <FunctionField label='Country' source='svg' render={ ( r: RaRecord ) => {
          const code = ( r.svg || '' ).slice( 11, -4 ).toUpperCase();
          return getCountryData( code )?.name ? `${ code }: ${ getCountryData( code )?.name }` : '';
        } } />

        <BooleanColorField label="RTL" source="rtl" noFalse />
        <BooleanColorField label='Supported' source='isSupported' />
        <BooleanColorField label='Enabled' source='isEnabled' />
        <TextArrayField source='translators' size='small' />
      </Datagrid>
    </List>
  );
}

// export const LanguageShow: FC<ShowProps> = props => {
//  
//   return (
//     <Show actions={ <ShowActionsWithReset /> }>
//       <AdminShowLayout>
//         <TextField label='Name' source='id' fullWidth />
//         <TextField source='value' fullWidth />
//         <TextField source='comment' fullWidth />
//  
//         <TimestampsField label="Updated" source="updatedAt" />
//         <TimestampsField label="Updated" source="createdAt" />
//       </AdminShowLayout>
//     </Show>
//   );
// }
//  
//  
// // TODO set comment to edited in UI
// const LanguageForm: FC<Omit<SimpleFormProps, 'children'>> = props => (
//   <AdminSimpleForm sanitizeEmptyValues  { ...props } toolbar={ <CustomToolbar /> } >
//  
//  
//     <TextField label="Name" source="id" />
//     <TextInput source="comment" fullWidth />
//  
//     <TextInput source="value" label="Value" fullWidth />
//  
//   </AdminSimpleForm>
// );
//  
// export const LanguageExpandEdit: FC<EditProps> = props => (
//   <Edit
//     actions={ false }
//     title={ '&nbsp;' }
//     redirect='list'
//     { ...props } >
//     <LanguageForm />
//   </Edit>
// );
//  
// export const LanguageEdit: FC<EditProps> = props => (
//   <Edit
//     // actions={ <EditActions breadcrumb={ <MyBreadcrumb variant="actions" /> } /> }
//     redirect='list'
//     { ...props }
//   >
//     <LanguageForm />
//   </Edit>
// );

