import { Help, SsidChart as LineChartIcon, TableChartOutlined as TableIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, IconButton as MuiIconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { Datum, Line, Serie } from '@nivo/line';
import { addDays } from 'date-fns';
import { chunk, sumBy } from 'lodash';
import { default as queryString } from 'query-string';
import { FC, useCallback, useMemo, useState } from 'react';
import { Loading, useGetList, useRedirect } from 'react-admin';
import { DailyLineChartSliceTooltipHeader, makeLineChartSliceTooltip } from './DashboardTooltip';
import { ChartProps, elevation, LineLegendProps } from './DashboardWidget';
import { useUserPreference } from './UserPreferences';
import useColorPalettes from './use-color-palettes';
import { useOrgDateMarkers } from './use-markers';
import useSummary from './use-summary';
import { DashboardDatagrid } from './DashboardDatagrid';

export interface ReputationActivityDatum extends Datum {
  date: string;
  md: string;
  ymd: string;
  Clicks: number;
  Views: number;
  Blocks: number;
  Reviews: number;
}

export interface ReputationActivityLineDatum extends Datum {
  y: number | null;
  ymd: string;
  md: string;
}

export interface ReputationActivityLinePoint extends Serie {
  data: ReputationActivityLineDatum[];
}


export const DashboardReputationActivity: FC<ChartProps> = ( { date } ) => {
  const theme = useTheme();
  const redirect = useRedirect();
  const isSmall = false;
  const defaultDurationDays = 30;
  const legendCols = 2;
  const cacheKey = 'dashboard-reputation-activity';
  const { data: orgs } = useGetList( 'organizations', { filter: { parent: null } } );
  const [ showDatagrid, setShowDatagrid ] = useState( false );
  // const isEmpty = useMemo( () => !!data?.length, [ data ] );
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const keys = [ 'Views', 'Clicks', 'Blocks', 'Reviews' ];

  const durationDays = useMemo( () => {
    if( isLoadingPreferences || !preferences?.dashboardActivityDays ) return defaultDurationDays;
    return preferences.dashboardActivityDays as number;
  }, [ preferences, isLoadingPreferences ] );

  const summary = useSummary<ReputationActivityDatum[]>( 'reputationActivity', { date, days: durationDays } );

  const data = useMemo( (): ReputationActivityLinePoint[] => keys.map( id => ( {
    id, data: summary.data?.map( d => {
      const { ymd, md, date } = d;
      const y = d[ id ] >= 1 ? d[ id ] : null; // no zeros for log scale
      return { x: ymd, y, ymd, md, date };
    } )
      .filter( d => !!d.y )
      .sort( ( a, b ) => a.ymd < b.ymd ? -1 : 1 ) || []
  } ) ), [ date, keys, summary.data ] );

  const isEmpty = useMemo( () => sumBy( data, d => d.data.length ) === 0, [ data ] );
  const counts = useMemo( () => (
    Object.fromEntries( ( data || [] ).map( ( { id, data = [] } ) => [ id, data.reduce( ( tot, d ) => ( d.y || 0 ) + tot, 0 ) ] ) )
  ), [ data ] );
  const yMax = useMemo( () => Math.max( ...[ 10, ...( data || [] ).flatMap( row => ( row.data || [] ).map( d => d.y || 0 ) ) ] ), [ data ] );

  const [ org ] = orgs || [];
  const perImpression = org?.valuePerImpression || 5;
  const perReview = org?.valuePerReview || 200;
  const prettyPos = ( n: number ) => Math.max( 0, n ).toLocaleString( undefined, { maximumFractionDigits: 0 } );
  const impressionsValue = prettyPos( counts[ 'Views' ] * perImpression );
  const reviewsValue = prettyPos( counts[ 'Reviews' ] * perReview );
  const totalValue = prettyPos( counts[ 'Views' ] * perImpression + counts[ 'Reviews' ] * perReview );

  const [ hiddenSeries, setHiddenSeries ] = useState<string[]>( [] );
  const handleLegendClick = useCallback( ( datum: Datum ) => {
    const id = datum.id;
    const idx = hiddenSeries.indexOf( id );
    if( idx < 0 ) {
      setHiddenSeries( [ ...hiddenSeries, id ] );
      return;
    }
    const newValue = [ ...hiddenSeries ];
    newValue.splice( idx, 1 );
    setHiddenSeries( newValue );
  }, [ hiddenSeries, setHiddenSeries ] );

  //   discretePrimary: [ '#FF8A00', '#00BEE6', '#F6484D', '#00BBC1', '#2973EF', '#9600E5', '#18B74E' ], // orange, cyan, red, turquois, blue, purple, green
  const colorPalettes = useColorPalettes();
  const colors = useMemo( () => [ // ...colorPalettes.discretePrimary.slice( -keys.length + 1 ),
    colorPalettes.discretePrimary[ 4 ], // blue
    colorPalettes.discretePrimary[ 5 ], // purple
    colorPalettes.discretePrimary[ 2 ], // red
    colorPalettes.discretePrimary[ 6 ], // green
  ], [ colorPalettes, keys ] );

  const markers = useOrgDateMarkers( { date, duration: { type: 'days', count: durationDays } } );

  return (
    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={
          <Box sx={ { display: 'flex' } } >
            <Typography>Reputation Activity</Typography>
            <Box sx={ { flexGrow: 1 } } />
            <MuiIconButton
              sx={ {
                // paddingTop: 0, paddingBottom: 0,
                marginTop: '-8px',
                marginBottom: '-8px',
              } }
              onClick={ () => setShowDatagrid( !showDatagrid ) }
            >
              { showDatagrid ? <LineChartIcon /> : <TableIcon /> }
            </MuiIconButton>
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `${ durationDays } Days Ending ${ date.format( 'MMM D' ) }` : `Previous ${ durationDays } Days` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      // avatar={ <DashboardAlertIcon /> }
      />
      < CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        {
          summary.isLoading
            ? <Box>
              < Loading
                loadingPrimary=''
                loadingSecondary=''
                sx={ {
                  '@media (min-width: 0)': {
                    marginTop: 0,
                    marginBottom: 7,
                    height: '100%',
                    width: 500,
                    minHeight: 400,
                  }
                } }
              />
            </Box >

            :
            <Box
              sx={ {
                position: 'relative',
              } }
            >
              { showDatagrid
                ? (
                  <DashboardDatagrid
                    rows={ ( summary.data || [] ).map( ( d, id ) => ( { id, ...d } ) ) }
                    columns={ keys }
                    exportFileName={ cacheKey }
                  />
                )
                : <>
                  <Line
                    width={ 500 }
                    height={ 400 }
                    curve='monotoneX'

                    data={ !data || isEmpty ? [] : data }
                    margin={ { top: 60, right: 20, bottom: 55, left: 75 } }
                    gridYValues={ 4 }

                    isInteractive={ !isEmpty }
                    useMesh={ !isEmpty }
                    enableCrosshair
                    areaOpacity={ 0.1 }
                    lineWidth={ 3 }
                    pointSize={ 6 }

                    xFormat="time:%Y-%m-%d"
                    xScale={ {
                      type: 'time',
                      format: '%Y-%m-%d',
                      precision: 'day',
                      useUTC: false,
                      max: date ? date.toDate() : new Date(),
                      min: date ? addDays( date.toDate(), 0 - durationDays ) : addDays( new Date(), 0 - durationDays ),
                    } }

                    yScale={ {
                      type: 'log',
                      base: 2,
                      max: yMax * 1.5,
                      min: 1,
                    } }

                    onClick={ ( point ) => {
                      const { serieId: activity, data: { xFormatted: ymd } } = point;
                      if( !ymd ) return;
                      const resource = typeof activity == 'string' && activity.toLowerCase() == 'views'
                        ? 'reputationviews'
                        : typeof activity == 'string' && activity.toLowerCase() == 'blocks'
                          ? 'reputationfeedbacks'
                          : typeof activity == 'string' && activity.toLowerCase() == 'clicks'
                            ? 'reputationclicks'
                            : 'reputationreviews';
                      const filter = JSON.stringify( {
                        delimiter: ';',
                        [ resource == 'reputationreviews' ? 'publishedAt' : 'createdAt' ]: ymd,
                      } );
                      redirect( `/reviews/${ resource }?${ queryString.stringify( { filter } ) }` );
                    } }


                    enableSlices='x'
                    sliceTooltip={ makeLineChartSliceTooltip( {
                      theme,
                      renderHeader: DailyLineChartSliceTooltipHeader,
                    } ) }

                    markers={ markers }

                    theme={ {
                      background: theme.palette?.background?.default,
                      axis: {
                        legend: { text: { fontSize: isSmall ? 16 : 18 } },
                        ticks: { text: { fontSize: isSmall ? 0 : 14 } },
                      },
                      labels: { text: { fill: '#333' } },
                      legends: { text: { fontSize: 14 } },
                      text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
                      tooltip: {
                        container: {
                          borderRadius: '8px',
                          border: `0.5px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
                          boxShadow: `${ theme.palette?.grey?.A400 ?? 'grey' } 0px 2px 2px`,
                          backgroundColor: theme.palette?.background?.default,
                          color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
                          padding: '5px 9px',
                        },
                      },
                    } }

                    colors={ isEmpty ? [ theme.palette?.mode === 'dark' ? '#555' : '#ccc' ] : colors } // { scheme: 'accent', size: keys.length } }
                    enableGridX={ false }
                    enableGridY={ true }
                    axisTop={ null }
                    axisRight={ null }
                    axisLeft={ {
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      tickValues: 4, // [ 0, 1000, 2000, 3000 ],
                      legend: 'Daily count (log2)',
                      legendPosition: 'middle',
                      legendOffset: -67
                    } }
                    axisBottom={ {
                      tickSize: 5,
                      tickPadding: 5,
                      // tickRotation: 0,
                      format: '%-m/%d',
                      tickValues: `every ${ durationDays < 30 ? 2 : 7 } days`,
                      legend: 'Activity date',
                      legendPosition: 'middle',
                      legendOffset: 42,
                    } }
                    // multirow legend (by 2)
                    legends={ chunk( keys, legendCols ).map<LineLegendProps>( ( ch, chIdx, chArr ) => (
                      {
                        data: [
                          ...ch.map( ( id, idx, _arr ) => ( {
                            id,
                            label: `${ id } (${ counts[ id ] || 0 })`,
                            fill: colors[ idx + legendCols * chIdx ],
                            hidden: !!hiddenSeries.includes( `${ id }` ),
                          } ) ),
                          // this works for legendCols 2 but not 3
                          ...( keys.length % legendCols !== 0 && chIdx == chArr.length - 1
                            ? [ { id: 'x', opacity: 0.2, label: '', fill: 'transparent' } ]
                            : []
                          ),
                        ],
                        anchor: 'top-left',
                        direction: 'row',
                        justify: false,
                        translateX: -30,
                        translateY: -60 + chIdx * 22,
                        itemsSpacing: 80,
                        itemWidth: 110, // * ( keys.length % legendCols !== 0 && chIdx == chArr.length - 1 ? legendCols : 1 ),
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 1, // 0.95,
                        onClick: handleLegendClick,
                        symbolSize: 18, // 12,
                        symbolShape: 'circle', // 'square',
                        toggleSerie: true,
                      }
                    ) ) }
                  />
                  <Tooltip
                    // leaveDelay={ 3333333 }
                    // arrow
                    followCursor
                    placement='bottom-end'
                    title={
                      <Box
                        sx={ {
                          fontSize: '1.1rem',
                          minWidth: 300,
                        } }
                      >
                        <Typography variant='inherit' fontWeight='bold'>
                          ${ reviewsValue } Reviews
                        </Typography>
                        <Typography variant='inherit' align='right'>
                          { counts[ 'Reviews' ] } Reviews x ${ perReview }
                        </Typography>
                        {/*
                                         <Typography variant='inherit' mt={ 2 } fontWeight='bold'>
                                         ${ impressionsValue } Impresssions
                                         </Typography>
                                         <Typography variant='inherit' align='right'>
                                         { counts[ 'Views' ] } Views x ${ perImpression }
                                         </Typography>
                                       */}
                        <Typography variant='inherit' mt={ 2 } fontWeight='bold'>
                          ${ reviewsValue /* totalValue */ } Total ROI
                        </Typography>
                      </Box>
                    }
                    componentsProps={ {
                      tooltip: {
                        sx: {
                          borderRadius: '8px',
                          border: `0.5px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
                          boxShadow: `${ theme.palette?.grey?.A400 ?? 'grey' } 0px 2px 2px`,
                          backgroundColor: theme.palette?.background?.default,
                          color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
                          padding: '5px 9px',
                          maxWidth: '400px',
                        },
                      }
                    } }
                  >
                    <Box
                      sx={ {
                        position: 'absolute',
                        display: 'flex',
                        top: '-1.75rem',
                        left: 0,
                        width: '100%',
                      } }
                    >
                      <Typography
                        variant='body1'
                        fontSize={ '90%' }
                        color={ theme.palette?.mode === 'dark' ? '#ccc' : '#555' }
                      >
                        ROI delivered: { `$${ reviewsValue /* totalValue */ } ` }
                        <Help color='inherit' fontSize='inherit' />
                      </Typography>
                    </Box>
                  </Tooltip>
                </>
              }
            </Box>
        }
      </CardContent >
    </Card >
  );

}

