import { Editor, IAllProps as EditorProps } from '@tinymce/tinymce-react';
import get from 'lodash/get';
import { FC, useCallback } from 'react';
import { CommonInputProps, FieldProps, Labeled, useInput, useRecordContext } from 'react-admin';

export type EditorInitProps = EditorProps[ 'init' ];
export type EditorSetup = NonNullable<EditorProps[ 'init' ]>[ 'setup' ];

export interface HtmlEditorProps extends CommonInputProps {
  plugins?: EditorProps[ 'plugins' ];
  toolbar?: EditorProps[ 'toolbar' ];
  setup?: NonNullable<EditorProps[ 'init' ]>[ 'setup' ];
  templates?: NonNullable<EditorProps[ 'init' ]>[ 'templates' ];
  width?: number;
  height?: number;
  disabled?: NonNullable<EditorProps[ 'disabled' ]>;
  content_style?: string;
  br_in_pre?: boolean;
}

export const HtmlEditor: FC<HtmlEditorProps> = props => {
  const { label, source, defaultValue, format, parse, plugins, setup, toolbar, templates, width, height, disabled, br_in_pre } = props;
  const { field } = useInput( { source, format, parse, defaultValue } );

  const onBlur = useCallback<NonNullable<EditorProps[ 'onBlur' ]>>( () => {
    field.onBlur();
  }, [ field ] );

  // const onFocus: EditorProps[ 'onFocus' ] = useCallback( ( e ) => {
  //   field.onFocus();
  // }, [ field ] );

  const onChange = useCallback<NonNullable<EditorProps[ 'onEditorChange' ]>>( ( value ) => {
    field.onChange( value )
  }, [ field ] );

  return (
    <>
      <Labeled label={ label }>
        <EditorWrapper label>
          <Editor
            value={ field.value }
            init={ {
              branding: false,
              menubar: false,
              statusbar: false,
              // content_style: 'body,pre,p { font-family:Roboto,Helvetica,Arial,sans-serif; font-size:16px; margin: 0; } body { background-color:rgba(0,0,0,0.09) }' + content_style,
              content_css: [
                '/tinymce/js/tinymce/skins/content/default/content.min.css',
                '/static/css/html-editor.css',
              ],
              autoresize_bottom_margin: 50,
              plugins,
              setup,
              toolbar,
              templates,
              width,
              height,
              br_in_pre,
              table_resize_bars: false,
              object_resizing: false,
            } }
            disabled={ disabled }
            onBlur={ onBlur }
            // onFocus={ onFocus }
            onEditorChange={ onChange }
          />
        </EditorWrapper>
      </Labeled>
      <p></p>
    </>
  );

}

export type EditorWrapperProps = Pick<Parameters<typeof Labeled>[ 0 ], 'label' | 'children'>;

export const EditorWrapper: FC<EditorWrapperProps> = props => {
  const { children } = props;

  return ( <div> { children }</div> );
}

export interface HtmlViewerProps extends FieldProps {
  bogus?: string;
}

export const HtmlViewer: FC<HtmlViewerProps> = props => {
  const { source = '' } = props;
  const record = useRecordContext( props );

  return (
    <Editor
      disabled={ true }
      initialValue={ get( record, source ) }
      init={ {
        branding: false,
        menubar: false,
        statusbar: false,
        plugins: [ 'autoresize' ],
        toolbar: false,
        // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        content_css: [
          '/tinymce/js/tinymce/skins/content/default/content.min.css',
          '/static/css/html-editor.css',
        ],
        autoresize_bottom_marging: 8,
        table_resize_bars: false,
        object_resizing: false,
      } }
    />

  );

}
