import { useMemo } from 'react';
import { useUserPreference } from './UserPreferences';

export type ColorPalette = string[];
export interface ColorPalettes {
  discretePrimary: ColorPalette,
  spectrumPrimary: ColorPalette,
  spectrumGreenRed: ColorPalette,
  spectrumTealRed: ColorPalette,
}

export function useColorPalettes(): ColorPalettes {
  const { preferences, isLoading } = useUserPreference();

  const isVisionAssistiveModeEnabled = useMemo( () => {
    if( isLoading || !preferences ) return;
    return !!preferences.enableVisionAssistiveMode;
  }, [ isLoading, preferences ] );

  const defaultPalette: ColorPalettes = {
    discretePrimary: [ '#FF8A00', '#00BEE6', '#F6484D', '#00BBC1', '#2973EF', '#9600E5', '#18B74E' ], // orange, cyan, red, turquois, blue, purple, green
    spectrumGreenRed: [ '#009933', '#00BBC1', '#F7E71E', '#FF8A00', '#E22D2D' ],
    spectrumPrimary: [ '#9600E5', '#2973EF', '#00C78E', '#FF8A00', '#F7E71E' ],
    spectrumTealRed: [ '#005763', '#00C78E', '#F7E71E', '#FF8A00', '#E22D2D' ],
  };

  if( isVisionAssistiveModeEnabled ) {
    return {
      ...defaultPalette,
      discretePrimary: [ '#E69F03', '#57B4E9', '#D55E00', '#0072B2', '#F0E441', '#CB79A7', '#009E73' ],
      spectrumGreenRed: [ '#1A7939', '#5BAE63', '#DAF1D5', '#C3A4CF', '#742881' ],
      spectrumPrimary: [ '#5B8DFE', '#725DEE', '#DD227D', '#FE5F00', '#FFB109' ],
      spectrumTealRed: [ '#3A93C3', '#8EC3DE', '#FEDBC7', '#F6A482', '#B31529' ],
    };
  }
  return defaultPalette;
}

export default useColorPalettes;
