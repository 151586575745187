import { CropRotate as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Theme, useTheme, SxProps } from '@mui/material/styles';
import { has } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslate } from 'ra-core';
import { FC, ReactNode, useEffect } from 'react';
import { FileContainer, FileWithPreview } from './DataProvider';

interface ImageInputEditPreviewProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
  onEdit: () => void;
  onRemove: () => void;
  file: FileContainer | FileWithPreview;
}

const ImageInputEditPreview: FC<ImageInputEditPreviewProps> = props => {
  const {
    children,
    onEdit,
    onRemove,
    file,
    ...rest
  } = props;
  const theme = useTheme();
  const translate = useTranslate();

  useEffect( () => {
    return () => {
      // const preview = file as FileContainer && has( file, 'rawFile' ) ? file.rawFile.preview : file.preview;
      let preview = file.preview;
      if( has( file, 'rawFile' ) ) {
        preview = ( file as FileContainer ).rawFile?.preview || preview;
      }

      if( preview ) {
        window.URL.revokeObjectURL( preview );
      }
    };
  }, [ file ] );

  const tryOnRemove = () => {
    console.log( 'remove' )
    onRemove();
  }

  return (
    <Box { ...rest }>
      <IconButton
        sx={ {
          top: theme.spacing( 0 ),
          right: theme.spacing( 2 ),

        } }
        onClick={ onEdit }
        aria-label={ translate( 'ra.action.edit' ) }
        title={ translate( 'ra.action.edit' ) }
        size="large">
        <EditIcon
          sx={ {
            color: theme.palette.grey[ 500 ],
          } }
          fontSize="large" />
      </IconButton>
      <IconButton
        sx={ {
          bottom: theme.spacing( 0 ),
          right: theme.spacing( 2 ),

        } }
        onClick={ tryOnRemove }
        aria-label={ translate( 'ra.action.delete' ) }
        title={ translate( 'ra.action.delete' ) }
        size="large">
        <DeleteIcon
          sx={ {
            color: theme.palette.grey[ 500 ],
          } }
          fontSize="large" />
      </IconButton>
      { children }
    </Box>
  );
};

ImageInputEditPreview.propTypes = {
  children: PropTypes.element.isRequired,
  // file: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

ImageInputEditPreview.defaultProps = {
  file: undefined,
};

export default ImageInputEditPreview;
