import { useTheme } from '@mui/material';
import { CartesianMarkerProps, DatumValue } from '@nivo/core';
import * as dateFns from 'date-fns';
import { useMemo } from 'react';
import { useParentOrg } from './Organizations';
import { Dayjs } from 'dayjs';

type OrgDateMarkerValueFormatter = ( value: Date ) => string | Date;
type OrgDateMarkerOptions = {
  date: Dayjs|null|undefined;
  duration: {
    type: 'days'|'months';
    count: number;
  };
  formatValue?: OrgDateMarkerValueFormatter;
};
export const useOrgDateMarkers = ( opts: OrgDateMarkerOptions ): CartesianMarkerProps<DatumValue>[] => {
  const { launchDate, startupDate } = useParentOrg();
  const theme = useTheme();
  const textColor = useMemo( () => theme.palette?.mode === 'dark' ? '#ccc' : '#333', [ theme.palette ] );

  const formatValue = useMemo<OrgDateMarkerValueFormatter>( () => {
    const { duration: { type }, formatValue } = opts;
    if( formatValue ) return formatValue;
    return type == 'days' ? date => dateFns.startOfDay( date ) : date => dateFns.startOfMonth( date );
  }, [ opts.duration, opts.formatValue ] );

  const markers: CartesianMarkerProps<DatumValue>[] = [];
  const isInRange = ( d: Date ) => {
    if( !d ) return false;
    if( dateFns.isBefore( d, dateFns.sub( opts.date?.toDate() || new Date(), { [opts.duration.type]: opts.duration.count } ) ) ) return false;
    if( dateFns.isAfter( d, opts.date?.toDate() || new Date() ) ) return false;
    return true;
  }
  if( launchDate && isInRange( launchDate ) ) markers.push( {
    axis: 'x',
    value: formatValue( launchDate ),
    legend: 'Launch',
    legendOrientation: 'vertical',
    legendPosition: 'top-left',
    lineStyle: { strokeDasharray: '2px 4px' },
    textStyle: { fill: textColor, fontSize: 14 },
  } );
  if( startupDate && isInRange( startupDate ) && ( !launchDate || !dateFns.isSameDay( launchDate, startupDate ) ) ) markers.push( {
    axis: 'x',
    value: formatValue( startupDate ),
    legend: 'Startup',
    legendOrientation: 'vertical',
    legendPosition: 'top-left',
    lineStyle: { strokeDasharray: '2px 4px' },
    textStyle: { fill: textColor, fontSize: 14 },
  } );

  return markers;
};
