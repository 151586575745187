import { ChatBubble } from '@mui/icons-material';
import { Badge, Box } from '@mui/material';
import { FC, useMemo, useRef } from 'react';
import { IconButtonWithTooltip, useGetList, useRedirect } from 'react-admin';
import { InboundText } from './IncomingText';


export const UnreadTextsButton: FC = () => {
  const ref = useRef<HTMLDivElement>( null );
  const redirect = useRedirect();
  const { total } = useGetList<InboundText>( 'inboundtexts', { filter: { isResolved: false } } );
  const buttonStyles = useMemo<CSSStyleDeclaration | undefined>( () => ref?.current ? window.getComputedStyle( ref.current ) : undefined, [ ref ] );

  const handleClick = () => { redirect( '/messaging/incomingText' ) };

  // if( isLoading || total == 0 ) return null;

  return (
    <Box
      ref={ ref }
      sx={ {
        color: 'inherit',
      } }
    >
      <IconButtonWithTooltip
        label='Tap to see unread incoming text messages'
        onClick={ handleClick }
        color='inherit'
        sx={ {
          marginTop: '4px',
        } }
      >
        <Badge badgeContent={ total } color='error'>
          <ChatBubble
            sx={ {
              color: buttonStyles?.getPropertyValue( 'color' ), // inherit from grandparent ;-)
            } }
          />
        </Badge>
      </IconButtonWithTooltip>
    </Box>
  );
}
