import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { BooleanField, BooleanFieldProps } from 'react-admin';
import { Clear as ClearIcon, Done as DoneIcon } from '@mui/icons-material';


export interface BooleanColorFieldProps extends BooleanFieldProps {
  noFalse?: boolean;
  noTrue?: boolean;
  invert?: boolean;
}

export const BooleanColorField: FC<BooleanColorFieldProps> = props => {
  const { sx = {}, invert, noFalse, noTrue, ...rest } = props;
  const theme = useTheme();
  return (
    <BooleanField
      looseValue
      FalseIcon={ !invert ? ( noFalse ? null : undefined ) : ( noTrue ? null : DoneIcon ) }
      TrueIcon={ !invert ? ( noTrue ? null : undefined ) : ( noFalse ? null : ClearIcon ) }
      { ...rest }
      sx={ {
        'svg[data-testid="false"]': { color: !invert ? theme.palette.error.main : theme.palette.success.main },
        'svg[data-testid="true"]': { color: !invert ? theme.palette.success.main : theme.palette.error.main },
        ...sx
      } }
    />
  );

}

