/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { debounce } from 'lodash';
import { FC, useCallback, useEffect } from 'react';
import { ArrayField, ArrayInput, BooleanInput, Datagrid, Edit, EditProps, List, ListProps, NumberField, NumberInput, SaveButton, SelectField, SelectInput, Show, ShowProps, SimpleFormIterator, SimpleFormProps, TextField, TextInput, TimeInput, Toolbar, ToolbarProps } from 'react-admin';
import { useFormState } from 'react-hook-form';
import { BooleanColorField } from './BooleanColorField';
import { apiUrl, choices, httpClient } from './DataProvider';
import { SimpleFormWithLocation, SimpleShowLayoutWithLocation, useListAppLocation } from './MyBreadcrumb';
import { useShowScheduleFields } from './Organizations';
import { formatBaseTimeInput, parseBaseTimeInput } from './RetailHours';
import { TimestampsField } from './TimestampsField';
import { Childless } from './types';
import { TimeStringPeriodField } from './Practitioners';
import { Typography } from '@mui/material';

export { default as AppointmentTypeIcon } from '@mui/icons-material/SpeakerNotes';


export const AppointmentTypeList: FC<Childless<ListProps>> = props => {
  useListAppLocation( 'appointment' );

  const fetchNewEmr = useCallback( debounce( () => {
    httpClient( `${ apiUrl }/appointmenttypes/actions/fetchNewEmr`, { method: 'POST' } );
  }, 10000, { leading: true, trailing: true } ), [] )

  useEffect( () => {
    fetchNewEmr();
  }, [ fetchNewEmr ] );

  // const filterDefaultValues = {};

  return (
    <List { ...props }
      exporter={ false }
      sort={ { field: 'name', order: 'ASC' } }
      perPage={ 25 }
    >
      <Datagrid rowClick="show" bulkActionButtons={ false } >
        <TextField label="Code" source="id" />
        <TextField source="name" />
        <TextField source="comment" />

        <TimestampsField label="Updated" source="updatedAt" />
      </Datagrid>
    </List >
  );
}

export const AppointmentTypeShow: FC<Childless<ShowProps>> = props => {
  const showScheduleFields = useShowScheduleFields();

  return (
    <Show  >
      <SimpleShowLayoutWithLocation basePath='appointment' >
        <TextField source="comment" />

        { showScheduleFields &&   // TODO make this a SelectField display units * unitMinutes
          <NumberField source='units' />
        }
        { showScheduleFields &&
          <ArrayField label="Available times by day" source="dayAvailableTimes"  >
            <Datagrid bulkActionButtons={ false } empty={ <Typography>No available days/times</Typography> } >
              <SelectField source='weekday' label='Day' choices={ choices.weekday } />
              <TimeStringPeriodField label='Period' />
            </Datagrid>
          </ArrayField>
        }
        { showScheduleFields &&
          <NumberField source='overbookLevelMax' />
        }
        { showScheduleFields &&
          <NumberField source='sameSpacing.units' />
        }
        { showScheduleFields &&
          <BooleanColorField source='sameSpacing.allLocations' />
        }
        { showScheduleFields &&
          <BooleanColorField source='sameSpacing.allPractitioners' />
        }
        { showScheduleFields &&
          <NumberField source='overbookSameCount' />
        }
        { showScheduleFields &&

          <TextField source="tagSpacing.tag" />
        }
        { showScheduleFields &&
          <NumberField source='tagSpacing.units' />
        }
        { showScheduleFields &&
          <BooleanColorField source='tagSpacing.allLocations' />
        }
        { showScheduleFields &&
          <BooleanColorField source='tagSpacing.allPractitioners' />
        }
        { showScheduleFields &&
          <NumberField source='overbookTagCount' />
        }
      </SimpleShowLayoutWithLocation>
    </Show>
  );
}

const CustomToolbar: FC<ToolbarProps> = () => {
  // const { saving } = props;
  const { isValid } = useFormState();

  return (
    <Toolbar>
      <SaveButton
        invalid={ !isValid }
      // saving={ saving }
      />
    </Toolbar>
  );
}



//   sameSpacing?: {  // codeBuffer
//     units: number;
//     allLocations?: boolean;
//     allPractitioners?: boolean;  // sameBufferAll
//   };
//  
//   tagSpacing?: {         // tagBuffer
//     tag: string;
//     units: number;
//     allLocations?: boolean;
//     allPractitioners?: boolean;
//   };

const AppointmentTypeForm: FC<Omit<SimpleFormProps, 'children'>> = props => {
  const showScheduleFields = useShowScheduleFields();

  return (
    <SimpleFormWithLocation basePath='appointment' sanitizeEmptyValues toolbar={ <CustomToolbar /> }  { ...props }    >
      <TextInput source="comment" />
      { showScheduleFields &&
        <>
          <NumberInput source='units' fullWidth={ false } min={ 1 } step={ 1 } />

          { showScheduleFields &&
            <ArrayInput label='Available times by day' source="dayAvailableTimes">
              <SimpleFormIterator disableReordering inline>
                <SelectInput source='weekday' label='Day' choices={ choices.weekday } required />
                <TimeInput source='from' parse={ parseBaseTimeInput } format={ formatBaseTimeInput } required defaultValue={ parseBaseTimeInput( '08:00' ) } />
                <TimeInput source='to' parse={ parseBaseTimeInput } format={ formatBaseTimeInput } required defaultValue={ parseBaseTimeInput( '17:00' ) } />
              </SimpleFormIterator>
            </ArrayInput>
          }

          <NumberInput source='overbookLevelMax' fullWidth={ false } min={ 0 } step={ 1 } />

          <NumberInput source='sameSpacing.units' fullWidth={ false } min={ 0 } step={ 1 } />
          <BooleanInput source='sameSpacing.allLocations' fullWidth={ false } />
          <BooleanInput source='sameSpacing.allPractitioners' fullWidth={ false } />
          <NumberInput source='overbookSameCount' fullWidth={ false } min={ 0 } step={ 1 } />

          <TextInput source="tagSpacing.tag" fullWidth={ false } />
          <NumberInput source='tagSpacing.units' fullWidth={ false } min={ 0 } step={ 1 } />
          <BooleanInput source='tagSpacing.allLocations' fullWidth={ false } />
          <BooleanInput source='tagSpacing.allPractitioners' fullWidth={ false } />
          <NumberInput source='overbookTagCount' fullWidth={ false } min={ 0 } step={ 1 } />
        </>
      }
    </SimpleFormWithLocation >
  );
}

export const AppointmentTypeEdit: FC<EditProps> = props => (
  <Edit
    redirect='list'
    mutationMode='pessimistic'
    { ...props }
  >
    <AppointmentTypeForm />
  </Edit>
);
