/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { get } from 'lodash';
import { FC } from 'react';
import { ArrayInput, Datagrid, Edit, EditProps, email, Labeled, List, ListProps, maxLength, RecordContextProvider, SaveButton, Show, ShowProps, SimpleFormIterator, SimpleFormProps, TextField, TextInput, TitleProps, Toolbar, ToolbarProps, WithRecord } from 'react-admin';
import { useFormState } from 'react-hook-form';
import { SimpleFormWithLocation, SimpleShowLayoutWithLocation, useListAppLocation } from './MyBreadcrumb';
import TextArrayField from './TextArrayField';
import { nodupes } from './validate';

export { default as AnomalyReportEmailIcon } from '@mui/icons-material/ReportOutlined';


const AnomalyReportEmailListTitle: FC<TitleProps> = props => {
  return <span>ReminderTemplateList { props.record?.name ? `"${ props.record.name }"` : '' }</span>;
};

export const AnomalyReportEmailList: FC<Omit<ListProps, 'children'>> = props => {
  useListAppLocation( 'anomalies' );
  return (
    <List
      { ...props }
      exporter={ false }
      perPage={ 25 }
      sort={ { field: 'name', order: 'ASC' } }
    >
      <Datagrid rowClick="show"
        // expand={ <AnomalyReportEmailExpandEdit /> }
        bulkActionButtons={ false }
      >
        <TextField source="name" />

        { /*    <TextArrayField source='emailList' label='Emails' /> */ }

        <TextField source="comment" />

      </Datagrid>
    </List>
  );
}

export const AnomalyReportEmailShow: FC<ShowProps> = props => (
  <Show
    title={ <AnomalyReportEmailListTitle /> }
    { ...props }>
    <SimpleShowLayoutWithLocation basePath='anomalies'>
      <TextField source="name" fullWidth />
      <TextField source="comment" fullWidth emptyText='' />

      <TextArrayField source='emailList' label='Emails' />

    </SimpleShowLayoutWithLocation>
  </Show>
);


export const AnomalyReportEmailToolbar: FC<ToolbarProps> = props => {
  const { isValid, isDirty } = useFormState();
  return (
    <Toolbar { ...props }
    >
      <SaveButton disabled={ !isDirty } invalid={ !isValid } />
    </Toolbar>
  );
}

const AnomalyReportEmailForm: FC<Omit<SimpleFormProps, 'children'>> = props => {

  return (
    <SimpleFormWithLocation basePath='anomalies' sanitizeEmptyValues warnWhenUnsavedChanges { ...props }
      toolbar={ <AnomalyReportEmailToolbar /> }
    >
      <Labeled label="ID" >
        <TextField source="id" fullWidth />
      </Labeled>
      <TextInput source="name" fullWidth />
      <TextInput source="comment" fullWidth />
      <WithRecord render={ record => record?.emailList && (
        <RecordContextProvider value={ { ...record, emailList: get( record, 'emailList', [] ).sort() } }>
          <ArrayInput source='emailList' validate={ [ nodupes() ] } >
            <SimpleFormIterator inline disableReordering fullWidth >
              <TextInput source='' label='Email' helperText={ false } fullWidth autoComplete={ 'new-password' } validate={ [ email(), maxLength( 255 ) ] } required />
            </SimpleFormIterator>
          </ArrayInput>
        </RecordContextProvider>
      )
      } />
    </SimpleFormWithLocation >
  );
}

export const AnomalyReportEmailExpandEdit: FC<EditProps> = props => (
  <Edit
    actions={ false }
    title={ '&nbsp;' }
    redirect='list'
    { ...props } >
    <AnomalyReportEmailForm />
  </Edit>
);

export const AnomalyReportEmailEdit: FC<EditProps> = props => (
  <Edit
    // actions={ <EditActions breadcrumb={ <MyBreadcrumb variant="actions" /> } /> }
    redirect='show'
    mutationMode='pessimistic'
    { ...props }
  >
    <AnomalyReportEmailForm />
  </Edit>
);
// export const AnomalyReportEmailCreate: FC<CreateProps> = props => <Create { ...props } ><AnomalyReportEmailForm /></Create>;

