import { Remove, Save, Send } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Card, CardContent, Checkbox, CircularProgress, FormControl, FormLabel, Toolbar, Typography, useTheme } from '@mui/material';
import { DataGridPremium, GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridRowId, GridRowsProp, GridSlotsComponentsProps, useGridApiRef } from '@mui/x-data-grid-premium';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { IfCanAccess } from '@react-admin/ra-rbac';
import { titleize } from 'inflection';
import { without } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Title, useAuthenticated, useCreate, useGetList, useUpdate } from 'react-admin';
import { EmailsInput } from './EmailsInput';
import { LanguagesSelect } from './LanguageSelect';
import { SplitButton } from './SplitButton';

export { NewReleases as GoLiveIcon } from '@mui/icons-material';


export interface GoLiveTemplateConfig {
  messageId: string;
  all?: boolean;
  email?: boolean;
  sms?: boolean;
  voice?: boolean;
}

export interface GoLiveTemplateData extends GoLiveTemplateConfig {
  id: number;
  name: string;
  recipientType: string;
  messagePurpose: string;
  hasVoice?: boolean;
}

export interface GoLiveInterface {
  id?: string;
  targetEmails: string[];
  langs: string[];
  recipientTypes: string[];
  messages: Omit<GoLiveTemplateConfig, 'all'>[];
  status: string;
  createdAt?: string;
  updatedAt?: string;
}

export type ExistingGoLiveInterface = GoLiveInterface & { id: string };

declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides {
    templateCount: number;
    templateTotal: number;
    emailCount: number;
    smsCount: number;
    voiceCount: number;
    sendCount: number;
  }
}

export const GoLive: FC = () => {
  useAuthenticated();
  const theme = useTheme();
  const [ _location, setLocation ] = useAppLocationState();
  const blockedMessagePurposes = [ 'Alert', 'Test Message', 'Unknown' ];
  const channelTypes = [ 'all', 'email', 'sms', 'voice' ];
  const recipientTypes = [ 'Patient', 'Non-Patient', 'Practitioner' ];
  const [ recipientType, setRecipientType ] = useState<string>( 'Patient' ) // recipientTypes.join( ';' ) );   // semicolon delimited
  const apiRef = useGridApiRef();
  const [ gridRev, setGridRev ] = useState( 0 );
  const { data: goLives, isLoading: isGoLiveLoading, refetch: refetchGoLives } = useGetList<ExistingGoLiveInterface>( 'golives', {
    pagination: { perPage: 10, page: 1 },
    sort: { field: 'updatedAt', order: 'DESC' },
  } );
  const { data } = useGetList( 'messagetemplates', {
    filter: { recipientType },
    pagination: { perPage: 200, page: 1 },
    sort: { field: 'name', order: 'ASC' },
  } );
  const [ create, { error: _createError, isLoading: isCreating } ] = useCreate<GoLiveInterface>( 'golives', undefined, { returnPromise: true } );
  const [ update, { error: _updateError, isLoading: isUpdating } ] = useUpdate<ExistingGoLiveInterface>( 'golives', undefined, { returnPromise: true } );
  const [ headerSelect, setHeaderSelect ] = useState<Record<string, boolean>>( { all: false, email: false, sms: false, voice: false } );
  const [ langs, setLangs ] = useState( [ 'en' ] );
  const [ targetEmails, setTargetEmails ] = useState<string[]>( [] );
  const [ loadedGoLive, setLoadedGoLive ] = useState<ExistingGoLiveInterface | undefined>();

  useEffect( () => {
    setLocation( 'admin.golive' );
    return () => setLocation( null );
  }, [] );

  const rows = useMemo<GridRowsProp<GoLiveTemplateData>>( () => {
    if( !data ) return [];
    return data
      .filter( m => !blockedMessagePurposes.includes( m.messagePurpose ) )
      .map( ( row, id ) => {
        const { id: messageId, name, recipientType, messagePurpose, twimlStart } = row;
        const hasVoice = !!twimlStart;
        const loaded = ( loadedGoLive?.messages || [] ).find( c => c.messageId == messageId );
        return {
          id,
          messageId,
          name,
          recipientType,
          messagePurpose,
          email: !!headerSelect.email || loaded?.email,
          sms: !!headerSelect.sms || loaded?.sms,
          voice: !!headerSelect.voice || loaded?.voice,
          hasVoice,
        };
      } )
  }, [ data, loadedGoLive ] );


  const updateChannelHeaderSelect = useCallback( ( channel: string, value: boolean ) => {
    const ids = apiRef.current.getAllRowIds();
    const rowMap = apiRef.current.getRowModels();
    const idsWithVoice: GridRowId[] = ( ids ).filter( id => rowMap.get( id )?.hasVoice );
    if( channel == 'all' ) {
      const channels = Object.fromEntries( channelTypes.map( channel => [ channel, value ] ) );
      // const channelsWithoutVoice = Object.fromEntries( without( channelTypes, 'voice' ).map( channel => [ channel, value ] ) );
      const channelsById = ( id: GridRowId ) => {
        if( !idsWithVoice.includes( id ) ) return { ...channels, voice: false };
        return channels;
      };
      setHeaderSelect( { ...headerSelect, ...channels } );
      apiRef.current.updateRows( ids.map( id => ( { id, ...channelsById( id ) } ) ) );
      return;
    }
    setHeaderSelect( { ...headerSelect, [ channel ]: value, 'all': false } );
    // if( !value ) {
    //   setHeaderSelect( { ...headerSelect, 'all': value } );
    // }
    apiRef.current.updateRows( [
      ...ids.map( id => ( {
        id,
        [ channel ]: value,
        ...( !value ? { all: false } : {} ),
        ...( !idsWithVoice.includes( id ) ? { voice: false } : {} ),
      } ) ),
    ] );
  }, [ apiRef, headerSelect ] );

  interface ChannelHeaderProps {
    channel: string;
    params: GridColumnHeaderParams<GoLiveTemplateData, boolean | undefined>;
  }

  const ChannelHeader: FC<ChannelHeaderProps> = props => {
    const { channel } = props;
    if( !channelTypes.includes( channel ) ) return <>{ channel }</>;
    return (
      <Box>
        <Checkbox
          checked={ !!headerSelect[ channel ] }
          // @ts-ignore: 2339
          onChange={ ( value ) => updateChannelHeaderSelect( channel, value.target.checked ) }
        />
        <Typography component='span'>{ titleize( channel ) }</Typography>
      </Box>
    )
  }

  interface ChannelCellProps {
    channel: string;
    params: GridRenderCellParams<GoLiveTemplateData, GoLiveTemplateData, boolean | undefined>;
  }

  const updateChannelCellSelect = useCallback( ( id: number, channel: string, value: boolean, hasVoice?: boolean ) => {
    if( channel == 'all' ) {
      const allChannels = hasVoice ? channelTypes : without( channelTypes, 'voice' );
      apiRef.current.updateRows(
        [ {
          id: id,
          ...Object.fromEntries( allChannels.map( channel => [ channel, value ] ) ),
        } ] );
      return;
    }
    apiRef.current.updateRows(
      [ {
        id: id,
        [ channel ]: value,
        all: false,
      } ] );
    if( !value ) {
      setHeaderSelect( { ...headerSelect, [ channel ]: value } );
    }
  }, [ apiRef, headerSelect ] );


  const ChannelCell: FC<ChannelCellProps> = props => {
    const { channel, params } = props;
    if( !channelTypes.includes( channel ) ) return <>{ channel }</>;
    const { hasVoice } = params.row;
    if( channel == 'voice' && !hasVoice ) return <></>;
    return (
      <Checkbox
        checked={ !!params.value }
        // @ts-ignore: 2339
        onChange={ ( value ) => updateChannelCellSelect( params.id, channel, value.target.checked, hasVoice ) }
      />
    );
  }

  const columns = useMemo<GridColDef<GoLiveTemplateData>[]>( () => [
    // { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'recipientType', headerName: 'Recipient', flex: 1 },
    { field: 'messagePurpose', headerName: 'Purpose', flex: 1 },
    { field: 'name', headerName: 'Template', flex: 1 },
    {
      field: 'all',
      headerName: 'All',
      flex: 0.5,
      renderHeader: ( params ) => <ChannelHeader channel='all' params={ params } />,
      renderCell: ( params ) => <ChannelCell channel='all' params={ params } />,
      sortable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 0.5,
      renderHeader: ( params ) => <ChannelHeader channel='email' params={ params } />,
      renderCell: ( params ) => <ChannelCell channel='email' params={ params } />,
      sortable: false,
    },
    {
      field: 'sms',
      headerName: 'Sms',
      flex: 0.5,
      renderHeader: ( params ) => <ChannelHeader channel='sms' params={ params } />,
      renderCell: ( params ) => <ChannelCell channel='sms' params={ params } />,
      sortable: false,
    },
    {
      field: 'voice',
      headerName: 'Voice',
      flex: 0.5,
      renderHeader: ( params ) => <ChannelHeader channel='voice' params={ params } />,
      renderCell: ( params ) => <ChannelCell channel='voice' params={ params } />,
      sortable: false,
    },
  ], [ headerSelect ] );

  const messages = useMemo( () => {
    if( !apiRef.current?.getRowModels ) return [];
    const rows = Array.from( apiRef.current.getRowModels().values() );
    return rows.filter( row => {
      return !!row.email || !!row.sms || !!row.voice;
    } )
      .map( row => {
        const { messageId, email, sms, voice } = row;
        return { messageId, email, sms, voice };
      } )
  }, [ gridRev, apiRef.current ] );

  const stats = useMemo( () => {
    return {
      templateTotal: rows.length,
      templateCount: messages.length,
      emailCount: messages.filter( m => m.email ).length,
      smsCount: messages.filter( m => m.sms ).length,
      voiceCount: messages.filter( m => m.voice ).length,
      sendCount: targetEmails.length * langs.length * ( messages.filter( m => m.email ).length
        + messages.filter( m => m.sms ).length
        + messages.filter( m => m.voice ).length ),
    };
  }, [ rows, messages, targetEmails, langs ] )

  const StatsFooter: FC<NonNullable<GridSlotsComponentsProps[ 'footer' ]>> = ( props ) => {
    const { templateCount, templateTotal, emailCount: Email, smsCount: SMS, voiceCount: Voice, sendCount: Sends } = props;
    const stats = { Templates: `${ templateCount } of ${ templateTotal }`, Email, SMS, Voice, 'Total sends': Sends };
    // const className = 'MuiDataGrid-footerContainer MuiDataGrid-withBorderColor';
    return (
      <Box sx={ {
        p: '1rem',
        display: 'flex',
        justifyContent: 'space-between',
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.divider,
        backgroundColor: 'rgb(252, 252, 254)', // theme ?
      } }>
        {
          Object.entries( stats ).map( ( [ k, v ] ) => (
            <Typography key={ k } sx={ {} } >
              { k }: { v }
            </Typography>
          ) )
        }
      </Box >
    );
  }

  const onSubmit = useCallback( async ( status: string ) => {
    if( status == 'draft' && loadedGoLive && loadedGoLive.status == 'draft' ) {
      await update( 'golives', {
        id: loadedGoLive.id,
        previousData: loadedGoLive,
        data: {
          ...loadedGoLive,
          status,
          targetEmails,
          langs,
          recipientTypes: recipientType.split( ';' ),
          messages,
        }
      } );
      await refetchGoLives();
      return
    }
    await create( 'golives', {
      data: {
        status,
        targetEmails,
        langs,
        recipientTypes: recipientType.split( ';' ),
        messages,
      }
    } );
    await refetchGoLives();
  }, [ targetEmails, langs, messages, create, loadedGoLive, refetchGoLives ] );

  const goLiveOptions = useMemo( () => {
    return ( goLives || [] ).map( goLive => {
      const {
        id, status, langs, messages, recipientTypes,
        createdAt = new Date().toISOString(),
        updatedAt = new Date().toISOString(),
      } = goLive;
      const emailCount = messages.filter( m => m.email ).length;
      const smsCount = messages.filter( m => m.sms ).length;
      const voiceCount = messages.filter( m => m.voice ).length;
      return {
        value: id,
        label: (
          <Box sx={ {
            // pl: '1rem',
            display: 'flex', justifyContent: 'space-between', justifyItems: 'flex-end', width: '100%'
          } } >
            <Typography>{ recipientTypes.join( ',' ) }</Typography>
            <Box sx={ { flexGrow: 1 } } />
            <Typography>{ `${ emailCount }e/${ smsCount }s/${ voiceCount }v` }</Typography>
            <Box sx={ { flexGrow: 1 } } />
            <Typography>{ langs.join( ',' ) }</Typography>
            <Box sx={ { flexGrow: 1 } } />
            <Typography>{ new Date( createdAt ).toLocaleString() }</Typography>
            <Box sx={ { flexGrow: 1 } } />
            <Typography>{ new Date( updatedAt ).toLocaleString() }</Typography>
            <Box sx={ { flexGrow: 1 } } />
            <Typography>{ status }</Typography>
          </Box>
        ),
        // disabled: true,
      };
    } );
  }, [ goLives ] );

  const onLoad = useCallback( ( id: string ) => {
    if( !goLives ) return;
    const goLive = goLives.find( g => g.id == id );
    if( !goLive ) return;
    const { targetEmails, langs, recipientTypes } = goLive;
    setLoadedGoLive( goLive );
    setTargetEmails( targetEmails );
    setLangs( langs );
    setRecipientType( recipientTypes.join( ';' ) );
  }, [ goLives, setTargetEmails, setLangs, setRecipientType, setLoadedGoLive ] );


  return (
    ( <IfCanAccess resource='system' action='manage'>
      <Card
        sx={ {
          minHeight: '25em',
        } }
      >

        <Title title="GoLive! Console" />
        <CardContent>

          <Toolbar
            sx={ {
              flexFlow: 'row wrap',
              gap: '1rem',
              alignItems: 'flex-start',
            } }
          >
            <FormControl fullWidth={ false } >
              <FormLabel id='recipient-type-select'>Recipient Types</FormLabel>
              <ButtonGroup id='recipient-type-select'>
                { recipientTypes.map( t => (
                  <Button
                    key={ t }
                    variant={ recipientType.split( ';' ).includes( t ) ? 'contained' : undefined }
                    onClick={ () => {
                      const selected = recipientType.split( ';' );
                      if( selected.includes( t ) ) {
                        setRecipientType( without( selected, t ).join( ';' ) )
                      } else {
                        setRecipientType( [ ...selected, t ].join( ';' ) );
                      }
                    } }
                  >
                    { t }
                  </Button>
                ) ) }
              </ButtonGroup>
            </FormControl>

            <Box sx={ { flexGrow: 1 } } />

            <FormControl fullWidth={ false } >
              <FormLabel id='load-select'>&nbsp;</FormLabel>
              <SplitButton
                options={ goLiveOptions }
                onClick={ ( value ) => onLoad( value ) }
                disabled={ isGoLiveLoading || !goLives?.length }
                disabledFully={ isGoLiveLoading }
                variant={ loadedGoLive ? 'outlined' : undefined }
              >
                Load latest
              </SplitButton>
            </FormControl>

            <Box sx={ { flexBasis: '100%', height: 0 } } />

            <FormControl fullWidth={ false } >
              <FormLabel id='emails-select'>Target emails</FormLabel>
              <EmailsInput
                value={ targetEmails }
                onChange={ ( value ) => setTargetEmails( value ) }
              />
            </FormControl>

            <Box sx={ { flexGrow: 1 } } />

            <FormControl fullWidth={ false } >
              <FormLabel id='langs-select'>Languages</FormLabel>
              <LanguagesSelect
                // id='langs-select'
                langs={ langs }
                onChange={ ( e ) => {
                  const { value } = e.target;
                  setLangs( Array.isArray( value ) ? value : [ value ] );
                } }
              />
            </FormControl>




          </Toolbar>


          <FormControl
            sx={ {
              marginTop: '2rem',
              width: '100%',
            } }
          >
            { rows.length > 0 &&

              <DataGridPremium
                apiRef={ apiRef }
                columns={ columns }
                rows={ rows }
                autoHeight
                rowGroupingColumnMode="single"
                onStateChange={ () => setGridRev( gridRev + 1 ) }
                initialState={ {
                  // rowGrouping: {
                  //   model: [ 'recipientType' ],
                  // },
                  density: 'comfortable',
                  // hideFooter
                  // autoHeight
                  // autoPageSize
                } }
                disableRowSelectionOnClick
                getRowHeight={ () => 'auto' }
                // getDetailPanelContent={ getDetailPanelContent }
                getDetailPanelHeight={ () => 'auto' }
                sx={ {
                  '& .MuiDataGrid-cell--withRenderer .MuiButtonBase-root.Mui-disabled': {
                    color: 'hsl(0deg 0% 0% / 0%)',
                  },
                } }
                slots={ {
                  detailPanelCollapseIcon: Remove,
                  footer: StatsFooter,
                } }
                slotProps={ {
                  footer: stats,
                } }
              />
            }
          </FormControl>

          <Toolbar
          >
            <Button
              disabled={ messages.length === 0 || isCreating || isUpdating }
              onClick={ () => onSubmit( 'draft' ) }
              startIcon={ isUpdating ? <CircularProgress size='1rem' /> : <Save /> }
            >
              Save { loadedGoLive && loadedGoLive.status != 'draft' ? 'new' : '' } draft
            </Button>
            <Box sx={ { flexGrow: 1 } } />
            <Button
              variant='contained'
              disabled={ messages.length === 0 || isCreating || isUpdating }
              onClick={ () => onSubmit( 'submitted' ) }
              startIcon={ isCreating ? <CircularProgress size='1rem' /> : <Send /> }
            >
              Submit for processing
            </Button>

          </Toolbar>

          {/* <Box component='pre'>
              { dump( {
              loadedGoLive,
              data: {
              targetEmails,
              langs,
              messages,
              }
              } ) }
              </Box> */}

        </CardContent >
      </Card >
    </IfCanAccess > )
  );

}
