import { Link as MuiLink } from '@mui/material';
import { get } from 'lodash';
import { FC } from 'react';
import { Datagrid, DateField, DateInput, Filter, FilterProps, List, ListProps, RaRecord, ReferenceField, SelectField, SelectInput, TextField, useRedirect, WithRecord } from 'react-admin';
import { useAdminListAppLocation } from './Admin';
import { choices } from './DataProvider';
import { TimestampsField } from './TimestampsField';
import { Childless } from './types';

export { default as ActionLogIcon } from '@mui/icons-material/AssignmentTurnedIn';

const ActionLogFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <SelectInput source='category' choices={ choices.actionLogCategory } />
    <SelectInput source='action' choices={ choices.actionLogAction } />
    <DateInput label="Date" source="createdAt" alwaysOn />
  </Filter>
);

export const ActionLogList: FC<Childless<ListProps>> = props => {
  useAdminListAppLocation();
  const redirect = useRedirect();

  const onClickRecipient = ( recipient: RaRecord ) => () => {
    redirect( '/contacteditor', undefined, undefined, undefined, { recipient } )
  };

  const filterDefaultValues = {
    //transmitMethod: 'sms'
  };

  return (
    <List { ...props }
      filterDefaultValues={ filterDefaultValues }
      filters={ <ActionLogFilter /> }
      exporter={ false }
      sort={ { field: 'createdAt', order: 'DESC' } }
    >
      <Datagrid
        bulkActionButtons={ false }
        rowStyle={ ( record ) => {
          const colors = { notAtAll: 'red', slightly: 'orange' };
          return {
            borderLeft: `solid 10px ${ get( colors, record.severity ) }`,
          }
        } }

      >

        { /* <SelectField source='category' choices={ choices.actionLogCategory } /> */ }
        <SelectField source='action' choices={ choices.actionLogAction } />
        <TimestampsField label="Submitted" source='createdAt' />

        <ReferenceField label="Patient" source="recipient" reference="recipients" sortable={ false }>
          <WithRecord render={ ( record ) =>
            <MuiLink onClick={ onClickRecipient( record ) } underline='none' >
              <TextField source="fullName" />
            </MuiLink>
          } />
        </ReferenceField>

        <ReferenceField source="appointment" reference="appointments" link='show' sortable={ false }>
          <DateField source="startTime" showTime />
        </ReferenceField>

        <ReferenceField label="Type" source="appointment" reference="appointments" sortable={ false }>
          <ReferenceField label="Type" source="appointmentTypeCode" reference="appointmenttypepackages">
            <TextField source="name"
            // link='show'
            />
          </ReferenceField>
        </ReferenceField>

        <ReferenceField label="Practitioner" source="appointment" reference="appointments" sortable={ false }>
          <ReferenceField label="Practitioner" source="practitioner" reference="practitioners" link='show'>
            <TextField source="fullName" />
          </ReferenceField>
        </ReferenceField>

        <ReferenceField label="Location" source="appointment" reference="appointments" sortable={ false }>
          <ReferenceField label="Location" source="location" reference="locations" link='show'>
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>


      </Datagrid>
    </List>
  );
}
