import { SsidChart as LineChartIcon, TableChartOutlined as TableIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, IconButton, Typography, useTheme } from '@mui/material';
import { AxisTick } from '@nivo/axes';
import { Bar, BarDatum } from '@nivo/bar';
import { useCanAccess } from '@react-admin/ra-rbac';
import * as dateFns from 'date-fns';
import _ from 'lodash';
import { FC, useMemo, useState } from 'react';
import { Loading } from 'react-admin';
import { DashboardDatagrid } from './DashboardDatagrid';
import { ChartProps, elevation, OnlyNumbers } from './DashboardWidget';
import { useUserPreference } from './UserPreferences';
import useColorPalettes from './use-color-palettes';
import { useOrgDateMarkers } from './use-markers';
import usePatterns from './use-patterns';
import { useBarTrendPredictions } from './use-predictions';
import useSummary from './use-summary';

interface ReviewTrendDatum extends BarDatum {
  date: string;
  md: string;
  ymd: string;
  '1 star': number;
  '2 stars': number;
  '3 stars': number;
  '4 stars': number;
  '5 stars': number;
}

export const DashboardReviewTrend: FC<ChartProps> = ( { date } ) => {
  const theme = useTheme();
  const { canAccess: canAccessSystem } = useCanAccess( { action: 'manage', resource: 'system' } );
  const isSmall = false;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const defaultDurationMonths = 6;
  const cacheKey = 'dashboard-review-trend';
  const [ showDatagrid, setShowDatagrid ] = useState( false );

  const keys: ( keyof OnlyNumbers<ReviewTrendDatum> )[] = [ '1 star', '2 stars', '3 stars', '4 stars', '5 stars' ];

  const colorPalettes = useColorPalettes();
  const colors = useMemo( () => colorPalettes.spectrumGreenRed.reverse(), [ colorPalettes ] );
  const textColor = useMemo( () => theme.palette?.mode === 'dark' ? '#ccc' : '#333', [ theme.palette ] );
  const patterns = usePatterns();

  const durationMonths = useMemo( () => {
    if( isLoadingPreferences || !preferences?.dashboardTrendMonths ) return defaultDurationMonths;
    return preferences.dashboardTrendMonths as number;
  }, [ preferences, isLoadingPreferences ] );

  const summary = useSummary<ReviewTrendDatum[]>( 'reviewTrend', { date, months: durationMonths } );

  const { predictedData, layers } = useBarTrendPredictions<ReviewTrendDatum>( summary.data ?? [], date, keys );

  const data = useMemo( () => predictedData || summary.data || [], [ summary.data ] );

  const isEmpty = useMemo( () => {
    return !data.find( datum => {
      for( const key in datum ) {
        if( key == 'id' ) continue;
        if( datum[ key ] ) return true;
      }
      return false;
    } )
  }, [ data ] );

  const tickMonths = useMemo( () => {
    const skipSize = Math.ceil( data.length / 8 );
    return data.map( d => d.id ).filter( ( _d, i ) => !( i % skipSize ) );
  }, [ data ] );

  const yMax = useMemo( () => Math.max( ...[ 10, ...data.flatMap( row => _( row ).pick( keys ).values().sum() ) ] ), [ data ] );

  const markers = useOrgDateMarkers( {
    date,
    duration: { type: 'months', count: durationMonths },
    formatValue: d => dateFns.format( d, `MMM ''yy` ),
  } );

  const tickValues = useMemo( () => {
    const end = Math.ceil( yMax / 20 ) * 20;
    const step = end / 4;
    return _.range( step, end + step, step );
  }, [ yMax ] );

  return (
    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={
          <Box sx={ { display: 'flex' } } >
            <Typography>Review Trend</Typography>
            <Box sx={ { flexGrow: 1 } } />
            <IconButton
              sx={ {
                // paddingTop: 0, paddingBottom: 0,
                marginTop: '-8px',
                marginBottom: '-8px',
              } }
              onClick={ () => setShowDatagrid( !showDatagrid ) }
            >
              { showDatagrid ? <LineChartIcon /> : <TableIcon /> }
            </IconButton>
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `${ durationMonths } Months Ending ${ date.format( 'MMM YYYY' ) }` : `Previous ${ durationMonths } Months` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
            // fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        { summary.isLoading
          ? <Box>
            <Loading
              loadingPrimary=''
              loadingSecondary=''
              sx={ {
                '@media (min-width: 0)': {
                  marginTop: 0,
                  marginBottom: 7,
                  height: '100%',
                  width: 500,
                  minHeight: 400,
                }
              } }
            />
          </Box>

          :
          <Box
            sx={ {
              position: 'relative',
            } }
          >
            { showDatagrid
              ? (
                <DashboardDatagrid
                  rows={ ( summary.data || [] ).map( ( d, id ) => ( { id, ...d } ) ) }
                  columns={ keys }
                  exportFileName={ cacheKey }
                />
              )
              : <>

                <Bar
                  width={ 500 }
                  height={ 400 }
                  data={ isEmpty ? [] : data }
                  keys={ keys }
                  margin={ { top: 50, right: 50, bottom: 55, left: 75 } }
                  padding={ 0.25 }
                  gridYValues={ tickValues }

                  isInteractive={ !isEmpty }

                  maxValue={ tickValues[ tickValues.length - 1 ] }

                  theme={ {
                    background: theme.palette?.background?.default,
                    axis: {
                      legend: { text: { fontSize: isSmall ? 16 : 18 } },
                      ticks: { text: { fontSize: isSmall ? 0 : 14 } },
                    },
                    labels: { text: { fill: '#333' } },
                    legends: { text: { fontSize: 14 } },
                    text: { fill: textColor },
                    tooltip: {
                      container: {
                        borderRadius: '8px',
                        border: `0.5px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
                        boxShadow: `${ theme.palette?.grey?.A400 ?? 'grey' } 0px 2px 2px`,
                        backgroundColor: theme.palette?.background?.default,
                        color: textColor,
                        padding: '5px 9px',
                      },
                    },
                  } }

                  colors={ isEmpty ? [ textColor ] : colors } //{ scheme: colors, size: keys.length } }
                  defs={ patterns.defs }
                  fill={ patterns.fill }
                  
                  enableGridX={ false }
                  enableGridY={ true }
                  enableLabel={ false }
                  axisTop={ null }
                  axisRight={ null }
                  axisLeft={ {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: tickValues,
                    legend: 'Ratings',
                    legendPosition: 'middle',
                    legendOffset: -67

                  } }

                  axisBottom={ {
                    tickSize: 5,
                    tickPadding: 5,
                    renderTick: ( props => tickMonths.includes( props.value ) ? <AxisTick {...props}/> : <></> ),
                    legend: 'Month',
                    legendPosition: 'middle',
                    legendOffset: 42,
                  } }

                  layers={ layers }

                  markers={ markers }

                  legends={ [ {
                    anchor: 'top-left',
                    direction: 'row',
                    justify: false,
                    dataFrom: 'keys',
                    translateX: -45,
                    translateY: -40,
                    itemsSpacing: 45,
                    itemWidth: 50,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 1, // 0.95,
                    symbolSize: 18, // 20,
                    symbolShape: 'circle', // 'square',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1
                        }
                      }
                    ],
                    toggleSerie: true,
                  } ] }
                />
              </>
            }
          </Box>
        }
      </CardContent>
    </Card >

  )
}
