import { Box, Card, CardContent, Tab, Tabs } from '@mui/material';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Title } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocationList } from './Locations';
import { OrganizationList, useHasNoEmr } from './Organizations';
import { PractitionerList } from './Practitioners';
import { ResourceContextProvider } from './ResourceContextProvider';

export const Organization: FC = () => {
  const basePath = 'organization';
  const hasNoEmr = useHasNoEmr();
  const tabs = [ 'locations', 'practitioners', 'organizations' ];
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [ _location, setLocation ] = useAppLocationState();
  const [ tab, setTab ] = useState( tabs[ 0 ] );

  const handleChange = ( _event: SyntheticEvent, newValue: string ) => {
    navigate( `/${ basePath }/${ newValue }` );
  };

  useEffect( () => {
    setLocation( basePath );
    return () => setLocation( null );
  }, [] );

  useEffect( () => {
    const [ , , tab ] = pathname.split( '/' )
    if( !tabs.includes( tab ) ) return;
    setTab( tab );
  }, [ pathname ] );

  useEffect( () => {
    setLocation( `${ basePath }.${ tab }` );
  }, [ tab ] );

  return (
    <Box>
      <Title title='Organization ' />
      <Tabs value={ tab } onChange={ handleChange }>
        <Tab disableRipple value='locations' label='Locations' />
        { !hasNoEmr &&
          <Tab disableRipple value='practitioners' label='Practitioners' />
        }
        <Tab disableRipple value='organizations' label='Organizations' />
      </Tabs>

      <Card
        sx={ {
          minHeight: '25em',
        } }
      >
        <CardContent>
          <ResourceContextProvider value={ tab }>
            { tab == 'organizations'
              ? <OrganizationList title={ <></> } />
              : tab == 'practitioners'
                ? <PractitionerList title={ <></> } />
                : <LocationList title={ <></> } />
            }
          </ResourceContextProvider>
        </CardContent>
      </Card>


    </Box >
  );
}
