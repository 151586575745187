// import { useGetTreeCallback } from '@react-admin/ra-tree';
import { uniq } from 'lodash';
import { maxLength, minLength, required } from 'react-admin';

const restrictedNames = 'api test debug db sql default organization alert run location member user asset image comment object objectid _id version _v createdAt updatedAt'.split( /\s+/ );

type ValidatorFunction = ( value: string, values: string[] ) => string | undefined;
type MakeValidatorFunction = ( message?: string ) => ValidatorFunction;

export const noRestrictedWords: MakeValidatorFunction = ( message = 'May not be a certain words' ) =>
  ( value ) => restrictedNames.includes( value.trim().toLowerCase() ) ? message : undefined;

export const noSpecialChars: MakeValidatorFunction = ( message = 'May not use certain characters' ) =>
  ( value: string ) => value.match( /[\][<>!?*#]/ ) ? message : undefined;

export const validateName = [ required(), minLength( 2 ), maxLength( 60 ), noRestrictedWords(), noSpecialChars() ];


export const nodupes: MakeValidatorFunction = ( message = 'app.validation.nodupes' ) => ( value, _values ) => {

  return Array.isArray( value ) && value.length > uniq( value ).length ? message : undefined;
}


// // Usage: 
// //   const getTree = useGetTreeCallback( 'organizations' );
// //   const validateNameIsUnique = useValidateNameIsUniqueIn( getTree );
// //   <TextInput source="name" validate={ [ ...validateName, validateNameIsUnique ] } />
// export const useValidateNameIsUniqueIn = ( getTree: ReturnType<typeof useGetTreeCallback> ) => {
//   return useCallback<Validator>( async ( value: string, _values: unknown, fieldState: UseInputValue[ 'fieldState' ] ) => {
//     const data = await getTree();
//     return data.find( o => o.name == value && fieldState.isDirty ) ? 'Cannot be a duplicate name' : undefined;
//   }, [ getTree ] );
// }

// TOOD HERE url validate - add https:// , do reachability check
// TODO HERE address validate - lookup, reformat, confirm changes
