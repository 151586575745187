import { FC, useCallback, useEffect, useState } from 'react';
import { AutocompleteInput, Datagrid, DatagridProps, DateField, DateInput, Filter, FilterProps, FunctionField, ImageField, LinearProgress, List, ListProps, ReferenceField, ReferenceInput, TextField, useListContext, WithRecord } from 'react-admin';
import { apiUrl, httpClient } from './DataProvider';
import MyUrlField from './MyUrlField';
import { Childless } from './types';

export { default as ReputationClickIcon } from '@mui/icons-material/Mail';

const ReputationClickFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <DateInput label="Date" source="createdAt" alwaysOn />
    <ReferenceInput label="Location" source="location" reference="locations" allowEmpty filter={ { status: 'active' } } sort={ { field: 'name', order: 'ASC' } }>
      <AutocompleteInput source="name" />
    </ReferenceInput>
    { /*
       <ReferenceInput label="Recipient" source="recipient" reference="recipients" alwaysOn allowEmpty filter={ { active: true } } sort={ { field: 'fullName', order: 'ASC' } } >
       <AutocompleteInput source="fullName" />
       </ReferenceInput>
       <ReferenceInput label="Service" source="reputationService" reference="reputationplatforms" alwaysOn allowEmpty >
       <AutocompleteInput source="name"
       sx={ {
       width: '15rem',
       } }
       />
       </ReferenceInput>
     */}
  </Filter>
);

export const ReputationClickList: FC<Childless<ListProps>> = props => (
  <List { ...props }
    exporter={ false }
    filters={ <ReputationClickFilter /> }
    sort={ { field: 'createdAt', order: 'DESC' } }
  >
    <ReputationClickListGrid />
  </List>
);

export const ReputationClickListGrid: FC<DatagridProps> = () => {
  const { data } = useListContext<{ id: string; pledgeId?: string; feedback: { id: string } }>();
  const [ nonprofitMap, setNonprofitMap ] = useState<Record<string, string>>( {} );
  const [ isLoading, setIsLoading ] = useState( false );

  const fetchNonprofitName = useCallback( async ( id: string | undefined, feedback: string | undefined ): Promise<string | undefined> => {
    if( !id || !feedback ) return;
    const { body } = await httpClient( `${ apiUrl }/data/nonprofits/${ id }?t=${ feedback }` );
    try {
      const { name } = JSON.parse( body ) as { name: string };
      return name;
    } catch( e ) { return; }
  }, [ httpClient ] );

  useEffect( () => {
    ( async () => {
      if( !data ) return;
      const map = nonprofitMap;
      const feedback = data.find( d => d.feedback.id )?.feedback.id || '';
      const ids = Array.from( new Set( data.map( d => d.pledgeId || '' ) ) );
      setIsLoading( true );
      for( const id of ids ) {
        const name = await fetchNonprofitName( id, feedback );
        if( name ) map[ id ] = name;
      }
      setNonprofitMap( map );
      setIsLoading( false );
    } )();
  }, [ data, setNonprofitMap ] );

  return (
    <Datagrid
      bulkActionButtons={ false }
      sx={ {
        '& .column-userAgent, & .column-referer': {
          width: '16em',
        }
      } }
    >
      <DateField label="Clicked" source="createdAt" showTime={ true } />
      <ReferenceField label="Recipient" source="recipient" reference="recipients" link={ false }>
        <TextField source="fullName" />
      </ReferenceField>

      <ReferenceField label="Location" source="location" reference="locations" link={ false }>
        <TextField source="nickname" />
      </ReferenceField>

      {/* <TextField label="Service" source='reputationService.name' /> */ }
      <ReferenceField label='Service' source='reputationService.id' reference='reputationplatforms' >
        <WithRecord render={ ( record ) => (
          <ImageField source='src' record={ { src: `/${ record.id }.png` } } sx={ {
            '& .RaImageField-image': {
              width: '60px',
              height: '30px',
            }
          } } />
        ) } />
      </ReferenceField>

      <MyUrlField label="Review page" source='reputationService.url'
        style={ {
          textDecoration: 'none',
        } }
        rel="noopener"
        target="_blank"
        onClick={ e => { e.stopPropagation(); } }
      />

      <FunctionField
        label='Charity'
        source='pledgeId'
        render={ ( r ) => ( isLoading ? <LinearProgress /> : r?.pledgeId && nonprofitMap[ r.pledgeId ] || null ) }

      />
      { /*
         <TextField label="IP Address" source='senderIp' />
         <TextField
         label="User Agent (Debug)"
         source='userAgent'
         />
         <TextField
         label="Referer"
         source='referer'
         />
         <TextField
         label="Charity Id"
         source='pledgeId'
         />
         <ReferenceField label="Prepared" source="feedback" reference="feedbacks" link={ false }>
         <DateField source="createdAt" />
         </ReferenceField>
       */ }
    </Datagrid >

  );
}

