import { Add as AddIcon, Edit as EditIcon, EditNote as DraftIcon, Star, StarOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, TextField as MuiTextField, Select, Tooltip, Typography, useTheme } from '@mui/material';
import { BarDatum, BarLegendProps, ResponsiveBar } from '@nivo/bar';
import _ from 'lodash';
import { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AutocompleteArrayInput, DataProviderContext, Datagrid, DateField, DateInput, Filter, FunctionField, Identifier, List, ListProps, Loading, NumberField, RaRecord, ReferenceField, ReferenceInput, SelectArrayInput, SimpleShowLayout, TextField, WithRecord, useGetList, useGetMany, useGetOne, useNotify } from 'react-admin';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { Childless } from './types';
import { ReputationResponseTemplate } from './ReputationResponseTemplates';

export { default as ReputationSubjectReviewIcon } from '@mui/icons-material/StarHalf';

const ReputationSubjectReviewFilters: FC = props => {
  return (
    <Filter { ...props }>
      <DateInput label="Published Date" source="publishedAt" />
      <ReferenceInput label='Location' source='reference' reference='locations' filter={ { status: 'active' } } >
        <AutocompleteArrayInput label='Location' />
      </ReferenceInput>
      <ReferenceInput source='platform' reference='reputationplatforms'><AutocompleteArrayInput /></ReferenceInput>
      <SelectArrayInput source='rating' choices={ _.range( 1, 6 ).map( n => ( { id: n, name: n } ) ) } />
    </Filter>
  );
};

interface ReviewRecord extends RaRecord {
  body?: string,
  rating: number,
  response?: { body?: string },
}
export const ReputationSubjectReviewList: FC<Childless<ListProps>> = props => {
  const { data, isLoading } = useGetList<{ name: string } & RaRecord>( 'organizations' );
  if( isLoading ) return null;
  return (
    <List { ...props }
      filters={ <ReputationSubjectReviewFilters /> }
      filter={ {
        kind: 'Location',
        organization: data?.[ 0 ].name,
      } }
      exporter={ false }
      sort={ { field: 'publishedAt', order: 'DESC' } }
    >
      <Datagrid
        bulkActionButtons={ false }
        expand={ <ReputationSubjectReviewExpandShow /> }
        isRowExpandable={ ( record: ReviewRecord ) => !!( record.body || record.response?.body ) }
        rowStyle={ ( record: ReviewRecord ) => {
          const colors = [ undefined, 'red', 'orange', 'yellow', 'yellowgreen', 'green' ];
          return {
            borderLeft: `solid 10px ${ colors[ record.rating ] }`,
          }
        } }
      >
        <NumberField source="rating" />
        <DateField source="publishedAt" label="Date" sortByOrder='DESC' />
        <TextField label="Profile" source="name" />
        <ReferenceField label="Platform" source="profile" reference="reputationprofiles" link={ false } >
          <ReferenceField source="platform" reference="reputationplatforms" link={ false } >
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>
        <TextField source="author" />
        <ReferenceField label={ false } source="review" reference="reputationreviews" link={ false } >
          <WithRecord render={ ( record: RaRecord ) => {
            return (
              <ManageReviewResponseDialog
                reviewId={ record.id }
                initialText={ record.response?.body ?? '' }
                isDraft={ !record.externalId }
              />
            );
          } } />
        </ReferenceField>
      </Datagrid>
    </List>
  )
};

const ReputationSubjectReviewExpandShow: FC = () => (
  <SimpleShowLayout>
    <FunctionField label="Comment" render={ ( record: { body?: string } ) => record.body?.replaceAll( '<br>', '\n' ).replaceAll( '&nbsp;', ' ' ) } />
    <ReferenceField label="Response" reference='reputationreviews' source='review' >
      <TextField label="Response" source='response.body' />
    </ReferenceField>
  </SimpleShowLayout>
);

interface ManageReviewResponseDialogParams {
  reviewId: Identifier;
  initialText: string;
  isDraft?: boolean;
}

export const ManageReviewResponseDialog: FC<ManageReviewResponseDialogParams> = ( params ) => {
  const { data, isLoading: isDataLoading } = useGetOne( 'reputationreviews', { id: params.reviewId } );
  const { data: organizations, isLoading: isOrganizationsLoading } = useGetList<{ name: string } & RaRecord>( 'organizations' );
  const { data: templates, isLoading: isTemplatesLoading } = useGetList<ReputationResponseTemplate>( 'reputationresponsetemplates', {
    filter: {
      organization: organizations?.length ? organizations[ 0 ].id : undefined,
      ratings: data?.rating,
    }
  } );
  const [ isSavingResponse, setIsSavingResponse ] = useState( false );
  const [ isGeneratingResponse, setIsGeneratingResponse ] = useState( false );
  const dataProvider = useContext( DataProviderContext );
  const [ open, setOpen ] = useState( false );
  const [ defaultText, setDefaultText ] = useState( params.initialText );
  const [ selectedTemplate, setSelectedTemplate ] = useState<Identifier>( -1 );
  const [ input, setInput ] = useState( params.initialText );
  const queryClient = useQueryClient();
  const notify = useNotify();

  const handleClickOpen = () => setOpen( true );
  const handleClose = () => setOpen( false );

  useEffect( () => {
    if( selectedTemplate == -1 ) {
      setInput( defaultText );
      return;
    }
    setInput( templates?.find( template => template.id == selectedTemplate )?.body ?? defaultText );
  }, [ selectedTemplate ] );

  // true if input is empty but the existing response is not
  const shouldDeleteOnSubmit = useMemo( () => !!( params.initialText?.length && !input?.length ), [ params.initialText, input ] );

  const generateResponse = useCallback( async () => {
    if( !dataProvider ) return;
    setIsGeneratingResponse( true );
    try {
      const { json } = await dataProvider.fetchJson( `${ dataProvider.apiUrl }/reputationreviews/${ params.reviewId }/generateresponse` );
      setInput( json.content );
    } finally {
      setIsGeneratingResponse( false );
    }
  }, [ dataProvider, params.reviewId, setInput ] )

  const handleSubmit = useCallback( async () => {
    if( !dataProvider ) return;
    setIsSavingResponse( true );

    const options = shouldDeleteOnSubmit ? { method: 'DELETE' } : { method: 'PATCH', body: JSON.stringify( { comment: input } ) };
    try {
      await dataProvider.fetchJson( `${ dataProvider.apiUrl }/reputationreviews/${ params.reviewId }/response`, options );
      await queryClient.invalidateQueries( {
        queryKey: [ 'reputationreviews', 'getOne', { id: params.reviewId } ]
      } );
      await queryClient.invalidateQueries( {
        queryKey: [ 'reputationsubjectreviews', 'getList' ]
      } );
      setDefaultText( input );
    } catch {
      notify( 'ra.page.error', { type: 'error' } );
    }

    setIsSavingResponse( false );
    handleClose();
  }, [ input ] );

  const handleCopy = useCallback( async () => {
    await navigator.clipboard.writeText( input );
    handleClose();
    notify( 'app.confirmation.add.clipboard', { type: 'success' } );
  }, [ input ] );

  const [ buttonLabel, buttonIcon ] = useMemo( () => {
    if( params.isDraft ) return [ 'Draft Reply', <DraftIcon /> ];
    return params.initialText ? [ 'Edit Reply', <EditIcon /> ] : [ 'Add Reply', <AddIcon /> ];
  }, [ params.isDraft, params.initialText ] );

  if( isDataLoading || isOrganizationsLoading || isTemplatesLoading ) return null;
  return (
    <>
      <Tooltip title={ params.isDraft ? `This location is not configured for managing replies, but you can draft a response to submit manually.` : '' } >
        <Button
          size='small'
          onClick={ handleClickOpen }
          disableFocusRipple
          startIcon={ buttonIcon }
        > { buttonLabel } </Button>
      </Tooltip>
      <Dialog
        open={ open || isSavingResponse }
        onClose={ handleClose }
        fullWidth
        maxWidth='xl'
      >
        <DialogTitle> Manage Review Response </DialogTitle>
        <DialogContent>
          <div style={ { color: 'gold' } }>
            { new Array( 5 ).fill( undefined ).map( ( _value, index ) => index < data.rating ? <Star key={ index } /> : <StarOutline key={ index } /> ) }
          </div>
          <Typography> { data.body } </Typography>
          <Typography> - { data.author } </Typography>
          <FormControl fullWidth>
            <InputLabel id='template-select-label'> Response Template </InputLabel>
            <Select
              labelId='template-select-label'
              id='template-select'
              // label="Response Template"
              value={ selectedTemplate }
              onChange={ e => setSelectedTemplate( e.target.value as number ) }
              fullWidth
            >
              <MenuItem key={ -1 } value={ -1 } > None </MenuItem>
              { templates?.map( template => <MenuItem key={ template.id } value={ template.id }> { template.title } </MenuItem> ) }
            </Select>
            <MuiTextField
              autoFocus
              label="Response"
              fullWidth
              multiline
              variant='standard'
              value={ input }
              onChange={ e => setInput( e.target.value ) }
              minRows={ 10 }
              maxRows={ 20 }
              inputProps={ { maxLength: 4096 } }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={ isGeneratingResponse }
            disabled={ isGeneratingResponse || isSavingResponse }
            onClick={ generateResponse }
            variant='contained'
            disableFocusRipple
            tabIndex={ 3 }
          > Generate AI Response </LoadingButton>
          <div style={ { flexGrow: 1 } }></div>
          <Button
            onClick={ () => { setInput( params.initialText ); handleClose() } }
            color='inherit'
            variant='contained'
            disableFocusRipple
            tabIndex={ 2 }
          >Cancel</Button>
          {
            params.isDraft
              ? (
                <Button
                  onClick={ handleCopy }
                  color='primary'
                  variant='contained'
                  disableFocusRipple
                  disabled={ isSavingResponse || isGeneratingResponse || input == params.initialText }
                  tabIndex={ 1 }
                >
                  Copy to Clipboard
                </Button>
              )
              : (
                <LoadingButton
                  loading={ isSavingResponse }
                  onClick={ handleSubmit }
                  color={ shouldDeleteOnSubmit ? 'error' : 'primary' }
                  variant='contained'
                  disableFocusRipple
                  disabled={ isSavingResponse || isGeneratingResponse || input == params.initialText }
                  tabIndex={ 1 }
                >
                  { shouldDeleteOnSubmit ? 'Delete' : 'Save' }
                </LoadingButton>
              )
          }

        </DialogActions>
      </Dialog>
    </>
  );
}

export type DurationType = 'months' | 'years';
export interface ReputationSubjectReviewChartProps {
  title?: string;
  height?: number;
  small?: boolean;
  duration: {
    type: DurationType,
    count: number,
  };
  subjects?: string[];
  organizations?: string[];
  kinds?: string[];
  platforms?: string[];
}
export const ReputationSubjectReviewChart: FC<ReputationSubjectReviewChartProps> = props => {
  const {
    title,
    small: isSmall,
    duration,
    subjects,
    organizations,
    kinds,
    platforms,
  } = props;
  const height = isSmall ? 250 : 500;
  const dataProvider = useContext( DataProviderContext );
  const { data: subjectData } = useGetMany<{ id: Identifier, name: string }>( 'reputationsubjects', { ids: subjects } );
  const theme = useTheme();

  const isFiltered = useMemo( () => !!_.find( props, p => Array.isArray( p ) && p.length > 0 ), [ subjects, organizations, kinds, platforms ] );

  const defaultTitle = useMemo( () => {
    // if( organizations?.length ) {
    //   return organizations.length > 1 ? `${ organizations[ 0 ] }, et al.` : organizations[ 0 ];
    // }
    if( subjectData?.length ) {
      const firstSubjectName = subjectData[ 0 ].name;
      return subjectData.length > 1 ? `${ firstSubjectName }, et al.` : firstSubjectName;
    }
    return platforms?.join( ', ' );
  }, [ organizations, platforms, subjectData ] );

  const chartQueryString = useMemo( () => {
    const query = new URLSearchParams( {
      durationCount: `${ duration.count }`,
      durationType: duration.type,
    } );
    subjects?.length && query.append( 'subject', encodeURIComponent( subjects.join() ) );
    organizations?.length && query.append( 'organization', encodeURIComponent( organizations.join() ) );
    kinds?.length && query.append( 'kind', encodeURIComponent( kinds.join() ) );
    platforms?.length && query.append( 'platform', encodeURIComponent( platforms.join() ) );
    return query.toString();
  }, [ duration, subjects, organizations, kinds, platforms ] )

  const storedData = useMemo( () => {
    const data = localStorage.getItem( `ReputationChart_${ chartQueryString }` );
    return data ? JSON.parse( data ) as BarDatum[] : undefined;
  }, [ chartQueryString ] );

  const chartQuery = useQuery<BarDatum[]>( {
    queryKey: [ 'ReputationChart', { duration, subjects, organizations, kinds, platforms } ],
    queryFn: async () => {
      if( !isFiltered || !dataProvider ) return [];
      const response = await dataProvider.fetchJson( `${ dataProvider.apiUrl }/reputationsubjectreviews/trends?${ chartQueryString }` );
      if( response.status >= 500 ) throw new Error();
      const data = response.json;
      try {
        localStorage.setItem( `ReputationChart_${ chartQueryString }`, JSON.stringify( data ) );
      }
      catch { /* ignore errors setting local storage */ }
      return data;
    },
    placeholderData: storedData,
    retry: 3,
  } );

  const chartData = useMemo<BarDatum[]>(
    () => chartQuery.isSuccess ? chartQuery.data : storedData || [],
    [ chartQuery.data, chartQuery.isSuccess, storedData ]
  );

  const hasData = useMemo<boolean>( () => {
    if( !chartData ) return false;
    return !!chartData.find( datum => {
      for( const key in datum ) {
        if( key == 'id' ) continue;
        if( datum[ key ] ) return true;
      }
      return false;
    } )

  }, [ chartData ] );

  const Message: FC<{ children: ReactNode }> = ( props ) => (
    <Typography
      variant='body1'
      margin='10px'
    >{ props.children }</Typography>
  );
  if( chartQuery.isPending ) return (
    <Box height={ height } marginY={ 1 }>
      <Loading
        loadingPrimary=''
        loadingSecondary=''
        sx={ {
          '@media (min-width: 0)': {
            marginTop: 0,
            height: '100%',
          }
        } }
      />
    </Box>
  );
  if( !isFiltered ) return <Box height={ height } marginY={ 1 }><Message>Unable to display chart.</Message></Box>;
  if( !chartData.length ) return <Box height={ height } marginY={ 1 }><Message>No data available for selected options.</Message></Box>;

  const legend: BarLegendProps = {
    anchor: 'top',
    direction: 'row',
    justify: false,
    translateY: -40,
    itemWidth: 80,
    itemHeight: 20,
    itemsSpacing: 0,
    dataFrom: 'keys',
  };

  return (
    <Box
      height={ height }
      marginY={ 1 }
    >
      <ResponsiveBar
        data={ chartData }
        indexBy='id'
        keys={ [ '1 star', '2 stars', '3 stars', '4 stars', '5 stars' ] }

        margin={ isSmall ?
          { bottom: 30 } :
          { top: 50, right: 20, bottom: 60, left: 65 }
        }

        theme={ {
          background: theme.palette?.background?.default,
          axis: {
            legend: { text: { fontSize: isSmall ? 16 : 18 } },
            ticks: { text: { fontSize: isSmall ? 0 : 14 } },
          },
          labels: { text: { fill: '#333' } },
          legends: { text: { fontSize: 14 } },
          text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
          tooltip: {
            container: {
              backgroundColor: theme.palette?.background?.default,
              color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
            },
          },
        } }

        groupMode='stacked'
        padding={ 0.25 }

        minValue={ 0 }
        maxValue={ hasData ? 'auto' : 5 }

        colors={ { scheme: 'red_yellow_green', size: 5 } }

        enableLabel={ false }

        axisLeft={ isSmall ? null : { legend: 'New Ratings Count', tickSize: 5, tickPadding: 5, tickValues: 5, legendOffset: -45, legendPosition: 'middle' } }
        axisBottom={ {
          legend: title || defaultTitle,
          legendPosition: 'middle',
          legendOffset: isSmall ? 20 : 40,
          tickRotation: isSmall ? -60 : 0,
          tickSize: isSmall ? 0 : 5,
        } }

        legends={ isSmall ? undefined : [ legend ] }
        isInteractive={ !isSmall }
      />
    </Box>
  );
};
