import { Box, Card, CardContent, Tab, Tabs } from '@mui/material';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Title } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppointmentList } from './Appointments';
import { AppointmentTypeList } from './AppointmentTypes';
import { ResourceContextProvider } from './ResourceContextProvider';

export const Appointment: FC = () => {
  const basePath = 'appointment';
  const tabs = [ 'appointments', 'appointmenttypes' ];
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [ _location, setLocation ] = useAppLocationState();
  const [ tab, setTab ] = useState( tabs[ 0 ] );

  const handleChange = ( _event: SyntheticEvent, newValue: string ) => {
    navigate( `/${ basePath }/${ newValue }` );
  };

  useEffect( () => {
    setLocation( basePath );
    return () => setLocation( null );
  }, [] );

  useEffect( () => {
    const [ , , tab ] = pathname.split( '/' )
    if( !tabs.includes( tab ) ) return;
    setTab( tab );
  }, [ pathname ] );

  useEffect( () => {
    setLocation( `${ basePath }.${ tab }` );
  }, [ tab ] );

  return (
    <Box>
      <Title title='Appointment ' />
      <Tabs value={ tab } onChange={ handleChange } >
        <Tab disableRipple value='appointments' label='Appointments' />
        <Tab disableRipple value='appointmenttypes' label='Types' />
      </Tabs>

      <Card
        sx={ {
          minHeight: '25em',
        } }
      >
        <CardContent>
          <ResourceContextProvider value={ tab }>
            { tab == 'appointmenttypes'
              ? <AppointmentTypeList title={ <></> } />
              : <AppointmentList title={ <></> } />
            }
          </ResourceContextProvider>
        </CardContent>
      </Card>


    </Box >
  );
}
