import { Checkbox, CheckboxProps, FormControlLabel, FormGroup, FormGroupProps, FormHelperText } from '@mui/material';
import clsx from 'clsx';
import { ChangeEvent, useCallback } from 'react';
import { CommonInputProps, FieldTitle, InputHelperText, sanitizeInputRestProps, useInput } from 'react-admin';

export type BooleanCheckboxInputProps = CommonInputProps &
  CheckboxProps &
  Omit<FormGroupProps, 'defaultValue' | 'onChange' | 'onBlur' | 'onFocus'> & {
    options?: CheckboxProps;
  };

export const BooleanCheckboxInput = ( props: BooleanCheckboxInputProps ) => {
  const {
    className,
    row = false,
    defaultValue = false,
    format,
    label,
    fullWidth,
    helperText,
    onBlur,
    onChange,
    onFocus,
    disabled,
    parse,
    resource,
    source,
    validate,
    options = {},
    sx,
    ...rest
  } = props;
  const {
    id,
    field,
    isRequired,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
  } = useInput( {
    defaultValue,
    format,
    parse,
    resource,
    source,
    onBlur,
    onChange,
    type: 'checkbox',
    validate,
    ...rest,
  } );

  // @ts-ignore:7006
  const handleChange = useCallback<ChangeEvent<HTMLInputElement>>( event => {
    field.onChange( event );
    // Ensure field is considered as touched
    field.onBlur();
  }, [ field ] );

  return (
    <FormGroup
      className={ clsx( 'ra-input', `ra-input-${ source }`, className ) }
      row={ row }
      sx={ sx }
    >
      <FormControlLabel
        control={
          <Checkbox
            id={ id }
            name={ field.name }
            color="primary"
            onChange={ handleChange }
            onFocus={ onFocus }
            checked={ field.value }
            { ...sanitizeInputRestProps( rest ) }
            { ...options }
            disabled={ disabled }
          />
        }
        label={
          <FieldTitle
            label={ label }
            source={ source }
            resource={ resource }
            isRequired={ isRequired }
          />
        }
      />
      <FormHelperText error={ ( isTouched || isSubmitted ) && invalid }>
        <InputHelperText
          // touched={ isTouched || isSubmitted }
          error={ error?.message }
          helperText={ helperText }
        />
      </FormHelperText>
    </FormGroup>
  );
};

