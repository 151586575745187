import { Box, Card, CardContent, Tab, Tabs } from '@mui/material';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { useCanAccess } from '@react-admin/ra-rbac';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Title } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import { FeedbackMessageList } from './FeedbackMessages';
import { ReputationClickList } from './ReputationClicks';
import { ReputationReferralList } from './ReputationReferrals';
import { ReputationResponseTemplateList } from './ReputationResponseTemplates';
import { ReputationSubjectReviewList } from './ReputationSubjectReviews';
import { ResourceContextProvider } from './ResourceContextProvider';

// const ReputationExplorer: FC<Childless<ListProps>> = () => (
//   <Button variant='contained' sx={ { margin: 16 } }>
//     <Link underline='none' href="https://reputation.analoginformation.com/#/demo" target="_blank" rel="noopener" color="inherit" >
//       Reputation Explorer
//     </Link>
//   </Button>
// );

export const Reviews: FC = () => {
  const basePath = 'reviews';
  const tabs = [ 'reputationreviews', 'reputationresponsetemplates', 'reputationfeedbacks', 'reputationviews', 'reputationclicks' ];
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [ _location, setLocation ] = useAppLocationState();
  const [ tab, setTab ] = useState( tabs[ 0 ] );
  const { canAccess: _isAdmin, isLoading } = useCanAccess( { resource: 'system', action: 'manage' } );

  const handleChange = ( _event: SyntheticEvent, newValue: string ) => {
    navigate( `/${ basePath }/${ newValue }` );
  };

  useEffect( () => {
    setLocation( basePath );
    return () => setLocation( null );
  }, [] );

  useEffect( () => {
    const [ , , tab ] = pathname.split( '/' )
    if( !tabs.includes( tab ) ) return;
    setTab( tab );
  }, [ pathname ] );

  useEffect( () => {
    setLocation( `${ basePath }.${ tab }` );
  }, [ tab ] );

  if( isLoading ) return null;

  return (
    <Box>
      <Title title='Reviews' />
      <Tabs value={ tab } onChange={ handleChange }>
        <Tab disableRipple value='reputationreviews' label='Online' />
        <Tab disableRipple value='reputationresponsetemplates' label='Templates' />
        <Tab disableRipple value='reputationfeedbacks' label='Internal' />
        <Tab disableRipple value='reputationviews' label='Views' />
        <Tab disableRipple value='reputationclicks' label='Clicks' />
      </Tabs>

      <Card
        sx={ {
          minHeight: '25em',
        } }
      >
        <CardContent>
          <ResourceContextProvider value={ tab }>
            { tab == 'reputationviews'
              ? <ReputationReferralList title={ <></> } resource='reputationreferrals' />
              : tab == 'reputationclicks'
                ? <ReputationClickList title={ <></> } resource='reputationclicks' />
                : tab == 'reputationfeedbacks'
                  ? <FeedbackMessageList title={ <></> } resource='feedbackmessages' />
                  : tab == 'reputationresponsetemplates'
                    ? <ReputationResponseTemplateList title={ <></> } resource='reputationresponsetemplates' />
                    : <ReputationSubjectReviewList title={ <></> } resource='reputationsubjectreviews' />
            }
          </ResourceContextProvider>
        </CardContent>
      </Card>

    </Box >
  );
}
