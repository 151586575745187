import { Chip, Grid, TextField as MuiTextField } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

export interface EmailsInputProps {
  value: string[];
  onChange: ( value: string[] ) => unknown;
  setValidity?: ( value: boolean ) => unknown;
  tabIndex?: number;
}

export const EmailsInput: FC<EmailsInputProps> = ( props ) => {
  const { value, onChange, setValidity } = props;
  const [ input, setInput ] = useState( '' );
  const [ previousInput, setPreviousInput ] = useState( '' );

  const parsedInput = useMemo( () => {
    return input.trim().replace( /[,;]$/, '' );
  }, [ input ] );

  const isValid = useMemo( () => {
    if( !input ) return true;
    // https://stackoverflow.com/a/46181
    const validEmailMatcher = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return parsedInput.toLowerCase().match( validEmailMatcher ) ? true : false;
  }, [ input, parsedInput ] );
  useEffect( () => { setValidity && setValidity( isValid ) }, [ isValid, setValidity ] );

  const updateValue = useCallback( () => {
    if( !parsedInput ) return;
    setPreviousInput( '' );
    setInput( '' );
    onChange( value.concat( parsedInput ) );
  }, [ parsedInput, value ] );

  const handleKeypress: React.KeyboardEventHandler = useCallback( ( event ) => {
    if( 'Backspace' == event.key && !input ) {
      if( previousInput ) {
        setPreviousInput( '' )
        return;
      }
      setInput( value.pop() ?? '' );
      onChange( value ); // update state without the value that was just popped
      return;
    }
    if( ![ ' ', ',', ';' ].includes( event.key ) ) return;
    if( isValid ) updateValue();
  }, [ input, isValid, previousInput, setInput, updateValue ] );

  const removeValueAtIndex = useCallback( ( index: number ) => {
    onChange( value.filter( ( _v, i ) => i != index ) )
  }, [ onChange, value ] );

  return (
    <>
      <Grid container sx={ { padding: '2px' } }>
        { value.map( ( email, index ) => (
          <Grid key={ email } item sx={ { margin: '2px', position: 'relative' } }>
            <Chip
              label={ email }
              onClick={ () => { setInput( email ); removeValueAtIndex( index ); } }
              onDelete={ () => removeValueAtIndex( index ) }
            />
          </Grid>
        ) ) }

      </Grid>
      <MuiTextField
        autoFocus
        margin="dense"
        label="Email address"
        type="email"
        fullWidth
        variant="standard"
        value={ input }
        onChange={ e => { setPreviousInput( input ); setInput( e.target.value ) } }
        onKeyUp={ handleKeypress }
        onBlur={ () => { if( isValid ) updateValue() } }
        error={ !isValid }
        helperText={ !isValid && 'Please include a valid email address.' }
        tabIndex={ props.tabIndex }
      />
    </>
  );
}

