/*eslint @typescript-eslint/no-unused-vars: "warn"*/
import { Add as ContentAdd, Create as ContentCreate } from '@mui/icons-material';
import { Box, Card, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Viewer as MarkdownViewer } from '@toast-ui/react-editor';
import { pick } from 'lodash';
import { FC, ReactElement, useMemo } from 'react';
import { ArrayField, Button, DeleteButton, EditView, FunctionField, Identifier, Loading, RaRecord, ShowProps, ShowView, SingleFieldList, TextField, Toolbar, useGetList, useRecordContext, useResourceContext } from 'react-admin';
import { Link as RRLink, useLocation } from 'react-router-dom';
import { AmenityChoice, amenityChoices, assetChoices, practitionerChoices } from './AssetInput';
import { aspectRatioString } from './canvasUtils';
import { buildImageUrl } from './DataProvider';
import { defaultAspect } from './ImageCropper';

export type ShowViewProps = Parameters<typeof ShowView>[ 0 ];
export type EditViewProps = Parameters<typeof EditView>[ 0 ];

export const getResourceTagId = ( resource?: string ): string => {
  switch( resource ) {
    case 'organizations': return '61aa9c9106d49c894f38fa93';
    case 'locations': return '61aa9c0c06d49c894f38fa4c';
    case 'practitioners': return '628fd3fbf2d0f8ad67f9e0fd';
  }
  return '';
}

export interface PractitionerShowAssetsProps extends ShowProps {
  imageAspect?: number;
}

export const PractitionerShowAssets: FC<PractitionerShowAssetsProps> = props => {
  return (
    <ResourceShowAssets { ...props } choices={ practitionerChoices } basePath='organization.practitioners.show.profile' />
  );
}

export const LocationShowAssets: FC = () => {
  return (
    <ResourceShowAssets choices={ amenityChoices } basePath='organization.locations.show.amenities' />
  );
}

export const LocationShowProfileAssets: FC = () => {
  return (
    <ResourceShowAssets choices={ assetChoices.slice( 0, 2 ) } basePath='organization.locations.show.assets' />
  );
}

export const OrganizationShowAssets: FC = () => {
  return (
    <ResourceShowAssets choices={ assetChoices.slice( 0, 2 ) } basePath='organization.organizations.show.assets' />
  );
}

export interface ResourceShowAssetsProps extends Omit<ShowProps, 'children'> {
  choices: AmenityChoice[];
  imageAspect?: number;
  basePath?: string;
}


export const ResourceShowAssets: FC<ResourceShowAssetsProps> = props => {
  const { choices: resourceChoices, imageAspect, basePath, ...rest } = props;
  const theme = useTheme();
  const resource = useResourceContext();
  const record = useRecordContext( rest );
  const { pathname } = useLocation();

  const filter = { tags: record?.id };
  const { data: assets, isLoading } = useGetList( 'assets', { filter }, { enabled: !!filter.tags } );

  const getImageAspect = ( tagId?: string ): number => {
    return imageAspect ?? resourceChoices.find( c => c.id == tagId )?.imageAspect ?? defaultAspect;
  }

  const getImageMultiple = ( tagId?: string ): boolean => {
    return resourceChoices.find( c => c.id == tagId )?.imageMultiple ?? false;
  }

  const choices = useMemo( () => {
    if( !assets ) return;
    return resourceChoices
      .map( c => pick( c, [ 'id', 'name' ] ) )
      .map( choice => {
        const asset = assets.find( asset => asset.tags.includes( choice.id ) );
        return { name: choice.name, tagId: choice.id, ...asset };
      } );
  }, [ resourceChoices, assets ] );

  const CreateAssetButton: FC<{ r: RaRecord }> = ( { r } ) => {
    if( !record ) return null;

    return (
      <Button
        label="Add"
        component={ RRLink }
        to={ {
          pathname: '/assets/create',
          state: {
            record: {
              subject: r.name,
              tags: [ getResourceTagId( resource ), record.id, r.tagId ],
              body: typeof record.name == 'string' ? record.name || record.fullName : record.fullName,
            },
            hideTags: true,
            multiple: getImageMultiple( r.tagId ),
            imageAspect: getImageAspect( r.tagId ),
            returnTo: pathname,
            parentResource: resource,
            parent: record,
            basePath,
          },
        } }
      >
        <ContentAdd />
      </Button>
    );
  }

  const EditAssetButton: FC<{ r: RaRecord }> = ( { r } ) => (
    <Button
      label="Edit"
      component={ RRLink }
      to={ {
        pathname: `/assets/${ r.id }`,
        state: {
          hideTags: true,
          multiple: getImageMultiple( r.tagId ),
          imageAspect: getImageAspect( r.tagId ),
          returnTo: pathname,
          parentResource: resource,
          parent: record,
          basePath,
        },
      } }
    >
      <ContentCreate />
    </Button>
  );

  const DeleteAssetButton: FC<{ r: RaRecord }> = ( { r } ) => (
    <DeleteButton
      record={ r }
      redirect={ pathname }
      resource='assets'
      mutationMode='pessimistic'
      style={ { marginLeft: 'auto' } }
    />
  );


  const renderCard = ( r: RaRecord & Record<string, unknown> ) => {
    if( !r ) return null;

    if( !r.id ) return (
      <AssetShowCard
        toolbar={
          <Toolbar >
            <CreateAssetButton { ...{ r } } />
          </Toolbar>
        }>
        <Typography>{ r?.name }</Typography>
      </AssetShowCard>
    );

    return (
      <AssetShowCard
        toolbar={
          <Toolbar >
            <EditAssetButton { ...{ r } } />
            <DeleteAssetButton { ...{ r } } />
          </Toolbar>
        }>
        { r.subject && <TextField source="subject" style={ { fontWeight: 'bold' } } /> }
        { r.subject && <Divider
          sx={ {
            marginBottom: theme.spacing( 2 ),
          } }
        /> }
        <FunctionField label="" render={ ( r ) => {
          const data = r?.images.map( ( i: Identifier ) => ( {
            title: r?.subject || 'image',
            url: buildImageUrl( i ) + `?t=${ new Date( r.updatedAt ).getTime().toString() }${ Math.random().toString().slice( -5 ) }`
          } ) );
          return (
            <Box
              sx={ {
                '& div': {
                  display: 'inline-block',
                  paddingRight: '0.5rem',
                  paddingBottom: '0.5rem',
                  '& img': {
                    height: '16rem',
                    // width: ( 16 * getImageAspect( r?.tagId ) ).toFixed( 2 ) + 'rem',
                    verticalAlign: 'top',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: theme.palette.mode == 'dark' ? theme.palette.grey[ 700 ] : theme.palette.grey[ 300 ],
                    '&:hover': {
                      borderColor: theme.palette.mode == 'dark' ? theme.palette.grey[ 500 ] : theme.palette.grey[ 500 ],
                    },
                  },
                },
              } }>
              { data.map( ( item: { title: string, url: string } ) => (
                <Box key={ item.url }>
                  <img
                    src={ item.url }
                    alt={ item.title }
                    title={ 'Aspect ratio ' + aspectRatioString( getImageAspect( r?.tagId ) ) }

                  />
                </Box>
              ) ) }
            </Box>
          );
        } } />
        <Box
          // className={ classes.viewer }
          sx={ {
            '& .tui-editor-contents': {
              fontSize: '1.0rem',
              '& p,  ul,  menu,  ol,  dir':
              {
                color: theme.palette.text.primary,
              },
              '&  pre': {
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
              },
              '&  a': {
                color:
                  theme.palette.mode === 'light'
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light,
              },
            },
          } }
        >
          <MarkdownViewer initialValue={ r.body } />
        </Box>
      </AssetShowCard>
    );
  }

  if( isLoading ) return <Loading />;

  return (
    <Box >
      <ArrayField source="data" record={ { data: choices, id: 'assets' } } >
        <SingleFieldList
          //classes={ { root: classes.root } }
          sx={ {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: theme.spacing( 2 ),
          } }
          linkType={ false } >
          <FunctionField fullWidth source="type"
            render={ renderCard }
            sx={ { width: '100%' } } />
        </SingleFieldList>
      </ArrayField>
    </Box>
  );
}

export interface AssetShowCardProps {
  children: ReactElement | ReactElement[];
  toolbar?: ReactElement;
}

export const AssetShowCard: FC<AssetShowCardProps> = props => {
  const { children, toolbar } = props;

  return (
    <Card style={ { marginBottom: '16px' } }>
      <Box style={ { padding: '16px' } }>
        { children }
      </Box>
      { toolbar }
    </Card>

  );
}

