import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { Dispatch, FC, SetStateAction } from 'react';


export interface DashboardHistoricalDatePickerProps {
  date: Dayjs | null;
  setDate: Dispatch<SetStateAction<Dayjs | null>>;
}

export const DashboardHistoricalDatePicker: FC<DashboardHistoricalDatePickerProps> = ( { date, setDate } ) => {

  return (
    <LocalizationProvider dateAdapter={ AdapterDayjs }>
      <DatePicker
        label=""
        value={ date }
        views={ [ 'year', 'month', 'day' ] }
        onChange={ ( d ) => !d || d.isSame( new Date(), 'day' ) ? setDate( null ) : setDate( d ) }
        disableFuture
        slotProps={ {
          actionBar: ( { wrapperVariant } ) => ( {
            actions: wrapperVariant === 'desktop' && date ? [ 'clear' ] : [],
          } ),
          textField: {
            InputProps: {
              placeholder: 'Historical date',
              sx: {
                '&:before': {
                  border: '0px solid transparent',
                },
                backgroundColor: 'transparent',
                borderBottomColor: 'transparent',
                '& .MuiInputBase-input': {
                  textAlign: 'end',
                }
              }
            },
            fullWidth: false,
            size: 'small',
            color: 'primary',
            margin: 'none',
            hiddenLabel: true,
            sx: {
              border: '0px solid transparent',

            },

          }
        } }

      />
    </LocalizationProvider>
  );
}
