import { Box, Card, CardContent, Tab, Tabs } from '@mui/material';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Title } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import { EmrEventTypeList } from './EmrEventTypes';
import { PatientList } from './Patients';
import { ResourceContextProvider } from './ResourceContextProvider';

export const Patient: FC = () => {
  const basePath = 'patient';
  const tabs = [ 'patients', 'emreventtypes' ];
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [ _location, setLocation ] = useAppLocationState();
  const [ tab, setTab ] = useState( tabs[ 0 ] );

  const handleChange = ( _event: SyntheticEvent, newValue: string ) => {
    navigate( `/${ basePath }/${ newValue }` );
  };

  useEffect( () => {
    setLocation( basePath );
    return () => setLocation( null );
  }, [] );

  useEffect( () => {
    const [ , , tab ] = pathname.split( '/' )
    if( !tabs.includes( tab ) ) return;
    setTab( tab );
  }, [ pathname ] );

  useEffect( () => {
    setLocation( `${ basePath }.${ tab }` );
  }, [ tab ] );

  return (
    <Box>
      <Title title='Patient' />
      <Tabs value={ tab } onChange={ handleChange } >
        <Tab disableRipple value='patients' label='Patients' />
        <Tab disableRipple value='emreventtypes' label='Event Setup' />
      </Tabs>

      <Card
        sx={ {
          minHeight: '25em',
        } }
      >
        <CardContent>
          <ResourceContextProvider value={ tab }>
            { tab == 'emreventtypes'
              ? <EmrEventTypeList title={ <></> } resource='emreventtypes' />
              : <PatientList title={ <></> } resource='recipients' />
            }
          </ResourceContextProvider>
        </CardContent>
      </Card>


    </Box >
  );
}
