import { cloneElement, FC, ReactElement, useMemo } from 'react';
import { ShowActions, ShowActionsProps, TopToolbar } from 'react-admin';
import { useLocation } from 'react-router-dom';

export interface ShowActionsOnShowTabOnlyProps extends ShowActionsProps {
  actions?: ReactElement;
}

export const ShowActionsOnShowTabOnly: FC<ShowActionsOnShowTabOnlyProps> = props => {
  const { actions = <ShowActions />, ...rest } = props;
  const { pathname } = useLocation();
  const isEditEnabled = useMemo( () => {
    return pathname.slice( -5 ) == '/show';
  }, [ pathname ] );

  if( isEditEnabled ) return cloneElement( actions, { ...rest } );
  return <TopToolbar></TopToolbar>;
}

