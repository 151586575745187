import { Box, InputAdornment, ModalProps, Popover, TextField as MuiTextField } from '@mui/material';
import { FC, FocusEventHandler, useCallback, useState } from 'react';
import { FieldProps, FieldTitle, InputProps, useInput } from 'react-admin';
import { ColorChangeHandler, SketchPicker } from 'react-color';

export type ColorFieldProps = FieldProps

export const ColorField: FC<ColorFieldProps> = props => {
  const { record, source } = props;

  if( !record || !source || !record[ source ] ) return null;

  return (
    <Box style={ { display: 'flex' } }>
      <Box mr={ 1 } style={ { display: 'inline-block', backgroundColor: record[ source ], alignSelf: 'center', height: '1.1em', width: '1.1em' } } />
      <span>{ record[ source ] }</span>
    </Box>
  );
}



export const ColorInput: FC<InputProps> = props => {
  // const classes = useStyles();
  const [ anchorEl, setAnchorEl ] = useState<Element | null>( null );
  const {
    // options,
    label,
    source,
    resource,
    // className,
    defaultValue,
    onChange,
    isRequired,
    // disabled,
    format,
    parse,
  } = props;

  const { field, fieldState } = useInput( { source, format, parse, onChange, defaultValue } );
  const { isTouched, error } = fieldState;

  const onFocus: FocusEventHandler = useCallback( ( e ) => {
    // const { name, value } = e.currentTarget as HTMLInputElement;
    // console.log( 'onFocus', name, value );
    field.ref( { focus: ( e: HTMLInputElement ) => { e.focus() } } );
    setAnchorEl( e.currentTarget );
  }, [ anchorEl, setAnchorEl, field ] );

  const handleClose = useCallback<NonNullable<ModalProps[ 'onClose' ]>>( ( _e, _reason ) => {
    // const { name, value } = anchorEl as HTMLInputElement;
    setAnchorEl( null );
    // console.log( 'handleClose', reason, name, value );
  }, [ anchorEl, setAnchorEl, field ] );

  const open = Boolean( anchorEl );
  const id = open ? `${ source }-popover` : undefined;

  const onChangeComplete: ColorChangeHandler = useCallback( ( color, _e ) => {
    // const { name, value } = anchorEl as HTMLInputElement;
    // console.log( 'onChange', name, value, 'to', color.hex );
    field.onChange( color.hex )
  }, [ anchorEl, field ] );

  // @ts-ignore: 2339
  const presetColors = [ '#22B4CC', '#1F3E60', ...SketchPicker.defaultProps.presetColors ]; // SummitMD colors

  return (
    <div style={ { marginBottom: '2em' } } >
      <MuiTextField
        { ...field }
        variant="filled"
        onFocus={ onFocus }
        label={
          <FieldTitle label={ label } source={ source } resource={ resource } isRequired={ isRequired } />
        }
        error={ isTouched && !!error }
        helperText={ isTouched && error ? error.message : undefined }
        // className={ className }
        InputProps={ {
          startAdornment: (
            <InputAdornment position='start' >
              <Box style={ { backgroundColor: field.value, alignSelf: 'center', height: '1.1em', width: '1.1em' } } />
            </InputAdornment>
          ),
        } }
      />
      <Popover
        id={ id }
        open={ open }
        anchorEl={ anchorEl }
        disableRestoreFocus
        onClose={ handleClose }
        anchorOrigin={ {
          vertical: 'bottom',
          horizontal: 'left',
        } }
        transformOrigin={ {
          vertical: 'top',
          horizontal: 'left',
        } }
      >

        { /* @ts-ignore 2786 */ }
        <SketchPicker
          color={ field.value }
          onChangeComplete={ onChangeComplete }
          presetColors={ presetColors }
        />
      </Popover>
    </div >
  );

}


