import { SsidChart as LineChartIcon, TableChartOutlined as TableIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, IconButton as MuiIconButton, Typography, useTheme } from '@mui/material';
import { BarDatum } from '@nivo/bar';
import { Datum, Line, LineProps, Serie } from '@nivo/line';
import * as dateFns from 'date-fns';
import { chunk, sumBy } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { Loading } from 'react-admin';
import { makeLineChartSliceTooltip, makeLineChartTooltip, MonthlyLineChartSliceTooltipHeader } from './DashboardTooltip';
import { ChartProps, elevation, OnlyNumbers } from './DashboardWidget';
import { useUserPreference } from './UserPreferences';
import useColorPalettes from './use-color-palettes';
import { useOrgDateMarkers } from './use-markers';
import { useTrendPredictions } from './use-predictions';
import useSummary from './use-summary';
import { DashboardDatagrid } from './DashboardDatagrid';

interface ReputationTrendDatum extends BarDatum {
  date: string;
  md: string;
  ymd: string;
  Clicks: number;
  Views: number;
  Blocks: number;
  Reviews: number;
}
export interface ReputationTrendLineDatum extends Datum {
  y: number | null;
  ymd: string;
  md: string;
}
export interface ReputationTrendLinePoint extends Serie {
  data: ReputationTrendLineDatum[];
}

export type LineLegendProps = NonNullable<LineProps[ 'legends' ]>[ 0 ];

export const DashboardReputationTrend: FC<ChartProps> = ( { date } ) => {
  const theme = useTheme();
  const isSmall = false;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const defaultDurationMonths = 6;
  const legendCols = 2;
  const cacheKey = 'dashboard-reputation-trend';
  const [ showDatagrid, setShowDatagrid ] = useState( false );
  const keys: ( keyof OnlyNumbers<ReputationTrendDatum> )[] = [ 'Views', 'Clicks', 'Blocks', 'Reviews' ];

  const durationMonths = useMemo( () => {
    if( isLoadingPreferences || !preferences?.dashboardTrendMonths ) return defaultDurationMonths;
    return preferences.dashboardTrendMonths as number;
  }, [ preferences, isLoadingPreferences ] );

  // const yMin = useMemo( () => Math.min( ...[ yMax * 0.9, ...data.flatMap( row => row.data.map( d => d.y || 0 ) ) ] ), [ data ] );

  const summary = useSummary<ReputationTrendDatum[]>( 'reputationTrend', { date, months: durationMonths } );

  const data = useMemo( (): ReputationTrendLinePoint[] => keys.map( id => ( {
    id, data: summary.data?.map( d => {
      const { ymd, md, date } = d;
      const y = d[ id ] >= 1 ? d[ id ] : null; // no zeros for log scale
      // const y = d[ id ];
      return { x: ymd, y, ymd, md, date };
    } )
      .filter( d => !!d.y )
      .sort( ( a, b ) => a.ymd < b.ymd ? -1 : 1 ) || [],
  } ) ), [ date, keys, summary.data ] );

  const { predictedData, layers } = useTrendPredictions( data, date );

  const isEmpty = useMemo( () => sumBy( data, d => d.data.length ) === 0, [ data ] );
  const counts = useMemo( () => (
    Object.fromEntries( ( data || [] ).map( ( { id, data = [] } ) => [ id, data.reduce( ( tot, d ) => ( d.y || 0 ) + tot, 0 ) ] ) )
  ), [ data ] );
  const yMax = useMemo( () => Math.max( ...[ 10, ...( predictedData ?? data ).flatMap( row => row.data.map( d => d.y || 0 ) ) ] ), [ data, predictedData ] );

  const [ hiddenSeries, setHiddenSeries ] = useState<string[]>( [] );
  const handleLegendClick = useCallback( ( datum: Datum ) => {
    const id = datum.id;
    const idx = hiddenSeries.indexOf( id );
    if( idx < 0 ) {
      setHiddenSeries( [ ...hiddenSeries, id ] );
      return;
    }
    const newValue = [ ...hiddenSeries ];
    newValue.splice( idx, 1 );
    setHiddenSeries( newValue );
  }, [ hiddenSeries, setHiddenSeries ] );

  const colorPalettes = useColorPalettes();
  const colors = useMemo( () => [
    colorPalettes.discretePrimary[ 4 ], // blue
    colorPalettes.discretePrimary[ 5 ], // purple
    colorPalettes.discretePrimary[ 2 ], // red
    colorPalettes.discretePrimary[ 6 ], // green
  ], [ colorPalettes, keys ] );

  const markers = useOrgDateMarkers( { date, duration: { type: 'months', count: durationMonths } } );

  return (
    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={
          <Box sx={ { display: 'flex' } } >
            <Typography>Reputation Trend</Typography>
            <Box sx={ { flexGrow: 1 } } />
            <MuiIconButton
              sx={ {
                // paddingTop: 0, paddingBottom: 0,
                marginTop: '-8px',
                marginBottom: '-8px',
              } }
              onClick={ () => setShowDatagrid( !showDatagrid ) }
            >
              { showDatagrid ? <LineChartIcon /> : <TableIcon /> }
            </MuiIconButton>
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `${ durationMonths } Months Ending ${ date.format( 'MMM YYYY' ) }` : `Previous ${ durationMonths } Months` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
            // fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        { summary.isLoading
          ? <Box>
            <Loading
              loadingPrimary=''
              loadingSecondary=''
              sx={ {
                '@media (min-width: 0)': {
                  marginTop: 0,
                  marginBottom: 7,
                  height: '100%',
                  width: 500,
                  minHeight: 400,
                }
              } }
            />
          </Box>

          :
          <Box
            sx={ {
              position: 'relative',
            } }
          >
            { showDatagrid
              ? (
                <DashboardDatagrid
                  rows={ ( summary.data || [] ).map( ( d, id ) => ( { id, ...d } ) ) }
                  columns={ keys }
                  exportFileName={ cacheKey }
                />
              )
              : <>

                <Line
                  width={ 500 }
                  height={ 400 }
                  curve='monotoneX'
                  data={ isEmpty ? [] : predictedData ?? data }
                  margin={ { top: 60, right: 50, bottom: 55, left: 75 } }
                  gridYValues={ 4 }

                  isInteractive={ !isEmpty }
                  useMesh={ !isEmpty }
                  enableCrosshair
                  // enableArea
                  areaOpacity={ 0.1 }
                  lineWidth={ 3 }
                  pointSize={ 6 }

                  xFormat="time:%Y-%m"
                  xScale={ {
                    type: 'time',
                    format: '%Y-%m-%d',
                    precision: 'month',
                    useUTC: false,
                    max: date ? date.toDate() : new Date(),
                    min: date ? dateFns.addMonths( date.toDate(), 0 - durationMonths ) : dateFns.addMonths( new Date(), 0 - durationMonths ),
                  } }

                  yScale={ {
                    type: 'log',
                    base: 2,
                    max: yMax * 1.5,
                    min: 1,
                  } }

                  enableSlices='x'
                  sliceTooltip={ makeLineChartSliceTooltip( {
                    theme,
                    renderHeader: MonthlyLineChartSliceTooltipHeader
                  } ) }

                  tooltip={ makeLineChartTooltip( theme ) }

                  layers={ layers }

                  theme={ {
                    background: theme.palette?.background?.default,
                    axis: {
                      legend: { text: { fontSize: isSmall ? 16 : 18 } },
                      ticks: { text: { fontSize: isSmall ? 0 : 14 } },
                    },
                    labels: { text: { fill: '#333' } },
                    legends: { text: { fontSize: 14 } },
                    text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
                    tooltip: {
                      container: {
                        borderRadius: '8px',
                        border: `0.5px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
                        boxShadow: `${ theme.palette?.grey?.A400 ?? 'grey' } 0px 2px 2px`,
                        backgroundColor: theme.palette?.background?.default,
                        color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
                        padding: '5px 9px',
                      },
                    },
                  } }

                  colors={ isEmpty ? [ theme.palette?.mode === 'dark' ? '#555' : '#ccc' ] : colors } //{ scheme: colors, size: keys.length } }
                  // enableLabel={ false }
                  enableGridX={ false }
                  enableGridY={ true }
                  axisTop={ null }
                  axisRight={ null }
                  axisLeft={ {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: 4, // [ 0, 1000, 2000, 3000 ],
                    legend: 'Daily count (log2)',
                    legendPosition: 'middle',
                    legendOffset: -67

                  } }

                  axisBottom={ {
                    tickSize: 5,
                    // ticksPosition: 'before',
                    tickPadding: 5,
                    format: '%b',
                    tickValues: `every ${ Math.ceil( durationMonths / 9 ) } months`,
                    legend: 'Month',
                    legendPosition: 'middle',
                    legendOffset: 42,
                  } }

                  markers={ markers }

                  // multirow legend (by 2)
                  legends={ chunk( keys, legendCols ).map<LineLegendProps>( ( ch, chIdx, chArr ) => (
                    {
                      data: [
                        ...ch.map( ( id, idx, _arr ) => ( {
                          id,
                          label: `${ id } (${ counts[ id ] || 0 })`,
                          fill: colors[ idx + legendCols * chIdx ],
                          hidden: !!hiddenSeries.includes( `${ id }` ),
                        } ) ),
                        // this works for legendCols 2 but not 3
                        ...( keys.length % legendCols !== 0 && chIdx == chArr.length - 1
                          ? [ { id: 'x', opacity: 0.2, label: '', fill: 'transparent' } ]
                          : []
                        ),
                      ],
                      anchor: 'top-left',
                      direction: 'row',
                      justify: false,
                      translateX: -30,
                      translateY: -60 + chIdx * 22,
                      itemsSpacing: 80,
                      itemWidth: 110, // * ( keys.length % legendCols !== 0 && chIdx == chArr.length - 1 ? legendCols : 1 ),
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 1, // 0.95,
                      onClick: handleLegendClick,
                      symbolSize: 18, // 12,
                      symbolShape: 'circle', // 'square',
                      toggleSerie: true,
                    }
                  ) ) }
                />
              </>
            }
          </Box>
        }
      </CardContent>
    </Card >

  );

}
