import * as React from 'react';
import { SvgIcon as MuiSvgIcon, SvgIconProps, styled } from '@mui/material';

const SvgIcon = styled( MuiSvgIcon, {
  name: 'DoubleChevronRight',
  shouldForwardProp: ( prop ) => prop !== 'fill',
} )<SvgIconProps>( () => ( {
  fill: 'none',
  stroke: 'currentColor',
  // strokeLinecap: 'round',
  // strokeLinejoin: 'round',
  strokeWidth: '2.25px',
} ) );

SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  focusable: 'false',
  'aria-hidden': 'true',
};

export const DoubleChevronRight: React.FunctionComponent<SvgIconProps> = ( props ) => {
  return (
    <SvgIcon { ...props }>
      <path d="M 11 7 l 5 5 -5 5"></path>
      <path d="M 4 7 l 5 5 -5 5"></path>
    </SvgIcon>
  );
};

export default DoubleChevronRight;
